import "./AddDeviceTile.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { MenuDevicePlus } from "@/assets/icons/icons";
import { DeviceTile } from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";

export const AddDeviceTile = () => {
  const { t } = useTranslation("portal");

  return (
    <div id="add-device-wrapper">
      <DeviceTile dataTestid="add-device-tile">
        <Link to="/portal/add-device">
          <div className="add-device-frame">
            <div className="m-auto">
              <h2 className="add-device-title">
                {t("layout.tiles.addDevice.addNewDevice")}
              </h2>
              <div className="flex flex-col items-center">
                <div className="add-device-button">
                  <button>
                    <MenuDevicePlus className="add-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </DeviceTile>
    </div>
  );
};
