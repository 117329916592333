import QRCode from "qrcode.react";

import { LocalLoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";

export const TwoFactorChangeAuthQRCode = ({
  qrCodeData,
}: {
  qrCodeData: string | undefined;
}) => {
  if (!qrCodeData)
    return (
      <div className="h-full w-full flex justify-center items-center">
        <LocalLoadingSpinner />
      </div>
    );

  return (
    <div className="flex flex-col items-center">
      <QRCode value={qrCodeData} size={180} />
    </div>
  );
};
