import { useFormContext } from "react-hook-form";

import SelectedCheckbox from "@/assets/ux/SelectedCheckbox.svg?react";
import UnselectedCheckbox from "@/assets/ux/UnselectedCheckbox.svg?react";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { BillListBillIdForm } from "@/components/Portal/Bills/BillList";

type BillListCheckboxProps = {
  id: number;
};

export const BillListCheckbox = ({ id }: BillListCheckboxProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<BillListBillIdForm>();
  return (
    <Checkbox<BillListBillIdForm>
      id={`bill-list-checkbox-${id}`}
      value={id}
      // @ts-ignore - This works, as it indexes the object in the form
      name={`billIds.${id}`}
      errors={errors}
      register={register}
      selectedIcon={<SelectedCheckbox />}
      unselectedIcon={<UnselectedCheckbox />}
    />
  );
};
