import clsx from "clsx";
import { useAtom } from "jotai";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { useGetAccountContact } from "@/services/api";
import { userAccountStateAtom } from "@/utils/atoms";

import { BillingInfoForm } from "./BillingInfoForm/BillingInfoForm";

export const BillingInfo: FC = () => {
  const { data: billingInfo } = useGetAccountContact();
  const { t } = useTranslation();
  const [userAccountState, setUserAccountState] = useAtom(userAccountStateAtom);

  const editingPayment = userAccountState.editingPayment;
  const editingBillingInfo = userAccountState.editingBillingInfo;

  const setEditingBillingInfo = (isEditing: boolean) => {
    setUserAccountState((prev) => ({
      ...prev,
      editingBillingInfo: isEditing,
    }));
  };

  const FieldInfo = ({
    label,
    content,
  }: {
    label: string;
    content?: string;
  }) => (
    <>
      <p className="text-base font-semibold">{label}</p>
      <p className="font-x-small mb-6">
        {content
          ? content
          : t("portal:user-account.paymentMethods.billingForm.notSpecified")}
      </p>
    </>
  );

  return (
    <div data-testid="billing-info">
      <SectionButtonHeader
        className={"font-semibold text-xl-header text-secondary-100 mb-6"}
        header={t("portal:user-account.paymentMethods.billingForm.headline")}
      >
        <div className="selection-row">
          {!editingBillingInfo && !editingPayment && (
            <button
              className={clsx("change-button", !billingInfo && "opacity-50")}
              data-testid="billing-info-change-button"
              onClick={() => {
                setEditingBillingInfo(true);
              }}
              disabled={!billingInfo}
            >
              {t(`portal:user-account.paymentMethods.change`)}
            </button>
          )}
        </div>
      </SectionButtonHeader>
      {!editingPayment &&
        (editingBillingInfo ? (
          billingInfo && <BillingInfoForm billingInfo={billingInfo} />
        ) : (
          <>
            <FieldInfo
              label={t(
                "portal:user-account.paymentMethods.billingForm.emailAddress.label",
              )}
              content={billingInfo?.emailAddress}
            />
            <FieldInfo
              label={t(
                "portal:user-account.paymentMethods.billingForm.reference.label",
              )}
              content={billingInfo?.reference}
            />
            <FieldInfo
              label={t(
                "portal:user-account.paymentMethods.billingForm.note.label",
              )}
              content={billingInfo?.note}
            />
          </>
        ))}
    </div>
  );
};
