export const getUIDChecksum = (uid: string) => {
  const multipliers = [5, 4, 3, 2, 7, 6, 5, 4];
  let sum = 0;
  multipliers.forEach((value, index) => {
    sum += parseInt(uid.charAt(index + 3)) * value;
  });

  const modulo11 = sum % 11;
  if (modulo11 === 0) {
    return 0;
  } else {
    sum = 11 - (sum % 11);
    return sum;
  }
};

export const uidIsValid = (uid: string) => {
  const checksum = getUIDChecksum(uid);
  return Boolean(parseInt(uid.charAt(uid.length - 1)) === checksum);
};
