import { FC, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SubmitButton } from "@/components/Interface/Button/Button";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage";
import { ListBox } from "@/components/Interface/FormFields/ListBox/ListBox";
import { Modal } from "@/components/Interface/Modal/Modal";
import { useAllSims } from "@/hooks/useAllSims";
import { SimCardDetail } from "@/services/model";
import { getIconForDevice } from "@/utils/deviceUtils";

interface Props {
  closeModal: () => void;
}

// WatchSimOrWearableFormFieldKeys as form keys (fk)
enum fk {
  associatedSimId = "associatedSimId",
  independentWearable = "independentWearable",
}

interface WatchSimOrWearableFormFields {
  [fk.associatedSimId]: string;
  [fk.independentWearable]: boolean;
}
export const WatchSimOrWearable: FC<Props> = ({ closeModal }) => {
  const {
    control,
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<WatchSimOrWearableFormFields>();
  const { t } = useTranslation("portal", {
    keyPrefix: "watchSim.watchSimOrWearable",
  });
  const navigate = useNavigate();
  const simData = useAllSims();

  const simDataById: { [key: string]: SimCardDetail } = useMemo(
    () =>
      simData.reduce(
        (acc, curr) => {
          if (curr.simId) {
            acc[curr.simId.toString()] = curr;
          }
          return acc;
        },
        {} as { [key: string]: any },
      ) || {},
    [simData],
  );

  const simDataKeysArray: string[] =
    (simDataById && (Object.keys(simDataById) as string[])) || [];
  const renderOptionWithIcon = (simId: string, selected: boolean) => (
    <div className={`text text-primary-100 ${selected ? "selected" : ""}`}>
      <div className="icon my-auto">
        {getIconForDevice(
          simDataById && simDataById[simId]?.device?.description,
        )}
      </div>
    </div>
  );
  const onSubmit = (data: any) => {
    if (data.associatedSimId) {
      navigate(
        `/portal/cockpit/manage/watch-sim?simId=${data.associatedSimId}`,
      );
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      className="max-h-none max-w-[556px]"
      title={t("title")}
      onClose={() => closeModal()}
      classNameDescription="max-h-none sm:max-h-none overflow-y-visible"
    >
      <h3 className="text-primary-100 mb-4">{t("watchSim")}</h3>
      <p className="mb-6">{t("shareSim")}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={fk.associatedSimId}
          control={control}
          defaultValue={""}
          render={({ field: { onChange, value } }) => (
            <ListBox<string>
              selected={value}
              setSelected={(e) => {
                if (e === value) {
                  resetField(fk.associatedSimId);
                } else {
                  resetField(fk.independentWearable);
                  onChange(e);
                }
              }}
              displayValue={(simId) =>
                (simDataById && simDataById[simId].alias) || "?"
              }
              availableValues={simDataKeysArray}
              placeholder={t("selectDevice")}
              className="full-width"
              renderOption={renderOptionWithIcon}
              disabled={!simDataKeysArray}
            />
          )}
        />

        {/*Border Element*/}
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-2 border-primary-25" />
          </div>

          <div className="relative flex justify-center">
            <span className="bg-white text-primary-100 font-semibold px-6">
              {t("or")}
            </span>
          </div>
        </div>

        <h3 className="text-primary-100 mb-4">{t("other")}</h3>
        <Checkbox
          name={fk.independentWearable}
          errors={errors}
          register={register}
          labelComponent={<>{t("independentWearable")}</>}
          options={{
            required: false,
            validate: (value, formValues) => {
              const multipleSelected =
                formValues?.associatedSimId && formValues.independentWearable;
              if (multipleSelected) {
                return "validationError.multipleSelected";
              }
              const noneSelected =
                !formValues.independentWearable && !formValues?.associatedSimId;
              if (noneSelected) {
                return "validationError.noneSelected";
              }
              return true;
            },
            onChange: () => {
              resetField(fk.associatedSimId);
            },
          }}
        />

        {errors[fk.independentWearable]?.message && (
          <div className="mt-4">
            <ErrorMessage
              // @ts-ignore
              message={t(errors[fk.independentWearable]?.message)}
              name={fk.independentWearable}
            />
          </div>
        )}
        <div className="flex flex-col gap-4 mt-10 md:flex-row justify-between md:justify-end md:col-span-2">
          <SubmitButton
            className="accent w-full"
            dataTestid="delete-button-modal"
            label={t("portal:common.buttons.continue", { keyPrefix: "" })}
          />
        </div>
      </form>
    </Modal>
  );
};
