import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { TwoFactorChangeAuthQRCode } from "@/components/Portal/UserAccount/Security/Change/TwoFactorChangeAuthQRCode";
import { TwoFactorManualQrCodeField } from "@/components/Portal/UserAccount/Security/Change/TwoFactorManualQrCodeField";
import { SetUpTwoFactorAuthenticationReponse } from "@/services/model";

import { FAQLink } from "./FAQLink";
import { TwoFactorConfirmationInput } from "./TwoFactorConfirmationInput";

export const AuthenticatorAppForm = ({
  authSetupData,
}: {
  authSetupData?: SetUpTwoFactorAuthenticationReponse;
}) => {
  const { t } = useTranslation();
  const [showManualSetup, setShowManualSetup] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-6">
        <h2 className="text-secondary-100 text-xl font-semibold">
          {t("portal:authenticator.app.setup.step1.title")}
        </h2>
        <div className="flex flex-col md:flex-row gap-4">
          <p className="text-lg">
            <Trans
              i18nKey="portal:authenticator.app.setup.step1.description"
              components={{ FAQ: <FAQLink /> }}
            />
          </p>
          <div className="flex flex-col min-w-52">
            <TwoFactorChangeAuthQRCode qrCodeData={authSetupData?.qrCodeUri} />
            <button
              type="button"
              className="text-[13px] text-center text-primary-100 underline"
              onClick={() => setShowManualSetup(!showManualSetup)}
            >
              {t(
                showManualSetup
                  ? "portal:authenticator.app.setup.step1.back-to-qr-code"
                  : "portal:authenticator.app.setup.step1.enter-key-manually",
              )}
            </button>
          </div>
        </div>
        {showManualSetup && (
          <TwoFactorManualQrCodeField manualCode={authSetupData?.manualCode} />
        )}
      </div>
      <div className="flex flex-col gap-6">
        <h2 className="text-secondary-100 text-xl font-semibold">
          {t("portal:authenticator.app.setup.step2.title")}
        </h2>
        <div className="flex flex-col gap-4">
          <p className="text-lg">
            {t("portal:authenticator.app.setup.step2.description")}
          </p>
          <TwoFactorConfirmationInput />
        </div>
      </div>
    </>
  );
};
