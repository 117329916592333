import "./Modal.scss";

import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FCCC } from "types";

import { Close } from "@/assets/icons/icons";

interface Props {
  title: ReactNode;
  onClose: () => void;
  classNameDescription?: string;
  showCloseButton?: boolean;
  hasScroll?: boolean;
}

export const Modal: FCCC<Props> = ({
  children,
  className,
  classNameDescription,
  title,
  onClose,
  showCloseButton = true,
  hasScroll = true,
}) => {
  const { t } = useTranslation();

  const titleType = typeof title === "string" ? "h2" : "div";

  return (
    <Transition.Root as={Fragment} appear show>
      <Dialog
        className="relative z-30"
        as="div"
        onClose={onClose}
        data-testid="modal"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-10 bg-primary-100 opacity-25 transition-opacity" />
        </Transition.Child>
        <div id="modal" className="fixed z-40 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "bg-white max-w-[520px] overflow-hidden mx-6 my-auto pt-4 pr-0 p-6 md:p-8 md:pr-0",
                  className,
                )}
              >
                {/*The padding right is set on the lower containers so that the scrollbar is on the very right*/}
                <div className="pb-6 pr-6 md:pr-8 flex flex-row justify-between gap-2.5">
                  <Dialog.Title className="text-primary-100" as={titleType}>
                    {title}
                  </Dialog.Title>
                  {showCloseButton && (
                    <button
                      aria-label={t("common.buttons.abort")}
                      className="modal-close-button flex"
                      onClick={onClose}
                    >
                      <Close className="text-black cursor-auto w-6 mt-1" />
                    </button>
                  )}
                </div>
                <Dialog.Description
                  className={clsx(
                    "pr-6 md:pr-8 ",
                    hasScroll &&
                      "max-h-[336px] sm:max-h-[352px] overflow-y-auto",
                    classNameDescription,
                  )}
                  as="div"
                >
                  {children}
                </Dialog.Description>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
