import { Trans, useTranslation } from "react-i18next";
import { FCC } from "types";

import { LandingBlob } from "@/components/Welcome/LandingBlob/LandingBlob";

import { LoginButton } from "./LoginButton";

export const WelcomeContent: FCC = () => {
  const { t } = useTranslation();

  return (
    <LandingBlob>
      <div id="text-and-buttons" className="mb-10">
        <h1 className="mb-6 md:mb-8 max-w-min md:max-w-full md:hero text-secondary-100">
          {t("Content.headline")}
        </h1>

        <div className="mb-10 md:mb-14 max-w-[600px]">
          <p className="mb-4">
            <Trans
              t={t}
              i18nKey="Content.welcomeText"
              components={[
                <a
                  key="ratesLink"
                  href={t("Content.ratesLink")}
                  className="text-secondary-100 font-semibold underline"
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
            />
          </p>

          <p>{t("Content.welcomeTextEsimUpsell")}</p>
        </div>

        <div className="flex flex-col md:flex-row gap-4 md:gap-6 md:w-auto">
          <LoginButton />
        </div>
      </div>

      <div id="shop-link" className="flex text-base flex-wrap">
        <span className="mr-4 whitespace-nowrap">{t("Content.products")}</span>
        <a
          href={t("Content.shopLink")}
          className="font-semibold hover:underline text-secondary-100 whitespace-nowrap"
          target="_blank"
          rel="noreferrer"
        >
          {t("Content.shop")}
        </a>
      </div>
    </LandingBlob>
  );
};
