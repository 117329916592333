import "./RoamingOptionContainer.scss";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FCCWithClassName } from "types";

import { Subtract } from "@/assets/icons/icons";

interface Props {
  selected?: boolean;
  highlighted?: boolean;
  recommended?: boolean;
  precharged?: boolean;
  disabled?: boolean;
  voice?: boolean;
}

export const RoamingOptionContainer: FCCWithClassName<Props> = ({
  selected,
  children,
  recommended,
  precharged = false,
  highlighted,
  disabled = false,
  voice = false,
  className,
}) => {
  const { t } = useTranslation();
  let label = undefined;
  if (recommended && !precharged) {
    label = t("Onboarding.sections.rate-selection.recommended");
  }
  if (precharged) {
    label = t("Onboarding.sections.rate-selection.precharged");
  }
  return (
    <div
      className={clsx(
        className,
        "roaming-option-container",
        selected && "selected",
        highlighted && "highlighted",
        recommended && "recommended",
        disabled && !selected && "disabled",
        voice && "voice",
        precharged && "precharged",
      )}
    >
      {label && (
        <>
          <div className="label">{label}</div>
          <div className="hint">
            <Subtract />
          </div>
        </>
      )}
      {children}
    </div>
  );
};
