import { useTranslation } from "react-i18next";

export const OrSeparator = () => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-row">
      <hr className="border-primary-25 w-full" />
      <span className="text-primary-100 pt-px px-2 font-semibold">
        {t("portal:watchSim.watchSimOrWearable.or")}
      </span>
      <hr className="border-primary-25 w-full" />
    </div>
  );
};
