import { useEffect, useState } from "react";

export const useDebounce = <T>(value: T, delay: number): [T, boolean] => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  // State to keep track of timeout status
  const [isTimeoutActive, setIsTimeoutActive] = useState(false);

  useEffect(
    () => {
      setIsTimeoutActive(true);
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setIsTimeoutActive(false);
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return [debouncedValue, isTimeoutActive];
};
