import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { Country } from "@/constants/countries";
import { countries, SCHWEIZ } from "@/constants/countries";

/**
 * This hooks uses an array of objects containing country codes and returns the
 * same array sorted using the respective countries' names in the currently
 * selected language.
 * Switzerland will be the first element if not excluded from the result.
 *
 * The result is memoized to avoid sorting again between renders.
 *
 * @param excludeSwitzerland - keeps switzerland out of the returned list
 * @returns Alphabetically sorted array of countries
 */
export const useGetSortedCountries = (excludeSwitzerland?: boolean) => {
  const { t } = useTranslation("translation");

  return useMemo(() => {
    let countriesArray = Array(...countries);

    // We remove Switzerland from the list to either add it later at the
    // beginning of it or to keep it out altogether.
    countriesArray = countriesArray.filter(
      (country: Country) => country !== SCHWEIZ,
    );
    countriesArray = countriesArray.sort((a: Country, b: Country) => {
      return t(`countries.${a.ALPHA3}`).localeCompare(
        t(`countries.${b.ALPHA3}`),
      );
    });

    !excludeSwitzerland && countriesArray.unshift(SCHWEIZ);

    return countriesArray;
  }, [excludeSwitzerland, t]);
};
