import "./EsimHint.scss";

import { Trans } from "react-i18next";

import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";

export const EsimHint = () => (
  <div className="esim-hint">
    <HighlightPanel>
      <Trans components={[<span key="0" className="font-semibold" />]}>
        Onboarding.sections.checkout.summary.esimHintText
      </Trans>
    </HighlightPanel>
  </div>
);
