import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

import { Copy, Download } from "@/assets/icons/icons";
import { SubmitButton } from "@/components/Interface/Button/Button";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage";
import { useAlert } from "@/hooks/useAlert";
import { authenticatorRoutes } from "@/router/AuthenticatorRoutes";
import { useConfirmTwoFactorAuthenticationBackupCodes } from "@/services/auth";
import { AlertTypes, twoFactorBackupCodesAtom } from "@/utils/atoms";

import {
  copyCodesToClipboard,
  downloadCodes as _downloadCodes,
} from "./authenticator.utils";
import { NameConfirmationField } from "./NameConfirmationField";

export const AuthenticatorBackupCodeConfirm = ({
  successTarget = authenticatorRoutes.done,
}: {
  successTarget?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [backupCodes] = useAtom(twoFactorBackupCodesAtom);

  type AcceptTermsForm = {
    name: string;
    terms: boolean;
  };
  const formMethods = useForm<AcceptTermsForm>({
    defaultValues: {
      name: "",
      terms: false,
    },
    mode: "onSubmit",
  });

  const { mutateAsync: confirmBackupCodes } =
    useConfirmTwoFactorAuthenticationBackupCodes();

  const handleFormSubmit = formMethods.handleSubmit(() => {
    confirmBackupCodes()
      .then(() => {
        showToast({
          text: t("portal:authenticator.backupCodes.success"),
          type: AlertTypes.success,
        });
        navigate(successTarget);
      })
      .catch(() => {
        formMethods.setError("name", {
          type: "manual",
          message: t("portal:authenticator.backupCodes.error"),
        });
      });
  });

  const showToast = useAlert();
  const copyCodes = () =>
    copyCodesToClipboard(backupCodes)
      .then(() => {
        showToast({
          text: t("portal:authenticator.backupCodes.copySuccess"),
          type: AlertTypes.success,
        });
      })
      .catch(() => {
        showToast({
          text: t("portal:authenticator.backupCodes.copyError"),
          type: AlertTypes.error,
        });
      });

  const downloadCodes = () => {
    _downloadCodes(backupCodes);
    showToast({
      text: t("portal:authenticator.backupCodes.downloadSuccess"),
      type: AlertTypes.success,
    });
  };

  if (!backupCodes.length) {
    return <Navigate to="/authenticator/app" />;
  }
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <h1 className="text-primary-100 text-4xl font-semibold">
          {t("portal:authenticator.backupCodes.title")}
        </h1>
        <p className="text-lg">
          {t("portal:authenticator.backupCodes.description")}
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <h2 className="text-secondary-100 text-xl">
          {t("portal:authenticator.backupCodes.uniqueRecoveryCodes")}
        </h2>
        <div className="flex gap-2 flex-col">
          <div className="flex flex-col gap-4 p-4 border-2 border-primary-100">
            {backupCodes.map((code) => (
              <div key={code} className="flex flex-row gap-4">
                <span className="text-lg text-primary-100">{code}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col md:flex-row gap-4 text-primary-100">
            <button
              onClick={copyCodes}
              className="flex items-center hover:underline"
            >
              <Copy className="w-4 h-4 mr-2" />
              {t("portal:authenticator.backupCodes.copy")}
            </button>
            <button
              onClick={downloadCodes}
              className="flex items-center hover:underline"
            >
              <Download className="w-4 h-4 mr-2" />
              {t("portal:authenticator.backupCodes.download")}
            </button>
          </div>
        </div>
      </div>
      <form onSubmit={handleFormSubmit} className="flex flex-col gap-6">
        <h2 className="text-xl text-secondary-100">
          {t("portal:authenticator.backupCodes.proceedWithSteps")}
        </h2>
        <div className="flex flex-col gap-2">
          <h3 className="text-lg text-primary-100">
            {t("portal:authenticator.backupCodes.steps.1.title")}
          </h3>
          <p className="text-black">
            {t("portal:authenticator.backupCodes.steps.1.description")}
          </p>
        </div>
        <NameConfirmationField
          register={formMethods.register}
          errors={formMethods.formState.errors}
        />
        <div className="flex flex-col gap-2">
          <Checkbox
            errors={formMethods.formState.errors}
            register={formMethods.register}
            name="terms"
            options={{
              required: t(
                "portal:authenticator.backupCodes.acceptTermsRequired",
              ),
            }}
            labelComponent={
              <Trans
                t={t}
                i18nKey="portal:authenticator.backupCodes.acceptTerms"
                className="text-black"
                components={{
                  a: (
                    <a
                      href={t("translation:Footer.termsConditions.url")}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary-100 hover:underline"
                    />
                  ),
                }}
              />
            }
          />
          {formMethods.formState.errors.terms && (
            <ErrorMessage
              name="terms"
              message={formMethods.formState.errors.terms.message}
            />
          )}
        </div>
        <div className="mt-2 flex flex-row justify-end">
          <SubmitButton
            className="accent"
            label={t("portal:common.buttons.continue")}
          />
        </div>
      </form>
    </div>
  );
};
