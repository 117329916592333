import { useSearchParams } from "react-router-dom";

import {
  GetSimListDevicesItem,
  GetSimListParams,
  GetSimListSimAutoRenew,
  GetSimListSimStatusItem,
  GetSimListSimTypeItem,
  GetSimListSimVoiceOption,
  GetSimListSortDirection,
  GetSimListSortField,
} from "@/services/model";

const columns = {
  ID: "ID",
  SIMALIAS: "SIMALIAS",
  STATUSSIM: "STATUSSIM",
  SIMTYP: "SIMTYP",
  TARIF: "TARIF",
  VOICE: "VOICE",
  REMAININGDAYS: "REMAININGDAYS",
  SUCCESSOR: "SUCCESSOR",
  DEVICE: "DEVICE",
  ICCID: "ICCID",
  STATUSROAMING: "STATUSROAMING",
  MSISDN: "MSISDN",
} as const;
type Column = (typeof columns)[keyof typeof columns];

/**
 * Take query params from URL and format them into usable filters.
 */
export const useGetSimQueryParamFilter = (): GetSimListParams & {
  additionalColumns?: Column[];
} => {
  const [searchParams] = useSearchParams();

  const checkAssertValue = <T extends Record<string, string>>(
    key: string,
    obj: T,
  ) => {
    const value = searchParams.get(key);
    return value && value in obj ? (value as keyof T) : undefined;
  };

  const checkAssertArray = <T extends Record<string, string>>(
    key: string,
    obj: T,
  ) =>
    searchParams
      .get(key)
      ?.split(",")
      .filter((v) => v in obj) as (keyof T)[] | undefined;

  const searchTerm = searchParams.get("searchTerm") ?? undefined;
  const simVoiceOption = checkAssertValue(
    "simVoiceOption",
    GetSimListSimVoiceOption,
  );
  const simAutoRenew = checkAssertValue("simAutoRenew", GetSimListSimAutoRenew);
  const sortField = checkAssertValue("sortField", GetSimListSortField);
  const sortDirection = checkAssertValue(
    "sortDirection",
    GetSimListSortDirection,
  );
  const simStatusList = checkAssertArray("simStatus", GetSimListSimStatusItem);
  const simTypes = checkAssertArray("simType", GetSimListSimTypeItem);
  const devices = checkAssertArray("device", GetSimListDevicesItem);
  const additionalColumns = checkAssertArray("additionalColumns", columns);

  return {
    searchTerm,
    simAutoRenew,
    simStatus: simStatusList,
    simType: simTypes,
    simVoiceOption,
    sortDirection,
    sortField,
    devices,
    additionalColumns,
  };
};
