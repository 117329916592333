import { useEffect, useRef } from "react";

import { useGetIdentificationStatus } from "@/services/api";
import {
  IdentificationStatusResponse,
  IdentificationStatusResponseKycStatus,
} from "@/services/model";

export const needsIDCheck: (data: IdentificationStatusResponse) => boolean = (
  data,
) =>
  data.isVerified === false ||
  data.kycStatus !== IdentificationStatusResponseKycStatus.OK;

export const useNeedsIDCheck: () => boolean | undefined = () => {
  const staleTime = useRef(1000);
  const { data } = useGetIdentificationStatus({
    query: {
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      staleTime: staleTime.current,
    },
  });

  useEffect(() => {
    if (data) {
      if (
        data.isVerified &&
        data.kycStatus === IdentificationStatusResponseKycStatus.OK
      ) {
        staleTime.current = 5 * 60 * 1000;
      }
    }
  }, [data]);

  if (data) {
    return needsIDCheck(data);
  }
  return undefined;
};
