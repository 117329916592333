import "./SkeletonTile.scss";

import { FC } from "react";

import { DeviceTile } from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";

export const SkeletonTile: FC = () => {
  return (
    <DeviceTile dataTestid="skeleton-tile" className="skeleton-tile">
      <div className="tile-title">
        <h2 className="placeholder">Lorem dolor Lorem ipsum dolor</h2>
      </div>
      <div className="tile-subtitle">
        <h3 className="placeholder">Lorem ipsum</h3>
      </div>
      <div className="device-info">
        <div className="icon-row mr-4 placeholder">
          ICON<span>Lorem</span>
        </div>
        <div className="icon-row placeholder">
          ICON<span>Lorem</span>
        </div>
      </div>
      <div className="usage-info mt-14">
        <div id="bar-wrapper">
          <div id="background-bar" className="placeholder" />
        </div>
        <div className="mt-2">
          <p className="placeholder">Lorem ipsum</p>
        </div>
      </div>
    </DeviceTile>
  );
};
