import "@/components/Portal/Cockpit/DeviceManagement/Details/NewQRCodeWaiting/NewQRCodeWaiting.scss";

import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";

export const NewQRCodeWaiting = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="mb-6 text-secondary-100">
        {t("cockpit.managementTile.details.sections.details.esimProfile")}
      </h3>
      <div className="w-fit">
        <div className="flex flex-col md:flex-row">
          <div>
            <h3 className="font-semibold mb-4">{title}</h3>
            <p className="max-w-[560px]">{text}</p>
          </div>
          <div className="mx-auto mt-16 md:ml-16 md:mt-2 pulsating-blur">
            {<QRCodeSVG value="digitalrepublich.ch" size={210} />}
          </div>
        </div>
      </div>
    </div>
  );
};
