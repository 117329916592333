import "./RateSelection.scss";

import { Popover, Tab } from "@headlessui/react";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Close } from "@/assets/icons/icons";
import { Button } from "@/components/Interface/Button/Button";
import { PopUp } from "@/components/Interface/PopUp/PopUp";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { Step } from "@/components/Onboarding/Steps/Step";
import { useCustomerInOnboarding } from "@/hooks/useCustomerInOnboarding";
import { useIsInsidePortal } from "@/hooks/useIsInsidePortal";
import { useIsOnboarding } from "@/hooks/useIsOnboarding";
import { useWizardBasePath } from "@/hooks/useWizard";
import { DataContext } from "@/provider/DataContextProvider";
import { useAddOption, useListAvailableOptions } from "@/services/api";
import { canOnlyBookData } from "@/utils/customerUtils";
import { getEntry } from "@/utils/dataContextHelpers";
import {
  deviceNameToValueMap,
  getDeviceDescription,
} from "@/utils/deviceUtils";
import { env } from "@/utils/environmentHelpers";
import { log } from "@/utils/log";
import {
  getPreselectedOption,
  TransformedProduct,
  transformProducts,
  useHasPrechargedMobileAbo,
} from "@/utils/tariffUtils";

import { CustomRateForm } from "./CustomRateForm";
import { FreeEsimTrialBanner } from "./FreeEsimTrialBanner/FreeEsimTrialBanner";
import { MobileAboForm } from "./MobileAboForm";
import { MobileAboSwitch, mobileAboValues } from "./MobileAboSwitch";
import { NumberPortingChoice } from "./NumberPortingChoice/NumberPortingChoice";
import {
  getMobileAbos,
  isSmartphoneInBasket,
  mobileAbos as mobileAboIds,
} from "./rateSelection.utils";

export type RateFormProps = {
  rate: string; // The field's value must be string to enable setting the radio buttons by default
  voice: boolean;
  numberPorting: "no" | "yes";
};

/**
 * The continue should only be disabled if:
 * - we don't have a rate selection yet
 * - we are missing a pair of voice option and current rate
 * - the popup for the "you sure you dont want numberporting" hint is showing
 * - the continuation is manually disabled (popup showing)
 */
const getContinueIsDisabled = (
  currentRate: string,
  currentRateAsVoice: number | undefined,
  manualDisable: boolean,
) =>
  currentRate === "" || (!currentRate && !currentRateAsVoice) || manualDisable;

export const RateSelection: FC = () => {
  const [manualDisable, setManualDisable] = useState(false);
  const [dismissedPopup, setDismissedPopup] = useState(false);
  const { dataContext, setDataContext } = useContext(DataContext);
  const basePath = useWizardBasePath();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isOnboarding = useIsOnboarding();
  const isAddDevice = useIsInsidePortal();

  const entry = getEntry(dataContext);
  const device = getDeviceDescription(entry?.device?.description);
  const rateFormMethods = useForm<RateFormProps, any, RateFormProps>({
    // This handles the preselection of the rate and voice option
    defaultValues: () => getPreselectedOption(dataContext.basket),
  });

  const { watch, resetField } = rateFormMethods;

  const currentRate = watch("rate");
  const voiceIsSelected = watch("voice") || mobileAboIds.includes(currentRate);
  const selectedONP = watch("numberPorting");
  const isONP = voiceIsSelected && selectedONP === "yes";

  const closeModal = () => {
    setManualDisable(false);
    setDismissedPopup(true);
  };

  const handleNavigate = () => {
    setDataContext((prev) => ({
      ...prev,
      allowedMaxOnboardingStep: isONP ? 3 : 4,
    }));
    navigate(
      isAddDevice
        ? `${basePath}${isONP ? "/3/number-porting" : "/4/checkout"}`
        : `${basePath}${isONP ? "/3/number-porting" : "/4/personal-details"}`,
    );
  };

  const customerData = useCustomerInOnboarding();
  // Get all available options for this basket
  const { data: availableOptions, isFetching: isLoadingOptions } =
    useListAvailableOptions(dataContext.basket?.id || -1, 0, undefined, {
      query: {
        refetchOnWindowFocus: false,
        enabled: !!dataContext.basket?.id,
        onSuccess: (data) => transformProducts(data),
      },
    });

  const prechargedProduct = !!availableOptions?.find(
    (product) => product.precharged,
  );
  const hasPrechargedMobileAbo = useHasPrechargedMobileAbo(dataContext.basket);

  // Transform the available options into a usable data format (TransformedProduct)
  // and determine which product is preselected (precharged or recommended)
  const options = useMemo(
    () => availableOptions && transformProducts(availableOptions),
    [availableOptions],
  );
  const { processedProductsById } = options || {};

  const { mutateAsync: addOption, isLoading: isAddingOption } = useAddOption();

  // If the voice option gets un-selected, reset the numberPorting field as you cannot have numberporting without voice option.
  useEffect(() => {
    if (!voiceIsSelected && selectedONP !== undefined) {
      resetField("numberPorting", { defaultValue: "no" });
    }
  }, [voiceIsSelected, resetField, selectedONP]);

  if (!processedProductsById) return null;

  const currentRateAsVoice = processedProductsById[currentRate]?.voice;
  const currentProductWithVoice = currentRateAsVoice
    ? processedProductsById[currentRateAsVoice]
    : undefined;
  // The selectedOption is the option we will put in the basket on continue - it is either the selected data or the selected data + voice.
  const selectedOption: TransformedProduct | undefined = voiceIsSelected
    ? currentProductWithVoice
    : processedProductsById[currentRate];

  const navigateToNext = () => {
    if (selectedOption?.id && dataContext.basket?.id && entry?.uuid) {
      addOption({
        basketId: dataContext.basket.id,
        uuid: entry.uuid,
        params: {
          optionId: selectedOption.id,
          quantity: 1,
          isONP,
        },
      }).then((data) => {
        setDataContext({
          ...dataContext,
          basket: data,
          selectedOption,
        });
        handleNavigate();
      });
    } else {
      log(
        `option "${selectedOption?.id}", ${dataContext.basket?.id} "basket id", "entry 0 uuid" ${entry?.uuid}`,
      );
    }
  };

  const continueIsDisabled = getContinueIsDisabled(
    currentRate,
    currentRateAsVoice,
    manualDisable,
  );
  const shouldShowEsimTrial = !!(isOnboarding && entry?.esim);
  const canBookVoiceOption = !(customerData && canOnlyBookData(customerData));
  const showWarning = canBookVoiceOption && !dismissedPopup && !isONP;
  const shouldShowNumberPorting = !customerData?.dataOnly;

  const mobileAbos = getMobileAbos(processedProductsById);

  const initialMobileAboSwitchValue: keyof typeof mobileAboValues | undefined =
    prechargedProduct
      ? hasPrechargedMobileAbo
        ? mobileAboValues.FLAT_MOBILE
        : mobileAboValues.ALL_RATES
      : isSmartphoneInBasket(dataContext.basket)
        ? mobileAboValues.FLAT_MOBILE
        : mobileAboValues.ALL_RATES;

  const disableMobileAboSwitch = prechargedProduct;

  const isLoading =
    isLoadingOptions || isAddingOption || rateFormMethods.formState.isLoading;

  return (
    <Step
      id="onboarding-rate-selection"
      headline={
        t("Onboarding.sections.rate-selection.header.rateFor") +
        " " +
        t(`Onboarding.sections.rate-selection.header.your.${device}`)
      }
      subheadline={t("Onboarding.sections.rate-selection.subtitle")}
      headlinePrimary={t(`Common.devices.${deviceNameToValueMap[device]}`)}
      navigation={
        <div className="nav-button-container">
          <Button
            onClick={() => navigate(`${basePath}/2/device-type`)}
            className="accent inverted"
          >
            {t("Common.label.back")}
          </Button>
          {env.isTest ? (
            <Button
              dataTestid="continue-button"
              className="accent"
              disabled={continueIsDisabled}
              onClick={() => navigateToNext()}
            >
              {t("Common.label.forward")}
            </Button>
          ) : (
            <PopUp
              button={
                <Popover.Button
                  refName={"popoverButton"}
                  as={Button}
                  data-testid="popup-button"
                  className="accent focus:outline-none"
                  disabled={continueIsDisabled}
                  onClick={async (event: any) => {
                    if (!showWarning || dismissedPopup) event.preventDefault();
                    if (!showWarning) {
                      navigateToNext();
                    } else {
                      setManualDisable(true);
                      await new Promise((resolve) => setTimeout(resolve, 4000));
                      setDismissedPopup(true);
                      setManualDisable(false);
                    }
                  }}
                >
                  {t("Common.label.forward")}
                </Popover.Button>
              }
            >
              <h4 className="text-secondary-100 font-semibold text-lg mb-3">
                {t("Onboarding.sections.rate-selection.areYouSure")}
              </h4>
              <Popover.Button
                onClick={closeModal}
                className="absolute top-7 right-5"
              >
                <Close
                  id="close-icon"
                  className="w-6 h-6 text-black hover:rotate-90 transition-transform"
                />
              </Popover.Button>
              <p className="leading-normal text-black">
                {t("Onboarding.sections.rate-selection.areYouSureText")}
              </p>
            </PopUp>
          )}
        </div>
      }
    >
      <FormProvider {...rateFormMethods}>
        {shouldShowEsimTrial && <FreeEsimTrialBanner />}
        {isLoading && <LoadingSpinner />}
        <MobileAboSwitch
          initialValue={initialMobileAboSwitchValue}
          disabled={disableMobileAboSwitch}
        >
          <Tab.Panels>
            <Tab.Panel>
              <MobileAboForm
                value={rateFormMethods.watch("rate")}
                setValue={(rate) => rateFormMethods.setValue("rate", rate)}
                mobileAbos={mobileAbos}
              />
            </Tab.Panel>
            <Tab.Panel>
              <CustomRateForm
                selectedOption={selectedOption}
                rateFormMethods={rateFormMethods}
                options={options}
                currentRate={currentRate}
                shouldShowEsimTrial={shouldShowEsimTrial}
                canBookVoiceOption={canBookVoiceOption}
                currentVoiceOption={currentProductWithVoice}
                voiceIsSelected={voiceIsSelected}
              />
            </Tab.Panel>
          </Tab.Panels>
        </MobileAboSwitch>
        {shouldShowNumberPorting && (
          <NumberPortingChoice voiceIsSelected={voiceIsSelected} />
        )}
      </FormProvider>
    </Step>
  );
};
