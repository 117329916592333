import "./AutomaticRenewal.scss";

import { FC } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { RadioButton } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import { FinalFormProps } from "@/components/Onboarding/Steps/Checkout/Checkout";

interface Props {
  register: UseFormRegister<FinalFormProps>;
  errors: FieldErrors;
}

export const AutomaticRenewal: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();
  return (
    <fieldset id="automatic-renewal">
      <legend className="text-xl font-semibold mb-4">
        {t("Onboarding.sections.checkout.autoRenewal.title")}
      </legend>
      <section className="flex flex-col">
        <div className="mb-8">
          <Trans i18nKey="Onboarding.sections.checkout.autoRenewal.subtitle" />
        </div>
        <RadioButton<FinalFormProps>
          name="reoccuringPayment"
          value="yes"
          errors={errors}
          register={register}
          labelComponent={
            <Trans
              components={[
                <span key="1" className="text-base font-semibold" />,
                <span
                  key="recommended"
                  className="font-x-small text-primary-75 ml-2"
                />,
              ]}
              i18nKey="Onboarding.sections.checkout.autoRenewal.yesAutoRenew"
            />
          }
          className="mb-5"
        />
        <RadioButton<FinalFormProps>
          name="reoccuringPayment"
          value="no"
          errors={errors}
          register={register}
          labelComponent={
            <Trans
              components={[
                <span key="1" className="text-base font-semibold" />,
              ]}
              i18nKey="Onboarding.sections.checkout.autoRenewal.dontAutoRenew"
            />
          }
        />
      </section>
    </fieldset>
  );
};
