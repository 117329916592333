import "./BillList.scss";

import compact from "lodash/compact";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Button } from "@/components/Interface/Button/Button";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { PageNumberComboBox } from "@/components/Layout/Table/PageNumberComboBox/PageNumberComboBox";
import { TableControlGroup } from "@/components/Layout/Table/TabeControlGroup/TableControlGroup";
import { Table } from "@/components/Layout/Table/Table";
import { BillListHeader } from "@/components/Portal/Bills/BillListHeader/BillListHeader";
import { BillListRow } from "@/components/Portal/Bills/BillListRow/BillListRow";
import { PortalHeading } from "@/components/Portal/PortalHeading/PortalHeading";
import { useHandleError } from "@/hooks/useHandleError";
import { useQueryParam } from "@/hooks/useQueryParam";
import { getBillFile, useGetDrBillsForCustomer } from "@/services/api";
import { GetDrBillsForCustomerSortField } from "@/services/model";
import { log } from "@/utils/log";

const sortFields = [
  "Datum",
  "Abrechnungsmonat",
  "Rechnungsnummer",
  "Betrag",
  "Status",
];

export type BillListBillIdForm = {
  billIds: { [key: number]: string | boolean };
};
export const BillList: FC = () => {
  const { t } = useTranslation("portal");
  const [searchParams] = useSearchParams();
  const [isFetchingBillFile, setIsFetchingBillFile] = useState(false);
  const handleError = useHandleError();

  const formMethods = useForm<BillListBillIdForm>({
    defaultValues: { billIds: {} },
  });
  const billIds = compact(Object.values(formMethods.watch("billIds")));

  const [billPage, setCurrentBillPage] = useQueryParam("page");

  const currentBillPage = Number(billPage) || 1;
  const currentBillsPerPage = Number(searchParams.get("pageSize")) || 10;
  const currentSortField = searchParams.get("sortField");
  const typedCurrentSortField = sortFields.includes(`${currentSortField}`)
    ? (currentSortField as GetDrBillsForCustomerSortField)
    : undefined;
  const currentSortDirection = searchParams.get("sortDirection");
  const typedCurrentSortDirection =
    currentSortDirection === "asc"
      ? "asc"
      : currentSortDirection === "desc"
        ? "desc"
        : undefined;

  const { data: billOverview, isFetching: isFetchingBills } =
    useGetDrBillsForCustomer(
      {
        page: currentBillPage - 1,
        pageSize: currentBillsPerPage,
        sortField: typedCurrentSortField,
        sortDirection: typedCurrentSortDirection,
      },
      {
        query: {
          refetchOnWindowFocus: false,
          keepPreviousData: true,
          onSuccess: (data) => {
            // If the total number of pages is smaller than the current selected page,
            // (e.g. we are on page 3 but the data has only 2 pages now)
            // reset to the next possible value
            if (
              data.totalNumberPages &&
              data.totalNumberPages < currentBillPage
            ) {
              setCurrentBillPage(data.totalNumberPages.toString());
            }
          },
        },
      },
    );

  const downloadSelectedBills = () => {
    setIsFetchingBillFile(true);
    getBillFile({
      billId: billIds.map((id) => Number(id)),
    })
      .then((data) => {
        if (
          data.billFile?.file &&
          data.billFile.documentName &&
          data.billFile.mimeType === "application/zip"
        ) {
          const url = `data:application/zip;base64,${data.billFile?.file}`;
          const link = document.createElement("a");
          link.download = t("bills.zipName", { count: billIds.length });
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          log(
            "Could not save billFile zip, the check for valid object failed.",
          );
        }
      })
      .catch((error) => handleError(error))
      .finally(() => setIsFetchingBillFile(false));
  };

  const billPages = billOverview?.totalNumberPages || 1;
  const isLoading = isFetchingBills || isFetchingBillFile;

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <PortalHeading heading={t("bills.heading")}>
        <p className="mb-14 mt-8 md:mt-10">{t("bills.subheading")}</p>
      </PortalHeading>
      <FormProvider {...formMethods}>
        <Table
          paginationPages={billPages}
          data-testid="test"
          headline={t("bills.headline")}
        >
          <thead>
            <BillListHeader
              currentPageBillIds={
                compact(billOverview?.bills?.map((bill) => bill.id)) ?? []
              }
            />
          </thead>
          <tbody>
            {billOverview?.bills?.map((bill) => (
              <BillListRow key={bill.id} bill={bill} />
            ))}
          </tbody>
        </Table>
      </FormProvider>
      <TableControlGroup>
        <div className="mr-8">
          <PageNumberComboBox />
        </div>
        <Button
          className="accent"
          disabled={Object.values(billIds).length === 0}
          onClick={() => downloadSelectedBills()}
        >
          {t("bills.download", { count: billIds.length })}
        </Button>
      </TableControlGroup>
    </>
  );
};
