import "./Tiles.scss";

import { useAtom } from "jotai";
import { FC, Fragment, useEffect, useMemo, useState } from "react";

import { useExtendedSims } from "@/hooks/useExtendedSims";
import { useGetSimQueryParamFilter } from "@/hooks/useGetSimQueryParamFilter";
import { useNeedsIDCheck } from "@/hooks/useNeedsIDCheck";
import { useGetSimListInfinite } from "@/services/api";
import { managedUnscannedDevicesAtom } from "@/utils/atoms";

import { InfiniteTileScroll } from "./InfiniteTileScroll/InfiniteTileScroll";
import { SkeletonTile } from "./SkeletonLoadingTile/SkeletonTile";
import { mapDevice, MetaTiles } from "./tileHelpers";

const PAGE_SIZE = 10;

export const Tiles: FC = () => {
  const needsIdCheck = useNeedsIDCheck();

  const filter = useGetSimQueryParamFilter();
  const [refetchInterval, setRefetchInterval] = useState<false | number>(false);

  // The infinite query handler is setting the page property. We only need to define a page size
  const {
    data: simList,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    isError,
    hasNextPage,
  } = useGetSimListInfinite(
    {
      ...filter,
      pageSize: PAGE_SIZE,
    },
    {
      query: {
        getNextPageParam: (lastPage, allPages) => {
          if (
            lastPage?.totalNumberPages &&
            allPages.length < lastPage.totalNumberPages
          ) {
            return allPages.length;
          }

          return undefined;
        },
        refetchInterval,
      },
    },
  );

  const flatSimList = useMemo(
    () =>
      simList?.pages?.length
        ? simList.pages
            .map((page) => page?.simCards || [])
            .reduce((prevValue, currValue) => [...prevValue, ...currValue])
        : [],
    [simList],
  );

  // Enrich the list of Sims with roaming and esim data
  const { extendedSims: flatExtendedSimList, hasUnscannedEsims } =
    useExtendedSims(flatSimList, refetchInterval);

  // Once we know there are unscanned esims, we set a refetch interval so all data gets renewed every x seconds
  useEffect(() => {
    setRefetchInterval(hasUnscannedEsims && 10000);
  }, [hasUnscannedEsims, setRefetchInterval]);

  // Unscanned eSIMs can be managed anyway. Once that happened, we want to ignore
  // the fact that we should show the QR code here and just show the normal tiles.
  const [managedUnscannedDevices] = useAtom(managedUnscannedDevicesAtom);

  // Maps a device to its corresponding tile
  return (
    <>
      {isLoading ? (
        <div id="tiles">
          <SkeletonTile />
          <SkeletonTile />
          <SkeletonTile />
        </div>
      ) : (
        <InfiniteTileScroll
          items={flatSimList}
          hasNextPage={!!hasNextPage}
          loadingNextPage={isLoading || isFetchingNextPage}
          error={isError}
          fetchNextPage={fetchNextPage}
          noDataChild={""}
        >
          <div id="tiles" data-testid={"tiles-container"}>
            {flatSimList && (
              <>
                {flatExtendedSimList.map((device, i) => (
                  <Fragment key={device.simId}>
                    {mapDevice(device, needsIdCheck, managedUnscannedDevices)}
                    {/* Show the meta tiles if:
                    - only one page and last sim reached
                    - or more than one page and end of first page reached
                    - or more than two pages and end of list reached */}
                    {((flatSimList.length <= PAGE_SIZE &&
                      flatSimList.length - 1 === i) ||
                      (flatSimList.length > PAGE_SIZE && PAGE_SIZE - 1 === i) ||
                      (flatSimList.length > PAGE_SIZE * 2 &&
                        flatSimList.length - 1 === i)) && <>{MetaTiles}</>}
                  </Fragment>
                ))}
              </>
            )}
            {/* If there are no sims, show meta tiles */}
            {flatSimList.length === 0 && <>{MetaTiles}</>}
          </div>
        </InfiniteTileScroll>
      )}
    </>
  );
};
