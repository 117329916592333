import { PropsWithChildren } from "react";

import { WelcomeLoadingSpinner } from "@/components/Welcome/WelcomeLoadingSpinner.tsx";
import { useLoginRedirect } from "@/hooks/useLoginRedirect";

export const LoginRedirect = ({ children }: PropsWithChildren) => {
  const isRedirecting = useLoginRedirect();

  if (isRedirecting) {
    return <WelcomeLoadingSpinner />;
  }
  return children;
};
