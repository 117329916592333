import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import type { NumberForm } from "@/hooks/useNumberForm";

export const AuthenticatorPhoneNumberField = ({
  register,
  errors,
}: {
  register: UseFormRegister<NumberForm>;
  errors: FieldErrors<NumberForm>;
}) => {
  const { t } = useTranslation();
  return (
    <label className="text-primary-100 text-lg">
      <span className="font-semibold">
        {t("portal:authenticator.sms.enterNumber.label")}
      </span>
      <FormField<NumberForm>
        id="number"
        register={register}
        errors={errors}
        options={{
          required: t("translation:label.validation.required"),
          pattern: {
            // This has to either start with 07 for CH numbers or 00XX for international numbers,
            // but not with +.
            value: /^(07|00\d{2}).*$/,
            message: t("translation:label.validation.phoneNoPlus"),
          },
        }}
        name="number"
        autocomplete="tel"
        placeholder={t("portal:authenticator.sms.enterNumber.placeholder")}
      />
    </label>
  );
};
