/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type GetSimListSortField =
  (typeof GetSimListSortField)[keyof typeof GetSimListSortField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSimListSortField = {
  ID: "ID",
  SIMALIAS: "SIMALIAS",
  STATUSSIM: "STATUSSIM",
  SIMTYP: "SIMTYP",
  TARIF: "TARIF",
  VOICE: "VOICE",
  REMAININGDAYS: "REMAININGDAYS",
  SUCCESSOR: "SUCCESSOR",
  DEVICE: "DEVICE",
  ICCID: "ICCID",
  STATUSROAMING: "STATUSROAMING",
  MSISDN: "MSISDN",
} as const;
