// Endpoint infos from Slack:
// basketId and header are required for the /contract/{serviceId}/options request
// serviceId refers to simId
// client = Der client von dem der Request gesendet wird -> 1 für DR
// lastUser =  User für den Request = 1
// distributionChannel = neues ConfirmationButtonGroup
// distributionChannel = 100095212
// salespartner = client
// String können derzeit beliebig gewählt werden, müssen darüber noch reden
// source = 1 für webservice

import { atomWithStorage } from "jotai/utils";

import { Basket } from "@/services/model";
import { env } from "@/utils/environmentHelpers";

export const getHeader = () => ({
  client: Number(env.clientNumericId),
  externalOrderId: "TestOrderID",
  lastUser: 1,
  marketingCampaignId: "TestMarketingCampaignID",
  orderEntryOrganisation: {
    distributionsChannel: 100095212,
    salesPartner: Number(env.clientNumericId),
  },
  salesOrganisation: {
    distributionsChannel: 100095212,
    salesPartner: 1,
  },
  source: 1,
});

export const optionBasketAtom = atomWithStorage<Basket | undefined>(
  "optionBasket",
  undefined,
);
