import { useContext, useEffect } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { FCCC } from "types";

import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { DataContext } from "@/provider/DataContextProvider";

/**
 * This component guards Steps in the Onboarding so that the user cannot jump from Step 1 to Step 5.
 *
 * Each Onboarding step writes the **next allowed** step into the `dataContext`.
 * This component then allows the display and the Onboarding step navigates there.
 *
 * The component is used to protect the steps in the initial onboarding (e.g. /onboarding/1/sim-selection -> /onboarding/2/device-type)
 * and in the portal for every additional sim. This is also why the `basePath` is necessary.
 *
 * @param basePath - The basePath used for matching the onboarding location
 **/
export const StepGuard: FCCC<{ basePath: string }> = ({
  basePath,
  children,
}) => {
  const [isAnonymousUser] = useIsAnonymousUser();
  const { dataContext, setDataContext } = useContext(DataContext);
  const parameters = useMatch(`${basePath}/:step/:substep`);
  const step = parameters?.params.step
    ? Number.parseInt(parameters.params.step)
    : 1;
  useEffect(() => {
    setDataContext((prev) => ({
      ...prev,
      // If no `allowedMaxOnboardingStep` is set, we fallback to 1.
      allowedMaxOnboardingStep: prev.allowedMaxOnboardingStep ?? 1,
    }));
  }, [setDataContext]);

  // Currently, the anonymous user usage is exclusive to the esim onboarding.
  // In that scenario, the user should never be able to visit step 1.
  // Therefore, if an anonymous user attempts to visit step 1,
  // they are redirected to step 2.
  if (isAnonymousUser && step === 1) {
    return <Navigate to={`${basePath}/2/device-type`} replace />;
  }

  if (
    // If `allowedMaxOnboardingStep` is undefined, we should not navigate, as the onboarding is completed.
    dataContext?.allowedMaxOnboardingStep !== undefined &&
    dataContext.allowedMaxOnboardingStep < step
  ) {
    return (
      <Navigate
        to={`${basePath}/${dataContext.allowedMaxOnboardingStep}`}
        replace
      />
    );
  } else {
    return <>{children}</>;
  }
};
