/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type WatchSimWatchStatus =
  (typeof WatchSimWatchStatus)[keyof typeof WatchSimWatchStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WatchSimWatchStatus = {
  PENDING: "PENDING",
  ORDERED: "ORDERED",
  ACTIVE: "ACTIVE",
  LOCKED: "LOCKED",
  LOCK_REQUESTED: "LOCK_REQUESTED",
  ACTIVE_CANCELLATION_FLAGGED: "ACTIVE_CANCELLATION_FLAGGED",
  UNKNOWN: "UNKNOWN",
} as const;
