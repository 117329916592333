import "./QRCodeTile.scss";

import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRight, Exclamation } from "@/assets/icons/icons";
import {
  DeviceTile,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import { ExtendedSimCardDetailWithEsimInfo } from "@/hooks/useExtendedSims";
import { getIconForDevice, isEsimQRInProgress } from "@/utils/deviceUtils";

export const QRCodeTile = ({
  device,
}: {
  device: ExtendedSimCardDetailWithEsimInfo;
}) => {
  const { t } = useTranslation("portal");
  const navigate = useNavigate();

  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );
  const icon = getIconForDevice(device.device?.description);
  const eSimInProgress = isEsimQRInProgress(device);

  return (
    <DeviceTile dataTestid="qr-code-tile" icon={icon}>
      <h2 data-testid="tile-title" className="tile-title">
        {title}
      </h2>
      <h3 className="tile-subtitle">
        {t("layout.tiles.qrCodeTile.esimProfile")}
      </h3>

      <div className="qr-code-wrapper">
        {eSimInProgress ? (
          <>
            <p className="hint-text font-x-small mb-2">
              {t("layout.tiles.qrCodeTile.inProcessText")}
            </p>
            <p className="hint-text font-x-small">
              {t("layout.tiles.qrCodeTile.qrPerEMail")}
            </p>
            <div className="button-wrapper">
              <button className="button mt-auto pointer-events-none opacity-50">
                {t("layout.tiles.qrCodeTile.isInProcess")}
                <Exclamation className="text-highlight-50" />
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="hint-text font-x-small">
              {t("layout.tiles.qrCodeTile.activateText")}
            </p>
            <a
              href={t("layout.tiles.qrCodeTile.instructionsLink")}
              target="_blank"
              className="instructions-link font-x-small"
              rel="noreferrer"
            >
              {t("layout.tiles.qrCodeTile.showInstructions")}
            </a>
            <QRCode matchingId={device.matchingId} />
            <div className="button-wrapper">
              <button
                className="button mt-auto"
                onClick={() => {
                  navigate(`../manage?simId=${device.simId}`);
                }}
              >
                {t("common.buttons.continue")}
                <ArrowRight className="text-highlight-100" />
              </button>
            </div>
          </>
        )}
      </div>
    </DeviceTile>
  );
};

export const QRCode = ({ matchingId }: { matchingId: string }) => {
  return (
    <div className="mx-auto">
      <QRCodeSVG className="mt-6" value={matchingId} size={116} />
    </div>
  );
};
