import "./HintOverlay.scss";

import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Close } from "@/assets/icons/icons";

export const HintOverlay = ({
  visible = false,
  setVisible,
  hint,
}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hint: ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="hintOverlay"
      id="hintOverlay"
      className={visible ? "isVisible" : "isInvisible"}
    >
      <div id="opaqueBackground" />
      <div
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (target.id === "sim-hint-overlay") {
            setVisible(false);
          }
        }}
        id="sim-hint-overlay"
      >
        <div className="card">
          <div className="text-container">
            <div className="header-row">
              <span className="header">
                {t("Onboarding.sections.sim-verification.hint.header")}
              </span>
              <button
                type="button"
                onClick={() => setVisible(false)}
                className="h-6 w-6"
              >
                <Close />
              </button>
            </div>
            <span>{t("Onboarding.sections.sim-verification.hint.text")}</span>
          </div>
          <div className="image-container">{hint}</div>
        </div>
      </div>
    </div>
  );
};
