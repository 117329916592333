import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useAtom } from "jotai";
import isEmpty from "lodash/isEmpty";
import { Fragment, useEffect, useRef, useState } from "react";

import { CheckSingle, Close, Exclamation, Info } from "@/assets/icons/icons";
import { AlertInterface, alertListAtom, AlertTypes } from "@/utils/atoms";

export const Alert = ({
  timeoutDuration = 5000,
}: {
  timeoutDuration?: number;
}) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [visible, setVisible] = useState(false);
  const [currentAlert, setCurrentAlert] = useState<AlertInterface>();
  const [alertList, setAlertList] = useAtom(alertListAtom);

  useEffect(() => {
    if (!visible && !currentAlert && !isEmpty(alertList)) {
      setCurrentAlert(alertList[0]);
      setVisible(true);
      setAlertList(([_, ...rest]) => rest);
      timeout.current = setTimeout(() => setVisible(false), timeoutDuration);
    }
  }, [visible, currentAlert, alertList, setAlertList, timeoutDuration]);

  useEffect(() => () => clearTimeout(timeout.current), []);

  const getIcon = () => {
    switch (currentAlert?.type) {
      case AlertTypes.info:
        return <Info className="w-6 h-6 text-primary-100" />;
      case AlertTypes.success:
        return <CheckSingle className="w-6 h-6 text-primary-100" />;
      default:
        return <Exclamation className="w-6 h-6 text-white" />;
    }
  };

  const getColor = () => {
    switch (currentAlert?.type) {
      case AlertTypes.info:
        return "bg-primary-25";
      case AlertTypes.success:
        return "bg-highlight-25";
      case AlertTypes.error:
        return "bg-secondary-100";
      case AlertTypes.warning:
        return "bg-primary-100";
    }
  };

  const whiteAlert =
    currentAlert?.type === AlertTypes.error ||
    currentAlert?.type === AlertTypes.warning;

  return (
    <div
      aria-live="assertive"
      className="fixed inset-x-0 bottom-4 px-4 py-6 pointer-events-none z-[100] md:inset-x-20 xl:inset-x-96"
    >
      <div className="w-full">
        <Transition
          show={visible}
          as={Fragment}
          enter="duration-300 ease-out"
          enterFrom="translate-y-full opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="duration-750 ease-out"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="translate-y-full opacity-0"
          afterLeave={() => setCurrentAlert(undefined)}
        >
          <div
            role="alertdialog"
            aria-labelledby="alert-title"
            aria-describedby="alert-text"
            className={clsx(
              getColor(),
              "max-w-sm w-full mx-auto py-2 px-4 pointer-events-auto md:max-w-2xl rounded-[3px] overflow-hidden",
            )}
          >
            <div className="flex flex-row py-2">
              <div>{getIcon()}</div>
              <div className="flex-1">
                <p
                  id="alert-text"
                  className={clsx(
                    "ml-3 text-base text-left",
                    whiteAlert ? "text-white" : "text-primary-100",
                  )}
                >
                  {currentAlert?.text}
                </p>
              </div>
              <div className="shrink-0 ml-2">
                <button
                  className="transition-transform origin-center hover:rotate-90"
                  type="button"
                  onClick={() => {
                    clearTimeout(timeout.current);
                    setVisible(false);
                  }}
                  aria-label="Close current alert"
                >
                  <Close
                    className={clsx(
                      "w-4 h-4",
                      whiteAlert ? "text-white" : "text-primary-100",
                    )}
                  />
                  <span className="sr-only">Close current alert</span>
                </button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};
