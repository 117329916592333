import { Globe } from "@/assets/icons/icons";
import { DueNow } from "@/components/Layout/DueNow/DueNow";
import { Basket } from "@/services/model";
import { getCurrencyFromLookupValue } from "@/utils/translationHelpers";

type DueNowProps = {
  basket: Basket;
  flatName?: string;
  billingPeriod: string;
};

export const RoamingDueNow = ({
  basket,
  billingPeriod,
  flatName,
}: DueNowProps) => {
  const entry = basket.entries[0];
  const option = entry.options?.length ? entry?.options[0] : undefined;

  const totalOnce = basket.total?.oneTime?.amount || 0;
  const currency = getCurrencyFromLookupValue(basket.currency || { id: 1 });

  const flatPrice =
    option?.oneTimeCharges?.[0] && !!option.oneTimeCharges[0].amount
      ? option?.oneTimeCharges[0].amount
      : 0;

  return (
    <DueNow
      currency={currency}
      icon={<Globe />}
      totalOnce={totalOnce}
      dataRateName={flatName}
      dataRatePrice={flatPrice}
      billingPeriod={billingPeriod}
    />
  );
};
