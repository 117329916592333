import { QueryClientProvider } from "@tanstack/react-query";
import { FC } from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import { Alert } from "./components/Interface/Alert/Alert";
import { useHandleError } from "./hooks/useHandleError";
import { useQueryClient } from "./hooks/useQueryClient";
import { routes } from "./router/Routing";

const router = createHashRouter(createRoutesFromElements(routes));

export const Main: FC = () => {
  const handleError = useHandleError();
  const queryClient = useQueryClient(handleError);

  return (
    <QueryClientProvider client={queryClient}>
      <Alert />
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};
