import { countries } from "@/constants/countries";
import en from "@/locales/en/translation.json";

export const getCountryFromId = (id: number | undefined) => {
  return id ? countries.filter((value) => value.ID === id)?.[0] : countries[0];
};

export const getCountryAlpha3FromId: (
  id: number | undefined,
) => keyof typeof en.translation.countries = (id) => {
  return id ? countries.filter((value) => value.ID === id)?.[0].ALPHA3 : "CHE";
};

export const getCountryAlpha2FromAlpha3 = (alpha3: string | undefined) => {
  return alpha3
    ? countries.filter((value) => value.ALPHA3 === alpha3)?.[0].ALPHA2 || ""
    : "";
};
