import { useTranslation } from "react-i18next";

import { MissingTwoFactorIcon } from "@/assets/icons/icons";
import { StyledLink } from "@/components/Interface/Button/Button";
import { portalRoutes } from "@/router/routes/portalRoutes";

import { WarningHeader } from "./KYCWarning/WarningHeader";

export const TwoFactorWarning = () => {
  const { t } = useTranslation();

  return (
    <WarningHeader
      title={t("portal:layout.tiles.upsellSim.twoFactor.title")}
      info={t("portal:layout.tiles.upsellSim.twoFactor.text")}
      icon={<MissingTwoFactorIcon />}
      button={
        <StyledLink
          to={portalRoutes.user.security.base}
          className="inverted secondary w-fit whitespace-nowrap"
        >
          {t("portal:layout.tiles.upsellSim.twoFactor.button")}
        </StyledLink>
      }
    />
  );
};
