import "@/components/Portal/Cockpit/DeviceManagement/Tariff/AutomaticRenewal/AutomaticRenewalChange.scss";

import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FCC } from "types";

import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { DueNowLightInterface } from "@/components/Layout/DueNowLight/DueNowLightInterface";
import { DueRecurringCard } from "@/components/Layout/DueRecurringCard/DueRecurringCard";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { useAlert } from "@/hooks/useAlert";
import { useSetAutoRenew } from "@/services/api";
import { AlertTypes } from "@/utils/atoms";
import {
  getBaseFlatName,
  getDeviceDescription,
  getExtendedFlatNameFromLocalFlatSpeed,
  getIconForDevice,
  hasDifferentSuccessor,
} from "@/utils/deviceUtils";

export const AutomaticRenewalChange: FCC = () => {
  const { t: tPrefixed } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.details.sections.autoRenewal",
  });
  const { t } = useTranslation("portal");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const showAlert = useAlert();

  const { data: simDetail, isLoading } = useDisplayedSim();

  const { mutate: sendSetAutoRenew, isLoading: isLoadingSetAutoRenew } =
    useSetAutoRenew({
      mutation: {
        onSuccess: () => {
          // Added in order to trigger a refetching in the DeviceManagement
          queryClient.invalidateQueries(["/sim/sim"]).then(() => {
            navigate(`../display?simId=${simDetail?.simId}`, {
              replace: true,
            });
            showAlert({
              type: AlertTypes.success,
              text: tPrefixed(
                autoRenewalActive
                  ? "disable.successMessage"
                  : "enable.successMessage",
              ),
            });
          });
        },
      },
    });

  const changeAutoRenew = (newValue: boolean) => {
    if (simDetail?.simId) {
      sendSetAutoRenew({
        simId: simDetail?.simId,
        params: { autoRenew: newValue },
      });
    }
  };

  const autoRenewalActive = !!simDetail?.autoRenew;
  const flatName = simDetail ? getBaseFlatName(simDetail) : "";
  const deviceIcon = getIconForDevice(
    getDeviceDescription(simDetail?.device?.description),
  );

  return !isLoading && simDetail ? (
    <div id="checkout-renewal">
      <h3 className="text-secondary-100 mb-6">
        {tPrefixed(autoRenewalActive ? "disable.title" : "enable.title")}
      </h3>
      <div
        id="checkout-layout"
        className={
          autoRenewalActive
            ? "checkout-layout-disable"
            : "checkout-layout-enable"
        }
      >
        <div id="summary">
          {autoRenewalActive ? (
            <>
              <div className="text-wrapper">
                <p>
                  <Trans
                    t={tPrefixed}
                    values={{ count: simDetail.remainingDays ?? 0 }}
                  >
                    disable.description
                  </Trans>
                </p>
                <p className="font-semibold text-primary-100">
                  {getExtendedFlatNameFromLocalFlatSpeed(
                    simDetail.localFlatSpeed,
                  )}
                </p>

                {hasDifferentSuccessor(simDetail) && (
                  <p>
                    <Trans
                      t={t}
                      values={{
                        flatName: getExtendedFlatNameFromLocalFlatSpeed(
                          simDetail?.successorForRenew?.description,
                        ),
                      }}
                      i18nKey="cockpit.managementTile.details.sections.autoRenewal.disable.successorWarning"
                    />
                  </p>
                )}
              </div>

              <HighlightPanel>{tPrefixed("disable.goodToKnow")}</HighlightPanel>
            </>
          ) : (
            <>
              <div className="text-wrapper">
                <p>
                  <Trans
                    t={tPrefixed}
                    values={{ days: simDetail?.minContractDuration }}
                  >
                    enable.description
                  </Trans>
                </p>
                <p className="font-semibold text-primary-100">
                  {getExtendedFlatNameFromLocalFlatSpeed(
                    simDetail.localFlatSpeed,
                  )}
                </p>
              </div>

              <HighlightPanel>{tPrefixed("enable.goodToKnow")}</HighlightPanel>
            </>
          )}
        </div>

        <div id="due-cards">
          {!autoRenewalActive && (
            <>
              <DueNowLightInterface
                totalOnce={0}
                currency="CHF"
                icon={deviceIcon}
              />
              <DueRecurringCard
                remainingDays={simDetail.remainingDays}
                totalReoccurring={simDetail.priceTotal ?? -1}
                currency="CHF"
                flatName={flatName}
                billingPeriod={simDetail.minContractDuration ?? 30}
                flatPrice={simDetail.priceBase ?? -1}
                flatOptionPrice={simDetail.priceVoice}
                flatOption={tPrefixed("summary.voiceOption")}
                watchSimName={t("common.device.attributes.watchSim")}
                watchSimPrice={simDetail.priceWatch}
              />
            </>
          )}
        </div>
      </div>

      <ConfirmationButtonGroup
        disableSuccessButton={isLoadingSetAutoRenew}
        successText={t("common.buttons.confirm")}
        successAction={() => changeAutoRenew(!autoRenewalActive)}
        cancelText={t("common.buttons.cancel")}
        cancelAction={() =>
          navigate(`../display?simId=${simDetail?.simId}`, { replace: true })
        }
      />
    </div>
  ) : (
    <LoadingSpinner />
  );
};
