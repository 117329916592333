import { TwoFactorSmsPhonenumberChange } from "@/components/Portal/UserAccount/Security/Change/TwoFactorSmsPhonenumberChange";
import { TwoFactorChangeTitle } from "@/components/Portal/UserAccount/Security/TwoFactorChangeTitle";

export const TwoFactorChangeSmsView = () => {
  return (
    <div className="max-w-[560px] flex flex-col gap-6">
      <TwoFactorChangeTitle />
      <TwoFactorSmsPhonenumberChange />
    </div>
  );
};
