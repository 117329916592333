import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAlert } from "@/hooks/useAlert";
import { AlertTypes } from "@/utils/atoms";
import { log } from "@/utils/log";

export const useHandleError = () => {
  const { t } = useTranslation();
  const showAlert = useAlert();

  const handleError = useCallback(
    (err: unknown, errorMessage: string = t("Error.response")) => {
      showAlert({ type: AlertTypes.error, text: errorMessage });
      log(err);
    },
    [t, showAlert],
  );

  return handleError;
};
