/**
 * Generated by orval v6.14.3 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 97.0.0-SNAPSHOT
 */

export type ESimInfoEsimactionNotAllowedReasonItem =
  (typeof ESimInfoEsimactionNotAllowedReasonItem)[keyof typeof ESimInfoEsimactionNotAllowedReasonItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ESimInfoEsimactionNotAllowedReasonItem = {
  SimSuspended: "SimSuspended",
  SimInactive: "SimInactive",
  NoMoreActions: "NoMoreActions",
  WrongSimType: "WrongSimType",
  NoAutoRenew: "NoAutoRenew",
  SimNoActivePackage: "SimNoActivePackage",
  SimTerminated: "SimTerminated",
  SimCancelled: "SimCancelled",
  StatusNotAllowed: "StatusNotAllowed",
} as const;
