interface WarningHeader {
  title: string;
  info: string;
  icon: React.ReactNode;
  button: React.ReactNode;
  children?: React.ReactNode;
}
export const WarningHeader = (props: WarningHeader) => {
  return (
    <div className="border-l-2 border-secondary-100 pl-5 md:border-none xl:pl-20 flex mt-10 md:gap-12 xl:flex xl:flex-row xl:items-center xl:gap-8">
      <div className="self-center hidden md:block">{props.icon}</div>

      <div className="flex-col items-center xl:flex xl:flex-row xl:gap-[75px]">
        <div className="mb-4 xl:mb-0">
          <h2 className="text-secondary-100 text-left mb-6 md:mb-2">
            {props.title}
          </h2>
          <p className="text-left leading-snug">{props.info}</p>
          {props.children}
        </div>
        <div>{props.button}</div>
      </div>
    </div>
  );
};
