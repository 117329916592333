import { Basket, Device } from "@/services/model";
import { TransformedProductsById } from "@/utils/tariffUtils";

export const isSmartphoneInBasket = (basket?: Basket): boolean =>
  isDeviceSmartphone(basket?.entries[0]?.device);

export const isDeviceSmartphone = (device?: Device): boolean =>
  !!device && device.id === 1;

export const mobileAbos = ["131601", "131611"];
export const mobileAboRoamingPackages = [131208, 131209];

export const getMobileAbos = (
  processedProductsById: TransformedProductsById | undefined,
) =>
  processedProductsById
    ? Object.values(processedProductsById || {}).filter((product) =>
        product.shortName?.includes("Mobile Abo"),
      )
    : [];
