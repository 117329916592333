import "./LandingBlob.scss";

import { FCC } from "types";

export const LandingBlob: FCC = ({ children }) => {
  return (
    <main
      id="blob"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='1416' height='1416' viewBox='0 0 1416 1416' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M522.136 157.279c-170.868-27.13-332.746 89.132-361.122 259.779-17.118 101.944 16.843 200.899 83.405 270.472 36.886 38.635 53.513 92.549 44.208 145.317l-.586 3.324C260.496 1006.97 377.1 1169.33 547.332 1197.63c88.232 14.71 173.771-8.77 240.267-57.88 21.652-15.89 48.829-21.81 75.421-17.12l.415.07c208.585 36.78 407.235-103.41 442.775-312.211 34.38-204.719-102.78-401.128-307.332-438.909-7.895-1.392-15.374-2.711-22.926-3.614-108.224-13.085-209.903-58.433-290.298-132.158-44.365-39.954-100.216-68.224-163.518-78.529Z' fill='%23fff' /%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(-80 717.102 601.72)' d='M0 0h1222v1222H0z' /%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")`,
      }}
    >
      <div id="text-container" data-testid="welcome-content">
        {children}
      </div>
    </main>
  );
};
