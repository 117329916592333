import "./VoiceOption.scss";

import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { VoiceOptionCall } from "@/assets/icons/icons";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { FreeEsimTrialTooltip } from "@/components/Onboarding/Steps/RateSelection/FreeEsimTrialTooltip/FreeEsimTrialTooltip";
import { OptionContainer } from "@/components/Onboarding/Steps/RateSelection/OptionContainer/OptionContainer";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { TransformedProduct } from "@/utils/tariffUtils";

interface Props {
  currentVoice?: TransformedProduct;
  hasFreeTrial: boolean;
  recommendVoice: boolean;
  prechargeVoice: boolean;
}

export const VoiceOption: FC<Props> = ({
  currentVoice,
  hasFreeTrial,
  recommendVoice,
  prechargeVoice,
}) => {
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<RateFormProps>();

  const voiceIsSelected = watch("voice") !== undefined && watch("voice");

  const hasNecessaryData =
    currentVoice?.minContractDur && currentVoice?.chargeVoice;

  return (
    <div className="mt-4" data-testid="voice-option">
      <p className="mb-12">
        {t("translation:Onboarding.sections.rate-selection.wouldYouLikeTo")}
        <br />
        <span>
          <Trans
            t={t}
            components={[
              <a
                key="voiceOptionLink"
                href={t(
                  "translation:Onboarding.sections.rate-selection.voiceOptionLink",
                )}
                className="text-primary-100 underline"
                target="_blank"
                rel="noreferrer"
              />,
            ]}
            i18nKey="translation:Onboarding.sections.rate-selection.discoverVoiceOption"
          />
        </span>
      </p>
      <OptionContainer
        selected={voiceIsSelected}
        highlighted
        recommended={recommendVoice}
        precharged={prechargeVoice}
        voice
      >
        <div className="voice-container">
          <div className="descriptions">
            <div className="title">
              <VoiceOptionCall className="phone" />
              <span>
                {t("translation:Onboarding.sections.rate-selection.callAndSMS")}
              </span>
            </div>
          </div>
          <div className="durations">
            {hasNecessaryData ? (
              <>
                <div className="days">
                  {currentVoice?.minContractDur}{" "}
                  {t("translation:Onboarding.sections.rate-selection.days")}
                </div>
                <div id="price">
                  <div className="checkbox">
                    <Checkbox
                      id="voice"
                      name="voice"
                      label={`+ ${currentVoice?.chargeVoice}`}
                      errors={errors}
                      register={register}
                    />
                  </div>

                  <div className="days-label">
                    {currentVoice?.minContractDur}{" "}
                    {t("translation:Onboarding.sections.rate-selection.days")}
                  </div>
                </div>
                {hasFreeTrial && (
                  <p className="flex mt-4 col-span-2 font-x-small whitespace-break-spaces">
                    <span className="mr-2">
                      {t(
                        "translation:Onboarding.sections.rate-selection.esim-trial-hint",
                      )}
                    </span>
                    <FreeEsimTrialTooltip leftOnMobile={true} />
                  </p>
                )}
              </>
            ) : (
              <p className="font-normal break-words whitespace-normal">
                {t(
                  "translation:Onboarding.sections.rate-selection.voiceOptionRequirement",
                )}
              </p>
            )}
          </div>
        </div>
      </OptionContainer>
    </div>
  );
};
