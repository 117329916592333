export const env = {
  isProd: import.meta.env.MODE === "production",
  isTest: import.meta.env.MODE === "test",
  isDev: import.meta.env.MODE === "development",
  clientNumericId: Number(import.meta.env.VITE_APP_CLIENT_NUMERIC_ID),
  clientAppId: import.meta.env.VITE_APP_CLIENT_ID,
  publicUrl: import.meta.env.BASE_URL,
  releaseNumber: import.meta.env.VITE_APP_RELEASE,
};

export default env;
