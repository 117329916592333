import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { StyledLink } from "@/components/Interface/Button/Button";
import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { mobileAboRoamingPackages } from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import { BookedRoamingOption } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/ShowRoamingOptions/BookedRoamingOption/BookedRoamingOption";
import { RoamingGoodToKnow } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/ShowRoamingOptions/RoamingGoodToKnow/RoamingGoodToKnow";
import { useRoamingInfoForDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/useRoamingInfoForDisplayedSim";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { RoamingInfo, RoamingInfoRoamingStatus } from "@/services/model";
import { canOrderRoaming } from "@/utils/deviceUtils";

/** Displays the currently-booked roaming options for the selected sim.
 *
 * Also shows the "Add roaming option" button if there are less than 3 options booked,
 * as the user cannot book more than three options.
 *
 * If there are no options booked, it shows the "Roaming good to know" section.
 * */
export const ShowRoamingOptions: FCC = () => {
  const { t } = useTranslation(["portal"], {
    keyPrefix: "cockpit.managementTile.roaming",
  });

  const { data: simDetail } = useDisplayedSim();

  const orderRoamingNotAllowed = !!simDetail && !canOrderRoaming(simDetail);

  const roamingInfo = useRoamingInfoForDisplayedSim();

  const orderInProgress = roamingInfo?.some(
    (roaming) => roaming.roamingStatus === RoamingInfoRoamingStatus.ordered,
  );

  // We don't want to show not available roaming options.
  // Defaults to empty array so we show 'no roaming' content while loading.
  const roamingOptions = roamingInfo
    ? roamingInfo.filter(
        (roaming) => roaming.roamingStatus !== RoamingInfoRoamingStatus.na,
      )
    : [];
  const addRoamingOptionTexts =
    roamingOptions.length === 0
      ? {
          title: t("title"),
          sectionHeader: t("availableOptions"),
          button: t("showOption.inactive.bookOption"),
        }
      : {
          title: t("package", {
            index: roamingOptions.length + 1,
          }),
          sectionHeader: t("showOption.inactive.option"),
          button: t("showOption.inactive.bookAnotherOption"),
        };

  const mobileAboRoamingPackage = roamingOptions.find(
    (roaming) =>
      !!roaming.optionId && mobileAboRoamingPackages.includes(roaming.optionId),
  );
  const hasMobileAboRoaming = !!mobileAboRoamingPackage;

  const doesNotIncludeMobileAboRoaming = (option: RoamingInfo) =>
    option.optionId && !mobileAboRoamingPackages.includes(option.optionId);

  const isMonthlyMobileAboRoaming =
    !!mobileAboRoamingPackage && mobileAboRoamingPackage.optionId === 131208;
  return (
    <div className="management-grid-layout">
      {hasMobileAboRoaming && (
        <>
          <BookedRoamingOption
            key={`${mobileAboRoamingPackage.simId}-${mobileAboRoamingPackage.optionId}`}
            roamingInfo={mobileAboRoamingPackage}
            index={1}
            isFirstOfUsageZone={true}
          />
          {isMonthlyMobileAboRoaming && <MobileAboRoamingGoodToKnow />}
          <div className="col-span-1 md:col-span-2 text-primary-100 font-semibold gap-8 flex flex-row">
            <hr className="w-full border-primary-25" />
            <span className="whitespace-nowrap text-base mt-1">
              {t("portal:further-packages")}
            </span>
            <hr className="w-full border-primary-25" />
          </div>
        </>
      )}

      {roamingOptions
        .filter(doesNotIncludeMobileAboRoaming)
        .map((currentOption, currentIndex) => {
          // Since the roaming options for the same usage zone will be activated in
          // the same order we get them in this array, we can use the index of the first
          // option in the current zone to know if that is the current option or not.
          const indexOfFirstOptionInZone = roamingOptions.findIndex(
            (option) => option.usageZone === currentOption.usageZone,
          );

          return (
            <BookedRoamingOption
              key={`${currentOption.simId}-${currentOption.optionId}`}
              roamingInfo={currentOption}
              index={hasMobileAboRoaming ? currentIndex + 1 : currentIndex}
              isFirstOfUsageZone={indexOfFirstOptionInZone === currentIndex}
            />
          );
        })}

      <div data-testid="add-roaming" className="flex flex-col gap-14 h-min">
        <div>
          <h3 className="text-secondary-100 font-semibold mb-6">
            {addRoamingOptionTexts.title}
          </h3>

          <div id="roaming-option">
            <SectionButtonHeader header={addRoamingOptionTexts.sectionHeader} />

            <span className="font-semibold text-primary-100">
              {t("showOption.inactive.noOptionActive")}
            </span>
          </div>
        </div>

        <StyledLink
          to={`../booking?simId=${simDetail?.simId}`}
          className="accent w-full"
          replace
          data-testid="add-roaming-option-button"
          disabled={orderInProgress || orderRoamingNotAllowed}
        >
          {addRoamingOptionTexts.button}
        </StyledLink>
      </div>

      {roamingOptions.length === 0 && <RoamingGoodToKnow />}
    </div>
  );
};

export const MobileAboRoamingGoodToKnow = () => {
  const { t } = useTranslation("portal");

  return (
    <HighlightPanel
      data-testid="roaming-good-to-know"
      className="h-min md:col-span-2 xl:col-span-1"
      headline={t("layout.goodToKnow")}
    >
      <p>{t("mobileAbo.roamingGoodToKnow")}</p>
    </HighlightPanel>
  );
};
