import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ListBoxMultiple } from "@/components/Interface/FormFields/ListBoxMultiple/ListBoxMultiple";
import {
  AdditionalColumns,
  additionalColumns,
  FilterDialogForm,
} from "@/components/Portal/Cockpit/Filter/Filter";

interface Props {
  control: Control<FilterDialogForm, any>;
}

type columns =
  | "roamingDataConsumption"
  | "roamingOpenRuntime"
  | "roamingStatus"
  | "telephoneNumber"
  | "iccid";

export const ColumnFilter: FC<Props> = ({ control }) => {
  const { t } = useTranslation("portal");

  return (
    <div>
      <Controller<FilterDialogForm>
        control={control}
        name="additionalColumns"
        render={({ field: { onChange, value } }) => (
          <ListBoxMultiple<columns>
            selected={
              (typeof value === "string" ? [value] : value) as [
                ...AdditionalColumns,
              ]
            }
            setSelected={onChange}
            displayValue={(value) => t(`common.device.attributes.${value}`)}
            availableValues={[...additionalColumns]}
            placeholder={t("cockpit.filter.dropdowns.chooseColumns")}
            itemLabel={t("cockpit.filter.dropdowns.column")}
          />
        )}
      />
    </div>
  );
};
