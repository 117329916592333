/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type DatatransPaymentResponseStatus =
  (typeof DatatransPaymentResponseStatus)[keyof typeof DatatransPaymentResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DatatransPaymentResponseStatus = {
  OK: "OK",
  ERROR: "ERROR",
} as const;
