import { FocusEventHandler } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

export type ErrorMessageProp = {
  // Key can only be a string that ends with "Error"
  [K in `${string}Error`]: string;
};

export type CustomRegisterOptions = RegisterOptions &
  ErrorMessageProp & {
    readOnly?: boolean;
  };

export interface FormFieldProps<TFormValues extends FieldValues> {
  id: string;
  name: Path<TFormValues>;
  label?: string;
  ariaLabel?: string;
  options?: CustomRegisterOptions;
  showRequiredIndicator?: boolean;
  errors: FieldErrors;
  register: UseFormRegister<TFormValues>;
  type?: HTMLInputElement["type"];
  autocomplete?: string;
  placeholder?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: string | number;
  maxLength?: number;
  passwordRules?: string;
  icon?: JSX.Element;
  className?: string;
}

export function extendFormOptions(
  options: CustomRegisterOptions,
  t: any,
): CustomRegisterOptions {
  return {
    ...options,
    maxLength:
      // if only the number of max allowed characters was given, we add a default error message
      typeof options?.maxLength === "number"
        ? {
            value: options.maxLength,
            message: t("label.validation.maxLength", {
              replace: {
                max: options.maxLength,
              },
            }),
          }
        : options?.maxLength,
  };
}

export function getFormFieldError(
  errors: any,
  name: any,
  optionsWithParsedMaxLength: CustomRegisterOptions,
) {
  const errorType = errors[name]?.type;
  // We aim to utilize the message available in the options object to enable dynamic translation
  // when there is a change in the error.
  // Given the various checks we perform, we can confirm that we have a valid error message.
  // If necessary, we can fall back on the actual message provided in the errors object.
  const error =
    errorType &&
    typeof errorType === "string" &&
    errorType in optionsWithParsedMaxLength &&
    // @ts-ignore
    optionsWithParsedMaxLength[errorType];

  let errorMessage: string = "";
  if (typeof errors[name]?.message === "string") {
    errorMessage = errors[name]?.message;
  } else if (typeof error === "object" && typeof error.message === "string") {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  }
  return errorMessage;
}
