import { TFuncKey } from "i18next";
import { FC } from "react";

import { Section } from "@/components/Onboarding/Onboarding.constants";

import { WizardStep } from "./WizardStep/WizardStep";

export interface StepsProps {
  sections: Section[];
  currentStep: number;
  currentSubStep: string | undefined;
  property?: PropertyKey;
}

export const Steps: FC<StepsProps> = ({ sections, ...restProps }) => (
  <>
    {sections.map((section, index) => (
      <WizardStep
        key={section.title}
        {...restProps}
        {...section}
        title={section.title as TFuncKey}
        step={index + 1}
        last={index === sections.length - 1}
      />
    ))}
  </>
);
