import "./SimSelection.scss";

import keys from "lodash/keys";
import size from "lodash/size";
import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ESim } from "@/assets/icons/icons";
import SimCard from "@/assets/icons/SimCard.svg?react";
import { Anchor } from "@/components/Interface/Button/Button";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { IconTile } from "@/components/Onboarding/IconTile/IconTile";
import { OPTIONS } from "@/components/Onboarding/Onboarding.constants";
import { Step } from "@/components/Onboarding/Steps/Step";
import { useIsOnboarding } from "@/hooks/useIsOnboarding";
import { useWizardBasePath } from "@/hooks/useWizard";
import { DataContext } from "@/provider/DataContextProvider";
import {
  useAddBasketEntry,
  useGetSimCustomerActions,
  useRemoveBasketEntry,
  useSaveBasket,
} from "@/services/api";
import { getEntry } from "@/utils/dataContextHelpers";

import { AddSimDisabledTooltip } from "./AddSimDisabledTooltip/AddSimDisabledTooltip";

/**
 * This is the first step of the onboarding.
 * It is used to check whether the customer wants to onboard a physical SIM (later called Tripple SIM)
 * or a eSIM. Both have their own specialities, which we will describe later.
 **/
export const SimSelection: FC = () => {
  const { dataContext, setDataContext } = useContext(DataContext);
  const basePath = useWizardBasePath();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isOnboarding = useIsOnboarding();
  // Need this data to determine if the user has reached limited amount of SIM possible.  If yes, they cannot add another.
  const { data: simCustomerInfo } = useGetSimCustomerActions();

  const canAddESim =
    (isOnboarding
      ? // In onboarding, we want to check both attributes for legacy compatibility
        simCustomerInfo?.addESIMAllowed || simCustomerInfo?.canActivateESim
      : simCustomerInfo?.addESIMAllowed) ?? true;
  const canAddSim =
    (isOnboarding
      ? // In onboarding, we want to check both attributes for legacy compatibility
        simCustomerInfo?.addTripleSIMAllowed || simCustomerInfo?.canActivateSim
      : simCustomerInfo?.addTripleSIMAllowed) ?? true;

  // Get the query to create our basket
  const { mutate: getBasicBasket, isLoading: isSavingBasket } = useSaveBasket({
    mutation: {
      onSuccess: (data) => {
        setDataContext((prev) => ({
          ...prev,
          basket: data,
        }));
      },
    },
  });

  const { mutateAsync: addBasketEntry, isLoading: isAddingBasketEntry } =
    useAddBasketEntry({
      mutation: {
        onSuccess: (data) => {
          setDataContext((prev) => ({
            ...prev,
            basket: data,
          }));
        },
      },
    });

  const { mutateAsync: removeBasketEntry, isLoading: isRemovingBasketEntry } =
    useRemoveBasketEntry({
      mutation: {
        onSuccess: (data) => {
          setDataContext((prev) => ({
            ...prev,
            basket: data,
          }));
        },
      },
    });

  useEffect(() => {
    // Only initialize a new basket if theres none in the dataContext
    // (which is loaded from localStorage, so only if there hasn't been one pre-reload e.g.)
    if (!dataContext.basket) getBasicBasket({});
  }, [dataContext.basket, getBasicBasket]);

  const addBaseBasketEntry = async ({ isESIM }: { isESIM: boolean }) => {
    const entries = dataContext.basket?.entries;

    // If we have a basket...
    if (dataContext.basket?.id) {
      // Check if we have a faulty (aka more than one element) basket state
      if (size(entries) > 1) {
        // Clear basket
        const indexes = keys(entries);

        // Can't use Promise.all here as the backend can't handle
        // multiple remove requests and would only do a partial removal
        for (const index of indexes) {
          await removeBasketEntry({
            index: Number.parseInt(index),
            basketId: dataContext.basket.id,
          });
        }
      }

      const entry = getEntry(dataContext);
      const basketNeedsRefresh = entry && entry.esim !== isESIM;

      if (basketNeedsRefresh) {
        // If we change sim type, we need to remove the old entry
        await removeBasketEntry({
          index: 0,
          basketId: dataContext.basket.id,
        });
      }

      if (!entry || basketNeedsRefresh) {
        // We don't have a basket entry already or removed the old entry
        await addBasketEntry({
          basketId: dataContext.basket.id,
          productId: OPTIONS.BASE_OPTION,
          params: { isESIM },
        });
      }

      setDataContext((prev) => ({ ...prev, allowedMaxOnboardingStep: 2 }));
      if (isESIM) {
        // eSIM does not need verification, so we go to device-type next.
        navigate(`${basePath}/2/device-type`);
      } else {
        // physical SIM needs a ICCID and maybe a PUK.
        navigate(`${basePath}/1/sim-verification`);
      }
    }
  };

  const isLoading =
    isAddingBasketEntry || isRemovingBasketEntry || isSavingBasket;

  return (
    <Step
      id="onboarding-select-sim"
      data-testid="select-sim"
      headline={t("Onboarding.sections.sim-selection.title")}
      subheadline={t("Onboarding.sections.sim-selection.subtitle")}
    >
      {isLoading && <LoadingSpinner />}

      <div id="add-device" className="content-wrapper">
        <div className="tile-column">
          {canAddSim ? (
            <IconTile
              label={t("Tile.label.activateSim")}
              icon={<SimCard />}
              onClick={() => addBaseBasketEntry({ isESIM: false })}
            />
          ) : (
            <AddSimDisabledTooltip
              reasons={simCustomerInfo?.addTripleSIMNotAllowedReason || []}
              dataTestId="sim-tooltip"
            >
              <IconTile
                dataTestId="sim-tile"
                icon={<SimCard />}
                label={t("Tile.label.activateSim")}
                disabled
              />
            </AddSimDisabledTooltip>
          )}

          <div className="h-3" />

          {canAddESim ? (
            <IconTile
              dataTestId="esim-tile"
              label={t("Tile.label.activateESim")}
              icon={<ESim />}
              onClick={() => addBaseBasketEntry({ isESIM: true })}
            />
          ) : (
            <AddSimDisabledTooltip
              reasons={simCustomerInfo?.addESIMNotAllowedReason || []}
              leftOnMobile={true}
              dataTestId="esim-tooltip"
            >
              <IconTile
                dataTestId="esim-tile"
                icon={<ESim />}
                label={t("Tile.label.activateESim")}
                disabled
              />
            </AddSimDisabledTooltip>
          )}
        </div>
        <div className="line-flair" />

        <div className="text-column">
          <h3 className="text-column-headline">
            {t("Onboarding.sections.sim-selection.noSimCardTitle")}
          </h3>

          <p className="font-regular text-column-subline">
            {t("Onboarding.sections.sim-selection.noSimCardText")}
          </p>

          <Anchor
            href={t("Onboarding.sections.sim-selection.shopLink")}
            className="inverted secondary max-w-fit"
            label={t("Common.label.toShop")}
            target="_blank"
          />
        </div>
      </div>
    </Step>
  );
};
