import "./Tooltip.scss";

import clsx from "clsx";
import { ReactNode, useRef } from "react";
import { FCC } from "types";

interface Props {
  // Textcontent of the tooltip, shown when hovering the children
  className?: string;
  content: string | ReactNode;
  dataTestId?: string;
  position?: "top" | "bottom";
  leftOnMobile?: boolean;
}

export const Tooltip: FCC<Props> = ({
  children,
  className,
  content,
  dataTestId,
  position = "top",
  leftOnMobile = false,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  return (
    <div
      onMouseOver={() => {
        if (tooltipRef.current?.hidden === true)
          tooltipRef.current.hidden = false;
      }}
      onMouseLeave={() => {
        if (tooltipRef.current?.hidden === false)
          tooltipRef.current.hidden = true;
      }}
      className="tooltip-container"
    >
      <div
        hidden
        ref={tooltipRef}
        data-testid={dataTestId}
        className={clsx(
          "tooltip",
          className,
          position,
          leftOnMobile && "left-on-mobile",
        )}
      >
        {content}
      </div>
      {children}
    </div>
  );
};
