import { FC } from "react";
import { useTranslation } from "react-i18next";

import { TableHeader } from "@/components/Layout/Table/TableHeader/TableHeader";
import { TableSortButton } from "@/components/Layout/Table/TableSortButton/TableSortButton";
import { AdditionalColumnMap } from "@/components/Portal/Cockpit/Filter/Filter";

interface Props {
  additionalColumns?: AdditionalColumnMap;
}

const enum availableSortFields {
  ID = "ID",
  SIMALIAS = "SIMALIAS",
  STATUSSIM = "STATUSSIM",
  SIMTYP = "SIMTYP",
  TARIF = "TARIF",
  VOICE = "VOICE",
  REMAININGDAYS = "REMAININGDAYS",
  SUCCESSOR = "SUCCESSOR",
  DEVICE = "DEVICE",
  ICCID = "ICCID",
  STATUSROAMING = "STATUSROAMING",
  MSISDN = "MSISDN",
}

export const DeviceListHeaderRow: FC<Props> = ({ additionalColumns }) => {
  const { t } = useTranslation("portal");
  const {
    roamingDataConsumption,
    roamingOpenRuntime,
    roamingStatus,
    telephoneNumber,
    iccid,
  } = additionalColumns || {};

  return (
    <TableHeader>
      <th data-testid="th-status" className="pl-6">
        <TableSortButton
          title={t("common.device.attributes.status")}
          sortField={availableSortFields.STATUSSIM}
        />
      </th>
      <th data-testid="th-type" className="center">
        <TableSortButton
          title={t("common.device.attributes.deviceType")}
          sortField={availableSortFields.DEVICE}
        />
      </th>
      <th data-testid="th-device-name">
        <TableSortButton
          title={t("common.device.attributes.deviceName")}
          sortField={availableSortFields.SIMALIAS}
        />
      </th>
      <th data-testid="th-device-simType">
        <TableSortButton
          title={t("common.device.attributes.simType")}
          sortField={availableSortFields.SIMTYP}
        />
      </th>
      <th data-testid="th-device-flat">
        <TableSortButton
          title={t("common.device.attributes.tariff")}
          sortField={availableSortFields.TARIF}
        />
      </th>
      <th data-testid="th-voice-option">
        <TableSortButton
          title={t("common.device.attributes.voiceOption")}
          sortField={availableSortFields.VOICE}
        />
      </th>
      <th data-testid="th-runtime">
        <TableSortButton
          title={t("common.device.attributes.openRuntime")}
          sortField={availableSortFields.REMAININGDAYS}
        />
      </th>
      <th data-testid="th-followups">
        <TableSortButton
          title={t("common.device.attributes.followupPackage")}
          sortField={availableSortFields.SUCCESSOR}
        />
      </th>
      {/*Optional Columns*/}
      {roamingDataConsumption && (
        <th data-testid="th-roaming-consumption">
          {t("common.device.attributes.roamingDataConsumption")}
        </th>
      )}
      {roamingOpenRuntime && (
        <th data-testid="th-roaming-open-runtime">
          {t("common.device.attributes.roamingOpenRuntime")}
        </th>
      )}
      {roamingStatus && (
        <th data-testid="th-roaming-status">
          <TableSortButton
            title={t("common.device.attributes.roamingStatus")}
            sortField={availableSortFields.STATUSROAMING}
          />
        </th>
      )}
      {telephoneNumber && (
        <th data-testid="th-number">
          {t("common.device.attributes.telephoneNumber")}
        </th>
      )}
      {iccid && (
        <th data-testid="th-ICCID">
          <TableSortButton
            title={t("common.device.attributes.iccid")}
            sortField={availableSortFields.ICCID}
          />
        </th>
      )}
      <th className="center">{t("common.device.attributes.manageDevice")}</th>
    </TableHeader>
  );
};
