import "./MobileSplashScreen.scss";

import { Transition } from "@headlessui/react";

import MobileFooter from "@/assets/footer/MobileFooter.svg?react";
import MobileHeader from "@/assets/header/MobileHeader.svg?react";
import Logo from "@/assets/welcome/logo.svg?react";
import { SplashScreenInterface } from "@/components/Layout/SplashScreens/SplashScreens";

/**
 * # Mobile splash screen
 * We display this on the first load of the website on mobile devices.
 * It is a white screen with the logo in the middle and two blue
 * waves coming in from the top and bottom.
 *
 **/
export const MobileSplashScreen = ({
  showTransition,
}: SplashScreenInterface) => (
  <Transition appear id="mobile-splash-screen" show={showTransition}>
    {/* White background to cover app content */}
    <Transition.Child
      leave="transition-opacity ease-out duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute h-screen w-screen z-0 bg-white" />
    </Transition.Child>

    {/* Top header wave */}
    <Transition.Child
      className="transition-transform"
      enter="ease-in duration-500"
      enterFrom="-translate-y-[800px]"
      enterTo="translate-y-0"
      leave="ease-out duration-500"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-[800px]"
    >
      <div className="absolute z-10 inset-x-0" id="top-bar">
        <MobileHeader />
      </div>
    </Transition.Child>

    {/* Bottom logo -
    We need the container setting the z-index for the logo to be outside of
    Transition.Child to avoid flickering caused by its delayed mount. */}
    <div id="splash-screen-logo-container">
      <Transition.Child
        className="transition-opacity"
        enter="delay-[400ms] ease-in duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Logo />
      </Transition.Child>
    </div>

    {/* Bottom wave */}
    <Transition.Child
      className="transition-transform"
      enter="ease-in duration-500"
      enterFrom="translate-y-[150vh]"
      enterTo="translate-y-[45vh]"
      leave="ease-out duration-500"
      leaveFrom="translate-y-[45vh]"
      leaveTo="translate-y-[150vh]"
    >
      <div className="absolute z-10 inset-x-0" id="bottom-bar">
        <MobileFooter />
      </div>
    </Transition.Child>
  </Transition>
);
