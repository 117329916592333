// TODO: This file can probably removed (like many others)

import {
  accessTokenAtom,
  refreshTokenAtom,
  store,
  verifierAtom,
} from "@/utils/atoms";

/**
 * Parse string to json and catch errors.
 * @param str - String to be parsed.
 * @returns [error || null, result? ] - Either [null, parsedJson] or [caughtError]
 */
export function safeJsonParse(str: string) {
  try {
    return [null, JSON.parse(str)];
  } catch (err) {
    return [err];
  }
}

export const setTokens = (
  access_token?: string,
  refresh_token?: string,
  verifier?: string,
) => {
  if (access_token) {
    store.set(accessTokenAtom, access_token);
  }
  if (refresh_token) {
    store.set(refreshTokenAtom, refresh_token);
  }
  if (verifier) {
    store.set(verifierAtom, verifier);
  }
};
