import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { useAlert } from "@/hooks/useAlert";
import { useLogout } from "@/hooks/useLogout";
import { AlertTypes } from "@/utils/atoms";

export const useLogoutOnTwoFactorError = () => {
  const showAlert = useAlert();
  const logout = useLogout();
  const { t } = useTranslation();

  return (error: AxiosError) => {
    if (
      error.response?.status === 401 ||
      error.response?.status === 403 ||
      !error.response
    ) {
      showAlert({
        text: t("portal:sessionExpired"),
        type: AlertTypes.error,
      });
      logout();
    }
  };
};

export const downloadCodes = (backupCodes: number[]) => {
  const blob = new Blob([backupCodes.join("\n")], {
    type: "text/plain;charset=utf-8",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "backup-codes.txt";
  a.click();
  URL.revokeObjectURL(url);
};

export const copyCodesToClipboard = (backupCodes: number[]) =>
  navigator.clipboard.writeText(backupCodes.join("\n"));
