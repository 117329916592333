import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { RemainingTime } from "@/components/Portal/Cockpit/RemainingTime/RemainingTime";

export const AutomaticRenewalBarGraph = () => {
  const { data: simDetail } = useDisplayedSim();

  // If the current sim has a watch sim, we need to compare if the successor will still have it
  // as the serviceID's will not be different
  const willNotHaveWatchSimAfterAR =
    simDetail?.localFlatSpeed?.includes("Watch") &&
    !simDetail.successorForRenew?.description?.includes("Watch");

  const hasNecessaryAttributes =
    simDetail !== undefined &&
    simDetail.autoRenew !== undefined &&
    simDetail.remainingDays !== undefined &&
    simDetail.minContractDuration !== undefined;

  if (hasNecessaryAttributes) {
    return (
      <RemainingTime
        className="h-fit mb-2"
        totalTime={simDetail.minContractDuration ?? 0}
        remainingTime={simDetail.remainingDays ?? 0}
        hasRenewal={simDetail.autoRenew ?? false}
        isPending={simDetail.servicePending}
        successorForRenew={
          simDetail.successorForRenew?.id !== simDetail.serviceID ||
          willNotHaveWatchSimAfterAR
            ? simDetail.successorForRenew
            : undefined
        }
      />
    );
  }
  return null;
};
