export interface Redirect {
  from: string;
  to: string;
}

// List of redirects, get converted to working redirects in ./Routing.tsx
const redirects: Redirect[] = [
  {
    from: "/dr/user",
    to: "/portal/cockpit/tiles",
  },
  {
    from: "/dr/user/payment/card",
    to: "/portal/user/payments",
  },
  {
    from: "/dr/user/dashboard",
    to: "/portal/cockpit/tiles",
  },
  {
    from: "/dr/user/check-id",
    to: "/portal/cockpit/tiles",
  },
  {
    from: "/dr/user/referral",
    to: "/portal/referral",
  },
];

export default redirects;
