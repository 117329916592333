/**
 * Renders the actions for managing a SIM card detail.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {SimCardDetail} props.simDetail - The SIM card detail.
 * @param {ESimInfo[]} props.esimInfo - The eSIM information.
 * @param {string} props.simId - The SIM card ID.
 * @returns {JSX.Element} The rendered component.
 */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { ESimInfo, SimCardDetail } from "@/services/model";
import {
  checkIfDeviceCanReloadEsim,
  checkIfDeviceCanTransferEsim,
} from "@/utils/deviceUtils";

import { EsimDisabledActionsTooltip } from "./EsimDisabledActionsTooltip/EsimDisabledActionsTooltip";

export const SimDetailActions = ({
  simDetail,
  esimInfo,
  simId,
}: {
  simDetail: SimCardDetail;
  esimInfo: ESimInfo[];
  simId: string;
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.details.sections.details",
  });
  const canReloadEsim = checkIfDeviceCanReloadEsim(esimInfo);
  const canChangeEsim = checkIfDeviceCanTransferEsim(esimInfo);

  const esimReloadDisabledReasons =
    esimInfo?.[0]?.esimResetNotAllowedReason || [];

  const esimTransferDisabledReasons =
    esimInfo?.[0]?.esimTransferNotAllowedReason || [];

  return (
    <div data-testid="manage-esim" id="manage-esim">
      <div>
        <SectionButtonHeader
          className="font-semibold !mb-0"
          header={t("reloadEsim.title")}
        >
          {canReloadEsim ? (
            <Link
              className="font-normal change-button"
              to={`../reload?simId=${simId}`}
              replace
              data-testid="reload-esim-button"
            >
              {t("reloadEsim.button")}
            </Link>
          ) : (
            <EsimDisabledActionsTooltip
              reasons={esimReloadDisabledReasons}
              disabledButtonText={t("reloadEsim.button")}
            />
          )}
        </SectionButtonHeader>
        <p className="text-xs leading-6">{t("reloadEsim.text")}</p>
      </div>
      <div>
        <SectionButtonHeader
          className="font-semibold !mb-0"
          header={t("transferEsim.title")}
        >
          {canChangeEsim ? (
            <Link
              className="font-normal change-button"
              to={`../transfer?simId=${simId}`}
              replace
              data-testid="transfer-esim-button"
            >
              {t("transferEsim.button")}
            </Link>
          ) : (
            <EsimDisabledActionsTooltip
              reasons={esimTransferDisabledReasons}
              disabledButtonText={t("transferEsim.button")}
            />
          )}
        </SectionButtonHeader>
        <p className="text-xs leading-6">{t("transferEsim.text")}</p>
      </div>
    </div>
  );
};
