import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { mobileAbos } from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import {
  DataContext,
  DataContextInterface,
} from "@/provider/DataContextProvider";
import {
  getEntry,
  hasBasketEntryWithVoiceOption,
} from "@/utils/dataContextHelpers";
import { getIconForDevice } from "@/utils/deviceUtils";

export const useGetFlatPricesAndName: () => {
  onceTotal: number;
  recurringTotal: number;
  flatName: string;
  flatPrice: number;
  voicePrice: number;
  flatPriceRecurring: number;
  voicePriceRecurring: number;
} = () => {
  const { dataContext } = useContext(DataContext);
  const { t } = useTranslation();

  const entry = getEntry(dataContext);
  const option = entry && entry.options && entry.options[0];
  const selectedOption = dataContext.selectedOption;
  const hasVoiceOption = hasBasketEntryWithVoiceOption(dataContext);

  let flatName = "Flat Name";
  const isMobileAbo = mobileAbos.includes(`${option?.product.id}`);

  if (isMobileAbo) {
    flatName = t("portal:mobileAbo.name");
  } else {
    flatName = selectedOption?.baseDisplayValue || flatName;
  }

  let flatPrice: number;
  let flatPriceRecurring: number;
  let voicePrice = 0;

  // TODO: replace 'flatPriceRecurring' value with option?.recurringCharges once it's fixed by backend

  // If the user picked a voice option, oneTimeCharge[0] is the voice price
  if (hasVoiceOption) {
    voicePrice = option?.oneTimeCharges?.[0]?.amount || 0;
    flatPrice = option?.oneTimeCharges?.[1]?.amount || 0;
    flatPriceRecurring = option?.oneTimeCharges?.[1]?.unitAmount || 0;
  }
  // Otherwise oneTimeCharge[0] is the flat price
  else {
    flatPrice = option?.oneTimeCharges?.[0]?.amount || 0;
    flatPriceRecurring = option?.oneTimeCharges?.[0]?.unitAmount || 0;
  }

  const voicePriceRecurring = voicePrice;

  if (selectedOption?.precharged) flatPrice = 0;

  const onceTotal = dataContext.basket?.total?.oneTime?.amount || 0;
  const recurringTotal = dataContext.basket?.total?.recurring?.amount ?? 0;

  return {
    onceTotal,
    recurringTotal,
    flatName,
    flatPrice,
    voicePrice,
    flatPriceRecurring,
    voicePriceRecurring,
  };
};

export const useGetIconForDevice = () => {
  const { dataContext } = useContext(DataContext);
  const entry = getEntry(dataContext);
  const device = entry && entry.device;
  return getIconForDevice(device?.description);
};

export const useHasVoiceOption = () => {
  const { dataContext } = useContext(DataContext);
  return hasBasketEntryWithVoiceOption(dataContext);
};

export const useBillingPeriod = () => {
  const { dataContext } = useContext(DataContext);
  return dataContext.selectedOption?.minContractDur || 30;
};

export const hasMobileAbo = (dataContext: DataContextInterface) => {
  const entry = getEntry(dataContext);
  const option = entry && entry.options && entry.options[0];
  return mobileAbos.includes(`${option?.product?.id}`);
};

export const useIsMobileAbo = () => {
  const { dataContext } = useContext(DataContext);
  return hasMobileAbo(dataContext);
};
