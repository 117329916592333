import "@/components/Layout/LogoutButton/LogoutButton.scss";

import { useTranslation } from "react-i18next";
import { FCC } from "types";

import ExitIcon from "@/assets/icons/ExitAnimated.svg?react";
import { useLogout } from "@/hooks/useLogout";

export const LogoutButton: FCC = () => {
  const logout = useLogout();
  const { t } = useTranslation("portal");

  return (
    <button
      data-testid="logout-button"
      onClick={() => logout()}
      className="header-logout-button"
    >
      <span className="logout-text">{t("layout.header.menu.logout")}</span>
      <div className="exit-icon">
        <ExitIcon />
      </div>
    </button>
  );
};
