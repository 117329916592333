import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ClipboardField } from "@/components/Interface/ClipboardFiled/ClipboardField";

interface Props {
  matchingId: string;
}

export const RegistrationDetails: FC<Props> = ({ matchingId }) => {
  const splitArray = matchingId.replace("matching_id:", "").split("$");
  const smdpPlusAddress = splitArray[splitArray.length - 2];
  const activationCode = splitArray[splitArray.length - 1];

  const { t } = useTranslation(undefined, {
    keyPrefix:
      "cockpit.managementTile.details.sections.details.registrationDetails",
  });

  return (
    <div data-testid="registration-details" className="flex flex-col gap-2">
      <p data-testid="info-text" className="text-base">
        {t("infoText")}
      </p>
      <h4
        data-testid="smdp-plus-address-label"
        className="text-primary-100 font-semibold mt-4"
      >
        {t("smdpPlusAdress")}
      </h4>
      <div data-testid="copy-smdp-plus-address">
        <ClipboardField
          content={smdpPlusAddress}
          successMessage={t("addressCopied")}
          errorMessage={t("addressCopyError")}
        />
      </div>
      <h4
        data-testid="activation-code-label"
        className="text-primary-100 font-semibold mt-4"
      >
        {t("activationCode")}
      </h4>
      <div data-testid="copy-activation-code">
        <ClipboardField
          content={activationCode}
          successMessage={t("codeCopied")}
          errorMessage={t("codeCopyError")}
        />
      </div>
    </div>
  );
};
