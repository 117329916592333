import "./PersonalData.scss";

import { useAtom } from "jotai";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { TextContainer } from "@/components/Portal/UserAccount/PersonalDetails/TextContainer/TextContainer";
import { useGetCustomer, useGetIdentificationStatus } from "@/services/api";
import { BasicCustomerData } from "@/services/model";
import { userAccountStateAtom } from "@/utils/atoms";
import { getCountryAlpha3FromId } from "@/utils/countries";
import {
  getLocalizedBirthday,
  getPhoneFromBasicCustomerDataPhone,
  getSalutationTranslationKey,
  isBusinessCustomer,
  transformBasicCustomerDataToPersonalDetailsForm,
} from "@/utils/customerUtils";

import { PersonalDataForm } from "./PersonalDataForm";

export const PersonalData: FC = () => {
  const [userAccountState, setUserAccountState] = useAtom(userAccountStateAtom);
  // The local state of the customer, not the same as the backend response!
  const [localCustomerData, setLocalCustomerData] = useState<
    BasicCustomerData | undefined
  >();
  const { t, i18n } = useTranslation();
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "Onboarding.sections.personal-details.form",
  });

  const { data: customer, isLoading: isGettingCustomer } = useGetCustomer();
  const { data: identificationStatus } = useGetIdentificationStatus({});

  const isLoading = isGettingCustomer;

  useEffect(() => {
    if (customer) {
      setLocalCustomerData(customer);
    }
  }, [customer]);

  /** Set the form to the current customerData as prefilled values,
   * so the user can easily edit data.
   * Also, force the dateOfBirth as a localized string,
   * because passed in Date gets serialized weirdly.
   **/
  const formData =
    localCustomerData && userAccountState.editingPersonalData
      ? transformBasicCustomerDataToPersonalDetailsForm(localCustomerData)
      : undefined;

  const shouldShowCustomerForm =
    userAccountState.editingPersonalData &&
    formData &&
    identificationStatus &&
    customer;

  const canClickChangeButton =
    !userAccountState.editingMail && !userAccountState.editingPassword;

  return (
    <div id="user-account-personal-data" className="gap-flex">
      {isLoading && <LoadingSpinner />}

      {shouldShowCustomerForm ? (
        <PersonalDataForm
          localCustomerData={localCustomerData}
          setLocalCustomerData={setLocalCustomerData}
          customer={customer}
          formData={formData}
          identificationStatus={identificationStatus}
        />
      ) : (
        <>
          <div className="header-row">
            <h3 className="text-secondary-100 font-semibold">
              {t("Onboarding.sections.personal-details.title")}
            </h3>
            {canClickChangeButton && (
              <button
                onClick={() =>
                  setUserAccountState((prev) => ({
                    ...prev,
                    editingPersonalData: true,
                  }))
                }
                className="header-button"
                data-testid="change-personal-data-button"
              >
                {t("common.buttons.change")}
              </button>
            )}
          </div>
          <div>
            <p className="label">{tForm("placeholder.formOfAddress")}</p>
            <TextContainer>
              {t(
                `${getSalutationTranslationKey(localCustomerData?.salutation)}`,
              )}
            </TextContainer>
          </div>
          <div>
            <p className="label">{tForm("placeholder.firstName")}</p>
            <TextContainer>{localCustomerData?.firstName}</TextContainer>
          </div>
          <div>
            <p className="label">{tForm("placeholder.lastName")}</p>
            <TextContainer>{localCustomerData?.lastName}</TextContainer>
          </div>
          <div className="gap-grid">
            <div>
              <p className="label">{tForm("placeholder.street")}</p>
              <TextContainer>
                {localCustomerData?.mainAddress?.street}
              </TextContainer>
            </div>
            <div>
              <p className="label">{tForm("placeholder.houseNumber")}</p>
              <TextContainer>
                {localCustomerData?.mainAddress?.houseNumber}
              </TextContainer>
            </div>
          </div>
          {localCustomerData?.mainAddress?.postalDeliveryNotes && (
            <div className="gap-grid">
              <div>
                <p className="label">
                  {tForm("placeholder.addressSupplement")}
                </p>
                <TextContainer>
                  {localCustomerData?.mainAddress?.postalDeliveryNotes}
                </TextContainer>
              </div>
            </div>
          )}
          <div className="gap-grid">
            <div>
              <p className="label">{tForm("placeholder.zip")}</p>
              <TextContainer>
                {localCustomerData?.mainAddress?.zip}
              </TextContainer>
            </div>
            <div>
              <p className="label">{tForm("placeholder.city")}</p>
              <TextContainer>
                {localCustomerData?.mainAddress?.city}
              </TextContainer>
            </div>
          </div>
          <div>
            <p className="label">{tForm("placeholder.country")}</p>
            <TextContainer>
              {t(
                `countries.${getCountryAlpha3FromId(
                  localCustomerData?.mainAddress?.country?.id,
                )}`,
                { defaultValue: "-" },
              )}
            </TextContainer>
          </div>
          <div className="gap-grid">
            <div>
              <p className="label">{tForm("placeholder.phone")}</p>
              <TextContainer>
                {getPhoneFromBasicCustomerDataPhone(localCustomerData?.phone)}
              </TextContainer>
            </div>
            {customer && !isBusinessCustomer(customer) && (
              <div>
                <p className="label" data-testid="dateOfBirth-display">
                  {tForm("label.dateOfBirth")}
                </p>
                <TextContainer>
                  {getLocalizedBirthday(
                    localCustomerData?.birthday,
                    i18n.language,
                  )}
                </TextContainer>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
