import { useContext, useEffect, useState } from "react";

import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser.ts";
import { DataContext } from "@/provider/DataContextProvider.tsx";
import { useGetCustomer } from "@/services/api.ts";
import { BasicCustomerDataTwoFactorMethod } from "@/services/model";
import {
  hasValidPersonalDetails,
  transformBasicCustomerDataToPersonalDetailsForm,
} from "@/utils/customerUtils.ts";

export const useCustomerData = () => {
  const { dataContext, setDataContext } = useContext(DataContext);
  const [twoFactorMethod, setTwoFactorMethod] =
    useState<BasicCustomerDataTwoFactorMethod | null>(null);
  const [isExistingAccount, setIsExistingAccount] = useState<boolean | null>(
    null,
  );
  const [customerHasPersonalDetails, setCustomerHasPersonalDetails] = useState<
    boolean | null
  >(null);
  const [selectedCustomerTypeTabIndex, setSelectedCustomerTypeTabIndex] =
    useState(0);

  const isBusinessCustomer = !!dataContext.personalDetails?.organizationName;
  useEffect(() => {
    if (isBusinessCustomer) {
      setSelectedCustomerTypeTabIndex(1);
    }
  }, [isBusinessCustomer]);

  const isAnonymousUser = useIsAnonymousUser();
  const { data: customerData, isFetching: isLoadingCustomer } = useGetCustomer(
    undefined,
    {
      query: { enabled: !isAnonymousUser },
    },
  );

  useEffect(() => {
    if (customerData && hasValidPersonalDetails(customerData)) {
      setCustomerHasPersonalDetails(true);
      setDataContext((prev) => ({
        ...prev,
        personalDetails:
          transformBasicCustomerDataToPersonalDetailsForm(customerData),
      }));
    }
  }, [customerData, setDataContext]);

  return {
    twoFactorMethod,
    setTwoFactorMethod,
    isExistingAccount,
    setIsExistingAccount,
    customerHasPersonalDetails,
    selectedCustomerTypeTabIndex,
    setSelectedCustomerTypeTabIndex,
    customerData,
    isLoadingCustomer,
    isBusinessCustomer,
  };
};
