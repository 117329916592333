import { Trans, useTranslation } from "react-i18next";

import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";

export const RequestInvoicePanel = () => {
  const { t } = useTranslation();

  return (
    <HighlightPanel className="xl:mt-12" headline={t("layout.goodToKnow")}>
      <p
        className="text-lg leading-tight"
        data-testid="invoice-request-description"
      >
        <Trans
          i18nKey="user-account.paymentMethods.requestPaymentViaInvoice"
          components={[
            <a
              href={t(
                "user-account.paymentMethods.requestPaymentViaInvoiceLink",
              )}
              key="linkToRequest"
              className="text-primary-100 underline"
              target="_blank"
              rel="noreferrer"
            />,
          ]}
          t={t}
        />
      </p>
    </HighlightPanel>
  );
};
