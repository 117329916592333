import { Navigate, Outlet, Route } from "react-router-dom";

import { Checkout } from "@/components/Onboarding/Steps/Checkout/Checkout";
import { DeviceType } from "@/components/Onboarding/Steps/DeviceType/DeviceType";
import { NumberPorting } from "@/components/Onboarding/Steps/NumberPorting/NumberPorting";
import { RateSelection } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { SimSelection } from "@/components/Onboarding/Steps/SimSelection/SimSelection";
import { SimVerification } from "@/components/Onboarding/Steps/SimVerification/SimVerification";
import { AddDevice } from "@/components/Portal/AddDevice/AddDevice";
import { BillList } from "@/components/Portal/Bills/BillList";
import { Cockpit } from "@/components/Portal/Cockpit/Cockpit";
import { DeviceList } from "@/components/Portal/Cockpit/DeviceList/DeviceList";
import { DetailsDisplay } from "@/components/Portal/Cockpit/DeviceManagement/Details/Details";
import { NetworkResetConfirmation } from "@/components/Portal/Cockpit/DeviceManagement/Details/NetworkReset/NetworkResetConfirmation";
import { DetailsPhysicalToEsim } from "@/components/Portal/Cockpit/DeviceManagement/Details/PhysicalToEsim/DetailsPhysicalToEsim";
import { ReloadEsim } from "@/components/Portal/Cockpit/DeviceManagement/Details/ReloadEsim/ReloadEsim";
import { TransferEsim } from "@/components/Portal/Cockpit/DeviceManagement/Details/TransferEsim/TransferEsim";
import { DeviceManagement } from "@/components/Portal/Cockpit/DeviceManagement/DeviceManagement";
import { AddRoamingOption } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/AddRoamingOption/AddRoamingOption";
import { CheckoutRoamingOption } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/CheckoutRoamingOption/CheckoutRoamingOption";
import { ShowRoamingOptions } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/ShowRoamingOptions/ShowRoamingOptions";
import { AutomaticRenewalChange } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/AutomaticRenewal/AutomaticRenewalChange";
import { TariffChange } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffChange/TariffChange";
import { TariffCheckout } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffCheckout/TariffCheckout";
import { TariffDisplay } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffDisplay/TariffDisplay";
import { VoiceSettings } from "@/components/Portal/Cockpit/DeviceManagement/VoiceSettings/VoiceSettings";
import { AddWatchSimOption } from "@/components/Portal/Cockpit/DeviceManagement/WatchSim/AddWatchSimOption/AddWatchSimOption";
import { ChangeWatchSimRenewal } from "@/components/Portal/Cockpit/DeviceManagement/WatchSim/ChangeWatchSimRenewal/ChangeWatchSimRenewal";
import { ShowWatchSimOptions } from "@/components/Portal/Cockpit/DeviceManagement/WatchSim/ShowWatchSimOptions/ShowWatchSimOptions";
import { Tiles } from "@/components/Portal/Cockpit/Tiles/Tiles";
import { Referral } from "@/components/Portal/Referral/Referral";
import { Inbox } from "@/components/Portal/UserAccount/Inbox/Inbox";
import { PaymentMethods } from "@/components/Portal/UserAccount/PaymentMethods/PaymentMethods";
import { TwoFactorChangeProtection } from "@/components/Portal/UserAccount/TwoFactorChangeProtection";
import { portalRoutes } from "@/router/routes/portalRoutes";
import { PersonalDetails } from "@/views/Portal/UserAccount/PersonalDetailsView";
import { TwoFactorChangeAppView } from "@/views/Portal/UserAccount/Security/change/TwoFactorChangeAppView";
import { TwoFactorChangeSmsView } from "@/views/Portal/UserAccount/Security/change/TwoFactorChangeSmsView";
import { TwoFactorInitialAppSetupView } from "@/views/Portal/UserAccount/Security/initialSetup/TwoFactorInitialAppSetupView";
import { TwoFactorInitialSmsConfirmView } from "@/views/Portal/UserAccount/Security/initialSetup/TwoFactorInitialSmsConfirmView";
import { TwoFactorInitialSmsSetupView } from "@/views/Portal/UserAccount/Security/initialSetup/TwoFactorInitialSmsSetupView";
import { SecurityView } from "@/views/Portal/UserAccount/Security/SecurityView";
import { TwoFactorBackupConfirmView } from "@/views/Portal/UserAccount/Security/shared/TwoFactorBackupConfirmView";
import { TwoFactorConfirmNumberChange } from "@/views/Portal/UserAccount/Security/shared/TwoFactorConfirmNumberChange";
import { TwoFactorConfirmSmsView } from "@/views/Portal/UserAccount/Security/shared/TwoFactorConfirmSmsView.tsx";
import { TwoFactorConfirmStandardView } from "@/views/Portal/UserAccount/Security/shared/TwoFactorConfirmStandardView";
import { TwoFactorSetupAppView } from "@/views/Portal/UserAccount/Security/TwoFactorSetupAppView";
import { TwoFactorSetupSmsView } from "@/views/Portal/UserAccount/Security/TwoFactorSetupSmsView";
import { TwoFactorInitialSetupView } from "@/views/Portal/UserAccount/TwoFactorInitialSetupView";
import { UserAccountView } from "@/views/Portal/UserAccount/UserAccountView";

import { CockpitRoute } from "./CockpitRoute";
import { NavigateWithQueryParams } from "./NavigateWithQueryParams";

export const portalRouter = [
  <Route
    key="index"
    index
    element={<Navigate to={portalRoutes.cockpit.base} replace />}
  />,
  <Route
    key={portalRoutes.cockpit.base}
    path={portalRoutes.cockpit.base}
    element={<Cockpit />}
  >
    <Route index element={<CockpitRoute />} />
    <Route path={portalRoutes.cockpit.tiles} element={<Tiles />} />
    <Route path={portalRoutes.cockpit.manage} element={<DeviceManagement />}>
      <Route
        index
        element={
          <NavigateWithQueryParams to={portalRoutes.cockpit.tariff.base} />
        }
      />
      <Route path={portalRoutes.cockpit.tariff.base}>
        <Route
          index
          element={
            <NavigateWithQueryParams to={portalRoutes.cockpit.tariff.display} />
          }
        />
        <Route
          path={portalRoutes.cockpit.tariff.display}
          element={<TariffDisplay />}
        />
        <Route
          path={portalRoutes.cockpit.tariff.change}
          element={<TariffChange />}
        />
        <Route
          path={portalRoutes.cockpit.tariff.checkout}
          element={<TariffCheckout />}
        />
        <Route
          path={portalRoutes.cockpit.tariff.automaticRenewal}
          element={<AutomaticRenewalChange />}
        />
      </Route>
      <Route path={portalRoutes.cockpit.roaming.base}>
        <Route
          index
          element={
            <NavigateWithQueryParams
              to={portalRoutes.cockpit.roaming.display}
            />
          }
        />
        <Route
          path={portalRoutes.cockpit.roaming.display}
          element={<ShowRoamingOptions />}
        />
        <Route
          path={portalRoutes.cockpit.roaming.checkout}
          element={<CheckoutRoamingOption />}
        />
        <Route
          path={portalRoutes.cockpit.roaming.booking}
          element={<AddRoamingOption />}
        />
      </Route>
      <Route path={portalRoutes.cockpit.voice} element={<VoiceSettings />} />
      <Route path={portalRoutes.cockpit.watchSim.base}>
        <Route
          index
          element={
            <NavigateWithQueryParams
              to={portalRoutes.cockpit.watchSim.display}
            />
          }
        />
        <Route
          path={portalRoutes.cockpit.watchSim.display}
          element={<ShowWatchSimOptions />}
        />
        <Route
          path={portalRoutes.cockpit.watchSim.checkout}
          element={<AddWatchSimOption />}
        />
        <Route
          path={portalRoutes.cockpit.watchSim.automaticRenewal}
          element={<ChangeWatchSimRenewal />}
        />
      </Route>
      <Route path={portalRoutes.cockpit.details.base}>
        <Route
          index
          element={
            <NavigateWithQueryParams
              to={portalRoutes.cockpit.details.display}
            />
          }
        />
        <Route path={portalRoutes.cockpit.details.display}>
          <Route index element={<DetailsDisplay />} />
          <Route
            path={portalRoutes.cockpit.details.reset}
            element={<NetworkResetConfirmation />}
          />
        </Route>
        <Route
          path={portalRoutes.cockpit.details.reload}
          element={<ReloadEsim />}
        />
        <Route
          path={portalRoutes.cockpit.details.transfer}
          element={<TransferEsim />}
        />
        <Route
          path={portalRoutes.cockpit.details.physicalToEsim}
          element={<DetailsPhysicalToEsim />}
        />
      </Route>
    </Route>
    <Route path={portalRoutes.cockpit.list} element={<DeviceList />} />
  </Route>,
  <Route
    key={portalRoutes.addDevice.base}
    path={portalRoutes.addDevice.base}
    element={<AddDevice />}
  >
    <Route index element={<Navigate to={portalRoutes.addDevice.step1} />} />
    <Route path={portalRoutes.addDevice.step1}>
      <Route index element={<Navigate to="sim-selection" replace />} />
      <Route
        path={portalRoutes.addDevice.simSelection}
        element={<SimSelection />}
      />
      <Route
        path={portalRoutes.addDevice.simVerification}
        element={<SimVerification />}
      />
    </Route>
    <Route path={portalRoutes.addDevice.step2}>
      <Route
        index
        element={<Navigate to={portalRoutes.addDevice.deviceType} replace />}
      />
      <Route
        path={portalRoutes.addDevice.deviceType}
        element={<DeviceType />}
      />
    </Route>
    <Route path={portalRoutes.addDevice.step3}>
      <Route
        index
        element={<Navigate to={portalRoutes.addDevice.rateSelection} replace />}
      />
      <Route
        path={portalRoutes.addDevice.rateSelection}
        element={<RateSelection />}
      />
      <Route
        path={portalRoutes.addDevice.numberPorting}
        element={<NumberPorting />}
      />
    </Route>
    <Route path={portalRoutes.addDevice.step4}>
      <Route
        index
        element={<Navigate to={portalRoutes.addDevice.checkout} replace />}
      />
      <Route path={portalRoutes.addDevice.checkout} element={<Checkout />} />
    </Route>
  </Route>,
  <Route
    key={portalRoutes.user.base}
    path={portalRoutes.user.base}
    element={<UserAccountView />}
  >
    <Route
      index
      element={<Navigate to={portalRoutes.user.personal} replace />}
    />
    <Route path={portalRoutes.user.personal} element={<PersonalDetails />} />
    <Route path={portalRoutes.user.payments} element={<PaymentMethods />} />
    <Route path={portalRoutes.user.inbox} element={<Inbox />} />
    <Route path={portalRoutes.user.security.base} element={<SecurityView />} />
    <Route
      path={portalRoutes.user.security.initialSetup}
      element={<TwoFactorInitialSetupView />}
    />
    <Route
      path={portalRoutes.user.security.initialSmsSetup}
      element={<TwoFactorInitialSmsSetupView />}
    />
    <Route
      path={portalRoutes.user.security.initialSmsConfirm}
      element={<TwoFactorInitialSmsConfirmView />}
    />
    <Route
      path={portalRoutes.user.security.initialAppSetup}
      element={<TwoFactorInitialAppSetupView />}
    />
    <Route
      path={portalRoutes.user.security.confirmBackupCodeReception}
      element={<TwoFactorBackupConfirmView />}
    />
    <Route
      path={portalRoutes.user.security.confirmStandard}
      element={<TwoFactorConfirmStandardView />}
    />
    <Route
      path={portalRoutes.user.security.verify2faSms}
      element={<TwoFactorConfirmSmsView />}
    />
    {/* INFO: These changes require the verification via 2FA token, which we get via the TwoFactorCodeModal */}
    <Route
      element={
        <TwoFactorChangeProtection>
          <Outlet />
        </TwoFactorChangeProtection>
      }
    >
      <Route
        path={portalRoutes.user.security.change2faSms}
        element={<TwoFactorChangeSmsView />}
      />
      <Route
        path={portalRoutes.user.security.change2faApp}
        element={<TwoFactorChangeAppView />}
      />
      <Route
        path={portalRoutes.user.security.setup2faSms}
        element={<TwoFactorSetupSmsView />}
      />
      <Route
        path={portalRoutes.user.security.verify2faNumberChange}
        element={<TwoFactorConfirmNumberChange />}
      />
      <Route
        path={portalRoutes.user.security.setup2faApp}
        element={<TwoFactorSetupAppView />}
      />
    </Route>
  </Route>,
  <Route
    key={portalRoutes.bills}
    path={portalRoutes.bills}
    element={<BillList />}
  />,
  <Route
    key={portalRoutes.referral}
    path={portalRoutes.referral}
    element={<Referral />}
  />,
  <Route
    key={portalRoutes.notFound}
    path={portalRoutes.notFound}
    element={<Navigate to="/404" replace />}
  />,
];
