import "./ProgressBar.scss";

import { FCC } from "types";

type ProgressBarProps = {
  usageBarWidth: number | undefined;
};

export const ProgressBar: FCC<ProgressBarProps> = ({ usageBarWidth }) => {
  return (
    <div id="bar-wrapper">
      <div id="background-bar" />
      {typeof usageBarWidth === "number" && (
        <div
          style={{ width: `${usageBarWidth}%`, maxWidth: "100%" }}
          id="usage-bar"
        />
      )}
    </div>
  );
};
