import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { errorShouldBeRetried } from "@/components/Portal/UserAccount/Security/utils";
import { NumberForm, useNumberForm } from "@/hooks/useNumberForm";
import { authenticatorRoutes } from "@/router/AuthenticatorRoutes";
import {
  useCancelSetup,
  useSetUpTwoFactorAuthentication,
} from "@/services/auth";
import { ValidateTwoFactorAuthenticationMethod } from "@/services/model";
import { phoneNumberAtom } from "@/utils/atoms";

import { AuthenticatorSmsSetupForm } from "./AuthenticatorSmsSetupForm";

// TODO: This file and TwoFactorSmsSetup are very similar.
// Refactor them into a single component.
export const AuthenticatorSmsSetup = ({
  successTarget = authenticatorRoutes.smsVerify,
}: {
  successTarget?: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, setPhoneNumber] = useAtom(phoneNumberAtom);

  const formMethods = useNumberForm();

  const { mutateAsync: cancelSetup } = useCancelSetup();
  const { mutateAsync: requestChange } = useSetUpTwoFactorAuthentication();

  const onSubmit = (data: NumberForm) => {
    const handleSuccess = () => {
      setPhoneNumber(data.number);
      navigate(successTarget);
    };

    const tryToChange = () =>
      requestChange({
        params: {
          method: ValidateTwoFactorAuthenticationMethod.SMS,
          phoneNumber: data.number,
        },
      });

    tryToChange()
      .then(handleSuccess)
      .catch((error: AxiosError<any>) => {
        if (errorShouldBeRetried(error)) {
          cancelSetup().then(() => {
            setTimeout(() => tryToChange().then(handleSuccess), 5000);
          });
        } else if (
          error.response?.data.errorCode === 403 ||
          error.response?.data ===
            '500 Internal Server Error: ["invalid phone number"]'
        ) {
          formMethods.setError("number", {
            type: "manual",
            message: t("translation:label.validation.phoneBackend"),
          });
        }
      });
  };

  return (
    <AuthenticatorSmsSetupForm formMethods={formMethods} onSubmit={onSubmit} />
  );
};
