import { useEffect, useState } from "react";

import { useGetSimList } from "@/services/api";

export const useAllSims = () => {
  const [pageSize, setPageSize] = useState(10000);
  const { data: simData } = useGetSimList({ pageSize: 10000 });
  useEffect(() => {
    if (simData?.totalElements && simData.totalElements > pageSize) {
      setPageSize(simData.totalElements);
    }
  }, [simData, pageSize]);

  return simData?.simCards || [];
};
