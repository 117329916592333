import { Namespace, TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { TextContainer } from "@/components/Portal/UserAccount/PersonalDetails/TextContainer/TextContainer";
import { useTwoFactorMethodFromQueryParam } from "@/hooks/useTwoFactorMethodFromQueryParam.ts";
import { portalRoutes } from "@/router/routes/portalRoutes";
import {
  BasicCustomerDataTwoFactorMethod,
  ValidateTwoFactorAuthenticationMethod,
} from "@/services/model";
import { FCC } from "@/types";

import { TwoFactorCodeModal } from "./TwoFactorCodeModal";

export const expectedAuthTypeQueryParam = "expectedAuthType";

/**
 * Displays the current 2fa status and a link to change that status.
 * Determines status by combining query parameters (we might expect a change that the backend does not return yet)
 * and the backend state.
 */
export const TwoFactorAuthStatus: FCC = () => {
  const { t } = useTranslation();
  const twoFactorMethod = useTwoFactorMethodFromQueryParam();

  const [showModal, setShowModal] = useState(false);
  const hasAnyTwoFactorAuth =
    twoFactorMethod !== BasicCustomerDataTwoFactorMethod.STANDARD;

  const twoFactorText = getTwoFactorAuthText(t, twoFactorMethod);
  const twoFactorCodeRedirect = useTwoFactorCodeRedirect();
  return (
    <div className="flex flex-col gap-6 md:min-w-[464px]">
      <div>
        <h3 className="text-secondary-100 font-semibold">
          {t("portal:user-account.security.2fa.title")}
        </h3>
        <p className="text-base">
          <span>{t("portal:user-account.security.2fa.description")}</span>
          <a
            className="text-primary-100 underline ml-2"
            href={t("portal:user-account.security.2fa.readMoreUrl")}
            target="_blank"
            rel="noreferrer"
          >
            {t("portal:user-account.security.2fa.readMore")}
          </a>
        </p>
      </div>
      <div>
        <SectionButtonHeader
          header={t("portal:user-account.security.2fa.header")}
        >
          {showModal && (
            <TwoFactorCodeModal
              setShowModal={setShowModal}
              method={twoFactorMethod}
              successAction={twoFactorCodeRedirect}
            />
          )}

          {hasAnyTwoFactorAuth ? (
            <button
              type="button"
              className="text-primary-100 underline"
              onClick={() => setShowModal(true)}
            >
              {t("common.buttons.change")}
            </button>
          ) : (
            <Link to={portalRoutes.user.security.initialSetup}>
              {t("common.buttons.activate")}
            </Link>
          )}
        </SectionButtonHeader>
        <TextContainer>{twoFactorText}</TextContainer>
      </div>
    </div>
  );
};

const getTwoFactorAuthText = (
  t: TFunction<Namespace, undefined, Namespace>,
  authType?: BasicCustomerDataTwoFactorMethod,
) => {
  if (authType === BasicCustomerDataTwoFactorMethod.APP) {
    return t("portal:user-account.security.2fa.method.app");
  } else if (authType === BasicCustomerDataTwoFactorMethod.SMS) {
    return t("portal:user-account.security.2fa.method.sms");
  }
};

/**
 * Returns a function to handle navigation based on the two-factor
 * authentication method specified in the query parameters. Depending on the method (SMS or App),
 * it will navigate to the relevant 2FA change page.
 */
const useTwoFactorCodeRedirect = () => {
  const navigate = useNavigate();
  const method = useTwoFactorMethodFromQueryParam();

  return () => {
    const hasSmsTwoFactorMethod =
      method === ValidateTwoFactorAuthenticationMethod.SMS;
    const hasAppTwoFactorMethod =
      method === ValidateTwoFactorAuthenticationMethod.APP;

    if (hasAppTwoFactorMethod) {
      navigate(portalRoutes.user.security.change2faApp);
    }
    if (hasSmsTwoFactorMethod) {
      navigate(portalRoutes.user.security.change2faSms);
    }
  };
};
