// little helper to generate a sequence of numbers as an array
const getPagesArray = (start: number, end: number) => {
  const setPages = [] as number[];
  for (start > 0 ? start : 1; start <= end; start++) {
    setPages.push(start);
  }
  return setPages;
};

/**
 * Helper function that generates an array of pagination items. Every spacer (...) gets returned as -1.
 * This was heavily inspired by vuesax pagination
 * @link https://github.com/lusaxweb/vuesax/blob/master/src/components/vsPagination/vsPagination.vue
 * @param current active page number
 * @param total number of available pages
 * @param max number of visible pages
 */
export const getPages = (current: number, total: number, max = 9) => {
  let prevRange = -1,
    nextRange = -1;
  if (total <= max) {
    return getPagesArray(1, total);
  }
  const even = max % 2 === 0 ? 1 : 0;
  if (total < 6) {
    prevRange = Math.floor(max / (max / 2));
  } else {
    prevRange = Math.floor(max / 2);
  }
  nextRange = total - prevRange + 1 + even;
  if (current >= prevRange && current <= nextRange) {
    const start = current - prevRange + 2;
    const end = current + prevRange - 2 - even;
    return [1, -1, ...getPagesArray(start, end), -1, total];
  } else if (total < 6) {
    return [...getPagesArray(1, total)];
  }
  return [
    ...getPagesArray(1, prevRange),
    -1,
    ...getPagesArray(nextRange, total),
  ];
};
