import "./Pagination.scss";

import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";

import ArrowRight from "@/assets/icons/ArrowRight.svg?react";
import { useWindowResize } from "@/hooks/useWindowResize";

import { getPages } from "./paginationCalculations";

interface Props {
  paginationPages: number;
}

export const Pagination = ({ paginationPages }: Props) => {
  const { t } = useTranslation("portal");
  const paramName = "page";

  const location = useLocation();
  // Use search params to control pagination
  const [searchParams] = useSearchParams();
  // The currently active param is the value for the search param with paramName
  const currentPage = Number(searchParams.get(paramName)) || 1;
  // Transform our searchParams to a native URLSearchParams object
  const nativeSearchParams = new URLSearchParams(searchParams.toString());
  const getPaginationTarget = (value: number) => {
    nativeSearchParams.set(paramName, value.toString());
    return location.pathname + "?" + nativeSearchParams.toString();
  };

  // run media query to find out if user is on desktop screen
  useWindowResize();
  const isDesktop = window.matchMedia("(min-width: 768px)").matches;

  const pageNumbers = getPages(currentPage, paginationPages, isDesktop ? 9 : 7);
  const lastElement = pageNumbers[pageNumbers.length - 1];

  return (
    <nav className="pagination-nav">
      <div className="navigation-arrow left">
        {currentPage !== 1 && (
          <NavLink
            to={getPaginationTarget(currentPage - 1)}
            className="arrow-anchor"
          >
            <div className="arrow-anchor-text">
              <ArrowRight
                className="text-primary-100 arrow-svg rotate-180"
                aria-hidden="true"
              />
              <span className="hidden lg:block">
                {t("layout.table.pagination.previous")}
              </span>
            </div>
          </NavLink>
        )}
      </div>
      <div className="numbers-container">
        {pageNumbers.map((pageNumber, index) =>
          pageNumber === -1 ? (
            <span
              key={`placeholder-${index}`}
              data-testid={`pagination-placeholder`}
              className={"inactive number"}
            >
              ...
            </span>
          ) : (
            <NavLink
              to={getPaginationTarget(pageNumber)}
              key={pageNumber}
              data-testid={`pagination-${pageNumber}`}
              className={
                `${currentPage}` === `${pageNumber}`
                  ? "active number link"
                  : "inactive number link"
              }
            >
              {pageNumber}
            </NavLink>
          ),
        )}
      </div>
      <div className="navigation-arrow right">
        {currentPage !== lastElement && (
          <NavLink
            to={getPaginationTarget(currentPage + 1)}
            className="arrow-anchor"
          >
            <div className="arrow-anchor-text">
              <span className="hidden lg:block">
                {t("layout.table.pagination.next")}
              </span>
              <ArrowRight
                className="text-primary-100 arrow-svg"
                aria-hidden="true"
              />
            </div>
          </NavLink>
        )}
      </div>
    </nav>
  );
};
