import { Menu } from "@headlessui/react";
import clsx from "clsx";

import { Check } from "@/assets/icons/icons";

type MenuItemButtonProps = {
  buttonHandler: () => void;
  label: string;
  selected: boolean;
};

/**
 * This component is used to display a button in a menu.
 * It has to be wrapped in a Menu component.
 *
 * @example
 * ```jsx
 * <Menu>
 *   <MenuItemButton
 *     buttonHandler={buttonHandler}
 *     selected={false}
 *     label="My Label"
 *    />
 * </Menu>
 * ```
 *
 * @param buttonHandler - The function that is called when the button is clicked.
 * @param label - The label of the button. This has to be unique.
 * @param selected - Whether the button is selected or not.
 * @returns A button that can be used in a menu.
 **/
export const MenuItemButton: React.FC<MenuItemButtonProps> = ({
  label,
  buttonHandler,
  selected,
}) => (
  <Menu.Item key={label}>
    {({ active }) => (
      <button
        id={label.toLowerCase().replace(" ", "-")}
        className={clsx(
          "mb-4 md:mb-2 cursor-pointer flex justify-between font-small",
          active && "underline font-semibold",
          selected && "font-semibold",
        )}
        onClick={buttonHandler}
      >
        {label}
        <div className="ml-2">
          {selected && <Check className="text-white md:text-primary-100" />}
        </div>
      </button>
    )}
  </Menu.Item>
);
