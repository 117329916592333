import "@/components/Portal/Cockpit/DeviceManagement/VoiceSettings/VoiceSettings.scss";

import clsx from "clsx";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AppearTransition } from "@/components/Interface/AppearTransition/AppearTransition";
import { ListBox } from "@/components/Interface/FormFields/ListBox/ListBox";
import { SettingToggle } from "@/components/Interface/SettingToggle/SettingToggle";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useGetCustomer, useUpdateCustomer } from "@/services/api";
import {
  BasicCustomerData,
  LookupValue,
  PermissionPermissionType,
} from "@/services/model";

// TODO: combine with VoiceSettings logic
export const LANGUAGES: LookupValue[] = [
  { id: 1, description: "german" },
  { id: 2, description: "english" },
  { id: 3, description: "french" },
];

export const Inbox: FC = () => {
  const [copyOfCustomerData, setCopyOfCustomerData] =
    useState<BasicCustomerData>();
  const [mode, setMode] = useQueryParam("mode");
  const { t } = useTranslation("portal");

  const isChangingInboxSetting = mode === "edit";

  const { data: currentCustomer, isLoading: customerIsLoading } =
    useGetCustomer();
  const {
    data: updatedCustomer,
    mutate: updateCustomer,
    isLoading: mutationIsLoading,
  } = useUpdateCustomer();

  const customer = updatedCustomer || currentCustomer;

  // Form Data copy should be kept in sync with customer data
  useEffect(() => {
    if (customer) {
      setCopyOfCustomerData(customer);
    }
  }, [customer]);

  // Filter the current permissions if MARKETING is accepted
  // and memoize it
  const isMarketingActive: boolean = useMemo(
    () =>
      copyOfCustomerData?.permissions !== undefined &&
      customer?.permissions !== undefined &&
      (isChangingInboxSetting
        ? copyOfCustomerData.permissions
        : customer.permissions
      ).some(
        ({ permissionType, accepted }) =>
          permissionType === PermissionPermissionType.MARKETING && accepted,
      ),
    [
      copyOfCustomerData?.permissions,
      customer?.permissions,
      isChangingInboxSetting,
    ],
  );

  const save = useCallback(() => {
    // Update customer data if the copyOfCustomerData changed
    const customerDataChanged =
      customer?.id !== undefined &&
      customer?.customerId !== undefined &&
      (customer?.language !== copyOfCustomerData?.language ||
        customer?.permissions !== copyOfCustomerData?.permissions);

    if (customerDataChanged) {
      updateCustomer({
        data: {
          customerId: customer.customerId,
          id: customer.id,
          language: copyOfCustomerData?.language,
          permissions: [
            {
              permissionType: PermissionPermissionType.MARKETING,
              accepted: isMarketingActive,
            },
          ],
        },
      });
    }
    // Always setMode
    setMode("", { replace: true });
  }, [
    updateCustomer,
    setMode,
    copyOfCustomerData?.language,
    copyOfCustomerData?.permissions,
    customer?.customerId,
    customer?.id,
    customer?.language,
    customer?.permissions,
    isMarketingActive,
  ]);

  const cancel = () => {
    setMode("", { replace: true });
  };

  return (
    <>
      <section
        data-testid="voice-settings-section-1"
        className="management-section"
      >
        <div className="flex flex-row justify-between mb-6">
          <h3 className="text-secondary-100 font-semibold">
            {t("user-account.inbox.title")}
          </h3>
          {!isChangingInboxSetting && (
            <button
              className="text-[13px] text-primary-100 underline"
              onClick={() => {
                setCopyOfCustomerData(customer);
                setMode("edit", { replace: true });
              }}
            >
              {t("common.buttons.change")}
            </button>
          )}
        </div>
        {customer === undefined || customerIsLoading || mutationIsLoading ? (
          <LoadingSpinner />
        ) : (
          <AppearTransition>
            <div className="toggle">
              <SettingToggle
                checked={isMarketingActive}
                title={t("user-account.inbox.marketingTitle")}
                subtitle={t("user-account.inbox.marketingSubtitle")}
                onToggle={() =>
                  setCopyOfCustomerData((prev) => ({
                    id: customer.id,
                    customerId: customer.customerId,
                    ...prev,
                    permissions: [
                      {
                        permissionType: PermissionPermissionType.MARKETING,
                        accepted: !isMarketingActive,
                      },
                    ],
                  }))
                }
              />
            </div>
            <div
              className={clsx(
                "text-base font-semibold",
                isChangingInboxSetting && "mb-2",
              )}
            >
              {t("user-account.inbox.contactlanguage")}
            </div>
            {isChangingInboxSetting ? (
              <ListBox<LookupValue>
                className="!max-w-none"
                displayValue={(language: LookupValue) =>
                  language &&
                  (language.id === 1 || language.id === 2 || language.id === 3)
                    ? t(`user-account.inbox.languages.${language.id}`)
                    : ""
                }
                setSelected={(s) =>
                  setCopyOfCustomerData((prev) => ({
                    id: customer.id,
                    customerId: customer.customerId,
                    ...prev,
                    language: s,
                  }))
                } // send value to hook form
                by={(a, b) => a.id === b.id}
                selected={copyOfCustomerData?.language}
                dataTestId="languagesCombobox"
                availableValues={LANGUAGES}
                placeholder={
                  t("user-account.inbox.contactlanguage") ?? "Contact language"
                }
              />
            ) : (
              <div className="font-x-small">
                {customer?.language?.id === 1 ||
                customer?.language?.id === 2 ||
                customer?.language?.id === 3
                  ? t(`user-account.inbox.languages.${customer?.language?.id}`)
                  : "No language available."}
              </div>
            )}
          </AppearTransition>
        )}
      </section>
      {isChangingInboxSetting && (
        <ConfirmationButtonGroup
          cancelAction={() => cancel()}
          cancelText={t("common.buttons.cancel")}
          successAction={() => save()}
          successText={t("common.buttons.confirm")}
        />
      )}
    </>
  );
};
