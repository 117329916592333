import "@/components/Portal/Cockpit/DeviceManagement/Roaming/CheckoutRoamingOption/RoamingSummary/RoamingSummary.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { SubscriberPackage } from "@/services/model";
import { stripFlatName } from "@/utils/deviceUtils";

type SummaryProps = {
  subscriberPackage: SubscriberPackage;
};

/**
 * Displays a summary of the given Roaming option from the given basket
 *
 */
export const RoamingSummary = ({ subscriberPackage }: SummaryProps) => {
  const { t } = useTranslation("portal", {
    keyPrefix: "cockpit.managementTile.roaming.checkout",
  });

  const [simId] = useSimIdFromQueryParam();

  return (
    <div id="roaming-payment-summary" className="payment-summary">
      <p className="summary-title">{t("summaryTitle")}</p>
      <div className="selected-summary">
        <SectionButtonHeader header={t("roamingOption")}>
          <div className="selection-row">
            <Link
              replace
              to={`../booking?simId=${simId}`}
              className="change-button"
            >
              {t("changeRate")}
            </Link>
          </div>
        </SectionButtonHeader>
        <div className="spans">
          <span>{stripFlatName(subscriberPackage.shortName)}</span>
        </div>
      </div>
    </div>
  );
};
