import { Trans, useTranslation } from "react-i18next";
import { FCC } from "types";

import { PaymentProviderPicker } from "@/components/Portal/UserAccount/PaymentMethods/PaymentProviderPicker/PaymentProviderPicker";
import { DrCreditCardCardType } from "@/services/model";

type PaymentProviderProps = {
  sumIsZero?: boolean;
  recommended?: boolean;
  paymentProvider: DrCreditCardCardType | undefined;
  onChange: (s: DrCreditCardCardType | "") => void;
};

/**
 * Component used within the checkout screen of the onboarding to display the payment provider picker
 * and recommended/optional text.
 *
 * @param sumIsZero
 * @param recommended
 * @param enablePayment
 * @param paymentProvider
 * @param onChange
 * @constructor
 */
export const PaymentProvider: FCC<PaymentProviderProps> = ({
  sumIsZero,
  recommended,
  paymentProvider,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <fieldset data-testid="payment-provider-checkout">
      <legend className="text-xl font-gellix leading-normal font-semibold text-black mb-4">
        {t("Onboarding.sections.checkout.paymentData")}{" "}
        {(sumIsZero || recommended) && (
          <Trans
            components={[
              <span
                key="recommended"
                className="font-x-small text-primary-75 ml-2 align-text-bottom"
              />,
            ]}
            i18nKey={
              recommended
                ? "Onboarding.sections.checkout.recommended"
                : "Onboarding.sections.checkout.optional"
            }
          />
        )}
      </legend>
      <p className="font-regular mb-8">
        {t("Onboarding.sections.checkout.choosePayment")}{" "}
        {sumIsZero && t("Onboarding.sections.checkout.noCharge")}
      </p>
      <PaymentProviderPicker
        className="w-fit"
        selected={paymentProvider}
        onChange={(s: DrCreditCardCardType) => {
          onChange(s);
        }}
      />
    </fieldset>
  );
};
