import { expectedAuthTypeQueryParam } from "@/components/Portal/UserAccount/Security/TwoFactorAuthStatus";
import { AuthenticatorSmsConfirm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsConfirm";
import { portalRoutes } from "@/router/routes/portalRoutes";
import { ChangeTwoFactorAuthenticationMethod } from "@/services/model";

export const TwoFactorInitialSmsConfirmView = () => {
  const queryParams = new URLSearchParams();
  queryParams.set(
    expectedAuthTypeQueryParam,
    ChangeTwoFactorAuthenticationMethod.SMS,
  );
  return (
    <div className="max-w-[560px]">
      <AuthenticatorSmsConfirm
        navigationTarget={
          portalRoutes.user.security.confirmBackupCodeReception +
          `?${queryParams.toString()}`
        }
      />
    </div>
  );
};
