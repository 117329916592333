import { useForm } from "react-hook-form";

export type NumberForm = {
  number: string;
};
// Form handling
const formParams = {
  defaultValues: {
    number: "",
  },
  mode: "onChange",
  reValidateMode: "onChange",
} as const;

export const useNumberForm = () => useForm<NumberForm>(formParams);
