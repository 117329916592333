import "./RoamingOptionRow.scss";

import clsx from "clsx";
import React, { FC } from "react";

import { RadioButtonOption } from "@/components/Interface/FormFields/RadioButton/RadioButton";

/** This component is used to display a unique roaming option, presented in the table when booking a new BookedRoamingOption.
 *
 * @param id The id of the BookedRoamingOption.
 * @param selected Whether the BookedRoamingOption is selected.
 * @param rate The rate is the name of the BookedRoamingOption, example: 3000 EU/USA
 * @param volume The volume is the amount of data that is included in the BookedRoamingOption, example: `3000` for 3000 MB
 * @param duration The duration is the time period that the BookedRoamingOption is valid for. It's always 365 days at the moment.
 * @param price The price is the price of the BookedRoamingOption, example: `CHF 10.00`
 * @param booked Whether the BookedRoamingOption is already booked or not. If booked, it cannot be selected again.
 *
 * */
export const RoamingOptionRow: FC<{
  id?: number;
  selected?: boolean;
  rate?: string;
  volume?: number;
  duration: string;
  price: string;
  booked?: boolean;
}> = ({ id, selected, rate, volume, duration, price, booked }) => (
  <div className="roaming-option-row" data-testid={id}>
    <div className={clsx("rate", selected && "selected")}>
      {rate?.replace(/ \|.*/g, "")}
    </div>

    <div className={clsx("volume", selected && "selected")}>{volume} MB</div>

    <div className={clsx("price", selected && "selected")}>
      <RadioButtonOption name="price" key={rate} value={id} label={price} />

      <div className="text-primary-100 font-x-small ml-7 md:hidden">
        {duration}
      </div>
    </div>
  </div>
);
