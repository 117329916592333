import { useAtom } from "jotai";
import { useContext, useEffect } from "react";

import { DataContext } from "@/provider/DataContextProvider";
import { getUpdatedBasket } from "@/services/api";
import { esimJourneyBasketIdAtom } from "@/utils/atoms";

export const useEsimJourneyBasket = () => {
  const [esimJourneyBasketId, setEsimJourneyBasketId] = useAtom(
    esimJourneyBasketIdAtom,
  );
  const { setDataContext } = useContext(DataContext);
  useEffect(() => {
    if (esimJourneyBasketId) {
      getUpdatedBasket(esimJourneyBasketId).then((basket) => {
        setDataContext((prev) => ({ ...prev, basket }));
        setEsimJourneyBasketId(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esimJourneyBasketId]);
};
