import { Background } from "@/components/Layout/Background/Background.tsx";
import { LocalLoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner.tsx";

export const WelcomeLoadingSpinner = () => (
  <Background>
    <div className="absolute inset-0 flex items-center justify-center">
      <LocalLoadingSpinner />
    </div>
  </Background>
);
