import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import BrowserIcon from "@/assets/icons/KycModal/Browser.svg?react";
import IDIcon from "@/assets/icons/KycModal/ID.svg?react";
import NoTabletIcon from "@/assets/icons/KycModal/NoTablet.svg?react";
import PrivacyIcon from "@/assets/icons/KycModal/Privacy.svg?react";
import { Anchor, Button } from "@/components/Interface/Button/Button";
import { Modal } from "@/components/Interface/Modal/Modal";
export const KYCModal = ({
  buttonLabel,
  target,
}: {
  buttonLabel: string;
  target?: string;
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        className="inverted secondary w-fit whitespace-nowrap"
      >
        {buttonLabel}
      </Button>
      {showModal && (
        <Modal
          classNameDescription="max-w-none"
          title={t("portal:cockpit.kycSection.modal.title")}
          onClose={() => setShowModal(false)}
          hasScroll={false}
        >
          <ul className="list-none list-inside mb-4">
            <li className="flex gap-2 mb-2">
              <IDIcon className="size-5 min-w-5 mt-1.5" />
              <div className="flex flex-col">
                <strong>
                  {t(
                    "portal:cockpit.kycSection.modal.getYourValidIDReady.title",
                  )}
                </strong>

                <Trans
                  i18nKey="portal:cockpit.kycSection.modal.getYourValidIDReady.text"
                  t={t}
                  components={[<b key="0" />]}
                ></Trans>
              </div>
            </li>
            <li className="flex gap-2 mb-2">
              <BrowserIcon className="size-5 min-w-5 mt-1.5" />
              <div className="flex flex-col">
                <strong>
                  {t(
                    "portal:cockpit.kycSection.modal.recommendedBrowsers.title",
                  )}
                </strong>
                {t("portal:cockpit.kycSection.modal.recommendedBrowsers.text")}
              </div>
            </li>
            <li className="flex gap-2 mb-2">
              <NoTabletIcon className="size-5 min-w-5 mt-1.5" />
              <div className="flex flex-col">
                <strong>
                  {t("portal:cockpit.kycSection.modal.noTablets.title")}
                </strong>
                {t("portal:cockpit.kycSection.modal.noTablets.text")}
              </div>
            </li>
            <li className="flex gap-2 mb-2">
              <PrivacyIcon className="size-5 min-w-5 mt-1.5" />
              <div className="flex flex-col">
                <strong>
                  {t("portal:cockpit.kycSection.modal.privacy.title")}
                </strong>
                {t("portal:cockpit.kycSection.modal.privacy.text")}
              </div>
            </li>
          </ul>

          <div className="flex justify-end mb-4">
            <Anchor
              disabled={target === undefined}
              href={target}
              target={"_blank"}
              label={buttonLabel}
              className="inverted secondary w-fit whitespace-nowrap"
            />
          </div>
        </Modal>
      )}
    </>
  );
};
