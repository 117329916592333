import isEmpty from "lodash/isEmpty";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { PageNumberComboBox } from "@/components/Layout/Table/PageNumberComboBox/PageNumberComboBox";
import { TableControlGroup } from "@/components/Layout/Table/TabeControlGroup/TableControlGroup";
import { Table } from "@/components/Layout/Table/Table";
import { DashboardListRow } from "@/components/Portal/Cockpit/DeviceList/DeviceListRow/DashboardListRow";
import { additionalColumns } from "@/components/Portal/Cockpit/Filter/Filter";
import { useExtendedSims } from "@/hooks/useExtendedSims";
import { useGetSimQueryParamFilter } from "@/hooks/useGetSimQueryParamFilter";
import { useGetSimList } from "@/services/api";

import { DeviceListHeaderRow } from "./DeviceListHeaderRow/DeviceListHeaderRow";

export const DeviceList: FC = () => {
  const [searchParams] = useSearchParams();
  let filter = useGetSimQueryParamFilter();
  const { t } = useTranslation("portal");

  // There is (probably) a bug with orval where it generates the useGetSimListInfinite functions without properly
  // respecting the page param in the query key which leads to some weird caching issues wile using useGetSimList.
  // We solve this by always sending the page param here. The infinite queries cache key never has the page param.
  // see https://github.com/anymaniax/orval/issues/708
  const page = Number(searchParams.get("page")) || 1;
  filter = {
    ...filter,
    page: page ? page - 1 : 1,
    pageSize: Number(searchParams.get("pageSize")) || 10,
  };

  const additionalColumnsString = searchParams.get("additionalColumns");
  const parsedColumns = useMemo(
    () =>
      additionalColumnsString
        ? Object.fromEntries(
            additionalColumnsString
              .split(",")
              .filter((column) =>
                (additionalColumns as ReadonlyArray<string>).includes(column),
              )
              .map((column) => [column, true]),
          )
        : undefined,
    [additionalColumnsString],
  );

  const [refetchInterval, setRefetchInterval] = useState<false | number>(false);

  const { data: simData, isLoading: isLoadingSimData } = useGetSimList(filter, {
    query: {
      refetchInterval,
      refetchOnMount: true,
      staleTime: 60 * 1000,
    },
  });

  // Enrich the list of Sims with roaming and esim data
  const {
    extendedSims,
    isLoading: isLoadingExtendedData,
    hasUnscannedEsims,
  } = useExtendedSims(simData?.simCards ?? [], refetchInterval);

  // Once we know there are unscanned esims, we set a refetch interval so all data gets renewed every x seconds
  useEffect(() => {
    setRefetchInterval(hasUnscannedEsims && 10000);
  }, [hasUnscannedEsims, setRefetchInterval]);

  const pages = simData?.totalNumberPages || 1;
  const noRows = isEmpty(simData?.simCards);

  return (
    <>
      <Table
        paginationPages={pages}
        headline={t("cockpit.deviceList.headline")}
        noRows={noRows && !isLoadingSimData}
      >
        <thead>
          <DeviceListHeaderRow additionalColumns={parsedColumns} />
        </thead>
        <tbody>
          {extendedSims.map((device, index) => {
            return (
              <DashboardListRow
                key={`${index}`}
                additionalColumns={parsedColumns}
                device={device}
              />
            );
          })}
        </tbody>
      </Table>
      <TableControlGroup>
        <PageNumberComboBox />
      </TableControlGroup>

      {(isLoadingSimData || isLoadingExtendedData) && <LoadingSpinner />}
    </>
  );
};
