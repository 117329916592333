import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "@/components/Interface/Modal/Modal";

/** A modal that shows the country list for all roaming zones. */
export const CountrylistDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("portal");

  return (
    <>
      <button
        className="underline text-primary-100"
        onClick={() => setIsOpen(true)}
        type="button"
      >
        {t("common.roaming.countryList")}
      </button>

      {isOpen && (
        <Modal
          title={t("common.roaming.dialog.title")}
          onClose={() => setIsOpen(false)}
        >
          <p className="font-semibold text-primary-100 mb-4">
            {"Zone " + t("common.roaming.zones.ZONE1")}
          </p>
          <p className="text-base mb-6">
            {t("common.roaming.zones.countries.ZONE1")}
          </p>
          <p className="font-semibold text-primary-100 mb-4">
            {t("common.roaming.zones.ZONE2")}
          </p>
          <p className="text-base mb-6">
            {t("common.roaming.zones.countries.ZONE2")}
          </p>
          <p className="font-semibold text-primary-100 mb-4">
            {t("common.roaming.zones.ZONE3")}
          </p>
          <p className="text-base mb-6">
            {t("common.roaming.zones.countries.ZONE3")}
          </p>
        </Modal>
      )}
    </>
  );
};
