import { Header } from "@/components/Layout/Header/Header";
import { LogoutButton } from "@/components/Layout/LogoutButton/LogoutButton";
import { MobileMenu } from "@/components/Layout/MobileMenu/MobileMenu";

export const PortalHeader = () => (
  <Header>
    <div className="block xl:hidden">
      <MobileMenu />
    </div>
    <div className="hidden xl:block z-0">
      <LogoutButton />
    </div>
  </Header>
);
