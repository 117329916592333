import "./UserSettingsIconBackground.scss";

import { FC } from "react";
import { useMatch } from "react-router-dom";

import {
  CreditCard,
  GuardLock,
  NewsletterInbox,
  User,
} from "@/assets/icons/icons";

export const UserSettingsIconBackground: FC<{ mobile?: boolean }> = ({
  mobile,
}) => (
  <div
    id={`user-watermark${mobile ? "-top" : ""}`}
    className="tile-watermark-container"
  >
    <BackgroundIcon />
  </div>
);

const BackgroundIcon = () => {
  const activeTab = useMatch("/portal/user/*");
  const params = activeTab?.params["*"];

  if (params?.startsWith("payments")) return <CreditCard />;
  if (params?.startsWith("personal")) return <User />;
  if (params?.startsWith("inbox")) return <NewsletterInbox />;
  if (params?.startsWith("security")) return <GuardLock />;

  return <User />;
};
