import { omit } from "lodash";

import { LightboxConfiguration } from "@/components/Interface/Lightbox/Lightbox";

export interface LightboxConfigurationWithAmount extends LightboxConfiguration {
  amount?: number;
}

export const filterConfigurationForParams: (
  config: LightboxConfigurationWithAmount,
) => Exclude<
  LightboxConfigurationWithAmount,
  { version: string; production: string }
> = (config) =>
  Object.entries(omit(config, ["version", "production"])).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: Array.isArray(value) ? value.join(",") : value,
    }),
    { amount: 0 } as LightboxConfigurationWithAmount,
  );
