import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, ReactNode } from "react";

import { ArrowDown } from "@/assets/icons/icons";
type CollapsibleProps = {
  id: string;
  icon?: ReactNode;
  label: string;
  children: ReactNode;
};
export const Collapsible = ({
  id,
  icon,
  label,
  children,
}: CollapsibleProps) => (
  <Menu
    as="div"
    data-testid={`collapsible-${id}`}
    className="relative inline-block text-left"
  >
    {({ open }) => (
      <>
        <Menu.Button
          tabIndex={0}
          aria-label={label}
          className={clsx(
            "group flex py-2 items-center text-lg text-white gap-2 focus-visible:ring-2 focus:outline-none focus-visible:ring-white focus-visible:ring-opacity-75 hover:font-semibold xl:py-0",
            open && "!font-semibold",
          )}
        >
          <div className="items-center justify-center h-10 flex border-transparent group-hover:border-white group-hover:bg-transparent xl:bg-white xl:rounded-full xl:h-10 xl:w-10 xl:border-2">
            {icon}
          </div>
          <span className="text-white pb-px my-auto font-inherit whitespace-nowrap xl:hidden">
            {label}
          </span>
          <ArrowDown
            className={clsx(
              "stroke-white transition ease-linear xl:hidden",
              open && "rotate-180",
            )}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform md:-translate-y-full opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            id={`collapsible-menu-${id}`}
            className="pt-6 ml-14 md:ml-0 z-20 md:absolute left-0 bottom-0 md:bottom-auto md:-top-3 origin-bottom md:origin-top-left md:bg-white shadow-none md:shadow-lg md:ring-1 md:ring-black md:ring-opacity-5 md:mb-4 md:p-4 text-white md:text-primary-100 font-small whitespace-nowrap focus:outline-none"
          >
            {children}
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);
