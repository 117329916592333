import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FCC } from "types";

import { useQueryParam } from "@/hooks/useQueryParam";
import { accessTokenAtom, destinationAtom } from "@/utils/atoms";

// TODO: Discuss whether this is a route component or not.
export const ProtectedRoute: FCC = ({ children }) => {
  const token = useAtomValue(accessTokenAtom);
  const setDestination = useSetAtom(destinationAtom);
  const [code] = useQueryParam("code");
  const navigate = useNavigate();
  const location = useLocation();

  const authorized = !!token;

  // This redirects us to /welcome, if the user is not authenticated.
  useEffect(() => {
    if (!authorized) {
      const searchParams = new URLSearchParams();

      if (location.pathname !== "/") {
        setDestination(`${location.pathname}${location.search}`);
      }

      if (code) {
        searchParams.set("code", code);
      }

      navigate({
        pathname: "/welcome",
        search: searchParams.toString(),
      });
    }
  }, [
    authorized,
    code,
    location.pathname,
    location.search,
    navigate,
    setDestination,
  ]);

  return <>{children}</>;
};
