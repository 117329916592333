import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StyledLink, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { expectedAuthTypeQueryParam } from "@/components/Portal/UserAccount/Security/TwoFactorAuthStatus";
import { AuthenticatorAppForm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorAppForm";
import { TwoFactorConfirmationCodeForm } from "@/components/Welcome/AuthenticatorSetup/TwoFactorConfirmationInput";
import { useAlert } from "@/hooks/useAlert";
import { useRequestTwoFactorAppSetup } from "@/hooks/useRequestTwoFactorAppSetup";
import { useValidateTwoFactorAppCode } from "@/hooks/useValidateTwoFactorAppCode";
import { portalRoutes } from "@/router/routes/portalRoutes";
import {
  useChangeTwoFactorAuthentication,
  useValidateTwoFactorAuthentication,
} from "@/services/auth";
import {
  ChangeTwoFactorAuthenticationMethod,
  ValidateTwoFactorAuthenticationMethod,
} from "@/services/model";
import { AlertTypes } from "@/utils/atoms";

export const TwoFactorAppSetup = () => {
  const showToast = useAlert();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { authSetupData } = useRequestTwoFactorAppSetup(
    useChangeTwoFactorAuthentication,
    {
      method: ValidateTwoFactorAuthenticationMethod.APP,
    },
    false,
  );

  // Form handling
  const formMethods = useForm<TwoFactorConfirmationCodeForm>({
    defaultValues: {
      code: "",
    },
    mode: "onChange",
  });

  const { isLoading, validateTwoFactorAuth } = useValidateTwoFactorAppCode(
    () => {
      formMethods.setError("code", {
        type: "manual",
        message: t("portal:authenticator.app.setup.step2.invalidCode"),
      });
    },
    false,
    useValidateTwoFactorAuthentication,
  );

  // TODO: This should be refactored as this is completely similar to:
  // - TwoFactorSmsConfirm
  // - AuthenticatorAppSetup
  const onSubmit = (data: TwoFactorConfirmationCodeForm) => {
    validateTwoFactorAuth({
      params: {
        code: parseInt(data.code),
        method: ValidateTwoFactorAuthenticationMethod.APP,
      },
    }).then(() => {
      showToast({
        text: t("portal:common.alerts.success"),
        type: AlertTypes.success,
      });
      const queryParams = new URLSearchParams();
      queryParams.set(
        expectedAuthTypeQueryParam,
        ChangeTwoFactorAuthenticationMethod.SMS,
      );

      navigate(portalRoutes.user.security.base + `?${queryParams}`);
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form
        className="flex flex-col gap-10 text-black"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <AuthenticatorAppForm authSetupData={authSetupData} />
        <ConfirmationButtonFlexBox forceButtonWidth>
          <StyledLink className="inverted accent" type="button" to={-1 as any}>
            {t("portal:common.buttons.back")}
          </StyledLink>
          <SubmitButton
            label={t("portal:authenticator.sms.verification.finishSetup")}
            className="accent"
            disabled={isLoading}
          />
        </ConfirmationButtonFlexBox>
      </form>
    </FormProvider>
  );
};
