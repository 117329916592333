/**
 * Takes a date object or a string in DD.MM.YYYY format, and returns a locale
 * date string, using the provided language.
 * Days and months default to always be displayed as two digits.
 *
 * @param date - date object or string in DD-MM-YYYY format
 * @param language - a valid language string
 * @param options - options to override the default ones
 * @returns {string}
 */
export const getLocalizedDateString = (
  date: Date | string,
  language: string,
  options?: object,
): string => {
  const lang = language === "en" ? "en-GB" : language;

  return new Date(date).toLocaleDateString(lang, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...options,
  });
};
