import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { AuthAndPersonalDetailsFormFields } from "@/components/Onboarding/Steps/PersonalDetails/personalDetails.types.ts";
import {
  BusinessPersonalDetailsFormFields,
  PersonalDetailsFormFieldKeys,
} from "@/components/Onboarding/Steps/PersonalDetails/PersonalDetailsFormFields/PersonalDetailsFormFields.tsx";
import { useCustomerData } from "@/components/Onboarding/Steps/PersonalDetails/useCustomerState.ts";
import { transformBasicCustomerDataToPersonalDetailsForm } from "@/utils/customerUtils.ts";

export const usePersonalDetailsForm = () => {
  const formMethods = useForm<AuthAndPersonalDetailsFormFields>({
    mode: "onChange",
  });

  const { customerHasPersonalDetails, customerData } = useCustomerData();

  useEffect(() => {
    if (!!customerData && customerHasPersonalDetails) {
      const formPrefillData =
        transformBasicCustomerDataToPersonalDetailsForm(customerData);

      Object.keys(formPrefillData).forEach((key) => {
        formMethods.setValue(
          key as keyof Omit<BusinessPersonalDetailsFormFields, "terms">,
          formPrefillData[
            key as keyof Omit<BusinessPersonalDetailsFormFields, "terms">
          ],
          { shouldDirty: false, shouldTouch: false },
        );
      });

      formMethods.trigger(Object.values(PersonalDetailsFormFieldKeys));
    }
  }, [customerHasPersonalDetails, customerData, formMethods]);

  const formValues = formMethods.watch();
  return { formMethods, formValues };
};
