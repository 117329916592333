import { useGetSimCustomerActions } from "@/services/api";
import {
  cannotAddEsimBecauseBillDunning,
  cannotAddEsimBecauseIDCheckOpen,
  cannotAddEsimBecauseReachedMaximumSims,
} from "@/utils/customerUtils";

export const useCannotAddEsim: () => boolean | undefined = () => {
  const { data: simCustomerActions } = useGetSimCustomerActions();
  const hasIDCheckPending = cannotAddEsimBecauseIDCheckOpen(simCustomerActions);
  const hasReachedMaximumAmountOfSims =
    cannotAddEsimBecauseReachedMaximumSims(simCustomerActions);
  const hasBillDunning = cannotAddEsimBecauseBillDunning(simCustomerActions);

  const cannotAddEsim =
    hasIDCheckPending || hasReachedMaximumAmountOfSims || hasBillDunning;

  return cannotAddEsim;
};
