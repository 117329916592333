import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PaymentProvider } from "@/components/Onboarding/Steps/Checkout/PaymentProvider/PaymentProvider";
import {
  PaymentProviderPicker,
  paymentProvidersObject,
} from "@/components/Portal/UserAccount/PaymentMethods/PaymentProviderPicker/PaymentProviderPicker";
import { DrCreditCardCardType } from "@/services/model";
export interface PaymentProviderObject extends FieldValues {
  paymentProvider: string;
}

/**
 * Displays the current payment mean - or if none is set, shows a number of radio buttons with payment means to choose from.
 * @param isInvoice Flag to indicate if "invoice" should be displayed as payment mean
 * @param isCreditCard Flag to indicate if "credit card" should be displayed as payment mean
 */
export const PaymentMeanSection = <T extends PaymentProviderObject>({
  isInvoice,
  isCreditCard,
  cardType,
  maskedCardNumberString,
  sumIsZero,
  recommended,
  control,
  enableShowCurrent,
}: {
  isInvoice: boolean;
  isCreditCard: boolean | undefined;
  cardType: DrCreditCardCardType | undefined;
  maskedCardNumberString: string;
  sumIsZero: boolean;
  recommended?: boolean;
  control: Control<T, any>;
  enableShowCurrent: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {enableShowCurrent && (isInvoice || isCreditCard) ? (
        <div className="pb-2" data-testid="payment-provider-add-device">
          <p className="text-xl font-gellix leading-normal font-semibold text-black mb-4">
            {t("Onboarding.sections.checkout.paymentData")}
          </p>
          <p className="font-regular mb-8">
            {t("Onboarding.sections.checkout.alreadyAddedPaymentData")}
          </p>
          {isInvoice && (
            <PaymentProviderPicker
              className="mt-8"
              paymentOptions={[
                {
                  label: t("portal:user-account.paymentMethods.invoice"),
                  value: "invoice",
                  disabled: true,
                },
              ]}
              selected={"invoice"}
            />
          )}
          {isCreditCard && cardType && (
            <PaymentProviderPicker
              className="mt-8 w-[208px] whitespace-pre-wrap"
              paymentOptions={[
                {
                  ...paymentProvidersObject[cardType],
                  subtext: maskedCardNumberString,
                  disabled: true,
                },
              ]}
              selected={cardType}
            />
          )}
        </div>
      ) : (
        <Controller
          control={control}
          // This should be guarded by the constraint of the type T
          name={"paymentProvider" as Path<T>}
          render={({ field: { onChange, value } }) => (
            <PaymentProvider
              paymentProvider={value}
              sumIsZero={sumIsZero}
              recommended={recommended}
              onChange={onChange}
            />
          )}
        />
      )}
    </>
  );
};
