import axios, {
  AxiosHeaders,
  AxiosInstance,
  ParamsSerializerOptions,
  RawAxiosRequestConfig,
} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import qs from "qs";

import { accessTokenAtom, store } from "@/utils/atoms";
import { refreshAuthorization } from "@/utils/authentication";

export const generateApiInstance = <T>(
  baseURL: string,
  config: RawAxiosRequestConfig,
  options?: RawAxiosRequestConfig,
): Promise<T> => {
  const axiosInstance = generateAxiosInstance(baseURL);
  const token = store.get(accessTokenAtom);

  // If we have headers we want to add the Authorization header
  if (options?.headers !== undefined) {
    // If we gave in a set Authorization header we don't want to override it
    if (!options.headers["Authorization"])
      options.headers["Authorization"] = `Bearer ${token}`;
    return axiosInstance({
      ...config,
      ...options,
    }).then(({ data }) => data);
  }
  // Else we create new headers for this request
  else {
    const headers = new AxiosHeaders({ Authorization: `Bearer ${token}` });
    return axiosInstance({
      ...config,
      ...options,
      headers,
    }).then(({ data }) => data);
  }
};

export const generateAxiosInstance: (baseURL: string) => AxiosInstance = (
  baseURL: string,
) => {
  const instance = axios.create({
    baseURL,
    paramsSerializer: {
      serialize: (params: ParamsSerializerOptions) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
  });

  // Instantiate the interceptor for refresh on 401
  createAuthRefreshInterceptor(instance, refreshAuthorization);
  return instance;
};
