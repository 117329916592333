import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { ConnectionIcon, Globe, VoiceOptionCall } from "@/assets/icons/icons";
import { TransformedProduct } from "@/utils/tariffUtils";

export const MobileAboCard = ({
  value,
  product,
  disabled,
}: {
  value: string | undefined;
  product: TransformedProduct;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const subtitle = t(
    `translation:Onboarding.sections.rate-selection.${
      product.minContractDur === 30 ? "30days" : "365days"
    }`,
  );
  if (!product.id) return null;
  return (
    <RadioGroup.Option
      disabled={disabled}
      defaultChecked={value === product.id.toString()}
      value={product.id.toString()}
    >
      {({ checked }) => (
        <div
          className={clsx(
            "icon-tile px-6 py-4 w-fit h-fit flex text-black",
            disabled && "opacity-50",
            checked && "selected",
          )}
        >
          <div className="flex justify-between">
            <h2 className="text-secondary-100">{t("portal:mobileAbo.name")}</h2>
            <div className="mt-px pt-px">
              {checked ? (
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.5 6c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-8a5 5 0 1 1 10 0 5 5 0 0 1-10 0z"
                    fill="#0014FF"
                  />
                </svg>
              ) : (
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 16c0-5.52 4.48-10 10-10s10 4.48 10 10-4.48 10-10 10-10-4.48-10-10zm2 0c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8-8 3.58-8 8z"
                    fill="#0014FF"
                  />
                </svg>
              )}
            </div>
          </div>
          <p className="text-base font-semibold mb-4">{subtitle}</p>
          <div
            className={clsx(
              "flex flex-col gap-4 text-base",
              checked ? "font-semibold text-primary-100" : "",
            )}
          >
            <p className="flex flex-row gap-2">
              <ConnectionIcon className="size-6 text-primary-100" />
              {t("portal:mobileAbo.features.5gCh")}
            </p>
            <p className="flex flex-row gap-2">
              <VoiceOptionCall className="size-6 text-primary-100" />
              {t("portal:mobileAbo.features.unlimitedCallsAndSms")}
            </p>

            <p className="flex flex-row gap-2">
              <Globe className="size-6 text-primary-100" fill="currentColor" />
              {t("portal:mobileAbo.features.roamingEuUsa", {
                volume: product.minContractDur === 30 ? "2" : "24",
              })}
            </p>
            <hr className="border-primary-100" />
            <p
              className={clsx(
                "text-lg font-semibold",
                checked ? "text-primary-100" : "text-black",
              )}
            >
              {product.chargeFlat}
            </p>
          </div>
        </div>
      )}
    </RadioGroup.Option>
  );
};
