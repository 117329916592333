import "./PopUp.scss";

import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FCC } from "types";

interface PopUpProps {
  button: JSX.Element;
}

export const PopUp: FCC<PopUpProps> = ({ button, children }) => {
  return (
    <Popover data-testid="popup" className="popup">
      {button}
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="flex">
          <div data-testid="popup-panel" className="panel-style">
            {children}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
