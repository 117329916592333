import { SimCardDetail } from "@/services/model";
import { isInactive } from "@/utils/deviceUtils";
import { TransformedProducts } from "@/utils/tariffUtils";

// Transform the available products and the sim detail to a preslection object for the tariff change form
export const transformOptionsToPreslection = (
  simDetail?: SimCardDetail,
  options?: TransformedProducts,
  localSavedProductId?: number,
) => {
  // We need at least the sim detail and the available products to proceed
  if (!simDetail || !options?.processedProductsById) {
    return { rate: undefined, voice: false };
  }

  const unavailableProductId = getUnavailableProductId(simDetail);
  const unavailableDataRate =
    unavailableProductId &&
    options.processedProductsById[unavailableProductId]?.data;

  let productId = getProductId(simDetail, unavailableDataRate);
  let hasVoice = checkIfHasVoice(simDetail, options);

  if (localSavedProductId) {
    ({ productId, hasVoice } = getProductAndVoiceFromLocalProduct(
      localSavedProductId,
      options,
      productId,
    ));
  } else if (hasVoice) {
    productId = findDataRateForVoiceProductId(options, productId);
  }

  return {
    rate: productId?.toString(),
    voice: hasVoice,
  };
};

// If the sim detail is not inactive, return the unavailable product ID
// which is either the current service ID or the successor for renew ID, depending on the auto renew status.
const getUnavailableProductId = (simDetail: SimCardDetail) => {
  if (!isInactive(simDetail)) {
    return simDetail.autoRenew
      ? simDetail.successorForRenew?.id
      : simDetail.serviceID;
  }
  return undefined;
};

// Determine the product ID based on sim detail and unavailable data rate
const getProductId = (simDetail: SimCardDetail, unavailableDataRate: any) => {
  if (!unavailableDataRate) return undefined;
  if (simDetail?.autoRenew) {
    return simDetail.successorForRenew?.id || simDetail.serviceID;
  }
  return simDetail.serviceID;
};

// Determine if the sim detail has a voice option by looking at the successors voice option or the current voice option
const checkIfHasVoice = (
  simDetail: SimCardDetail,
  options: TransformedProducts,
) => {
  if (simDetail.autoRenew && simDetail.successorForRenew?.id) {
    return !!options.processedProductsById[simDetail.successorForRenew.id]
      ?.isVoiceProduct;
  }
  return !!simDetail?.voiceOption;
};

// Handle the case where a portal tariff product ID is provided
const getProductAndVoiceFromLocalProduct = (
  portalTariffProductID: number,
  options: TransformedProducts,
  currentProductId?: number,
) => {
  let productId = currentProductId;
  let hasVoice = false;

  const portalProduct = options.processedProductsById[portalTariffProductID];
  if (portalProduct.voice === portalTariffProductID) {
    productId = findDataRateForVoiceProductId(options, portalTariffProductID);
    hasVoice = true;
  } else {
    productId = portalTariffProductID;
  }

  return { productId, hasVoice };
};

// Find the corresponding data rate product for a voice product
const findDataRateForVoiceProductId = (
  options: TransformedProducts,
  voiceProductId?: number,
) => {
  return (
    Object.values(options.processedProductsById).find(
      (product) => product?.voice === voiceProductId,
    )?.id || voiceProductId
  );
};
