import "@/components/Onboarding/Steps/RateSelection/VoiceOption/VoiceOption.scss";

import { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { VoiceOptionCall } from "@/assets/icons/icons";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { OptionContainer } from "@/components/Onboarding/Steps/RateSelection/OptionContainer/OptionContainer";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { ConfirmNoVoiceModal } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffChange/ConfirmNoVoiceModal/ConfirmNoVoiceModal";
import { TransformedProduct, TransformedProducts } from "@/utils/tariffUtils";

export const VoiceOption = ({
  options,
  hasWatchSim,
  currentRateId,
}: {
  options?: TransformedProducts;
  hasWatchSim: boolean;
  currentRateId?: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<RateFormProps>();
  const voiceIsSelected = watch("voice") === true;
  const recommendVoice = options?.recommendVoice ?? false;
  const prechargeVoice = options?.prechargeVoice ?? false;

  const getCurrentVoice = () => {
    if (currentRateId) {
      return options?.processedProductsById?.[
        options?.processedProductsById?.[currentRateId]?.voice || ""
      ];
    }
  };
  const currentVoice = getCurrentVoice();

  return (
    <div>
      <h3 className="mb-6 text-secondary-100">
        {t("translation:Onboarding.sections.checkout.summary.voiceOption")}
      </h3>
      <OptionContainer
        selected={voiceIsSelected}
        highlighted
        recommended={recommendVoice}
        precharged={prechargeVoice}
        voice
      >
        <div className="voice-container">
          <div className="descriptions">
            <div className="title">
              <VoiceOptionCall className="phone" />
              <span>{t("Onboarding.sections.rate-selection.callAndSMS")}</span>
            </div>
          </div>
          <div className="durations">
            {currentVoice?.minContractDur !== undefined &&
            currentVoice?.chargeVoice !== undefined ? (
              <>
                <div className="days">
                  {currentVoice?.minContractDur}{" "}
                  {t("Onboarding.sections.rate-selection.days")}
                </div>
                <div id="price">
                  <div className="checkbox">
                    <Checkbox
                      id="voice"
                      name="voice"
                      disabled={prechargeVoice}
                      label={`+ ${currentVoice?.chargeVoice}`}
                      errors={errors}
                      register={register}
                      options={{
                        onChange: (e: ChangeEvent) => {
                          if (hasWatchSim) {
                            const input = e.target as HTMLInputElement;
                            // If the event target value is 'off' the modal is opened for confirmation
                            if (!input.checked) {
                              setIsModalOpen(true);
                            } else {
                              setValue("voice", true);
                            }
                          }
                        },
                      }}
                    />
                  </div>

                  <div className="days-label">
                    {currentVoice?.minContractDur}{" "}
                    {t("Onboarding.sections.rate-selection.days")}
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmNoVoiceModal
                    onClose={() => {
                      setValue("voice", true);
                      setIsModalOpen(false);
                    }}
                    onConfirm={() => {
                      setValue("voice", false);
                      setIsModalOpen(false);
                    }}
                  />
                )}
              </>
            ) : (
              <span className="text-base text-black font-normal break-words whitespace-normal">
                {t("Onboarding.sections.rate-selection.voiceOptionRequirement")}
              </span>
            )}
          </div>
        </div>
      </OptionContainer>
    </div>
  );
};
