import clsx from "clsx";
import {
  ClipboardEvent,
  FormEvent,
  KeyboardEvent,
  useCallback,
  useRef,
} from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage";

export type TwoFactorConfirmationCodeForm = {
  code: string;
};

export const TwoFactorConfirmationInput = () => {
  const { t } = useTranslation();
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const {
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<TwoFactorConfirmationCodeForm>();

  const hasError = !!errors.code;

  // On backspace keydown, we want to delete to the previous input field
  // if the field is already empty.
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && (e.target as HTMLInputElement).value === "") {
      const prevInput = inputRefs.current[Math.max(0, index - 1)];
      if (prevInput) prevInput.focus();
    }
  };

  // On input, we want to move to the next input field if we get a character
  // and the field is not already holding a value.
  const handleInput = useCallback(
    (e: FormEvent<HTMLInputElement>, index: number) => {
      const inputElement = e.target as HTMLInputElement;
      const [firstCharacter, ...remainingInput] = inputElement.value;
      if (firstCharacter === undefined) return;
      inputElement.value = firstCharacter ?? "";
      setValue("code", inputRefs.current.map((input) => input.value).join(""));
      clearErrors("code");

      if (firstCharacter !== undefined) {
        const nextInput = inputRefs.current[index + 1];
        if (nextInput) {
          nextInput.focus();
          nextInput.value = remainingInput.join("");
          nextInput.dispatchEvent(new Event("input", { bubbles: true }));
        }
      }
    },
    [setValue, clearErrors],
  );

  // On paste, we want to paste the first 6 characters into the input fields
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData?.getData("text").slice(0, 6) ?? "";
    inputRefs.current.forEach((input, i) => {
      input.value = pasteData[i] || "";
      input.dispatchEvent(new Event("input", { bubbles: true }));
    });
  };

  return (
    <fieldset>
      <label className="text-primary-100 text-lg font-semibold flex gap-2 flex-col">
        <span>{t("portal:authenticator.sms.verification.label")}</span>
        <div className="flex justify-center w-fit text-black gap-4">
          {Array.from({ length: 6 }).map((_, index) => (
            <input
              key={index}
              className={clsx(
                "max-w-8 text-center border-b-2 rounded-none text-lg focus:outline-none focus:border-primary-200",
                hasError
                  ? "border-secondary-100 text-secondary-100"
                  : "border-primary-100 text-black",
              )}
              required
              maxLength={1}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onInput={(e) => handleInput(e, index)}
              onPaste={handlePaste}
              ref={(el) => {
                if (el) inputRefs.current[index] = el;
              }}
            />
          ))}
        </div>
      </label>
      {hasError && <ErrorMessage name="code" message={errors.code?.message} />}
    </fieldset>
  );
};
