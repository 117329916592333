import "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffChange/Rates/Rates.scss";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Rates as RatesList } from "@/components/Onboarding/Steps/RateSelection/Rates/Rates";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { isInactive } from "@/utils/deviceUtils";
import { TransformedProducts } from "@/utils/tariffUtils";

export const Rates = ({ options }: { options: TransformedProducts }) => {
  const { t } = useTranslation("portal");
  const { data: simDetail, isLoading } = useDisplayedSim();

  // This product id describes the product that is currently active and therefore not bookable.
  const currentProductWhichIsUnavailable =
    !(simDetail === undefined || isInactive(simDetail)) && simDetail.autoRenew
      ? simDetail?.successorForRenew?.id
      : simDetail?.serviceID;

  // We want to disable the data rate, so we need to get that id from the options
  const currentDataRateWhichIsUnavailable =
    currentProductWhichIsUnavailable && simDetail?.autoRenew
      ? options.processedProductsById[currentProductWhichIsUnavailable].data
      : undefined;

  const { watch } = useFormContext<RateFormProps>();
  const currentRate = watch("rate");

  return (
    <div role="radiogroup" className="tariff-rate-selection">
      <h3 className="mb-6 text-secondary-100">
        {t("cockpit.managementTile.tariff.yourTariff")}
      </h3>
      {!!options?.processedProductsById && !isLoading && (
        <RatesList
          readonlyProductId={currentDataRateWhichIsUnavailable}
          currentRate={currentRate}
          {...options}
        />
      )}
    </div>
  );
};
