import "./IconTile.scss";

import clsx from "clsx";
import { FCC } from "types";

interface Props {
  icon?: JSX.Element;
  label?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  dataTestId?: string;
}

export const IconTile: FCC<Props> = ({
  icon,
  label,
  selected,
  disabled,
  onClick,
  dataTestId = "icon-tile",
}) => (
  <button
    aria-label={label}
    {...(disabled ? { disabled: true } : {})}
    className={clsx(
      "icon-tile",
      selected && "selected",
      disabled && "opacity-50",
    )}
    onClick={onClick}
    data-testid={dataTestId}
  >
    {icon && <div className="icon-container">{icon}</div>}
    <p className="label-container">{label}</p>
  </button>
);
