import { useAtom } from "jotai";

import { esimTransferTypeAtom } from "@/utils/atoms";

export enum transferTypes {
  TRANSFER = "transfer",
  PHYSICALTOESIM = "physicalToEsim",
  RELOAD = "reload",
}

export type TransferObject = {
  type: transferTypes;
};
export type SimTransferStates = { [key: number]: transferTypes };

/**
 * Hook to return whether there is a transformation (transfer, physicalToEsim, reload) of a sim in the state.
 * @param simId
 * @param transferType
 */
export const useSimTransferStateInfo = (
  simId: string,
  transferType: transferTypes,
) => {
  const [esimTransferType] = useAtom(esimTransferTypeAtom);
  // Flag to test if there is an unnoted transformation (transfer, physicalToEsim, reload) in the state.
  return (
    !isNaN(parseInt(simId)) &&
    esimTransferType[parseInt(simId)] === transferType
  );
};
