import "@/components/Interface/FormFields/Checkbox/Checkbox.scss";

import clsx from "clsx";
import { ReactNode } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

import SelectedCheckbox from "@/assets/ux/SelectedCheckbox.svg?react";
import UnselectedCheckbox from "@/assets/ux/UnselectedCheckbox.svg?react";

// TODO: For uncontrolled DeviceTile it's best to spread the register props (and not pass the `register` function, `name` and `options`) into the component like this `<Checkbox {...register(name, options)} />`
export interface CheckboxProps<TFieldValues extends FieldValues> {
  id?: string;
  className?: string;
  name: Path<TFieldValues>;
  value?: string | number;
  disabled?: boolean; // TODO: If this can change dynamically, we probably need a controlled component
  label?: string;
  labelComponent?: ReactNode;
  selectedIcon?: JSX.Element;
  unselectedIcon?: JSX.Element;
  options?: RegisterOptions<TFieldValues>;
  errors: FieldErrors;
  register: UseFormRegister<TFieldValues>;
  key?: any;
}

export const Checkbox = <TFormValues extends object>({
  id,
  className,
  name,
  value,
  disabled,
  label,
  labelComponent,
  selectedIcon = <SelectedCheckbox />,
  unselectedIcon = <UnselectedCheckbox />,
  options = { required: true },
  errors,
  register,
  key,
}: CheckboxProps<TFormValues>) => {
  const uid = id || `${name}-${value}`;

  return (
    <div
      id="checkbox-input-container"
      className={clsx("checkbox-container", className)}
    >
      <div className="input-wrapper">
        <input
          {...register(name, options)}
          key={key}
          id={uid}
          className={clsx("min-w-max", errors[name] && "error")}
          type="checkbox"
          {...(value ? { value: value } : {})}
          disabled={disabled}
          data-testid={uid}
          aria-label={label}
        />
        <div className="checkbox">
          <div className="selected">{selectedIcon}</div>
          <div className="unselected">{unselectedIcon}</div>
        </div>
      </div>
      {(label !== undefined || labelComponent !== undefined) && (
        <label htmlFor={uid}>
          {label}
          {labelComponent}
        </label>
      )}
    </div>
  );
};
