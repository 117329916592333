import "./StatusChip.scss";

import { Switch } from "@headlessui/react";
import React from "react";

import { MenuDevicePlus } from "@/assets/icons/icons";
import { DeviceStatusIndicator } from "@/components/Portal/Cockpit/DeviceList/DeviceListRow/DeviceStatusIndicator/DeviceStatusIndicator";

interface StatusProps {
  statusColor: any;
  statusLabel: string;
  enabled: boolean;
  onChange: (checked: boolean) => void;
}

export const StatusChip = ({
  statusColor,
  statusLabel,
  enabled,
  onChange,
}: StatusProps) => {
  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      name="notifications"
      className="status-chip"
    >
      <DeviceStatusIndicator color={statusColor} className="mr-2" />
      <div className="status-label">{statusLabel}</div>

      <MenuDevicePlus className={`icon ${enabled ? "icon" : ""}`}>
        <path strokeWidth=".5" d="M1 1l6 6m0-6L1 7" />
      </MenuDevicePlus>
    </Switch>
  );
};
