import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FCC } from "types";

import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { NewQRCodeReady } from "@/components/Portal/Cockpit/DeviceManagement/Details/NewQRCodeReady/NewQRCodeReady";
import { NewQRCodeWaiting } from "@/components/Portal/Cockpit/DeviceManagement/Details/NewQRCodeWaiting/NewQRCodeWaiting";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import {
  transferTypes,
  useSimTransferStateInfo,
} from "@/hooks/useSimTransferStateInfo";
import { useStartAndFetchEsimChange } from "@/hooks/useStartAndFetchEsimChange";
import { useStartESimChange } from "@/services/api";
import { isEsimchangeInProgress } from "@/utils/deviceUtils";

export const DetailsPhysicalToEsim: FCC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [simId] = useSimIdFromQueryParam();

  const { data: simDetail, refetch: refetchSimDetail } = useDisplayedSim();

  const {
    startChange: startESimChange,
    changeInProgress: esimChangeInProgress,
  } = useStartAndFetchEsimChange(
    refetchSimDetail,
    isEsimchangeInProgress,
    simId ? Number(simId) : undefined,
    useStartESimChange,
    transferTypes.PHYSICALTOESIM,
  );

  const startedEsimChange = useSimTransferStateInfo(
    simId,
    transferTypes.PHYSICALTOESIM,
  );

  //Once there is a fresh qr available, it should be displayed.
  if (startedEsimChange && !esimChangeInProgress && simDetail?.matchingId) {
    return (
      <NewQRCodeReady
        matchingId={simDetail.matchingId}
        title={t(
          "cockpit.managementTile.details.sections.details.switchFromPhysicalToEsim.screens.confirm.title",
        )}
      />
    );
  }

  if (esimChangeInProgress) {
    return (
      <NewQRCodeWaiting
        title={t(
          "cockpit.managementTile.details.sections.details.switchFromPhysicalToEsim.screens.confirm.title",
        )}
        text={t(
          "cockpit.managementTile.details.sections.details.changeEsim.waitingText",
        )}
      />
    );
  }

  return (
    <div className="max-w-[560px]">
      <h3 className="mb-6 text-secondary-100">
        {t("cockpit.managementTile.details.sections.details.esimProfile")}
      </h3>
      <h3 className="font-semibold mb-4">
        {t(
          "cockpit.managementTile.details.sections.details.switchFromPhysicalToEsim.screens.confirm.title",
        )}
      </h3>
      <p>
        <Trans
          t={t}
          i18nKey="cockpit.managementTile.details.sections.details.switchFromPhysicalToEsim.screens.confirm.text"
          components={[
            <a
              key={"link"}
              href={`${t("links.isMyDeviceESIMCompatible")}`}
              className="text-primary-100 underline"
              target="_blank"
              rel="noreferrer"
            />,
          ]}
        />
      </p>
      <ConfirmationButtonGroup
        successText={t("common.buttons.confirm")}
        disableSuccessButton={!startESimChange}
        successAction={() => {
          if (startESimChange) startESimChange({ simId: parseInt(simId) });
        }}
        cancelText={t("common.buttons.cancel")}
        cancelAction={() => {
          navigate(`../display?simId=${simId}`, { replace: true });
        }}
      />
    </div>
  );
};
