import "./VoiceSettings.scss";

import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { SettingToggle } from "@/components/Interface/SettingToggle/SettingToggle";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { Reasons } from "@/components/Portal/Cockpit/DeviceManagement/VoiceSettings/Reasons/Reasons";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import {
  getGetVoiceSettingsQueryKey,
  useGetVoiceSettings,
  useUpdateVoiceSettings,
} from "@/services/api";
import { VoiceSettings as VoiceSettingsType } from "@/services/model";

export const VoiceSettings: FC = () => {
  const [copyOfVoiceSettings, setCopyOfVoiceSettings] =
    useState<VoiceSettingsType>();
  const [simId] = useSimIdFromQueryParam();

  const [mode, setMode] = useQueryParam("mode");
  const { t } = useTranslation();
  const { t: tVoiceSettings } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.voiceSettings.settings",
  });
  const queryClient = useQueryClient();

  const isChangingVoiceSettings = mode === "edit";

  const { data: voiceSettings } = useGetVoiceSettings(
    { simId: parseInt(simId) || -1 },
    {
      query: {
        enabled: !!simId && !copyOfVoiceSettings,
        onSuccess: (data) => setCopyOfVoiceSettings(data),
      },
    },
  );

  const { mutate: updateVoiceSettings } = useUpdateVoiceSettings({
    mutation: {
      onSuccess: (data) => {
        queryClient.setQueryData(
          getGetVoiceSettingsQueryKey({ simId: parseInt(simId) || -1 }),
          data,
        );

        setMode("", { replace: true });
      },
    },
  });

  const toggle = (
    setting:
      | "roamingAndInternationalCalls"
      | "valueAddedServiceNumber"
      | "valueAddedServiceMessages"
      | "adultEntertainment"
      | "voiceMail",
  ) => {
    setCopyOfVoiceSettings((prev) => ({
      ...prev,
      [setting]:
        //Convert booleans to integers as the backend schema is configured to accept integer($int64)
        prev && !prev[setting] ? 1 : 0,
    }));
    // In case valueAddedServiceMessages is switched off, adultEntertainment is switched off too.
    if (
      setting === "valueAddedServiceMessages" &&
      copyOfVoiceSettings &&
      copyOfVoiceSettings[setting] &&
      copyOfVoiceSettings["adultEntertainment"]
    ) {
      setCopyOfVoiceSettings((prev) => ({
        ...prev,
        adultEntertainment: 0,
      }));
    }
  };

  const currentVoiceSettings = isChangingVoiceSettings
    ? copyOfVoiceSettings
    : voiceSettings;
  const blocked = !currentVoiceSettings?.canChangeSettings;
  const settings = [
    {
      title: tVoiceSettings("roaming.title"),
      subtitle: (
        <Trans
          i18nKey="cockpit.managementTile.voiceSettings.settings.roaming.subtitle"
          components={[
            <a
              target="_blank"
              aria-label="Roaming"
              key="termsAndConditionsLink"
              href={tVoiceSettings("roaming.link")}
              className="text-primary-100 underline"
              rel="noreferrer"
            />,
          ]}
        />
      ),
      state: !!currentVoiceSettings?.roamingAndInternationalCalls,
      onToggle: () => toggle("roamingAndInternationalCalls"),
      blocked,
    },
    {
      title: tVoiceSettings("serviceNumbers.title"),
      subtitle: tVoiceSettings("serviceNumbers.subtitle"),
      state: !!currentVoiceSettings?.valueAddedServiceNumber,
      onToggle: () => toggle("valueAddedServiceNumber"),
      blocked,
    },
    {
      title: tVoiceSettings("serviceSMS.title"),
      subtitle: tVoiceSettings("serviceSMS.subtitle"),
      state: !!currentVoiceSettings?.valueAddedServiceMessages,
      onToggle: () => toggle("valueAddedServiceMessages"),
      blocked,
    },
    {
      title: tVoiceSettings("adult.title"),
      subtitle: tVoiceSettings("adult.subtitle"),
      state: !!currentVoiceSettings?.adultEntertainment,
      onToggle: () => toggle("adultEntertainment"),
      blocked: blocked || !currentVoiceSettings?.valueAddedServiceMessages,
    },
    {
      title: tVoiceSettings("voiceBox.title"),
      subtitle: tVoiceSettings("voiceBox.subtitle"),
      state: !!currentVoiceSettings?.voiceMail,
      onToggle: () => toggle("voiceMail"),
      // Voice Mail can always be changed
      blocked: !voiceSettings,
    },
  ];

  const save = () => {
    if (simId && currentVoiceSettings) {
      updateVoiceSettings({ data: currentVoiceSettings });
    }
  };

  const cancel = () => {
    setMode("", { replace: true });
  };

  return (
    <>
      <div className="voice-settings management-grid-layout">
        <section
          data-testid="voice-settings-section-1"
          className="management-section col-start-1"
        >
          <div className="flex flex-row justify-between mb-6">
            <h3 className="text-secondary-100 font-semibold ">
              {t("cockpit.managementTile.voiceSettings.title")}
            </h3>
            {!isChangingVoiceSettings && currentVoiceSettings && (
              <button
                className="text-[13px] text-primary-100 underline"
                onClick={() => setMode("edit", { replace: true })}
              >
                {t("common.buttons.change")}
              </button>
            )}
          </div>
          {settings
            .slice(0, 3)
            .map(({ title, subtitle, state, blocked, onToggle }, index) => (
              <SettingToggle
                key={index}
                checked={state}
                title={title}
                subtitle={subtitle}
                blocked={blocked}
                onToggle={onToggle}
              />
            ))}
        </section>
        <section
          data-testid="voice-settings-section-2"
          className="management-section xl:pt-14 col-start-1 xl:col-start-2"
        >
          {settings
            .slice(3)
            .map(({ title, subtitle, state, blocked, onToggle }, index) => (
              <SettingToggle
                key={index}
                checked={state}
                title={title}
                subtitle={subtitle}
                blocked={blocked}
                onToggle={onToggle}
              />
            ))}
        </section>
      </div>
      <div>
        {isChangingVoiceSettings && (
          <ConfirmationButtonGroup
            cancelAction={() => cancel()}
            cancelText={t("common.buttons.cancel")}
            successAction={() => save()}
            successText={t("common.buttons.confirm")}
          />
        )}
        {blocked && <Reasons reasons={voiceSettings?.reason || []} />}
      </div>
    </>
  );
};
