import "./FadeOverlayContainer.scss";

import { useCallback, useEffect, useRef, useState } from "react";
import { FCC } from "types";

interface Props {
  id?: string;
}

export const FadeOverlayContainer: FCC<Props> = ({ children, id }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const resizeObserverRef = useRef<ResizeObserver>();

  const updateShowOverlay = useCallback(() => {
    if (containerRef.current) {
      setShowOverlay(
        containerRef.current.scrollLeft + 10 <
          containerRef.current.scrollWidth - containerRef.current.clientWidth,
      );
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      resizeObserverRef.current = new ResizeObserver(updateShowOverlay);
      resizeObserverRef.current.observe(containerRef.current);
    }

    return () => resizeObserverRef.current?.disconnect();
  }, [updateShowOverlay]);

  return (
    <div id={id} className="overlay" data-show={showOverlay}>
      <div
        ref={containerRef}
        className="overflow-x-auto"
        onScroll={updateShowOverlay}
      >
        {children}
      </div>
    </div>
  );
};
