import { Transition } from "@headlessui/react";
import { FCC } from "types";

/**
 * A simple Transition that animates the appearance of its children.
 **/
export const AppearTransition: FCC = ({ children }) => {
  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity ease-linear duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
};
