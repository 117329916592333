import clsx from "clsx";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { CheckSingle } from "@/assets/icons/icons";
import { RadioButton } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { TransformedProduct } from "@/utils/tariffUtils";

interface RateRadioButtonProps {
  transformedProduct: TransformedProduct;
  disabled?: boolean;
  preSelectedProcessedProduct?: TransformedProduct;
  isReadonly?: boolean;
}

export const RateRadioButton: FC<RateRadioButtonProps> = ({
  transformedProduct,
  disabled,
  preSelectedProcessedProduct,
  isReadonly,
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<RateFormProps>();
  if (!transformedProduct.chargeFlat || !transformedProduct.id) {
    return null;
  }
  const isPrechargedPreselected =
    transformedProduct.bandwidthLabel ===
      preSelectedProcessedProduct?.bandwidthLabel &&
    preSelectedProcessedProduct?.precharged &&
    preSelectedProcessedProduct?.duration === transformedProduct?.duration;

  const label = isReadonly
    ? "Selected rate"
    : transformedProduct.precharged || isPrechargedPreselected
      ? "CHF 0.00"
      : transformedProduct.chargeFlat;

  return (
    <div className={clsx("radio-button", disabled && "disabled")}>
      <div
        className="radio-button-container"
        data-testid={`activeflag-${transformedProduct.id}`}
      >
        <RadioButton<RateFormProps>
          id={`option-${transformedProduct.id}`}
          name="rate"
          className={clsx(isReadonly && "hidden")}
          value={transformedProduct.id}
          label={label}
          options={{ readOnly: disabled }}
          errors={errors}
          register={register}
        />
        {isReadonly && (
          <>
            <CheckSingle />
            <label data-testid="activeflag-label" className="label">
              {transformedProduct.chargeFlat}
            </label>
          </>
        )}
      </div>
    </div>
  );
};
