import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { useOnboardingRedirect } from "@/hooks/useOnboardingRedirects";
import { useQueryParam } from "@/hooks/useQueryParam";
import { authenticatorRoutes } from "@/router/AuthenticatorRoutes.tsx";
import { getCustomer } from "@/services/api";
import { BasicCustomerDataTwoFactorMethod } from "@/services/model";
import {
  accessTokenAtom,
  destinationAtom,
  hasChosenStandardLoginAtom,
  refreshTokenAtom,
  verifierAtom,
} from "@/utils/atoms";
import { fetchAuthToken } from "@/utils/authentication";
import { log } from "@/utils/log";

/*
 * This hook handles the redirect to the portal from the Oauth2 provider after the user has logged in.
 * We decide where to redirect the user based on the user's onboarding status and the two-factor method they have chosen.
 */
export const useLoginRedirect = (): boolean => {
  const isRedirecting = useRef(false);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom);
  const hasChosenStandardLogin = useAtomValue(hasChosenStandardLoginAtom);
  const destination = useAtomValue(destinationAtom);
  const verifier = useAtomValue(verifierAtom);

  const navigate = useNavigate();
  const [redirectToOnboarding, isRedirectingToOnboarding] =
    useOnboardingRedirect();
  const [code] = useQueryParam("code");

  const authorized = !!accessToken;

  // This effect is triggered when the user is redirected back from the Oauth2 provider.
  // It fetches our auth tokens.
  useEffect(() => {
    if (code && verifier && !authorized) {
      isRedirecting.current = true;
      fetchAuthToken(code, verifier)
        .then(({ access_token, refresh_token }) => {
          setAccessToken(access_token);
          setRefreshToken(refresh_token);
        })
        .catch((e) => {
          log(e);
        })
        .finally(() => {
          isRedirecting.current = false;
        });
    }
  }, [authorized, code, setAccessToken, setRefreshToken, verifier]);

  const [isAnonymousUser] = useIsAnonymousUser();

  useEffect(() => {
    isRedirecting.current = true;
    if (accessToken && refreshToken && !isAnonymousUser) {
      getCustomer()
        .then((customerData) => {
          if (customerData.onboardingFinished) {
            const shouldSeeAuthenticatorSetup =
              customerData?.twoFactorMethod ===
                BasicCustomerDataTwoFactorMethod.STANDARD &&
              !hasChosenStandardLogin;
            if (shouldSeeAuthenticatorSetup) {
              navigate(authenticatorRoutes.base, { replace: true });
            } else {
              navigate(destination || "/portal/cockpit", { replace: true });
            }
          } else {
            redirectToOnboarding(customerData);
          }
        })
        .catch((e) => {
          setAccessToken(null);
          setRefreshToken(null);
        });
    }
    isRedirecting.current = false;
  }, [
    accessToken,
    destination,
    hasChosenStandardLogin,
    isAnonymousUser,
    navigate,
    redirectToOnboarding,
    refreshToken,
    setAccessToken,
    setRefreshToken,
  ]);

  return isRedirecting.current || isRedirectingToOnboarding;
};
