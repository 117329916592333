import { useAtom } from "jotai";

import {
  useChangeTwoFactorAuthentication,
  useChangeTwoFactorAuthenticationSMSNumber,
} from "@/services/auth";
import { ValidateTwoFactorAuthenticationMethod } from "@/services/model";
import { phoneNumberAtom } from "@/utils/atoms";

import { useRequestSmsToken } from "./useRequestSmsToken";

export const useResendActionForSmsSetup = () => {
  const [phoneNumber] = useAtom(phoneNumberAtom);
  const resendParams = {
    method: ValidateTwoFactorAuthenticationMethod.SMS,
    phoneNumber,
  };

  const resendAction = useRequestSmsToken(
    useChangeTwoFactorAuthentication,
    resendParams,
  );

  return resendAction;
};

export const useResendForSmsPhonenumberChange = () => {
  const [phoneNumber] = useAtom(phoneNumberAtom);
  const resendParams = {
    phoneNumber,
  };

  const resendAction = useRequestSmsToken(
    useChangeTwoFactorAuthenticationSMSNumber,
    resendParams,
  );

  return resendAction;
};
