import compact from "lodash/compact";
import { useMemo, useState } from "react";

import { useGetESimInfo, useGetRoaming } from "@/services/api";
import { ESimInfo, RoamingInfo, SimCardDetail } from "@/services/model";
import { isUnscannedEsimInfo } from "@/utils/deviceUtils";

export interface ExtendedSimData {
  roamingInfo?: RoamingInfo[];
  esimInfo?: ESimInfo;
}

export interface ExtendedSimCardDetail extends SimCardDetail {
  roamingInfo?: RoamingInfo[];
  esimInfo?: ESimInfo;
}

export interface ExtendedSimCardDetailWithEsimInfo
  extends ExtendedSimCardDetail {
  matchingId: string;
  esimInfo: ESimInfo;
}

/**
 * This hook takes a list of sims and extends these by fetching esim and roaming info to result in a list of ExtendedSims
 * @param sims list of basic sims to extend
 * @param refetchInterval an optional interval for the api requests to refetch all data
 */
export const useExtendedSims = (
  sims: SimCardDetail[],
  refetchInterval?: false | number,
) => {
  const [extendedSimData, setExtendedSimData] = useState<{
    [key: string]: ExtendedSimData;
  }>({});

  const simIds = useMemo(() => compact(sims.map((sim) => sim.simId)), [sims]);

  const { isFetching: isLoadingRoaming } = useGetRoaming(
    { simIds },
    {
      query: {
        enabled: simIds.length > 0,
        refetchInterval,
        refetchOnMount: true,
        onSuccess: (roamingInfos) => {
          const newExtendedSimData = {
            ...extendedSimData,
          };

          simIds.forEach((simCardID) => {
            const simKey = String(simCardID);
            newExtendedSimData[simKey] = {
              ...(newExtendedSimData[simKey] ?? {}),
              roamingInfo:
                roamingInfos?.filter(({ simId }) => simId === simCardID) ?? [],
            };
          });
          setExtendedSimData(newExtendedSimData);
        },
      },
    },
  );

  // get a list of SimIDs where esim infos are missing
  // if `remainingDays` is not set, the SIM is not ready for further processing, so we don't need additional data for now
  const esims = compact(
    sims.filter(
      (sim) =>
        sim.simId !== undefined &&
        sim.remainingDays !== undefined &&
        sim.simType === "eSIM",
    ),
  );
  const missingEsimsIDs = useMemo(
    () => compact(esims.map((sim) => sim.simId)),
    [esims],
  );

  const { isFetching: isLoadingEsim } = useGetESimInfo(
    { simIds: missingEsimsIDs },
    {
      query: {
        enabled: missingEsimsIDs.length > 0,
        refetchInterval,
        refetchOnMount: true,
        onSuccess: (esimInfos) => {
          const newExtendedSimData = {
            ...extendedSimData,
          };
          missingEsimsIDs.forEach((simCardID) => {
            const simKey = String(simCardID);
            newExtendedSimData[simKey] = {
              ...(newExtendedSimData[simKey] ?? {}),
              esimInfo:
                esimInfos?.find(({ simId }) => simId === simCardID) ?? {},
            };
          });
          setExtendedSimData(newExtendedSimData);
        },
      },
    },
  );

  // enrich the given sims and make them extended sims
  const extendedSims: ExtendedSimCardDetail[] = sims.map((simCard) => ({
    ...simCard,
    ...(extendedSimData[String(simCard.simId)] ?? {}),
  }));

  const isLoading = isLoadingRoaming || isLoadingEsim;

  // determine whether there are unscanned E-SIMs
  const hasUnscannedEsims = extendedSims.some((simCard) =>
    simCard.esimInfo ? isUnscannedEsimInfo(simCard.esimInfo) : false,
  );

  return { extendedSims, isLoading, hasUnscannedEsims };
};
