import "./RemainingTime.scss";

import clsx from "clsx";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import { FCWithClassName } from "types";

import { ProgressBar } from "@/components/Layout/ProgressBar/ProgressBar";
import { SuccessorForRenew } from "@/services/model";
import { getLocalizedDateString } from "@/utils/dateHelpers";
import { getFlatNameFromLocalFlatSpeed } from "@/utils/deviceUtils";

interface RemainingTimeProps {
  isPending?: boolean;
  hasRenewal: boolean;
  remainingTime: number;
  successorForRenew?: SuccessorForRenew;
  totalTime: number;
}

export const RemainingTime: FCWithClassName<RemainingTimeProps> = ({
  className,
  isPending = false,
  hasRenewal,
  remainingTime,
  successorForRenew,
  totalTime,
}) => {
  const { t, i18n } = useTranslation("portal");
  const [usageBarWidth, setUsageBarWidth] = useState<number | undefined>(
    undefined,
  );
  const isDeviceManagement = !!useMatch("/portal/cockpit/manage/*");
  const isWatchSim = !!useMatch("/portal/cockpit/manage/watch-sim/*");

  useEffect(() => {
    if (remainingTime !== undefined && !isPending) {
      setUsageBarWidth(((totalTime - remainingTime) / totalTime) * 100);
    }
  }, [totalTime, remainingTime, isPending]);

  const today = new Date();
  const renewalDate = new Date(
    Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + remainingTime,
    ),
  );
  const localizedRenewalDate = getLocalizedDateString(
    renewalDate,
    i18n.language,
  );

  const pendingTranslationKey = isWatchSim
    ? "cockpit.managementTile.watchSim.display.pending"
    : "layout.tiles.activeSim.pending";
  const renewalTranslationKey = isDeviceManagement
    ? "cockpit.managementTile.details.sections.autoRenewal.renewsOnDate"
    : "layout.tiles.activeSim.renewsInDays";
  const renewalWithSuccessorTranslationKey = isDeviceManagement
    ? "cockpit.managementTile.details.sections.autoRenewal.renewsOnDateWith"
    : "layout.tiles.activeSim.renewsInDaysWith";
  const endsInDaysTranslationKey = isDeviceManagement
    ? "cockpit.managementTile.details.sections.autoRenewal.endsInDays"
    : "layout.tiles.activeSim.endsInDays";

  return (
    <div className={clsx(className, "runtime-wrapper")}>
      <div className="mt-auto w-full">
        <ProgressBar usageBarWidth={usageBarWidth} />
        <div
          data-testid="remaining-time"
          className={clsx("remaining-time", isPending && "opacity-50")}
        >
          {hasRenewal && !!successorForRenew ? (
            <Trans
              t={t}
              components={[
                <span key={0} className="font-semibold" />,
                <span
                  key={1}
                  className="font-semibold text-highlight-100 whitespace-nowrap"
                />,
              ]}
              values={{
                count: remainingTime,
                flatName: getFlatNameFromLocalFlatSpeed(
                  successorForRenew.description,
                ),
                total: totalTime,
                renewalDate: localizedRenewalDate,
              }}
              i18nKey={
                isPending
                  ? pendingTranslationKey
                  : renewalWithSuccessorTranslationKey
              }
            />
          ) : (
            <Trans
              t={t}
              components={[<span key={0} className="font-semibold" />]}
              values={{
                count: remainingTime || 0,
                total: totalTime,
                renewalDate: localizedRenewalDate,
              }}
              i18nKey={
                isPending
                  ? pendingTranslationKey
                  : hasRenewal
                    ? renewalTranslationKey
                    : endsInDaysTranslationKey
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
