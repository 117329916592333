import { Navigate, Route } from "react-router-dom";

import { Checkout } from "@/components/Onboarding/Steps/Checkout/Checkout";
import { DeviceType } from "@/components/Onboarding/Steps/DeviceType/DeviceType";
import { NumberPorting } from "@/components/Onboarding/Steps/NumberPorting/NumberPorting";
import { PersonalDetails } from "@/components/Onboarding/Steps/PersonalDetails/PersonalDetails";
import { RateSelection } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { SimSelection } from "@/components/Onboarding/Steps/SimSelection/SimSelection";
import { SimVerification } from "@/components/Onboarding/Steps/SimVerification/SimVerification";

export const onboardingRoutes = [
  <Route key="index" index element={<Navigate to="/onboarding/1" replace />} />,
  <Route key="first-step" path="1">
    {/* Redirect incoming navigation from /onboarding/1/ to /onboarding/1/sim-selection/ */}
    <Route index element={<Navigate to="sim-selection" replace />} />
    <Route path="sim-selection" element={<SimSelection />} />
    <Route path="sim-verification" element={<SimVerification />} />
  </Route>,
  <Route key="second-step" path="2">
    <Route index element={<Navigate to="device-type" replace />} />
    <Route path="device-type" element={<DeviceType />} />
  </Route>,
  <Route key="third-step" path="3">
    <Route index element={<Navigate to="rate-selection" replace />} />
    <Route path="rate-selection" element={<RateSelection />} />
    <Route path="number-porting" element={<NumberPorting />} />
  </Route>,
  <Route key="fourth-step" path="4">
    <Route index element={<Navigate to="personal-details" replace />} />
    <Route path="personal-details" element={<PersonalDetails />} />
  </Route>,
  <Route key="fifth-step" path="5">
    <Route index element={<Navigate to="checkout" replace />} />
    <Route path="checkout" element={<Checkout />} />
  </Route>,
];
