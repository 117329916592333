import { useGetCustomer } from "@/services/api";
import { BasicCustomerDataTwoFactorMethod } from "@/services/model";

/**
 * Check if the user should skip the authenticator setup based on their onboarding status and two-factor configuration,
 * but disregard their choice to use "standard" login / no 2FA.
 */
export const useHas2fa: () => boolean = () => {
  const { data: customer } = useGetCustomer();
  return (
    customer?.twoFactorMethod !== BasicCustomerDataTwoFactorMethod.STANDARD
  );
};
