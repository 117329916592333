import { useTranslation } from "react-i18next";

import { PortalHeading } from "@/components/Portal/PortalHeading/PortalHeading";
import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";

export const DesktopHeading = () => {
  const { t } = useTranslation();
  const [isAnonymousUser] = useIsAnonymousUser();
  return (
    <div className="hidden xl:block max-w-[1048px] mx-auto">
      {!isAnonymousUser && (
        <PortalHeading heading={t("Common.welcome")}>
          <p className="mb-14 mt-8 md:mt-10">{t("Onboarding.subheading")}</p>
        </PortalHeading>
      )}
      {isAnonymousUser && (
        <PortalHeading heading={t("Common.welcomeEsim")}>
          <p className="mb-14 mt-8 md:mt-10">
            {t("Onboarding.subheadingEsim")}
          </p>
        </PortalHeading>
      )}
    </div>
  );
};
