import "./SearchBar.scss";

import clsx from "clsx";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Close } from "@/assets/icons/icons";
import { useDebounce } from "@/hooks/useDebounce";
import { useQueryParam } from "@/hooks/useQueryParam";

interface Props {
  isOpen: boolean;
  onEmptyBlur: () => void;
}

export const SearchBar: FC<Props> = ({ isOpen, onEmptyBlur }) => {
  const [queryParam, setQueryParams] = useQueryParam("searchTerm");
  const [searchTerm, setSearchTerm] = useState(queryParam);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);
  const [, setPaginationPage] = useQueryParam("page");
  const { t } = useTranslation("portal");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (queryParam !== debouncedSearchTerm) {
      setQueryParams(debouncedSearchTerm, { replace: true });
    }
  }, [queryParam, debouncedSearchTerm, setQueryParams]);

  const onBlur = () => {
    if (!searchTerm) {
      onEmptyBlur();
    }
  };

  const onClear = () => {
    setSearchTerm("");
    ref?.current?.focus();
  };

  return (
    <div
      data-testid="search-bar"
      id="search-bar"
      className={clsx("h-10", isOpen ? "open" : "closed")}
    >
      <input
        id="filter-searchbar-input"
        ref={ref}
        className="pl-2"
        value={searchTerm}
        placeholder={t("cockpit.filter.searchBar.placeholder")}
        onChange={(event) => {
          setPaginationPage("");
          setSearchTerm(event.target.value);
        }}
        onBlur={onBlur}
      />
      {searchTerm && (
        <button onClick={onClear} data-testid="search-close-button">
          <Close className="h-4 w-4 my-auto" />
        </button>
      )}
    </div>
  );
};
