import { useTranslation } from "react-i18next";

import { ClipboardField } from "@/components/Interface/ClipboardFiled/ClipboardField";
import { LocalLoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";

export const TwoFactorManualQrCodeField = ({
  manualCode,
}: {
  manualCode?: string;
}) => {
  const { t } = useTranslation();

  if (!manualCode)
    return (
      <div className="h-full w-full flex justify-center items-center">
        <LocalLoadingSpinner />
      </div>
    );

  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-secondary-100 text-base font-semibold">
        {t("portal:authenticator.app.setup.step1.your-manual-key")}
      </h2>

      <ClipboardField
        content={manualCode}
        successMessage={t("portal:authenticator.app.setup.step1.copied")}
        errorMessage={t("portal:authenticator.app.setup.step1.copy-error")}
      />
    </div>
  );
};
