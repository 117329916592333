import { RadioGroup } from "@headlessui/react";
import { ReactElement } from "react";
import { FCWithClassName } from "types";

import Mastercard from "@/assets/icons/payment/mastercard.svg?react";
import Postfinance from "@/assets/icons/payment/postfinance.svg?react";
import Twint from "@/assets/icons/payment/twint.svg?react";
import Visa from "@/assets/icons/payment/visa.svg?react";
import { RadioButtonOption } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import { DrCreditCardCardType } from "@/services/model";

export const paymentProvidersObject = {
  TWI: {
    label: "TWINT",
    value: DrCreditCardCardType.TWI,
    icon: <Twint className="ml-3" />,
  },
  ECA: {
    label: "Mastercard",
    value: DrCreditCardCardType.ECA,
    icon: <Mastercard className="ml-3" />,
  },
  VIS: {
    label: "Visa",
    value: DrCreditCardCardType.VIS,
    icon: <Visa className="ml-3" />,
  },
  PFC: {
    label: "PostFinance",
    value: DrCreditCardCardType.PFC,
    icon: <Postfinance className="ml-3" />,
  },
};

export const paymentProviders: PaymentOption[] = Object.values(
  paymentProvidersObject,
);

interface PaymentOption {
  label: string;
  subtext?: string;
  value: string;
  icon?: ReactElement;
  disabled?: boolean;
}

interface Props {
  paymentOptions?: PaymentOption[];
  selected?: string | undefined;
  onChange?: (s: DrCreditCardCardType) => void;
}

/**
 * Displays a choice of payment provders with radio buttons.
 */
export const PaymentProviderPicker: FCWithClassName<Props> = ({
  paymentOptions = paymentProviders,
  selected,
  onChange,
  className,
}) => (
  <RadioGroup
    id="payment-provider-picker"
    className={className}
    value={selected || ""}
    onChange={(s: DrCreditCardCardType) => s && onChange?.(s)}
  >
    {paymentOptions.map(({ label, value, icon, disabled, subtext }) => (
      <div className="mb-6" key={value} data-testid={`payment-option-${value}`}>
        <div className="flex justify-between items-center">
          <RadioButtonOption
            disabled={disabled}
            value={value}
            name="paymentOption"
            label={label}
          />
          <div>{icon}</div>
        </div>
        {subtext && (
          <div className="ml-7 mt-2 font-x-small text-primary-100">
            {subtext}
          </div>
        )}
      </div>
    ))}
  </RadioGroup>
);
