import "./DesktopSplashScreen.scss";

import { Transition } from "@headlessui/react";

import Logo from "@/assets/header/Logo.svg?react";
import GreenHeader from "@/assets/header/PortalDesktopHeader.svg?react";
import { BlueSidemenuBlob } from "@/components/Layout/BlueSidemenuBlob/BlueSidemenuBlob";
import { SplashScreenInterface } from "@/components/Layout/SplashScreens/SplashScreens";

export const DesktopSplashScreen = ({
  showTransition,
}: SplashScreenInterface) => (
  <Transition
    data-testid="desktop-splash-screen"
    appear
    className="desktop-splash-screen"
    show={showTransition}
  >
    {/* White background to cover app content */}
    <Transition.Child
      id="white-overlay"
      enter="duration-700 transition-opacity"
      leave="transition-opacity ease-in-out duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    />
    {/* Animated Blue SVG Layer */}
    <Transition.Child
      id="blue-sidebar"
      enter="duration-500 ease-in-out transition-transform transform"
      enterFrom="scale-100"
      enterTo="scale-[1.3] 2xl:scale-[1.5]"
      leave="duration-200 ease-in-out transition transform"
      leaveFrom="scale-[1.3] 2xl:scale-[1.5]"
      leaveTo="scale-100"
    >
      <BlueSidemenuBlob />
    </Transition.Child>

    {/* LOGO SVG Layer */}
    <Transition.Child
      className="z-50"
      id="screen-logo"
      enter="ease-in transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Logo />
    </Transition.Child>

    {/* Animated GREEN SVG Layer */}
    {/* we need to place the green svg in last position so that it's on top of the blue svg as per in design */}
    <Transition.Child
      id="green-header"
      enter="duration-500 ease-in-out transition-transform transform"
      enterFrom="scale-100"
      enterTo="scale-[2.5]"
      leave="ease-out transition transform duration-200"
      leaveFrom="scale-[2.5]"
      leaveTo="scale-100"
    >
      <GreenHeader />
    </Transition.Child>
  </Transition>
);
