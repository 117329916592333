import { useSetAtom } from "jotai";
import { QRCodeSVG } from "qrcode.react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { esimTransferTypeAtom } from "@/utils/atoms";

export const NewQRCodeReady = ({
  matchingId,
  title,
}: {
  matchingId: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [simId] = useSimIdFromQueryParam();
  const setEsimTransferType = useSetAtom(esimTransferTypeAtom);

  return (
    <div>
      <h3 className="mb-6 text-secondary-100">
        {t("cockpit.managementTile.details.sections.details.esimProfile")}
      </h3>
      <div className="w-fit">
        <div className="flex flex-col md:flex-row">
          <div>
            <h3 className="font-semibold mb-4">{title}</h3>
            <p className="max-w-[560px]">
              <Trans
                t={t}
                components={[
                  <a
                    key={0}
                    className="text-primary-100 font-small underline"
                    href={t("layout.tiles.qrCodeTile.instructionsLink")}
                    target="_blank"
                    rel="noreferrer"
                  />,
                ]}
                i18nKey="cockpit.managementTile.details.sections.details.changeEsim.qrCodeDone"
              />
            </p>
          </div>
          <div className="mx-auto mt-16 md:ml-16 md:mt-2">
            {matchingId && <QRCodeSVG value={matchingId} size={210} />}
          </div>
        </div>
        <ConfirmationButtonGroup
          successText={t("common.buttons.done")}
          successAction={() => {
            setEsimTransferType((prev) => {
              return {
                ...prev,
                [simId]: undefined,
              };
            });
            navigate(`../display?simId=${simId}`, { replace: true });
          }}
          cancelText={t("common.buttons.cancel")}
          cancelAction={() => {
            setEsimTransferType((prev) => {
              return {
                ...prev,
                [simId]: undefined,
              };
            });
            navigate(`../display?simId=${simId}`, { replace: true });
          }}
        />
      </div>
    </div>
  );
};
