import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { TextContainer } from "@/components/Portal/UserAccount/PersonalDetails/TextContainer/TextContainer";
import { setTokens } from "@/hooks/useToken";
import { useGetCustomer } from "@/services/api";
import { useChangePassword, useChangeUsername } from "@/services/auth";
import { BasicCustomerData } from "@/services/model";
import { userAccountStateAtom } from "@/utils/atoms";
import { emailRegex, passwordRegex } from "@/utils/customerUtils";

type EmailForm = { currentEmail: string; newEmail: string; password: string };
type PasswordForm = { currentPassword: string; newPassword: string };
export const LoginData: FCC = () => {
  const [userAccountState, setUserAccountState] = useAtom(userAccountStateAtom);
  const { t } = useTranslation();
  const {
    data: customer,
    isLoading: isGettingCustomer,
    refetch,
  } = useGetCustomer();

  const { mutate: updateCustomerMail, isLoading: isUpdatingMail } =
    useChangeUsername({
      mutation: {
        onSuccess: (data) => {
          // @ts-ignore
          setTokens(data.access_token, data.refresh_token, data.verifier);
          setUserAccountState((prev) => ({ ...prev, editingMail: false }));
          emailForm.reset();
          refetch();
        },
        onError: (error) => {
          if (
            `${error.response?.data}`.includes(
              "no user found for user identifier",
            )
          ) {
            emailForm.setError("currentEmail", {
              type: "backendError",
            });
          } else if (
            `${error.response?.data}`.includes("invalid credentials")
          ) {
            emailForm.setError("password", {
              type: "backendError",
            });
          } else if (
            `${error.response?.data}`.includes("username already in use")
          ) {
            emailForm.setError("newEmail", {
              type: "backendError",
            });
          } else if (
            `${error.response?.data}`.includes("Invalid Email Address")
          ) {
            emailForm.setError("newEmail", {
              type: "invalidEmailError",
            });
          } else {
            passwordForm.setError("newPassword", {
              type: "backendError",
            });
          }
        },
      },
    });
  const { mutate: updateCustomerPassword, isLoading: isUpdatingPassword } =
    useChangePassword({
      mutation: {
        onSuccess: (data) => {
          // @ts-ignore
          setTokens(data.access_token, data.refresh_token, data.verifier);
          setUserAccountState((prev) => ({ ...prev, editingPassword: false }));
          passwordForm.reset();
        },
        onError: (error) => {
          if (`${error.response?.data}`.includes("invalid credentials")) {
            passwordForm.setError("currentPassword", {
              type: "backendError",
            });
          } else {
            passwordForm.setError("newPassword", {
              message: t("translation:label.validation.password"),
              type: "backendError",
            });
          }
        },
      },
    });
  const isLoading = isGettingCustomer || isUpdatingMail || isUpdatingPassword;
  const [customerData, setCustomerData] = useState<
    BasicCustomerData | undefined
  >();
  useEffect(() => {
    setCustomerData(customer);
  }, [customer]);
  const emailForm = useForm<EmailForm>({
    mode: "onChange",
    defaultValues: { currentEmail: "", newEmail: "", password: "" },
  });
  const passwordForm = useForm<PasswordForm>({
    mode: "onChange",
    defaultValues: { newPassword: "", currentPassword: "" },
  });

  const saveMail = (data: EmailForm) => {
    if (customerData && data) {
      updateCustomerMail({
        data: {
          oldUsername: data.currentEmail,
          newUsername: data.newEmail,
          password: data.password,
        },
      });
    }
  };
  const savePassword = (data: PasswordForm) => {
    if (customerData && data) {
      updateCustomerPassword({
        data: {
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      });
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="flex flex-col gap-6 md:min-w-[464px]">
        <h3 className="text-secondary-100 font-semibold">
          {t("user-account.personalDetails.loginData.title")}
        </h3>
        {userAccountState.editingMail ? (
          <form
            id="change-email-form"
            className="flex flex-col gap-6"
            onSubmit={emailForm.handleSubmit(saveMail)}
          >
            <FormField
              id="current-email-input"
              label={t("user-account.personalDetails.loginData.currentEmail")}
              autocomplete="email"
              name="currentEmail"
              errors={emailForm.formState.errors}
              register={emailForm.register}
              type="email"
              placeholder={t(
                "translation:Onboarding.sections.personal-details.form.placeholder.email",
              )}
              options={{
                required: t("translation:label.validation.required"),
                pattern: {
                  value: emailRegex,
                  message: t("translation:label.validation.email"),
                },
                backendError: t(
                  "user-account.personalDetails.loginData.currentEmailError",
                ),
              }}
            />

            <FormField
              id="new-email-input"
              label={t("user-account.personalDetails.loginData.newEmail")}
              autocomplete="email"
              name="newEmail"
              errors={emailForm.formState.errors}
              register={emailForm.register}
              type="email"
              placeholder={t(
                "translation:Onboarding.sections.personal-details.form.placeholder.email",
              )}
              options={{
                required: t("translation:label.validation.required"),
                pattern: {
                  value: emailRegex,
                  message: t("translation:label.validation.email"),
                },
                maxLength: 50,
                backendError: t(
                  "user-account.personalDetails.loginData.emailInUseError",
                ),
                invalidEmailError: t(
                  "user-account.personalDetails.loginData.emailInvalid",
                ),
              }}
            />

            <FormField
              label={t("user-account.personalDetails.loginData.password")}
              id="current-password-input"
              autocomplete="current-password"
              name="password"
              errors={emailForm.formState.errors}
              register={emailForm.register}
              type="password"
              placeholder={t(
                "translation:Onboarding.sections.personal-details.form.placeholder.password",
              )}
              options={{
                required: t("translation:label.validation.required"),
                backendError: t(
                  "user-account.personalDetails.loginData.passwordError",
                ),
              }}
            />
            {!emailForm.formState.errors["password"] && (
              <span className="text-primary-100 inline-block font-x-small">
                {t("translation:label.hint.password")}
              </span>
            )}

            <div className="flex flex-col gap-4 mt-10 md:flex-row justify-between md:justify-end md:col-span-2">
              <Button
                type="button"
                onClick={() => {
                  passwordForm.reset();
                  setUserAccountState((prev) => ({
                    ...prev,
                    editingMail: false,
                  }));
                }}
                className="accent inverted"
              >
                {t("common.buttons.cancel")}
              </Button>
              <SubmitButton
                className="w-full accent"
                label={t("common.buttons.save")}
              />
            </div>
          </form>
        ) : (
          <div>
            <SectionButtonHeader
              header={t("user-account.personalDetails.loginData.email")}
            >
              {!userAccountState.editingPassword &&
                !userAccountState.editingPersonalData && (
                  <button
                    onClick={() =>
                      setUserAccountState((prev) => ({
                        ...prev,
                        editingMail: true,
                      }))
                    }
                    className="text-[13px] text-primary-100 underline"
                  >
                    {t("common.buttons.change")}
                  </button>
                )}
            </SectionButtonHeader>
            <TextContainer>{customerData?.email}</TextContainer>
          </div>
        )}
        {userAccountState.editingPassword ? (
          <form
            id="change-email-form"
            className="flex flex-col gap-6 max-w-[464px]"
            onSubmit={passwordForm.handleSubmit(savePassword)}
          >
            <div>
              <FormField
                id="current-password-input"
                label={t(
                  "user-account.personalDetails.loginData.currentPassword",
                )}
                autocomplete="current-password"
                name="currentPassword"
                errors={passwordForm.formState.errors}
                register={passwordForm.register}
                type="password"
                placeholder={t(
                  "translation:Onboarding.sections.personal-details.form.placeholder.password",
                )}
                options={{
                  required: t("translation:label.validation.required"),
                  backendError: t(
                    "user-account.personalDetails.loginData.passwordError",
                  ),
                }}
              />
            </div>
            <div>
              <FormField
                id="new-password-input"
                label={t("user-account.personalDetails.loginData.newPassword")}
                autocomplete="new-password"
                passwordRules="minlength: 8; maxlength: 30; required: lower; required: upper; required: digit; required: [!#$%&*?@];"
                name="newPassword"
                errors={passwordForm.formState.errors}
                register={passwordForm.register}
                type="password"
                placeholder={t(
                  "translation:Onboarding.sections.personal-details.form.placeholder.password",
                )}
                options={{
                  required: t("translation:label.validation.required"),
                  pattern: {
                    message: t("translation:label.validation.password"),
                    value: passwordRegex,
                  },
                  backendError: t("translation:label.validation.password"),
                }}
              />
              {!passwordForm.formState.errors["newPassword"] && (
                <span className="text-primary-100 inline-block font-x-small">
                  {t("translation:label.hint.newPassword")}
                </span>
              )}
            </div>
            <ConfirmationButtonFlexBox forceButtonWidth>
              <Button
                type="button"
                onClick={() => {
                  passwordForm.reset();
                  setUserAccountState((prev) => ({
                    ...prev,
                    editingPassword: false,
                  }));
                }}
                className="accent inverted"
              >
                {t("common.buttons.cancel")}
              </Button>
              <SubmitButton
                className="w-full accent"
                label={t("common.buttons.save")}
              />
            </ConfirmationButtonFlexBox>
          </form>
        ) : (
          <div>
            <SectionButtonHeader
              header={t("user-account.personalDetails.loginData.password")}
            >
              {!userAccountState.editingMail &&
                !userAccountState.editingPersonalData && (
                  <button
                    onClick={() =>
                      setUserAccountState((prev) => ({
                        ...prev,
                        editingPassword: true,
                      }))
                    }
                    className="text-[13px] text-primary-100 underline"
                  >
                    {t("common.buttons.change")}
                  </button>
                )}
            </SectionButtonHeader>
            <TextContainer>**********************</TextContainer>
          </div>
        )}
      </div>
    </>
  );
};
