import "./DeviceStatusIndicator.scss";

import { FCC } from "types";

interface Props {
  color?: string;
  className: string;
}

export const DeviceStatusIndicator: FCC<Props> = ({
  color,
  className = "",
}) => {
  return <span className={`device-status-indicator ${color} ${className}`} />;
};
