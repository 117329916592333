import { Route } from "react-router-dom";

import { AuthenticatorBackupCodeConfirm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorAppConfirm";
import { AuthenticatorAppSetup } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorAppSetup";
import { AuthenticatorDone } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorDone";
import { AuthenticatorSmsConfirm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsConfirm";
import { AuthenticatorSmsSetup } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsSetup";
import { RedirectToPortalIfHas2FA } from "@/router/RedirectToPortalIfHas2FA.tsx";
import { AuthenticatorFormView } from "@/views/AuthenticatorFormView";
import { AuthenticatorLayout } from "@/views/AuthenticatorLayout";

const AUTH_BASE_PATH = "/authenticator";
export const authenticatorRoutes = {
  base: AUTH_BASE_PATH,
  app: `${AUTH_BASE_PATH}/app`,
  confirmCodes: `${AUTH_BASE_PATH}/finalize-setup`,
  smsSetup: `${AUTH_BASE_PATH}/sms-setup`,
  smsVerify: `${AUTH_BASE_PATH}/sms-verify`,
  done: `${AUTH_BASE_PATH}/done`,
};

export const authenticatorRouteComponents = [
  <Route
    key="authbase"
    path={authenticatorRoutes.base}
    element={
      <RedirectToPortalIfHas2FA>
        <AuthenticatorLayout />
      </RedirectToPortalIfHas2FA>
    }
  >
    <Route index element={<AuthenticatorFormView />} />
    <Route
      path={authenticatorRoutes.app}
      element={<AuthenticatorAppSetup logoutOnAuthError={true} />}
    />
    <Route
      path={authenticatorRoutes.smsSetup}
      element={<AuthenticatorSmsSetup />}
    />
    <Route
      path={authenticatorRoutes.smsVerify}
      element={<AuthenticatorSmsConfirm />}
    />
    <Route
      path={authenticatorRoutes.confirmCodes}
      element={<AuthenticatorBackupCodeConfirm />}
    />
    <Route path={authenticatorRoutes.done} element={<AuthenticatorDone />} />
  </Route>,
];
