import { useAtom } from "jotai";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StyledLink, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { useRequestTwoFactorAppSetup } from "@/hooks/useRequestTwoFactorAppSetup";
import { useValidateTwoFactorAppCode } from "@/hooks/useValidateTwoFactorAppCode";
import {
  useSetUpTwoFactorAuthentication,
  useValidateTwoFactorAuthentication,
} from "@/services/auth";
import { ValidateTwoFactorAuthenticationMethod } from "@/services/model";
import { twoFactorBackupCodesAtom } from "@/utils/atoms";

import { AuthenticatorAppForm } from "./AuthenticatorAppForm";
import { TwoFactorConfirmationCodeForm } from "./TwoFactorConfirmationInput";

export const AuthenticatorAppSetup = ({
  logoutOnAuthError,
  navigationTarget = "/authenticator/finalize-setup",
}: {
  logoutOnAuthError: boolean;
  navigationTarget?: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { authSetupData } = useRequestTwoFactorAppSetup(
    useSetUpTwoFactorAuthentication,
    {
      method: ValidateTwoFactorAuthenticationMethod.APP,
    },
    true,
  );

  const [, setBackupCodes] = useAtom(twoFactorBackupCodesAtom);
  // Form handling
  const formMethods = useForm<TwoFactorConfirmationCodeForm>({
    defaultValues: {
      code: "",
    },
    mode: "onChange",
  });

  const { isLoading, validateTwoFactorAuth } = useValidateTwoFactorAppCode(
    () => {
      formMethods.setError("code", {
        type: "manual",
        message: t("portal:authenticator.app.setup.step2.invalidCode"),
      });
    },
    logoutOnAuthError,
    useValidateTwoFactorAuthentication,
  );

  const onSubmit = (data: TwoFactorConfirmationCodeForm) => {
    validateTwoFactorAuth({
      params: {
        code: parseInt(data.code),
        method: ValidateTwoFactorAuthenticationMethod.APP,
      },
    }).then((data) => {
      if (data.backupCodes) {
        setBackupCodes(data.backupCodes);
        navigate(navigationTarget);
      }
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form
        className="flex flex-col gap-10 text-black"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <AuthenticatorAppForm authSetupData={authSetupData} />
        <ConfirmationButtonFlexBox justify="between" forceButtonWidth>
          <StyledLink className="inverted accent" type="button" to={-1 as any}>
            {t("portal:common.buttons.back")}
          </StyledLink>
          <SubmitButton
            label={t("portal:authenticator.sms.verification.finishSetup")}
            className="accent"
            disabled={isLoading}
          />
        </ConfirmationButtonFlexBox>
      </form>
    </FormProvider>
  );
};
