import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { isEditingSimDetailsAtom } from "@/utils/atoms";

type DetailsChangeRowProps = {
  isSaveDisabled?: boolean;
  label: string;
  header: string;
};

export const DetailsChangeRow: FCC<DetailsChangeRowProps> = ({
  isSaveDisabled = false,
  children,
  label,
  header,
}) => {
  const [isEditing, setIsEditing] = useAtom(isEditingSimDetailsAtom);

  const { t } = useTranslation("portal");

  return (
    <>
      <SectionButtonHeader className="text-black" header={header}>
        <button
          hidden={!isEditing}
          className={clsx(
            "change-button",
            isSaveDisabled && "disabled",
            !isEditing && "hidden",
          )}
          disabled={isSaveDisabled}
          type="submit"
        >
          {t("common.buttons.save")}
        </button>
        <button
          hidden={isEditing}
          type="button"
          className={clsx("change-button", isEditing && "hidden")}
          onClick={() => setIsEditing(true)}
        >
          {t("common.buttons.change")}
        </button>
      </SectionButtonHeader>

      <Transition
        appear
        show={isEditing}
        enter="transition-opacity transition-height duration-150"
        enterFrom="opacity-0 h-6"
        enterTo="opacity-100 h-12"
      >
        {children}
      </Transition>

      <Transition
        appear
        show={!isEditing}
        enter="transition-opacity transition-height duration-150"
        enterFrom="opacity-0 h-12"
        enterTo="opacity-100 h-6"
      >
        <span className="font-semibold text-primary-100">{label}</span>
      </Transition>
    </>
  );
};
