import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";

/**
 * This component displays information about the roaming options.
 * It is displayed if the user has less than three roaming options booked.
 */
export const RoamingGoodToKnow = () => {
  const { t } = useTranslation("portal");
  const [simId] = useSimIdFromQueryParam();

  return (
    <HighlightPanel
      data-testid="roaming-good-to-know"
      className="max-w-[360px]"
      headline={t("layout.goodToKnow")}
    >
      <p>
        <Trans
          t={t}
          components={[
            <Link
              className="text-primary-100 underline"
              key={0}
              replace
              to={`../../voice?simId=${simId}`}
            />,
          ]}
          i18nKey="cockpit.managementTile.roaming.showOption.inactive.hint"
        />
      </p>
    </HighlightPanel>
  );
};
