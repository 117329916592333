import { useTranslation } from "react-i18next";

import { TariffHeader } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffHeader/TariffHeader";
import { SimCardDetail } from "@/services/model";
import { hasWatchSim } from "@/utils/deviceUtils";
import { getCurrency } from "@/utils/translationHelpers";

export const FlatMobileCostDetails = ({
  simDetail,
}: {
  simDetail: SimCardDetail;
}) => {
  const { t } = useTranslation();

  const includedFeatures = [
    {
      name: t("portal:mobileAbo.features.5gCh"),
      price: t("portal:mobileAbo.incl"),
    },
    {
      name: t("portal:mobileAbo.features.unlimitedCallsAndSms"),
      price: t("portal:mobileAbo.incl"),
    },
    {
      name: t("portal:mobileAbo.features.roamingEuUsa", {
        volume: simDetail.minContractDuration === 30 ? "2" : "24",
      }),
      price: t("portal:mobileAbo.incl"),
    },
  ];

  const price = simDetail.priceBase;
  const priceTotal = simDetail.priceTotal || 0;
  if (!price) return null;

  const payInterval =
    simDetail.minContractDuration === 30
      ? t("portal:cockpit.managementTile.tariff.payInterval", { days: 30 })
      : t("portal:cockpit.managementTile.tariff.payInterval", { days: 365 });

  return (
    <div data-testid="current-cost-display">
      <TariffHeader
        className="mb-6"
        title={t("portal:cockpit.managementTile.tariff.costs")}
      />
      <div className="flex text-base justify-between mb-2">
        <span>{t("portal:mobileAbo.name")}</span>
        <span>{getCurrency(price, "CHF")}</span>
      </div>
      <div className="ml-2 text-sm">
        {includedFeatures.map((feature) => (
          <div key={feature.name} className="flex justify-between  mb-2">
            <span>{feature.name}</span>
            <span>{feature.price}</span>
          </div>
        ))}
      </div>
      {hasWatchSim(simDetail) && (
        <div className="flex text-base justify-between mb-2">
          <span>
            {t("portal:cockpit.managementTile.watchSim.display.watchSIM")}
          </span>
          <span>{getCurrency(simDetail?.priceWatch || 0, "CHF")}</span>
        </div>
      )}

      <hr className="border-primary-100 mt-[14px]" />
      <p className="text-right text-xl font-semibold text-primary-100">
        {getCurrency(priceTotal, "CHF")}
      </p>
      <div className="text-right text-base" data-testid="pay-interval-label">
        {payInterval}
      </div>
    </div>
  );
};
