import "./PageNumberComboBox.scss";

import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { ListBox } from "@/components/Interface/FormFields/ListBox/ListBox";
import { useQueryParam } from "@/hooks/useQueryParam";

export const PageNumberComboBox: FCC = () => {
  const { t } = useTranslation("portal");
  const [pageSize, setPageSize] = useQueryParam("pageSize");
  const currentlyActive = pageSize.toString() || "10";

  return (
    <div className="page-number-combo-box">
      <div className="combobox-wrapper">
        <ListBox<string>
          setSelected={(value) => setPageSize(`${value}`)}
          availableValues={["10", "25", "50"]}
          placeholder={"10"}
          displayValue={(value) => value}
          dataTestId={"pageSize"}
          selected={currentlyActive}
        />
      </div>
      <span className="text-wrapper">
        {t("layout.table.pageNumberComboBox.entriesPerPage")}
      </span>
    </div>
  );
};
