import "@/components/Portal/Cockpit/DeviceManagement/DeviceManagement.scss";
import "./AddDevice.scss";

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { Add } from "@/assets/icons/icons";
import { onboardingSections } from "@/components/Onboarding/Onboarding.constants";
import { StepGuard } from "@/components/Onboarding/StepGuard/StepGuard";
import { Wizard } from "@/components/Onboarding/Wizard/Wizard";
import { PortalHeading } from "@/components/Portal/PortalHeading/PortalHeading";

const addDeviceSections = onboardingSections.filter((_, index) => index !== 3);

export const AddDevice: FC = () => {
  const { t } = useTranslation("portal", { keyPrefix: "add-device" });

  return (
    <>
      <PortalHeading heading={t("heading")}>
        <p className="mb-14 mt-8 md:mt-10">{t("subheading")}</p>
      </PortalHeading>
      <div
        id="user-watermark-top"
        className="tile-watermark-container md:hidden"
      >
        <Add />
      </div>
      <div id="add-device-wrapper" className="tile-width-wrapper">
        <div className="tile-watermark-container">
          <Add />
        </div>
        <div className="management-tile active !pt-0 md:!pt-14 md:!pb-8">
          <StepGuard basePath="/portal/add-device">
            <Wizard basePath="/portal/add-device" sections={addDeviceSections}>
              <Outlet context={"/portal/add-device"} />
            </Wizard>
          </StepGuard>
        </div>
      </div>
    </>
  );
};
