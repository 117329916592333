import { captureException, captureMessage, withScope } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RouterHomeIcon } from "@/assets/icons/icons";
import { AppearTransition } from "@/components/Interface/AppearTransition/AppearTransition";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { DueNow } from "@/components/Layout/DueNow/DueNow";
import { DueRecurringCard } from "@/components/Layout/DueRecurringCard/DueRecurringCard";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { PaymentMeanSection } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/CheckoutRoamingOption/PaymentMeanSection/PaymentMeanSection";
import { getHeader } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/roamingUtils";
import { getVoiceOptionEntryFromRateBasket } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffCheckout/TariffCheckout";
import { TariffHeader } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffHeader/TariffHeader";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { LightboxWrapper } from "@/components/Portal/UserAccount/PaymentMethods/LightboxWrapper/LightboxWrapper";
import { useAlert } from "@/hooks/useAlert";
import { useHandleError } from "@/hooks/useHandleError";
import { usePaymentMode } from "@/hooks/usePaymentMode";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { ErrorType } from "@/mutator/frontendApiInstance";
import { FullScreenLoadingContext } from "@/provider/FullScreenLoadingProvider/FullScreenLoadingProvider";
import {
  getAvailableSunriseRoamingPackages,
  orderRoamingPackage,
  payTransaction,
  useAddOptionToContract,
  useCreateTransaction,
  useGetProduct,
} from "@/services/api";
import {
  Basket,
  CreateTransactionDatatransOrderType,
  DatatransPaymentResponse,
  DatatransPaymentResponseStatus,
  PayTransactionParams,
  SignUpResponse,
  SubscriberOrderResponseSubscriberPackageType,
} from "@/services/model";
import { AlertTypes } from "@/utils/atoms";
import { stripFlatName } from "@/utils/deviceUtils";
import { env } from "@/utils/environmentHelpers";
import { getCurrencyFromLookupValue } from "@/utils/translationHelpers";

type FormProps = {
  paymentProvider: string;
};

export const AddWatchSimOption: FC = () => {
  const handleError = useHandleError();
  const [, setMode] = useQueryParam("mode");
  const { t } = useTranslation();
  const [simId] = useSimIdFromQueryParam();
  const { isInvoice, isCreditCard, maskedCardNumberString, cardType } =
    usePaymentMode();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const { control, watch } = useForm<FormProps>();

  const [optionBasket, setOptionBasket] = useState<Basket | null>(null);
  const [isSubmittingCheckout, setIsSubmittingCheckout] = useState(false);
  const { setFullScreenLoading } = useContext(FullScreenLoadingContext);

  const { data: simDetail } = useDisplayedSim();

  const serviceId = simDetail?.serviceID;
  const { data: dataProduct, isLoading: isLoadingProduct } = useGetProduct(
    serviceId || 0,
    {
      expanded: true,
      client: Number(env.clientNumericId ?? 1),
    },
    {
      query: {
        enabled: serviceId !== undefined && !isNaN(Number(env.clientNumericId)),
      },
    },
  );
  const voiceOptionEntry = getVoiceOptionEntryFromRateBasket(optionBasket);
  const voiceOptionOneTimeCharge = useMemo(
    () =>
      voiceOptionEntry?.oneTimeCharges?.filter(
        (value) => value.chargeType?.id === 400,
      ),
    [voiceOptionEntry?.oneTimeCharges],
  );
  const voiceOptionCharge = useMemo(
    () =>
      dataProduct?.charges?.filter(
        (charge) => charge.id === voiceOptionOneTimeCharge?.[0]?.charge?.id,
      ),
    [dataProduct, voiceOptionOneTimeCharge],
  );

  const watchOption = useMemo(
    () =>
      optionBasket?.entries?.[0]?.options?.[0]?.options?.filter(
        (option) =>
          option.product.id === 131701 || option.product.id === 131702,
      )[0],
    [optionBasket?.entries],
  );
  const watchOptionOneTimeAmount = watchOption?.oneTimeCharges?.[0]?.amount;
  // The one time data product charge, might be discounted as well
  const dataProductOneTimeCharge = useMemo(
    () =>
      voiceOptionEntry?.oneTimeCharges?.filter(
        (value) => value.chargeType?.id === 4,
      ),
    [voiceOptionEntry?.oneTimeCharges],
  );
  const dataProductCharge = useMemo(
    () =>
      dataProduct?.charges?.filter(
        (charge) => charge.id === dataProductOneTimeCharge?.[0]?.charge?.id,
      ),
    [dataProduct?.charges, dataProductOneTimeCharge],
  );
  const queryClient = useQueryClient();

  const goToWatchSimDisplay = useCallback(() => {
    navigate(`../display?simId=${simDetail?.simId}`, { replace: true });
  }, [navigate, simDetail]);

  const handleSuccess = useCallback(async () => {
    // invalidate queries to refetch sim details
    queryClient.invalidateQueries(["/sim/sim"]);
    // Added in order to trigger a refetching in the DeviceManagement
    showAlert({
      type: AlertTypes.success,
      text: t("cockpit.managementTile.roaming.checkout.success"),
    });

    goToWatchSimDisplay();
  }, [goToWatchSimDisplay, queryClient, showAlert, t]);

  const captureError = () => {
    setIsSubmittingCheckout(false);
    handleError(
      "Payment failure",
      `${t("Error.payment.fail")} ${t("Error.payment.pleaseCheckData")}`,
    );
    withScope(function (scope) {
      scope.setFingerprint(["/contract/serviceId/options"]);
      scope.setTag("section", "portal");
      scope.setTag("domain", "CheckoutRoamingOption");
      scope.setTag("endpoint", "addOptionToContract");
      scope.setExtra("simId", simId);
      scope.setContext("paymentContext", {
        isInvoice,
        isCreditCard,
        maskedCardNumberString,
        cardType,
      });
      captureMessage("Payment failure");
    });
  };

  const transactionMutationOptions = {
    onSuccess: (data: DatatransPaymentResponse | SignUpResponse) => {
      // Hide full screen loading
      setFullScreenLoading(false);
      if ("signUpId" in data) {
        handleSuccess();
      } else {
        if (data.status === DatatransPaymentResponseStatus.OK) {
          handleSuccess();
        } else {
          captureError();
        }
      }
    },
    onError: (err: ErrorType<unknown>) => {
      // Hide full screen loading
      setFullScreenLoading(false);
      // If it fails we report the error in sentry
      handleError(err);
      captureException(err);
    },
  };

  // This is used to pay transactions with a saved credit card
  const payAndHandleTransaction = (params: PayTransactionParams) => {
    payTransaction(params)
      .then((data) => {
        setFullScreenLoading(false);
        if (data.status === DatatransPaymentResponseStatus.OK) {
          handleSuccess();
        } else {
          captureError();
        }
      })
      .catch((err) => {
        setFullScreenLoading(false);
        handleError(err);
        captureException(err);
      });
  };

  const totalOnceAmount = optionBasket?.total?.oneTime?.amount;
  const handleSubmit = () => {
    const basketId = optionBasket?.id;
    if (basketId === undefined) {
      captureMessage("Wanted to submit undefined basket id!");
      handleError("Wanted to submit undefined basket id!");
      return;
    }
    if (totalOnceAmount === undefined) {
      captureMessage("Wanted to charge undefined oneTime amount");
      handleError("Wanted to charge undefined oneTime amount");
      return;
    }
    if (!isSubmittingCheckout) {
      if (isInvoice || isCreditCard) {
        setIsSubmittingCheckout(true); // To prevent double checkout on re-rendering
        setFullScreenLoading(
          true,
          t("cockpit.managementTile.roaming.checkout.loading"),
        );
        // Trigger transaction for this basket
        if (isCreditCard) {
          payAndHandleTransaction({
            simId: parseInt(simId),
            basketId: basketId,
            datatransOrderType:
              CreateTransactionDatatransOrderType.AddOptionToContract,
          });
        }
        // this customer is an invoice customer, we just add option to contract
        else if (isInvoice) {
          addContractCallback(basketId);
        }
      } else {
        // Else we create a transaction, which will in turn open the Lightbox, allow the user to enter payment information
        // and call addOptionToContract backend-side if the payment was successful.
        createTransaction({
          params: {
            simId: parseInt(simId),
            basketId: basketId,
            datatransOrderType:
              CreateTransactionDatatransOrderType.AddOptionToContract,
          },
        });
      }
    }
  };
  const {
    mutate: createTransaction,
    data: datatransRequestDocument,
    isLoading: isCreatingTransaction,
  } = useCreateTransaction();
  const {
    mutate: addOptionToContract,
    isLoading: isAddingOptionLoading,
    isSuccess: isAddingOptionDone,
  } = useAddOptionToContract({
    mutation: transactionMutationOptions,
  });
  const addContractCallback = useCallback(
    (basketId: number) => {
      if (optionBasket)
        addOptionToContract({
          serviceId: parseInt(simId),
          data: {
            header: getHeader(),
            basket: optionBasket,
            basketId,
          },
        });
    },
    [addOptionToContract, optionBasket, simId],
  );

  useEffect(() => {
    // Only initialize a new basket if theres none in the optionBasketAtom
    if (!optionBasket) {
      if (typeof serviceId === "number" && simId) {
        // Get available packages for this sim
        getAvailableSunriseRoamingPackages(parseInt(simId), {
          packageType: SubscriberOrderResponseSubscriberPackageType.Watch_Sim,
        }).then((data) => {
          const watchPackageId = data?.subscriberPackagesWatch?.[0].id;
          if (watchPackageId === undefined) {
            const e = new Error(
              `No watch package found for serviceId ${serviceId}`,
            );
            captureException(e);
            handleError(e);
            setMode("", { replace: true });
          } else {
            orderRoamingPackage(parseInt(simId), {
              optionId: serviceId,
              watchId: watchPackageId,
            }).then((data) => {
              setOptionBasket(data?.basket || null);
            });
          }
        });
      }
    }
  }, [optionBasket, serviceId, simId, setMode, handleError]);

  const watchedPaymentProvider = watch("paymentProvider");
  const disableSuccessButton =
    isCreatingTransaction ||
    isAddingOptionLoading ||
    isAddingOptionDone ||
    !(watchedPaymentProvider || isInvoice || isCreditCard);
  const currency = getCurrencyFromLookupValue(
    optionBasket?.currency || { id: 1 },
  );
  const billingPeriod = t(
    "Onboarding.sections.checkout.summary.billingPeriod",
    {
      days: simDetail?.minContractDuration || 30,
    },
  );
  const isLoading =
    isLoadingProduct ||
    optionBasket === null ||
    isCreatingTransaction ||
    isAddingOptionLoading;

  // simDetail should always be present. This is to prevent TypeScript warnings.
  if (!simDetail) return null;

  return (
    <div id="checkout-tariff" data-testid="add-watch-sim-option">
      <TariffHeader
        title={t("cockpit.managementTile.watchSim.checkout.title")}
        className="mb-6"
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <AppearTransition>
          <div id="checkout-layout">
            <div id="summary">
              <span className="block mb-4">
                <Trans
                  values={{ count: simDetail.remainingDays || 1 }}
                  components={[
                    <span key="boldTime" className="font-semibold" />,
                  ]}
                  t={t}
                >
                  cockpit.managementTile.watchSim.checkout.autoRenewal.hint
                </Trans>
              </span>
              <span className="block font-semibold text-primary-100 mb-16">
                {stripFlatName(simDetail.localFlatSpeed) +
                  " | " +
                  t("common.device.attributes.voiceOption") +
                  " | " +
                  t("common.device.attributes.watchSim")}
              </span>

              <h3 className="mb-4">
                {t("cockpit.managementTile.tariff.autoRenew")}
              </h3>
              <p data-testid="autorenew-hint">
                {t(
                  simDetail.autoRenew
                    ? "cockpit.managementTile.tariff.checkout.automaticRenewalHint.RemainsActivated"
                    : "cockpit.managementTile.tariff.checkout.automaticRenewalHint.GetsActivated",
                )}
              </p>
            </div>
            <div id="due-cards">
              <div id="due-now">
                <DueNow
                  voiceOptionName={t("common.device.attributes.voiceOption")}
                  voiceOptionPrice={voiceOptionCharge?.[0]?.amount || 0}
                  dataRateName={dataProduct?.baseDisplayValue}
                  dataRatePrice={dataProductCharge?.[0]?.amount || 0}
                  totalOnce={totalOnceAmount || 0}
                  currency={currency}
                  icon={<RouterHomeIcon />}
                  creditTotal={optionBasket?.total?.oneTime?.amountDiscounts}
                  watchSimName={t("common.device.attributes.watchSim")}
                  watchSimPrice={watchOptionOneTimeAmount}
                  billingPeriod={billingPeriod}
                  credit={t(
                    "cockpit.managementTile.tariff.checkout.creditText",
                  )}
                />
              </div>
              <div id="due-reoccuring">
                <DueRecurringCard
                  currency={currency}
                  isUpgrade={true}
                  totalReoccurring={optionBasket?.total?.recurring?.amount || 0}
                  flatName={dataProduct?.baseDisplayValue}
                  flatPrice={dataProductCharge?.[0]?.amount ?? 0}
                  flatOption={t("common.device.attributes.voiceOption")}
                  flatOptionPrice={voiceOptionCharge?.[0]?.amount ?? 0}
                  watchSimName={t("common.device.attributes.watchSim")}
                  // This is the correct price, the backend does not return the recurring price for the watch sim
                  watchSimPrice={watchOptionOneTimeAmount}
                  billingPeriod={dataProduct?.minContractDur ?? 30}
                />
              </div>
            </div>

            {!!optionBasket && (
              <section id="payment-section">
                <PaymentMeanSection
                  isInvoice={isInvoice}
                  isCreditCard={isCreditCard}
                  cardType={cardType}
                  maskedCardNumberString={maskedCardNumberString}
                  control={control}
                  enableShowCurrent={true}
                  sumIsZero={false}
                />

                <LightboxWrapper
                  paymentProvider={watchedPaymentProvider}
                  transactionId={datatransRequestDocument?.refNo}
                  sign={datatransRequestDocument?.sign}
                  enabled={datatransRequestDocument !== undefined}
                />

                <p className="text-base">
                  {t(
                    "cockpit.managementTile.watchSim.checkout.paymentMethod.hint",
                  )}
                </p>
              </section>
            )}
          </div>
          <ConfirmationButtonGroup
            successText={t("common.buttons.submit")}
            successAction={() => handleSubmit()}
            cancelText={t("common.buttons.cancel")}
            cancelAction={goToWatchSimDisplay}
            disableSuccessButton={disableSuccessButton}
          />
        </AppearTransition>
      )}
    </div>
  );
};
