import clsx from "clsx";
import { PropsWithChildren } from "react";
import { FCC } from "types";

import { Button } from "@/components/Interface/Button/Button";

type ConfirmationButtonGroupProps = {
  disableSuccessButton?: boolean;
  successText: string;
  successAction?: () => void;
  submitAsSuccess?: boolean;
  cancelText: string;
  cancelAction: () => void;
};

export const ConfirmationButtonGroup: FCC<ConfirmationButtonGroupProps> = ({
  disableSuccessButton = false,
  successText,
  successAction,
  cancelText,
  cancelAction,
}) => {
  return (
    <ConfirmationButtonFlexBox>
      <Button
        onClick={() => cancelAction()}
        className="accent inverted"
        dataTestid="cancel-button"
        aria-label="Cancel the current action"
      >
        {cancelText}
      </Button>
      <Button
        onClick={successAction}
        className="accent"
        disabled={disableSuccessButton}
        dataTestid="success-button"
      >
        {successText}
      </Button>
    </ConfirmationButtonFlexBox>
  );
};

export const ConfirmationButtonFlexBox = ({
  justify: align = "end",
  forceButtonWidth = false,
  children,
}: PropsWithChildren<{
  justify?: "between" | "end";
  forceButtonWidth?: boolean;
}>) => (
  <div
    className={clsx(
      `flex flex-col gap-4 mt-6 md:mt-10 md:flex-row justify-between md:justify-${align} md:col-span-2`,
      forceButtonWidth &&
        "[&>div>button]:w-full md:[&>div>button]:w-fit [&>div>a]:w-full md:[&>div>a]:w-fit",
    )}
  >
    {children}
  </div>
);
