import { useAtom } from "jotai";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "@/provider/DataContextProvider";
import { useSaveBasket } from "@/services/api";
import type { BasicCustomerData, Basket } from "@/services/model";
import { accessTokenAtom } from "@/utils/atoms";
import {
  hasValidPersonalDetails,
  transformBasicCustomerDataToPersonalDetailsForm,
} from "@/utils/customerUtils";
import {
  hasBasketEntryWithNamedDevice,
  hasBasketEntryWithSelectedRateOption,
  hasOpenBasketEntry,
} from "@/utils/dataContextHelpers";

/**
 * This hook returns a callback that checks which is the last onboarding step
 * that the user has previously filled, and redirects them to the immediate
 * step after that one.
 * It also returns its fetch loading state, so that it can be used to handle the
 * UI while the hook is in process.
 */
export const useOnboardingRedirect = (): [
  (customerData: BasicCustomerData) => void,
  boolean,
] => {
  const { dataContext, setDataContext } = useContext(DataContext);
  const navigate = useNavigate();
  const [accessToken] = useAtom(accessTokenAtom);

  // Get the query to get or create the customer's basket
  const { mutateAsync: getBasicBasket, isLoading: isGetBasketLoading } =
    useSaveBasket();

  useEffect(() => {
    if (!!accessToken) {
      getBasicBasket({}).then((data: Basket) => {
        setDataContext((prev) => ({
          ...prev,
          basket: data,
        }));
      });
    }
  }, [accessToken, getBasicBasket, setDataContext]);

  const redirect = async (customerData: BasicCustomerData) => {
    if (!dataContext.basket) {
      return;
    }

    if (hasValidPersonalDetails(customerData)) {
      setDataContext((prev) => ({
        ...prev,
        personalDetails:
          transformBasicCustomerDataToPersonalDetailsForm(customerData),
        allowedMaxOnboardingStep: 5,
      }));
      navigate("/onboarding/5");
    } else if (hasBasketEntryWithSelectedRateOption(dataContext)) {
      setDataContext((prev) => ({
        ...prev,
        allowedMaxOnboardingStep: 4,
      }));
      navigate("/onboarding/4");
    } else if (hasBasketEntryWithNamedDevice(dataContext)) {
      setDataContext((prev) => ({
        ...prev,
        allowedMaxOnboardingStep: 3,
      }));
      navigate("/onboarding/3");
    } else if (hasOpenBasketEntry(dataContext)) {
      setDataContext((prev) => ({
        ...prev,
        allowedMaxOnboardingStep: 2,
      }));
      navigate("/onboarding/2");
    } else {
      setDataContext((prev) => ({
        ...prev,
      }));
      navigate("/onboarding/1/");
    }
  };

  return [redirect, isGetBasketLoading];
};
