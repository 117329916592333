import "./HighlightPanel.scss";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FCCC } from "types";

export const HighlightPanel: FCCC<{ headline?: string }> = ({
  headline,
  children,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx("highlight-panel", className)} {...props}>
      <h2>{headline || t("Content.goodToKnow")}</h2>
      {children}
    </div>
  );
};
