import { env } from "./environmentHelpers";

export const API_ROOT = env.isProd
  ? "https://connect.digitalrepublic.ch"
  : "https://drint-csc2.dev.aax2.net";

// Authentication API
export const AUTH_API_URL = new URL("/api-auth-server", API_ROOT);
export const AUTHORIZE_URL = new URL(
  AUTH_API_URL.pathname + "/oauth/authorize",
  API_ROOT,
);
export const TOKEN_URL = new URL(
  AUTH_API_URL.pathname + "/oauth/token",
  API_ROOT,
);

export const AGENT_LOGIN_URL = new URL(
  AUTH_API_URL.pathname + "/user/agentlogin",
  API_ROOT,
);

// Frontend API
export const FRONTEND_API_URL = new URL("/frontend-api-v2", API_ROOT);
export const DATATRANS_CALLBACK_URL = new URL(
  FRONTEND_API_URL.pathname + "/datatrans/callback",
  API_ROOT,
);

export const BASIC_PORTAL_AUTHORIZATION = `Basic ${btoa("portal:portal")}`;

export const PUBLIC_USER_AUTHORIZATION = `Basic ${btoa(
  "digital-republic-public:",
)}`;

export const USER_AUTHORIZATION = `Basic ${btoa("digital-republic:")}`;

export const FORGOT_PASSWORD_URL = new URL(
  AUTH_API_URL.toString() + "/passwordReset",
);
