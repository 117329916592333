import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScrollComponent from "react-infinite-scroller";

import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { usePrevious } from "@/hooks/usePrevious";
import { useWindowResize } from "@/hooks/useWindowResize";

export interface InfiniteScrollProps<T> {
  items: T[];

  hasNextPage: boolean;
  fetchNextPage?: () => void;
  loadingNextPage: boolean;

  // when we are at the very bottom, this component may automatically
  // fetch more data even if the user did not scroll; you can disable this
  // behaviour with this prop
  disableAutoFetch?: boolean;

  error?: boolean;
  hideItems?: boolean;

  errorChild?: ReactNode;
  noDataChild?: ReactNode;
  noMoreDataChild?: ReactNode;

  children: ReactNode;
  parentId?: string;
}

enum ScrollDirection {
  ToTop = "toTop",
  ToBottom = "toBottom",
}

export const InfiniteTileScroll = <T,>({
  items,
  hasNextPage,
  fetchNextPage,
  error,
  loadingNextPage,
  disableAutoFetch,
  errorChild,
  hideItems,
  parentId,
  noMoreDataChild,
  children,
}: InfiniteScrollProps<T>) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef<number>(0);
  const prevItems = usePrevious(items);
  const scrollEl = !parentId ? window : document.getElementById(parentId);

  const [scrollDirection, setScrollDirection] = useState<
    ScrollDirection | undefined
  >();

  // determine how big the total "scroll-area" is and how big the client
  // height is to see if there is a scrollbar
  useWindowResize();
  const scrollHeight =
    (parentId
      ? (scrollEl as HTMLDivElement | null)?.scrollHeight
      : document.documentElement.scrollHeight) ?? 0;
  const clientHeight =
    (parentId
      ? (scrollEl as HTMLDivElement | null)?.clientHeight
      : document.documentElement.clientHeight) ?? 0;

  /**
   * Handles the scroll in the component. It sets the scroll direction
   * which is used to call the correct `fetchMore` function.
   */
  const scrollHandler = useCallback(() => {
    const scrollTop =
      (parentId
        ? (scrollEl as HTMLDivElement | null)?.scrollTop
        : document.documentElement.scrollTop) ?? 0;

    if (
      scrollTop > lastScrollTop.current &&
      scrollDirection !== ScrollDirection.ToBottom &&
      !loadingNextPage
    ) {
      setScrollDirection(ScrollDirection.ToBottom);
    } else if (
      scrollTop < lastScrollTop.current &&
      scrollDirection !== ScrollDirection.ToTop
    ) {
      setScrollDirection(ScrollDirection.ToTop);
    }

    lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
  }, [parentId, scrollDirection, scrollEl, loadingNextPage]);

  /**
   * Adds the event listeners for the scroll in the component that the
   * content can be found in.
   */
  useEffect(() => {
    scrollEl?.addEventListener("scroll", scrollHandler);

    return () => {
      scrollEl?.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollEl, scrollHandler]);

  /**
   * Returns how far the user has currently scrolled either in the document or
   * the scrollable parent.
   */
  const getCurrentScrollTop = useCallback(
    () =>
      (parentId
        ? (scrollEl as HTMLElement)?.scrollTop
        : document.documentElement.scrollTop) || 0,
    [scrollEl, parentId],
  );

  /**
   * There are cases where the content takes less than provided. Therefore, no
   * scroll bar is shown although more data might be available. This useEffect
   * will fetch more data for these cases.
   */
  useEffect(() => {
    const parent: HTMLElement | null | undefined = parentId
      ? document.getElementById(parentId)
      : undefined;

    // the height of the content
    const contentHeight = !parent
      ? document.body.clientHeight
      : containerRef.current?.clientHeight || 0;
    // the height that the parent sets
    const maxHeight = !parent ? window.innerHeight : parent.clientHeight;

    // if the content is smaller than the height, fetch the next or previous page immediately
    if (
      contentHeight <= maxHeight &&
      containerRef.current &&
      items.length > 0
    ) {
      if (fetchNextPage && hasNextPage && !loadingNextPage) {
        fetchNextPage();
      }
    }
  }, [parentId, fetchNextPage, items, loadingNextPage, hasNextPage]);

  /**
   * This hooks handles automatic fetching when we are either at the bottom
   * or the top of the list and cannot scroll any more.
   *
   * > fetchPrevious: while not loading, we check if the scroll is at the very top; if yes
   *     we automatically fetch the previous page in order to make scrolling easier.
   * > fetchNext: while not loading, we check if the scroll is at the very bottom; if yes
   *     we automatically fetch the nxt page in order to make scrolling easier.
   */
  useEffect(() => {
    const parent: HTMLElement | null | undefined = parentId
      ? document.getElementById(parentId)
      : undefined;

    const el = parentId ? parent : document.documentElement;

    if (
      !loadingNextPage &&
      !disableAutoFetch &&
      items.length > 0 &&
      // only if there is already a scrollbar; otherwise the useEffect above handles it
      (el?.scrollHeight ?? 0) > 0
    ) {
      const atBottom =
        (el?.scrollHeight ?? 0) - (el?.scrollTop ?? 0) === el?.clientHeight;

      if (fetchNextPage && hasNextPage && atBottom) {
        fetchNextPage();
      }
    }
  }, [
    parentId,
    hasNextPage,
    disableAutoFetch,
    prevItems,
    items,
    fetchNextPage,
    loadingNextPage,
    getCurrentScrollTop,
  ]);

  /**
   * Handles the fetch more that was fired by the library.
   */
  const onFetchMore = () => {
    if (
      fetchNextPage &&
      !loadingNextPage &&
      scrollDirection === ScrollDirection.ToBottom &&
      !error
    ) {
      fetchNextPage();
    }
  };

  const showNoMoreData =
    !hasNextPage &&
    !error &&
    !loadingNextPage &&
    items.length > 0 &&
    scrollHeight > clientHeight;
  const showError =
    (items.length === 0 || hasNextPage) && !loadingNextPage && error;

  return (
    <>
      {!hideItems && (
        <div
          ref={containerRef}
          data-testid={
            scrollDirection === ScrollDirection.ToTop
              ? "inf-scroll-to-top"
              : "inf-scroll-to-bot"
          }
        >
          <InfiniteScrollComponent
            loadMore={onFetchMore}
            hasMore={hasNextPage}
            pageStart={0}
            getScrollParent={
              parentId ? () => document.getElementById(parentId) : undefined
            }
            initialLoad={false}
            useWindow={!parentId}
          >
            <div
              // className is kept for backwards compatibility because we used a different infinite
              // scroll library
              className="infinite-scroll-component"
            >
              {children}
            </div>
          </InfiniteScrollComponent>
        </div>
      )}

      <div>
        {/* Show a message when no more data exists */}
        {showNoMoreData &&
          (noMoreDataChild || (
            <p className={"mt-6 text-center text-sm text-primary-100"}>
              {t("portal:common.listOfData.noMoreData")}
            </p>
          ))}

        {/* error */}
        {showError &&
          (errorChild || (
            <p className={"mt-6 text-center"}>
              {t("portal:common.listOfData.error")}
            </p>
          ))}

        {/* Loading animation */}
        {loadingNextPage && <LoadingSpinner />}
      </div>
    </>
  );
};
