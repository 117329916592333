import "./Settings.scss";

import { RadioGroup } from "@headlessui/react";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RadioButtonOption } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import { FilterDialogForm } from "@/components/Portal/Cockpit/Filter/Filter";
import { GetSimListSimAutoRenew } from "@/services/model";

export const Settings = ({
  register,
  setValue,
  watch,
  errors,
}: {
  register: UseFormRegister<FilterDialogForm>;
  setValue: UseFormSetValue<FilterDialogForm>;
  watch: UseFormWatch<FilterDialogForm>;
  errors: FieldErrors<FilterDialogForm>;
}) => {
  const { t } = useTranslation("portal");

  const autoRenewSettings: {
    state: GetSimListSimAutoRenew | "ALL";
    label: string;
  }[] = [
    {
      state: "ALL",
      label: t("cockpit.filter.activity-labels.all"),
    },
    {
      state: "ACTIVE",
      label: t("cockpit.filter.activity-labels.active"),
    },
    {
      state: "INACTIVE",
      label: t("cockpit.filter.activity-labels.inactive"),
    },
  ];

  return (
    <div className="radio-group-wrapper">
      <RadioGroup
        className="radio-group"
        value={watch("simAutoRenew")}
        onChange={(value: "ALL" | "ACTIVE" | "INACTIVE") =>
          setValue("simAutoRenew", value)
        }
      >
        <RadioGroup.Label>
          <h5 className="activity-label">
            {t("cockpit.filter.activity-labels.automRenewal")}
          </h5>
        </RadioGroup.Label>
        <div className="radio-buttons">
          {autoRenewSettings.map((setting, index) => (
            <RadioButtonOption<FilterDialogForm>
              value={setting.state}
              key={index}
              name={"simAutoRenew"}
              label={setting.label}
            />
          ))}
        </div>
      </RadioGroup>
      <RadioGroup
        className="radio-group"
        value={watch("simVoiceOption")}
        onChange={(value: "ALL" | "ACTIVE" | "INACTIVE") =>
          setValue("simVoiceOption", value)
        }
      >
        <RadioGroup.Label>
          <h5 className="activity-label">
            {t("cockpit.filter.activity-labels.voiceOption")}
          </h5>
        </RadioGroup.Label>
        <div className="radio-buttons">
          {autoRenewSettings.map((setting, index) => (
            <RadioButtonOption<FilterDialogForm>
              value={setting.state}
              key={index}
              name={"simVoiceOption"}
              label={setting.label}
            />
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};
