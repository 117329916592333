import { useTranslation } from "react-i18next";

import { DueNow } from "@/components/Layout/DueNow/DueNow";
import { DueNowLightInterface } from "@/components/Layout/DueNowLight/DueNowLightInterface";
import { availableCurrencies } from "@/utils/translationHelpers";

export const TariffDueNow = ({
  icon,
  totalOnceAmount,
  currency,
  oneTimeDiscount,
  dataRateName,
  dataRatePrice = 0,
  voiceOptionPrice,
  billingPeriod,
  watchSimName,
  watchSimPrice,
}: {
  icon: JSX.Element;
  totalOnceAmount: number;
  currency: availableCurrencies;
  oneTimeDiscount?: number;
  dataRateName?: string;
  dataRatePrice?: number;
  voiceOptionPrice?: number;
  billingPeriod: string;
  watchSimName?: string;
  watchSimPrice?: number;
}) => {
  const { t } = useTranslation();
  return totalOnceAmount === 0 ? (
    <DueNowLightInterface
      totalOnce={totalOnceAmount}
      currency={currency}
      icon={icon}
    />
  ) : (
    <DueNow
      totalOnce={totalOnceAmount}
      currency={currency}
      icon={icon}
      creditTotal={oneTimeDiscount}
      dataRateName={dataRateName}
      dataRatePrice={dataRatePrice}
      voiceOptionName={t("common.device.attributes.voiceOption")}
      voiceOptionPrice={voiceOptionPrice}
      billingPeriod={billingPeriod}
      credit={t("cockpit.managementTile.tariff.checkout.creditText")}
      watchSimName={watchSimName}
      watchSimPrice={watchSimPrice}
    />
  );
};
