/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type SimCardDetailSimStatus =
  (typeof SimCardDetailSimStatus)[keyof typeof SimCardDetailSimStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimCardDetailSimStatus = {
  green: "green",
  yellow: "yellow",
  grey: "grey",
  red: "red",
} as const;
