import "./TableHeader.scss";

import { FCC } from "types";

export const TableHeader: FCC = ({ children }) => {
  return (
    <tr data-testid="table-header" className="table-header">
      {children}
    </tr>
  );
};
