import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Interface/Button/Button";
import { Modal } from "@/components/Interface/Modal/Modal";

interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmNoVoiceModal: FC<Props> = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="max-h-[500px] max-w-[587px] pb-0 md:pb-0"
      title={t("cockpit.managementTile.tariff.change.modal.title")}
      onClose={onClose}
    >
      <p className="text-base">
        {t("cockpit.managementTile.tariff.change.modal.text1")}
      </p>
      <p className="text-base mt-6">
        {t("cockpit.managementTile.tariff.change.modal.text2")}
      </p>
      <div className="flex justify-end mt-6 mb-8">
        <Button
          className="accent"
          data-testid="confirm-button"
          onClick={onConfirm}
        >
          {t("cockpit.managementTile.tariff.change.modal.confirmButton")}
        </Button>
      </div>
    </Modal>
  );
};
