import { useHas2fa } from "@/hooks/useHas2fa.ts";
import { useNeedsIDCheck } from "@/hooks/useNeedsIDCheck";

import { KYCWarning } from "./KYCWarning/KYCWarning";
import { TwoFactorWarning } from "./TwoFactorWarning";

export const UserWarning = () => {
  const isIDCheck = useNeedsIDCheck();
  const hasTwoFactorAuth = useHas2fa();
  if (isIDCheck) return <KYCWarning />;
  if (!hasTwoFactorAuth) return <TwoFactorWarning />;
  return null;
};
