import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Copy } from "@/assets/icons/icons";
import { useAlert } from "@/hooks/useAlert";
import { AlertTypes } from "@/utils/atoms";

interface Props {
  content: string;
  successMessage: string;
  errorMessage: string;
}

export const ClipboardField: FC<Props> = ({
  content,
  successMessage,
  errorMessage,
}) => {
  const showAlert = useAlert();
  const { t } = useTranslation("translation");

  return (
    <button
      type="button"
      className="flex w-full justify-between gap-5 pl-4 pr-2 py-3 text-base text-primary-100 border-1 border-primary-100 cursor-pointer"
      aria-label={t("clipboardField.copy")}
      onClick={async () => {
        try {
          await navigator.clipboard.writeText(content);
          showAlert({
            type: AlertTypes.info,
            text: successMessage,
          });
        } catch {
          showAlert({
            type: AlertTypes.error,
            text: errorMessage,
          });
        }
      }}
    >
      <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
        {content}
      </span>
      <Copy className="flex-shrink-0 w-6 h-6" />
    </button>
  );
};
