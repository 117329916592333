import "@/components/Interface/FormFields/FormField/FormField.scss";
import "./DateInput.scss";

import clsx from "clsx";
import React from "react";

import { CalendarBlank } from "@/assets/icons/icons";
import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import type { FormFieldProps } from "@/components/Interface/FormFields/FormField/FormField.utils.ts";

/**
 * This component uses the native HTML input type="date" under the hood. Because
 * of this, it must take a value in YYYY-MM-DD format, which will be displayed
 * it in the browser's locale (not the app's locale).
 */
export const DateInput = <TFormValues extends object>(
  props: React.PropsWithChildren<FormFieldProps<TFormValues>>,
) => {
  return (
    <div className="form-field-container">
      <FormField
        {...props}
        className={props.className}
        type="date"
        icon={
          <CalendarBlank
            data-testid="calendar-icon"
            className={clsx(
              "cursor-pointer",
              props.options?.readOnly || props.options?.disabled
                ? "opacity-20"
                : "",
            )}
            onClick={async (event) => {
              const elem = event.target as HTMLElement;
              const input = elem.parentNode?.parentNode
                ?.childNodes[0] as HTMLInputElement;
              // We first focus the field, as this will always be possible.
              if (input?.click) {
                input.click();
              }
              if (Object.keys(input).includes("showPicker")) {
                // Then we try to show the date picker, but only few browsers support this.
                input?.showPicker();
              }
            }}
          />
        }
      >
        {props.children}
      </FormField>
    </div>
  );
};
