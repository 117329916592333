import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { DueNow } from "@/components/Layout/DueNow/DueNow";
import {
  useBillingPeriod,
  useGetFlatPricesAndName,
  useGetIconForDevice,
  useHasVoiceOption,
} from "@/hooks/useDataContextHooks";
import { DataContext } from "@/provider/DataContextProvider";
import { getCurrencyFromLookupValue } from "@/utils/translationHelpers";

/** Renders the payment due now box of the checkout page */
export const OnboardingDueNow: FCC = () => {
  const { dataContext } = useContext(DataContext);

  const { t } = useTranslation();
  const totalOnce = dataContext.basket?.total?.oneTime?.amount || 0;
  const currency = getCurrencyFromLookupValue(
    dataContext.basket?.currency || { id: 1 },
  );

  const hasVoiceOption = useHasVoiceOption();

  // flat name and flat price
  const { flatName, flatPrice, voicePrice } = useGetFlatPricesAndName();

  const billingPeriod = useBillingPeriod();
  const billingPeriodText = t(
    "Onboarding.sections.checkout.summary.billingPeriod",
    {
      days: billingPeriod,
    },
  );

  const icon = useGetIconForDevice();

  return (
    <DueNow
      icon={icon}
      billingPeriod={billingPeriodText}
      dataRateName={flatName}
      dataRatePrice={totalOnce === 0 ? 0 : flatPrice}
      currency={currency}
      totalOnce={totalOnce}
      {...(hasVoiceOption
        ? {
            voiceOptionName: t(
              "Onboarding.sections.checkout.summary.voiceOption",
            ),
            voiceOptionPrice: totalOnce === 0 ? 0 : voicePrice,
          }
        : {})}
    />
  );
};
