import { AxiosError, RawAxiosRequestConfig } from "axios";

import { generateApiInstance } from "@/mutator/apiHelpers";
import { FRONTEND_API_URL } from "@/utils/apiUrls";

// add a second `options` argument here if you want to pass extra options to each generated query
export const frontendApiInstance = <T>(
  config: RawAxiosRequestConfig,
  options?: RawAxiosRequestConfig,
) => generateApiInstance<T>(FRONTEND_API_URL.toString(), config, options);

// This export is required in every mutator instance to have orval generate the correct ErrorType
export type ErrorType<Error> = AxiosError<Error>;
