/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type RoamingInfoRoamingStatus =
  (typeof RoamingInfoRoamingStatus)[keyof typeof RoamingInfoRoamingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoamingInfoRoamingStatus = {
  active: "active",
  ready: "ready",
  ordered: "ordered",
  na: "na",
} as const;
