import { useAtom } from "jotai";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import { phoneNumberAtom } from "@/utils/atoms";

import { TwoFactorConfirmationInput } from "./TwoFactorConfirmationInput";

export const AuthenticatorSmsConfirmForm = ({
  resendAction,
}: {
  resendAction: () => void;
}) => {
  const { t } = useTranslation();
  const [phoneNumber] = useAtom(phoneNumberAtom);

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-secondary-100 text-xl font-semibold">
        {t("portal:authenticator.sms.verification.title")}
      </h1>
      <p>
        {t("portal:authenticator.sms.verification.description", {
          number: phoneNumber,
        })}
      </p>
      <TwoFactorSMSValidation resendAction={resendAction} />
    </div>
  );
};

export const TwoFactorSMSValidation = ({
  resendAction,
}: {
  resendAction: () => void;
}) => {
  const { t } = useTranslation();

  const handleClick = debounce(() => resendAction(), 1000, {
    leading: true,
    trailing: false,
  });

  return (
    <div className="w-fit flex flex-col gap-2">
      <TwoFactorConfirmationInput />
      <button
        type="button"
        onClick={handleClick}
        className="text-highlight-100 font-semibold underline w-fit"
      >
        {t("portal:authenticator.sms.verification.resend")}
      </button>
    </div>
  );
};
