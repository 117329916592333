import "./NumberPortingTile.scss";

import { useTranslation } from "react-i18next";
import { DeviceFC } from "types";

import { Section } from "@/components/Onboarding/Onboarding.constants";
import { Steps } from "@/components/Onboarding/Wizard/Steps";
import {
  DeviceTile,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import { getIconForDevice } from "@/utils/deviceUtils";

export const NumberPortingTile: DeviceFC = ({ device }) => {
  const { t } = useTranslation();

  const icon = getIconForDevice(device.device?.description);
  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );

  const numberPorting = device.numberPorting;

  const verifiedOrPendingSection: Section = {
    title:
      numberPorting?.identificationCheckStatus === "SUCCESS"
        ? "layout.tiles.numberPorting.sections.verified"
        : "layout.tiles.numberPorting.sections.pending",
    substeps: { 1: 0.001 },
  };
  const requestedSection: Section = {
    title: "layout.tiles.numberPorting.sections.requested",
    substeps: { 2: 0.001 },
  };
  const dateKnownSection: Section = {
    title: numberPorting?.date
      ? t("layout.tiles.numberPorting.sections.known", {
          val: new Date(numberPorting.date),
        })
      : "layout.tiles.numberPorting.sections.dateBeingIdentified",
    substeps: { 3: 0.001 },
  };
  const doneSection: Section = {
    title: "layout.tiles.numberPorting.sections.done",
    substeps: { 4: 0.001 },
  };

  const sections: Section[] = [
    verifiedOrPendingSection,
    requestedSection,
    dateKnownSection,
    doneSection,
  ];

  const step =
    ["pending", "requested", "dateKnown"].indexOf(numberPorting?.status || "") +
    1;

  return (
    <DeviceTile
      navigationTarget={`../manage?simId=${device.simId}`}
      dataTestid="number-porting-tile"
      icon={icon}
    >
      <h2 data-testid="tile-title" className="tile-title">
        {title}
      </h2>
      <h3 className="tile-subtitle">
        {t("layout.tiles.numberPorting.numberPorting")}
      </h3>
      <div
        data-testid="number-porting-content"
        id="number-porting-content"
        className="mt-6"
      >
        <Steps
          sections={sections}
          currentStep={step}
          currentSubStep={`${step}`}
          property="height"
        />
      </div>
    </DeviceTile>
  );
};
