import { useTranslation } from "react-i18next";

export const FAQLink = () => {
  const { t } = useTranslation();

  return (
    <a
      href={t("portal:authenticator.app.setup.step1.faqUrl")}
      className="text-primary-100 underline"
      target="_blank"
      rel="noreferrer"
    >
      {t("portal:authenticator.app.setup.step1.faqOn2faApps")}
    </a>
  );
};
