import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StyledLink, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import {
  AuthenticatorSetupMethodsForm,
  TwoFactorSmsOption,
  TwoFactorStandardOption,
} from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSetupForm";
import { portalRoutes } from "@/router/routes/portalRoutes";
import { ChangeTwoFactorAuthenticationMethod } from "@/services/model";

export const TwoFactorAppAlternatives = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AuthenticatorSetupMethodsForm>();

  const watchType = watch("type");

  const onSubmit = (data: AuthenticatorSetupMethodsForm) => {
    if (data.type === ChangeTwoFactorAuthenticationMethod.SMS) {
      // Handle SMS setup
      navigate(portalRoutes.user.security.setup2faSms);
    }
    if (data.type === ChangeTwoFactorAuthenticationMethod.STANDARD) {
      navigate(portalRoutes.user.security.confirmStandard);
    }
  };

  return (
    <div className="flex gap-4 flex-col">
      <h3>{t("portal:user-account.security.2fa.changeOption")}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <TwoFactorSmsOption register={register} errors={errors} />

          <TwoFactorStandardOption register={register} errors={errors} />

          <ConfirmationButtonFlexBox forceButtonWidth>
            <StyledLink
              className="accent inverted"
              to={portalRoutes.user.security.base}
            >
              {t("translation:Common.label.back")}
            </StyledLink>
            <SubmitButton
              className="accent"
              label={t("translation:2fa.next")}
              disabled={!watchType}
            />
          </ConfirmationButtonFlexBox>
        </div>
      </form>
    </div>
  );
};
