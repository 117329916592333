import clsx from "clsx";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Costs } from "@/assets/icons/icons";
import { StyledLink } from "@/components/Interface/Button/Button";
import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { RemainingTime } from "@/components/Portal/Cockpit/RemainingTime/RemainingTime";
import { SimCardDetailSimStatus } from "@/services/model";
import {
  canBookWatchSim,
  hasWatchSim,
  hasWatchSimAutoRenewal,
  hasWatchSimOrdered,
  hasWatchSimPending,
} from "@/utils/deviceUtils";
import { getCurrency } from "@/utils/translationHelpers";

export const ShowWatchSimOptions: FC = () => {
  const { data: simDetail, isLoading } = useDisplayedSim();
  const { t } = useTranslation();

  // simDetail should always be present and only here to prevent TypeScript warnings.
  if (isLoading || !simDetail) return <LoadingSpinner />;

  const hasWatchSimOption = hasWatchSim(simDetail);
  const watchSimOrdered = hasWatchSimOrdered(simDetail);
  const watchSimPending = hasWatchSimPending(simDetail);
  const canBookWatchSimOption = canBookWatchSim(simDetail);
  const hasWatchSimOptionRenewal = hasWatchSimAutoRenewal(simDetail);

  const disableLinkTarget = new URLSearchParams();
  disableLinkTarget.set("simId", `${simDetail?.simId}`);
  disableLinkTarget.set(
    "mode",
    hasWatchSimOptionRenewal ? "disable" : "enable",
  );

  return (
    <div
      className="management-grid-layout"
      data-testid="show-watch-sim-options"
    >
      <section className="max-w-[360px] flex flex-col gap-14 h-mins">
        <div>
          <h3 className="text-secondary-100 font-semibold mb-6">
            {t("cockpit.managementTile.watchSim.display.watchSIM")}
          </h3>

          <SectionButtonHeader
            className="mb-2"
            header={t("cockpit.managementTile.watchSim.display.optionTitle")}
          />
          <div
            className="flex flex-col gap-4"
            data-testid="watchsim-option-overview"
          >
            <span
              className={clsx(
                "block font-semibold text-primary-100",
                watchSimPending && "opacity-50",
              )}
            >
              {t(
                hasWatchSimOption
                  ? "cockpit.managementTile.watchSim.display.watchSIM"
                  : "cockpit.managementTile.watchSim.display.noActiveOption",
              )}
            </span>
            {simDetail.priceWatch && (
              <div className="flex gap-2">
                <Costs
                  className={clsx(
                    "fill-primary-100",
                    watchSimPending && "opacity-50",
                  )}
                />
                <span className="text-base">
                  {getCurrency(simDetail.priceWatch, "CHF")}
                </span>
              </div>
            )}
          </div>
        </div>

        {!hasWatchSimOption && (
          <StyledLink
            className="accent w-full"
            to={`../checkout?simId=${simDetail.simId}`}
            replace
            disabled={!canBookWatchSimOption}
          >
            {t("common.buttons.bookOption")}
          </StyledLink>
        )}

        {simDetail.simStatus !== SimCardDetailSimStatus.grey &&
          hasWatchSimOption && (
            <div>
              <SectionButtonHeader
                header={t("cockpit.managementTile.watchSim.display.autoRenew")}
              >
                {simDetail?.servicePending ? (
                  <span className="font-x-small text-primary-25 underline">
                    {t(
                      hasWatchSimOptionRenewal
                        ? "common.buttons.disable"
                        : "common.buttons.enable",
                    )}
                  </span>
                ) : (
                  <Link
                    className="change-button"
                    to={`../automatic-renewal?simId=${simDetail.simId}`}
                    replace
                  >
                    {t(
                      hasWatchSimOptionRenewal
                        ? "common.buttons.disable"
                        : "common.buttons.enable",
                    )}
                  </Link>
                )}
              </SectionButtonHeader>
              {simDetail.autoRenew !== undefined &&
                simDetail.remainingDays &&
                simDetail.minContractDuration && (
                  <RemainingTime
                    className="!h-fit !mb-2"
                    totalTime={simDetail.minContractDuration}
                    remainingTime={simDetail.remainingDays ?? 0}
                    isPending={hasWatchSimPending(simDetail)}
                    hasRenewal={hasWatchSimOptionRenewal}
                  />
                )}
            </div>
          )}
      </section>

      {(!hasWatchSimOption || watchSimOrdered) && (
        <section className="flex items-start xl:mt-16">
          <HighlightPanel
            headline={t("layout.goodToKnow")}
            className="max-w-[360px]"
          >
            {hasWatchSimOption ? (
              watchSimOrdered && (
                <Trans
                  t={t}
                  i18nKey="cockpit.managementTile.watchSim.display.linkHelpText"
                  components={[
                    <a
                      key="instruction-link"
                      className="text-primary-100 underline"
                      href={t(
                        "portal:cockpit.managementTile.watchSim.display.linkHelpUrl",
                      )}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              )
            ) : canBookWatchSimOption ? (
              <p>
                {t("cockpit.managementTile.watchSim.display.creationHelpText")}
              </p>
            ) : (
              <p>
                {t("cockpit.managementTile.watchSim.display.voiceOptionNeeded")}
              </p>
            )}
          </HighlightPanel>
        </section>
      )}
    </div>
  );
};
