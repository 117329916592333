export interface ITheme {
  [key: string]: string;
}

export interface IThemes {
  [key: string]: ITheme;
}

export interface IMappedTheme {
  [key: string]: string | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
  return {
    "--color-primary-100": variables["primary-100"] || "",
    "--color-primary-75": variables["primary-75"] || "",
    "--color-primary-50": variables["primary-50"] || "",
    "--color-primary-25": variables["primary-25"] || "",
    "--color-primary-1": variables["primary-1"] || "",

    "--color-secondary-100": variables["secondary-100"] || "",
    "--color-secondary-75": variables["secondary-75"] || "",
    "--color-secondary-50": variables["secondary-50"] || "",
    "--color-secondary-25": variables["secondary-25"] || "",

    "--color-highlight-100": variables["highlight-100"] || "",
    "--color-highlight-75": variables["highlight-75"] || "",
    "--color-highlight-50": variables["highlight-50"] || "",
    "--color-highlight-25": variables["highlight-25"] || "",

    "--color-accent-100": variables["accent-100"] || "",
    "--color-accent-75": variables["accent-75"] || "",
    "--color-accent-50": variables["accent-50"] || "",
    "--color-accent-25": variables["accent-25"] || "",

    "--color-error-100": variables["secondary-100"] || "",
    "--color-error-75": variables["secondary-75"] || "",
    "--color-error-50": variables["secondary-50"] || "",
    "--color-error-25": variables["secondary-25"] || "",

    "--color-subtle-selected": variables["subtle-selected"] || "",

    "--color-text-primary": variables.textPrimary || "",
    "--background-primary": variables.backgroundPrimary || "",
    "--background-secondary": variables.backgroundSecondary || "",
  };
};

export const applyTheme = (theme: ITheme): void => {
  const themeObject: IMappedTheme = mapTheme(theme);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === "name") {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};

export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
  return { ...extending, ...newTheme };
};
