import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { DeviceDetailsForm } from "@/components/Portal/Cockpit/DeviceManagement/Details/Details";
import { DetailsChangeRow } from "@/components/Portal/Cockpit/DeviceManagement/Details/DetailsChangeRow/DetailsChangeRow";
import { allowedDeviceNameRegex } from "@/utils/deviceUtils";

export const DeviceNameRow = () => {
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<DeviceDetailsForm>();
  const alias = watch("alias");
  const hasValidationErrors = !!Object.keys(errors).length;

  return (
    <DetailsChangeRow
      label={alias}
      header={t("portal:common.device.attributes.deviceName")}
      isSaveDisabled={hasValidationErrors}
    >
      <FormField<DeviceDetailsForm>
        ariaLabel={t("portal:common.device.attributes.deviceName")}
        id="device-name-input"
        errors={errors}
        register={register}
        name="alias"
        options={{
          onChange: (e: KeyboardEvent) => {
            const input = e.target as HTMLInputElement;
            let value = input.value;
            if (value.length > 24) {
              value = value.substring(0, 24);
            }
            setValue("alias", value);
          },
          required: t("translation:label.validation.required"),
          pattern: {
            message: t("translation:label.validation.deviceNameInvalidChars"),
            value: allowedDeviceNameRegex,
          },
        }}
      />
    </DetailsChangeRow>
  );
};
