import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { expectedAuthTypeQueryParam } from "@/components/Portal/UserAccount/Security/TwoFactorAuthStatus";
import { errorShouldBeRetried } from "@/components/Portal/UserAccount/Security/utils";
import { AuthenticatorSmsSetupForm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsSetupForm";
import { NumberForm, useNumberForm } from "@/hooks/useNumberForm";
import { portalRoutes } from "@/router/routes/portalRoutes";
import {
  useCancelSetup,
  useChangeTwoFactorAuthentication,
} from "@/services/auth";
import {
  ChangeTwoFactorAuthenticationMethod,
  ValidateTwoFactorAuthenticationMethod,
} from "@/services/model";
import { phoneNumberAtom } from "@/utils/atoms";

export const TwoFactorSmsSetup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setPhoneNumber] = useAtom(phoneNumberAtom);
  const isSubmitting = useRef(false);

  const formMethods = useNumberForm();

  const { mutateAsync: cancelSetup } = useCancelSetup();

  const { mutateAsync: requestChange } = useChangeTwoFactorAuthentication({
    mutation: {
      onError: () => null,
    },
  });

  const onSubmit = (data: NumberForm) => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;

    const handleSuccess = () => {
      setPhoneNumber(data.number);
      const queryParams = new URLSearchParams();
      queryParams.set(
        expectedAuthTypeQueryParam,
        ChangeTwoFactorAuthenticationMethod.SMS,
      );

      navigate(portalRoutes.user.security.verify2faSms + `?${queryParams}`);
    };
    const tryToChange = () =>
      requestChange({
        params: {
          method: ValidateTwoFactorAuthenticationMethod.SMS,
          phoneNumber: data.number,
        },
      });

    tryToChange()
      .then(handleSuccess)
      .catch((error: AxiosError<any>) => {
        if (
          error.response?.data.errorCode === 403 ||
          error.response?.data ===
            '500 Internal Server Error: ["invalid phone number"]'
        ) {
          formMethods.setError("number", {
            type: "manual",
            message: t("label.validation.phoneBackend"),
          });
        } else if (errorShouldBeRetried(error)) {
          cancelSetup().then(() => {
            // We need to wait before we can request a new setup
            setTimeout(() => tryToChange().then(handleSuccess), 2500);
          });
        }
        isSubmitting.current = false;
      });
  };

  return (
    <>
      {formMethods.formState.isSubmitting && <LoadingSpinner />}
      <AuthenticatorSmsSetupForm
        formMethods={formMethods}
        onSubmit={onSubmit}
      />
    </>
  );
};
