import { Navigate } from "react-router-dom";

import { WelcomeLoadingSpinner } from "@/components/Welcome/WelcomeLoadingSpinner.tsx";
import { useShouldSkipAuthenticatorSetup } from "@/hooks/useShouldSkipAuthenticatorSetup.ts";
import { FCC } from "@/types.ts";

export const RedirectToPortalIfHas2FA: FCC = ({ children }) => {
  const shouldSkip = useShouldSkipAuthenticatorSetup();
  if (shouldSkip === null) {
    return <WelcomeLoadingSpinner />;
  }
  if (shouldSkip) {
    return <Navigate to="/portal/cockpit" replace />;
  }
  return children;
};
