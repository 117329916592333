import compact from "lodash/compact";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { VoiceSettingsReasonItem } from "@/services/model";

// Reasons for disabled voice settings
const {
  BillNotPaid,
  BillDunning,
  CreditClassC,
  NoPaymentMean,
  NoActiveVoicePackage,
  CostLimitReached,
} = VoiceSettingsReasonItem;

export const Reasons = ({
  reasons,
}: {
  reasons: VoiceSettingsReasonItem[];
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.voiceSettings.reasons",
  });

  const translatedReasons = useMemo(() => {
    return compact(
      reasons.map((reason) => {
        switch (reason) {
          case BillNotPaid:
            return t("billNotPaid");
          case BillDunning:
            return t("billNotPaid");
          case NoActiveVoicePackage:
            return t("noActiveVoicePackage");
          case NoPaymentMean:
            return t("noPaymentMean");
          case CreditClassC:
            return t("creditClassC");
          case CostLimitReached:
            return t("costLimitReached");
          default:
            return undefined;
        }
      }),
    );
  }, [t, reasons]);

  return (
    <div className="mt-10">
      <HighlightPanel>
        {translatedReasons && (
          <div>
            {`${t("voiceSettingsBlocked")}`}
            <ul className="list-disc list-inside">
              {translatedReasons.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
            <br />
            <span>{t("askSupport")}</span>
          </div>
        )}
      </HighlightPanel>
    </div>
  );
};
