import "./FormField.scss";

import clsx from "clsx";
import React from "react";
import { FieldValues, RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage";
import {
  extendFormOptions,
  FormFieldProps,
  getFormFieldError,
} from "@/components/Interface/FormFields/FormField/FormField.utils.ts";
import { InputLabel } from "@/components/Interface/FormFields/InputLabel/InputLabel";

export const FormField = <TFormValues extends FieldValues>({
  id,
  name,
  label,
  ariaLabel,
  options = {
    required: true,
  },
  showRequiredIndicator = false,
  errors,
  register,
  type = "text",
  autocomplete,
  placeholder = "",
  onFocus,
  children,
  value,
  icon,
  passwordRules,
  className,
}: React.PropsWithChildren<FormFieldProps<TFormValues>>) => {
  const { t } = useTranslation();
  const optionsWithParsedMaxLength = extendFormOptions(options, t);
  const errorMessage: string = getFormFieldError(
    errors,
    name,
    optionsWithParsedMaxLength,
  );

  return (
    <div
      className={clsx("form-field-container", className, !!icon && "withIcon")}
    >
      {label && (
        <div className="label-container">
          <InputLabel
            error={errors[name] !== undefined}
            htmlFor={id}
            label={
              label +
              (showRequiredIndicator &&
              options?.required &&
              !options?.readOnly &&
              !options?.disabled
                ? " *"
                : "")
            }
            disabled={!!options?.disabled || !!options.readOnly}
          />
          {children}
        </div>
      )}

      <div className="relative">
        <input
          id={id}
          aria-label={label ?? ariaLabel}
          aria-required={!!options?.required}
          aria-invalid={errors[name] ? "true" : "false"}
          autoComplete={autocomplete}
          {...register(
            name,
            optionsWithParsedMaxLength as RegisterOptions<TFormValues>,
          )}
          {...(options.readOnly && { readOnly: true })}
          {...(type && { type })}
          {...(value && { value })}
          {...(passwordRules && { passwordrules: passwordRules })}
          data-testid={name}
          placeholder={placeholder}
          {...(onFocus && { onFocus })}
          className={clsx(errors[name] ? "error" : "", "min-w-max")}
        />
        {icon && <div className="icon">{icon}</div>}
      </div>

      {errorMessage && <ErrorMessage message={errorMessage} name={name} />}
    </div>
  );
};
