import "./ListBox.scss";

import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";

import Check from "@/assets/icons/Check.svg?react";
import { ArrowDown } from "@/assets/icons/icons";

/** ListBox is a dropdown of options. It's generic, so it can be easily typed.
 *
 * If your selected option does not appear selected, and is complex type,
 * supply a compare function in the by propery.*/
export const ListBox = <OptionType,>({
  className,
  selected,
  setSelected,
  dataTestId,
  displayValue,
  availableValues,
  placeholder,
  by,
  renderOption,
  disabled,
}: {
  className?: string;
  selected: OptionType | undefined;
  setSelected: (value: OptionType) => void;
  dataTestId?: string;
  displayValue: (value: OptionType) => string;
  availableValues: OptionType[];
  placeholder: string | OptionType;
  by?:
    | (keyof OptionType & string)
    | ((a: OptionType, z: OptionType) => boolean)
    | undefined;
  renderOption?: (
    option: OptionType,
    selected: boolean,
    active: boolean,
  ) => JSX.Element;
  disabled?: boolean;
}) => {
  return (
    <Listbox
      {...(by && { by })}
      value={selected}
      onChange={(value: OptionType) => {
        setSelected(value);
      }}
      disabled={disabled}
    >
      {({ open }) => (
        <div className={clsx("listbox-container", className)}>
          <div className="relative">
            <Listbox.Button
              data-testid={dataTestId}
              className={clsx("listbox-button", disabled && "opacity-25")}
            >
              <span className="block truncate">
                {selected
                  ? displayValue(selected)
                  : typeof placeholder === "string"
                    ? placeholder
                    : displayValue(placeholder)}
              </span>
            </Listbox.Button>
            <div className={clsx("icon-wrapper", disabled && "opacity-25")}>
              <ArrowDown className="icon" aria-hidden="true" />
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="listbox-options">
              {availableValues?.map((column, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) => `option ${active ? "active" : ""}`}
                  value={column}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`text ${selected ? "selected" : ""}`}>
                        {renderOption && renderOption(column, selected, active)}
                        {displayValue(column)}
                      </span>

                      {selected && (
                        <Check
                          aria-hidden="true"
                          className={`icon selected 
                            ${active ? "active" : ""}
                          `}
                        />
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
