import { captureMessage, withScope } from "@sentry/react";
import { FC, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Lightbox,
  LightboxConfiguration,
} from "@/components/Interface/Lightbox/Lightbox";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import theme from "@/themes/base";
import { DATATRANS_CALLBACK_URL } from "@/utils/apiUrls";
import { env } from "@/utils/environmentHelpers";
import { log } from "@/utils/log";

const callbackUrl = new URL(DATATRANS_CALLBACK_URL);
if (env.isDev) {
  callbackUrl.searchParams.append("debug", "true");
}

interface LightboxWrapperProps {
  transactionId: number | undefined;
  sign: string | undefined;
  enabled?: boolean;
  paymentProvider: string | undefined;
}

export const LightboxWrapper: FC<LightboxWrapperProps> = ({
  transactionId,
  sign,
  enabled,
  paymentProvider,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onLoaded = () => setIsLoading(false);
  const onOpened = () => null;
  const onCancelled = () => {
    setIsLoading(false);
  };
  const onError = (data: any) => {
    setIsLoading(false);
    log(`Error in payment process: ${data}`);
    withScope(function (scope) {
      scope.setTag("section", "onboarding");
      scope.setTag("onboardingStep", "checkout");
      scope.setTag("event", "lightboxError");
      scope.setContext("data", { data });
      captureMessage("A lightbox payment process resulted in an error.");
    });
  };
  const location = useLocation();
  const hashWithoutQuery = window.location.hash.split("?")[0];
  // The current path is a pathname like /static/ plus the hash,
  // which resembles the route, but we remove query params from the hash.
  const redirectUrls = useMemo(() => {
    const urls = {
      successUrl: "",
      cancelUrl: "",
      errorUrl: "",
    };
    const settings = ["success", "cancel", "error"] as const;
    settings.forEach((setting) => {
      const url = new URL(callbackUrl);
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("payment", setting);
      searchParams.delete("errorCode");
      url.searchParams.append(
        "redirect",
        `${hashWithoutQuery}?${searchParams.toString()}`,
      );
      urls[`${setting}Url`] = url.toString();
    });
    return urls;
  }, [location, hashWithoutQuery]);

  const config: LightboxConfiguration = {
    merchantId: env.isProd ? "3000013097" : "1100015891", // Merchant identifier provided to you by Datatrans.
    refno: `${transactionId}`, // Provided by the backend, this identifies the transaction that this is going to be.
    currency: "CHF", // The type of currency that will be used for the payment.
    sign: `${sign}`, // Transaction security parameter, provided by the backend.
    production: env.isProd,
    paymentmethod: ["ECA", "VIS", "PFC", "TWI"],
    themeConfiguration: {
      brandColor: theme["primary-100"],
    },
    ...redirectUrls,
    useAlias: "yes", // Save an alias for the entered payment mean so we can use it again
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {enabled && paymentProvider && transactionId && (
        <Lightbox
          config={{ ...config, paymentmethod: [paymentProvider] }}
          onLoaded={onLoaded}
          onOpened={onOpened}
          onCancelled={onCancelled}
          onError={onError}
        />
      )}
    </>
  );
};
