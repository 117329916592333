import "./SectionButtonHeader.scss";

import clsx from "clsx";
import { FCCC } from "types";

export const SectionButtonHeader: FCCC<{ header: string }> = ({
  className,
  children,
  header,
}) => (
  <div className={clsx(className, "selection-row")}>
    <p className="header">{header}</p>
    {children && <div className="change-button">{children}</div>}
  </div>
);
