import "./DeviceManagement.scss";

import { Transition } from "@headlessui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useMatch, useNavigate } from "react-router-dom";
import { FCC } from "types";

import { Close } from "@/assets/icons/icons";
import { Tablist } from "@/components/Interface/Tablist/Tablist";
import { DeviceStatusIndicator } from "@/components/Portal/Cockpit/DeviceList/DeviceListRow/DeviceStatusIndicator/DeviceStatusIndicator";
import { ExpiresSoonFlag } from "@/components/Portal/Cockpit/Tiles/ActiveSimTile/ActiveSimTile";
import { useHandleError } from "@/hooks/useHandleError";
import { useNeedsIDCheck } from "@/hooks/useNeedsIDCheck";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { transferTypes } from "@/hooks/useSimTransferStateInfo";
import { useGetCustomer, useGetESimInfo, useGetSim } from "@/services/api";
import { SimCardDetailSimStatus } from "@/services/model";
import {
  esimTransferTypeAtom,
  isEditingSimDetailsAtom,
  managedUnscannedDevicesAtom,
} from "@/utils/atoms";
import { canOnlyBookData } from "@/utils/customerUtils";
import {
  getDeviceDescription,
  getIconForDevice,
  hasNumberPorting,
  isEsimchangeInProgress,
  isEsimresetInProgress,
  isUnscannedEsimInfo,
} from "@/utils/deviceUtils";

import { useDisplayedSim } from "./useDisplayedSim";

export const ManagementTitle: FCC = () => {
  const { t } = useTranslation();
  const { data: simDetail } = useDisplayedSim();

  return (
    <div className="title-wrapper">
      <h2 data-testid="tile-title" className="tile-title">
        {simDetail?.alias}
      </h2>

      <div className="status-wrapper">
        <DeviceStatusIndicator
          color={simDetail?.simStatus}
          className="align-middle"
        />

        <span className="text-primary-100 font-small whitespace-nowrap">
          {simDetail?.simStatus !== undefined
            ? t(`common.device.status.${simDetail.simStatus}`)
            : "-"}
        </span>
      </div>
    </div>
  );
};

export const DeviceManagement: FCC = () => {
  const [simId] = useSimIdFromQueryParam();
  const [mode] = useQueryParam("mode");
  const managementUrlMatch = useMatch("/portal/cockpit/manage/*");
  const navigate = useNavigate();
  const handleError = useHandleError();
  const { t } = useTranslation("portal");
  const [isPendingSim, setIsPendingSim] = useState(false);
  const refetchInterval = isPendingSim && 10000;
  const { data: simData } = useGetSim(
    { simId: parseInt(simId) },
    {
      query: {
        enabled: !isNaN(parseInt(simId)),
        refetchInterval,
        onSuccess: (data) => setIsPendingSim(!!data.servicePending),
        onError: (error) => {
          handleError(error);
          if (
            typeof error?.response?.data === "string" &&
            error?.response?.data.includes("no sim found for sim identifier")
          ) {
            navigate("..", { replace: true });
          }
        },
        staleTime: 15 * 1000,
        refetchOnMount: true,
      },
    },
  );

  const { data: customerData } = useGetCustomer();

  const { data: esimInfo } = useGetESimInfo(
    { simIds: [parseInt(simId)] },
    {
      query: {
        enabled: !isNaN(parseInt(simId)),
      },
    },
  );

  const [managedUnscannedDevices, setManagedUnscannedDevices] = useAtom(
    managedUnscannedDevicesAtom,
  );
  const [isEditingSimDetails] = useAtom(isEditingSimDetailsAtom);

  const isChangingVoiceSettings = mode === "edit";

  const isBookingRoaming =
    useMatch("portal/cockpit/manage/roaming") &&
    (mode === "booking" || mode === "checkout");

  // TODO Refactor routing matching logic, this is prone to break
  const isChangingTariff =
    !!managementUrlMatch?.params["*"] &&
    ["tariff/change", "tariff/checkout", "tariff/automatic-renewal"].includes(
      managementUrlMatch.params["*"],
    );
  const isModifyingESim = !!managementUrlMatch?.params["*"]
    ? ["details/reload", "details/transfer", "details/physicalToEsim"].includes(
        managementUrlMatch.params["*"],
      )
    : false;
  const isModifyingWatchSim =
    !!managementUrlMatch?.params["*"] &&
    ["watch-sim/checkout", "watch-sim/automatic-renewal"].includes(
      managementUrlMatch.params["*"],
    );
  const canBookVoiceOption = !(customerData && canOnlyBookData(customerData));

  const isBookingWatchSIM =
    useMatch("portal/cockpit/manage/watch-sim") &&
    (mode === "booking" || mode === "checkout");

  useEffect(() => {
    if (
      simData &&
      isUnscannedEsimInfo(esimInfo?.[0]) &&
      !managedUnscannedDevices.includes(Number(simData.simId))
    ) {
      setManagedUnscannedDevices((prev) => [...prev, Number(simId)]);
    }
  }, [
    simData,
    simId,
    esimInfo,
    managedUnscannedDevices,
    setManagedUnscannedDevices,
  ]);

  const needsIdCheck = useNeedsIDCheck();

  const [esimTransferType] = useAtom(esimTransferTypeAtom);

  if (!simData) return null;

  const isInactiveSim = simData.simStatus === SimCardDetailSimStatus.grey;
  const isNumberPorting = hasNumberPorting(simData);
  const isRoamingDisabled =
    isBookingWatchSIM ||
    isChangingVoiceSettings ||
    isChangingTariff ||
    isModifyingESim ||
    isEditingSimDetails ||
    isNumberPorting ||
    isModifyingWatchSim;
  const isTariffDisabled =
    isBookingWatchSIM ||
    isChangingVoiceSettings ||
    isBookingRoaming ||
    isModifyingESim ||
    isEditingSimDetails ||
    isModifyingWatchSim;
  const isVoiceDisabled =
    isBookingWatchSIM ||
    isBookingRoaming ||
    isInactiveSim ||
    isChangingTariff ||
    isModifyingESim ||
    isEditingSimDetails ||
    isModifyingWatchSim;
  const isWatchSimTabDisabled =
    isChangingVoiceSettings ||
    isBookingRoaming ||
    isInactiveSim ||
    isChangingTariff ||
    isModifyingESim ||
    isEditingSimDetails;
  const isDetailsDisabled =
    isBookingWatchSIM ||
    isChangingVoiceSettings ||
    isBookingRoaming ||
    isChangingTariff ||
    isModifyingWatchSim;

  // Navigate back to overview if we can't find the sim or this is a blocked sim
  if (
    !simId ||
    simData.simStatus === SimCardDetailSimStatus.red ||
    needsIdCheck
  )
    return <Navigate to=".." replace={true} />;

  // Navigate to waiting state as long as eSim is being transferred
  // As a new state "physicalToEsim" separate from "transfer" was introduced, this must be taken care of.
  // It is necessary to determine the correct waiting page to navigate to.
  if (
    isEsimchangeInProgress(esimInfo) &&
    !isModifyingESim &&
    !isNaN(parseInt(simId)) &&
    esimTransferType[parseInt(simId)]
  ) {
    if (esimTransferType[parseInt(simId)] === transferTypes.TRANSFER) {
      return <Navigate to={`details/transfer?simId=${simId}`} replace={true} />;
    }
    if (esimTransferType[parseInt(simId)] === transferTypes.PHYSICALTOESIM) {
      return (
        <Navigate to={`details/physicalToEsim?simId=${simId}`} replace={true} />
      );
    }
  } else if (
    isEsimresetInProgress(esimInfo) &&
    managementUrlMatch?.params["*"] !== "details/reload"
  ) {
    return <Navigate to={`details/reload?simId=${simId}`} replace={true} />;
  }

  return (
    <div className="tile-width-wrapper">
      <Transition
        appear={true}
        show={true}
        enterFrom="inactive"
        enterTo="active"
        leaveFrom="active"
        leaveTo="inactive"
        className="management-tile"
      >
        <div className="tile-watermark-container">
          {getIconForDevice(getDeviceDescription(simData?.device?.description))}
        </div>

        <button className="close-button" onClick={() => navigate(-1)}>
          <Close />
        </button>

        <div className="content-container">
          <div id="device-management-view">
            {Boolean(
              simData?.remainingDays !== undefined &&
                simData.remainingDays <= 4 &&
                simData?.autoRenew === false &&
                simData?.simStatus !== SimCardDetailSimStatus.grey,
            ) && <ExpiresSoonFlag />}

            <ManagementTitle />

            <Tablist
              tabs={[
                {
                  target: `tariff?simId=${simId}`,
                  disabled: isTariffDisabled,
                  showHint: isInactiveSim,
                  text: t("cockpit.managementTile.tabs.tariff"),
                },
                {
                  target: `roaming?simId=${simId}`,
                  disabled: isRoamingDisabled,
                  showHint: isInactiveSim,
                  text: t("cockpit.managementTile.tabs.roaming"),
                },
                {
                  target: `voice?simId=${simId}`,
                  disabled: isVoiceDisabled,
                  hidden: !canBookVoiceOption,
                  showHint: isInactiveSim,
                  text: t("cockpit.managementTile.tabs.voice"),
                },
                {
                  target: `watch-sim?simId=${simId}`,
                  disabled: isWatchSimTabDisabled,
                  hidden: !canBookVoiceOption,
                  showHint: isInactiveSim,
                  text: t("cockpit.managementTile.tabs.watchSim"),
                },
                {
                  target: `details?simId=${simId}`,
                  disabled: isDetailsDisabled,
                  showHint: isInactiveSim,
                  text: t("cockpit.managementTile.tabs.details"),
                },
              ]}
            />

            <div id="device-management-container">
              <Outlet />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
