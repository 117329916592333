import "@/components/Portal/Cockpit/Tiles/InactiveTile/InactiveSimTile.scss";

import { useTranslation } from "react-i18next";
import { DeviceFC } from "types";

import { SpeedAndData } from "@/components/Portal/Cockpit/SpeedAndData/SpeedAndData";
import {
  DeviceTile,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import {
  getFlatNameFromDevice,
  getIconForDevice,
  getSpeedFromLocalFlatSpeed,
} from "@/utils/deviceUtils";

export const UnverifiedSimTile: DeviceFC = ({ device }) => {
  const { t } = useTranslation("portal");
  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );
  const flatName = getFlatNameFromDevice(device);
  const icon = getIconForDevice(device.device?.description);
  const speed = getSpeedFromLocalFlatSpeed(device.localFlatSpeed);

  return (
    <DeviceTile dataTestid="unverified-sim" icon={icon}>
      <div id="inactive-wrapper">
        <h2 className="tile-title">{title}</h2>
        <h3 className="tile-subtitle">{flatName}</h3>
        <div className="icon-row mr-4">
          <SpeedAndData speed={speed} />
        </div>
        <div className="usage-info mt-16">
          <div id="bar-wrapper">
            <div id="background-bar" />
          </div>
          <div className="mt-2">
            <span className="text-base text-primary-100 leading-normal text-left">
              {t("layout.tiles.inactive.beginsAfterID")}
            </span>
          </div>
        </div>
      </div>
    </DeviceTile>
  );
};
