/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type GetSimListSortDirection =
  (typeof GetSimListSortDirection)[keyof typeof GetSimListSortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSimListSortDirection = {
  asc: "asc",
  desc: "desc",
} as const;
