import "./Combobox.scss";

import { Combobox as HeadlessCombobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SelectorIcon from "@/assets/icons/ArrowDown.svg?react";
import Check from "@/assets/icons/Check.svg?react";
import { InputLabel } from "@/components/Interface/FormFields/InputLabel/InputLabel";

interface ComboboxProps<OptionType> {
  className?: string;
  displayValue?: (item: any) => string;
  options: readonly OptionType[];
  placeholder: string;
  label?: string;
  dataTestId: string;
  disabled?: boolean;
  showRequiredIndicator?: boolean;
  hasError?: boolean;
  field: ControllerRenderProps<any, any>;
}
export const Combobox = <OptionType,>({
  className,
  options,
  placeholder,
  label,
  displayValue = (item: string) => item,
  disabled,
  dataTestId,
  showRequiredIndicator = false,
  hasError,
  field,
}: ComboboxProps<OptionType>) => {
  const [query, setQuery] = useState("");
  const { t } = useTranslation();

  // Filter options based on input
  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          displayValue(option)
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  return (
    <HeadlessCombobox value={field.value} onChange={field.onChange}>
      <div className={clsx("relative", className)}>
        {label && (
          <div className="label-container">
            <InputLabel
              htmlFor={field.name}
              className="inline-block"
              label={label + (showRequiredIndicator && !disabled ? " *" : "")}
              disabled={disabled}
            />
          </div>
        )}
        <div
          className={clsx(
            "inputAndButton",
            hasError && "has-error",
            disabled && "opacity-20 pointer-events-none",
          )}
        >
          <HeadlessCombobox.Input
            {...(disabled && { tabIndex: -1 })}
            aria-disabled={disabled}
            id={field.name}
            // register the input with react-hook-form
            name={field.name}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            displayValue={displayValue}
            data-testid={dataTestId}
          />
          <HeadlessCombobox.Button
            aria-disabled={disabled}
            data-testid={`${dataTestId}-button`}
            aria-label={t("common.buttons.showOptions")}
            className="button"
          >
            <SelectorIcon className="icon" aria-hidden="true" />
          </HeadlessCombobox.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          afterLeave={() => setQuery("")}
        >
          <HeadlessCombobox.Options className="options">
            {filteredOptions.length === 0 && query !== "" ? (
              // No result for query / input.
              <div data-testid="nothingFound" className="noResult">
                {t("Common.nothingFound")}
              </div>
            ) : (
              filteredOptions.map((option: OptionType, i: number) => (
                <HeadlessCombobox.Option
                  key={i}
                  className={({ active }) => `option ${active ? "active" : ""}`}
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {displayValue(option)}
                      </span>
                      {selected ? (
                        <span className={`selected ${active && "text-white"}`}>
                          <Check
                            className="icon text-primary-100"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </HeadlessCombobox.Option>
              ))
            )}
          </HeadlessCombobox.Options>
        </Transition>
      </div>
    </HeadlessCombobox>
  );
};
