import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { TextContainer } from "@/components/Portal/UserAccount/PersonalDetails/TextContainer/TextContainer";
import { useGetCustomer } from "@/services/api";
import { isBusinessCustomer } from "@/utils/customerUtils";

export const CompanyData: FCC = () => {
  const { data: customer } = useGetCustomer();
  const { t } = useTranslation();

  return (
    <>
      {!!customer && isBusinessCustomer(customer) && (
        <div className="gap-flex">
          <div className="header-row">
            <h3 className="text-secondary-100 font-semibold">
              {t("portal:user-account.personalDetails.companyData.title")}
            </h3>
          </div>
          <div>
            <p className="label">
              {t(
                "Onboarding.sections.personal-details.form.label.organizationName",
              )}
            </p>
            <TextContainer>{customer?.companyName}</TextContainer>
          </div>
          <div>
            <p className="label">
              {t(
                "Onboarding.sections.personal-details.form.label.organizationForm",
              )}
            </p>
            <TextContainer>{customer?.legalForm?.description}</TextContainer>
          </div>
          <div>
            <p className="label">
              {t(
                "Onboarding.sections.personal-details.form.label.organizationId",
              )}
            </p>
            <TextContainer>{customer?.registerNo}</TextContainer>
          </div>
        </div>
      )}
    </>
  );
};
