import en from "@/locales/en/translation.json";

export type Country = {
  ID: number;
  ALPHA2: string;
  ALPHA3: keyof typeof en.translation.countries;
};

export const SCHWEIZ: Country = {
  ID: 200,
  ALPHA2: "CH",
  ALPHA3: "CHE",
};

export const countries: readonly Country[] = [
  {
    ID: 1,
    ALPHA2: "DE",
    ALPHA3: "DEU",
  },
  {
    ID: 2,
    ALPHA2: "BE",
    ALPHA3: "BEL",
  },
  {
    ID: 3,
    ALPHA2: "AT",
    ALPHA3: "AUT",
  },
  {
    ID: 4,
    ALPHA2: "HU",
    ALPHA3: "HUN",
  },
  {
    ID: 5,
    ALPHA2: "AF",
    ALPHA3: "AFG",
  },
  {
    ID: 6,
    ALPHA2: "EG",
    ALPHA3: "EGY",
  },
  {
    ID: 7,
    ALPHA2: "AX",
    ALPHA3: "ALA",
  },
  {
    ID: 8,
    ALPHA2: "AL",
    ALPHA3: "ALB",
  },
  {
    ID: 9,
    ALPHA2: "DZ",
    ALPHA3: "DZA",
  },
  {
    ID: 10,
    ALPHA2: "AS",
    ALPHA3: "ASM",
  },
  {
    ID: 11,
    ALPHA2: "VI",
    ALPHA3: "VIR",
  },
  {
    ID: 12,
    ALPHA2: "AD",
    ALPHA3: "AND",
  },
  {
    ID: 13,
    ALPHA2: "AO",
    ALPHA3: "AGO",
  },
  {
    ID: 14,
    ALPHA2: "AI",
    ALPHA3: "AIA",
  },
  {
    ID: 15,
    ALPHA2: "AQ",
    ALPHA3: "ATA",
  },
  {
    ID: 16,
    ALPHA2: "AG",
    ALPHA3: "ATG",
  },
  {
    ID: 17,
    ALPHA2: "GQ",
    ALPHA3: "GNQ",
  },
  {
    ID: 18,
    ALPHA2: "AR",
    ALPHA3: "ARG",
  },
  {
    ID: 19,
    ALPHA2: "AM",
    ALPHA3: "ARM",
  },
  {
    ID: 20,
    ALPHA2: "AW",
    ALPHA3: "ABW",
  },
  {
    ID: 22,
    ALPHA2: "AZ",
    ALPHA3: "AZE",
  },
  {
    ID: 23,
    ALPHA2: "ET",
    ALPHA3: "ETH",
  },
  {
    ID: 24,
    ALPHA2: "AU",
    ALPHA3: "AUS",
  },
  {
    ID: 25,
    ALPHA2: "BS",
    ALPHA3: "BHS",
  },
  {
    ID: 26,
    ALPHA2: "BH",
    ALPHA3: "BHR",
  },
  {
    ID: 27,
    ALPHA2: "BD",
    ALPHA3: "BGD",
  },
  {
    ID: 28,
    ALPHA2: "BB",
    ALPHA3: "BRB",
  },
  {
    ID: 29,
    ALPHA2: "BY",
    ALPHA3: "BLR",
  },
  {
    ID: 30,
    ALPHA2: "BZ",
    ALPHA3: "BLZ",
  },
  {
    ID: 31,
    ALPHA2: "BJ",
    ALPHA3: "BEN",
  },
  {
    ID: 32,
    ALPHA2: "BM",
    ALPHA3: "BMU",
  },
  {
    ID: 33,
    ALPHA2: "BT",
    ALPHA3: "BTN",
  },
  {
    ID: 34,
    ALPHA2: "BO",
    ALPHA3: "BOL",
  },
  {
    ID: 35,
    ALPHA2: "BQ",
    ALPHA3: "BES",
  },
  {
    ID: 36,
    ALPHA2: "BA",
    ALPHA3: "BIH",
  },
  {
    ID: 37,
    ALPHA2: "BW",
    ALPHA3: "BWA",
  },
  {
    ID: 38,
    ALPHA2: "BV",
    ALPHA3: "BVT",
  },
  {
    ID: 39,
    ALPHA2: "BR",
    ALPHA3: "BRA",
  },
  {
    ID: 40,
    ALPHA2: "VG",
    ALPHA3: "VGB",
  },
  {
    ID: 41,
    ALPHA2: "IO",
    ALPHA3: "IOT",
  },
  {
    ID: 42,
    ALPHA2: "BN",
    ALPHA3: "BRN",
  },
  {
    ID: 43,
    ALPHA2: "BG",
    ALPHA3: "BGR",
  },
  {
    ID: 44,
    ALPHA2: "BF",
    ALPHA3: "BFA",
  },
  {
    ID: 45,
    ALPHA2: "BI",
    ALPHA3: "BDI",
  },
  {
    ID: 47,
    ALPHA2: "CL",
    ALPHA3: "CHL",
  },
  {
    ID: 48,
    ALPHA2: "CN",
    ALPHA3: "CHN",
  },
  {
    ID: 50,
    ALPHA2: "CK",
    ALPHA3: "COK",
  },
  {
    ID: 51,
    ALPHA2: "CR",
    ALPHA3: "CRI",
  },
  {
    ID: 52,
    ALPHA2: "CI",
    ALPHA3: "CIV",
  },
  {
    ID: 53,
    ALPHA2: "CW",
    ALPHA3: "CUW",
  },
  {
    ID: 54,
    ALPHA2: "DK",
    ALPHA3: "DNK",
  },
  {
    ID: 56,
    ALPHA2: "DM",
    ALPHA3: "DMA",
  },
  {
    ID: 57,
    ALPHA2: "DO",
    ALPHA3: "DOM",
  },
  {
    ID: 58,
    ALPHA2: "DJ",
    ALPHA3: "DJI",
  },
  {
    ID: 59,
    ALPHA2: "EC",
    ALPHA3: "ECU",
  },
  {
    ID: 60,
    ALPHA2: "SV",
    ALPHA3: "SLV",
  },
  {
    ID: 61,
    ALPHA2: "ER",
    ALPHA3: "ERI",
  },
  {
    ID: 62,
    ALPHA2: "EE",
    ALPHA3: "EST",
  },
  {
    ID: 64,
    ALPHA2: "FK",
    ALPHA3: "FLK",
  },
  {
    ID: 65,
    ALPHA2: "FO",
    ALPHA3: "FRO",
  },
  {
    ID: 66,
    ALPHA2: "FJ",
    ALPHA3: "FJI",
  },
  {
    ID: 67,
    ALPHA2: "FI",
    ALPHA3: "FIN",
  },
  {
    ID: 68,
    ALPHA2: "FR",
    ALPHA3: "FRA",
  },
  {
    ID: 70,
    ALPHA2: "GF",
    ALPHA3: "GUF",
  },
  {
    ID: 71,
    ALPHA2: "PF",
    ALPHA3: "PYF",
  },
  {
    ID: 72,
    ALPHA2: "TF",
    ALPHA3: "ATF",
  },
  {
    ID: 73,
    ALPHA2: "GA",
    ALPHA3: "GAB",
  },
  {
    ID: 74,
    ALPHA2: "GM",
    ALPHA3: "GMB",
  },
  {
    ID: 75,
    ALPHA2: "GE",
    ALPHA3: "GEO",
  },
  {
    ID: 76,
    ALPHA2: "GH",
    ALPHA3: "GHA",
  },
  {
    ID: 77,
    ALPHA2: "GI",
    ALPHA3: "GIB",
  },
  {
    ID: 78,
    ALPHA2: "GD",
    ALPHA3: "GRD",
  },
  {
    ID: 79,
    ALPHA2: "GR",
    ALPHA3: "GRC",
  },
  {
    ID: 80,
    ALPHA2: "GL",
    ALPHA3: "GRL",
  },
  {
    ID: 81,
    ALPHA2: "GP",
    ALPHA3: "GLP",
  },
  {
    ID: 82,
    ALPHA2: "GU",
    ALPHA3: "GUM",
  },
  {
    ID: 83,
    ALPHA2: "GT",
    ALPHA3: "GTM",
  },
  {
    ID: 84,
    ALPHA2: "GG",
    ALPHA3: "GGY",
  },
  {
    ID: 85,
    ALPHA2: "GN",
    ALPHA3: "GIN",
  },
  {
    ID: 86,
    ALPHA2: "GW",
    ALPHA3: "GNB",
  },
  {
    ID: 87,
    ALPHA2: "GY",
    ALPHA3: "GUY",
  },
  {
    ID: 88,
    ALPHA2: "HT",
    ALPHA3: "HTI",
  },
  {
    ID: 89,
    ALPHA2: "HM",
    ALPHA3: "HMD",
  },
  {
    ID: 90,
    ALPHA2: "HN",
    ALPHA3: "HND",
  },
  {
    ID: 91,
    ALPHA2: "HK",
    ALPHA3: "HKG",
  },
  {
    ID: 92,
    ALPHA2: "IN",
    ALPHA3: "IND",
  },
  {
    ID: 93,
    ALPHA2: "ID",
    ALPHA3: "IDN",
  },
  {
    ID: 94,
    ALPHA2: "IM",
    ALPHA3: "IMN",
  },
  {
    ID: 95,
    ALPHA2: "IQ",
    ALPHA3: "IRQ",
  },
  {
    ID: 96,
    ALPHA2: "IR",
    ALPHA3: "IRN",
  },
  {
    ID: 97,
    ALPHA2: "IE",
    ALPHA3: "IRL",
  },
  {
    ID: 98,
    ALPHA2: "IS",
    ALPHA3: "ISL",
  },
  {
    ID: 99,
    ALPHA2: "IL",
    ALPHA3: "ISR",
  },
  {
    ID: 100,
    ALPHA2: "IT",
    ALPHA3: "ITA",
  },
  {
    ID: 101,
    ALPHA2: "JM",
    ALPHA3: "JAM",
  },
  {
    ID: 102,
    ALPHA2: "JP",
    ALPHA3: "JPN",
  },
  {
    ID: 103,
    ALPHA2: "YE",
    ALPHA3: "YEM",
  },
  {
    ID: 104,
    ALPHA2: "JE",
    ALPHA3: "JEY",
  },
  {
    ID: 105,
    ALPHA2: "JO",
    ALPHA3: "JOR",
  },
  {
    ID: 106,
    ALPHA2: "KY",
    ALPHA3: "CYM",
  },
  {
    ID: 107,
    ALPHA2: "KH",
    ALPHA3: "KHM",
  },
  {
    ID: 108,
    ALPHA2: "CM",
    ALPHA3: "CMR",
  },
  {
    ID: 109,
    ALPHA2: "CA",
    ALPHA3: "CAN",
  },
  {
    ID: 111,
    ALPHA2: "CV",
    ALPHA3: "CPV",
  },
  {
    ID: 112,
    ALPHA2: "KZ",
    ALPHA3: "KAZ",
  },
  {
    ID: 113,
    ALPHA2: "QA",
    ALPHA3: "QAT",
  },
  {
    ID: 114,
    ALPHA2: "KE",
    ALPHA3: "KEN",
  },
  {
    ID: 115,
    ALPHA2: "KG",
    ALPHA3: "KGZ",
  },
  {
    ID: 116,
    ALPHA2: "KI",
    ALPHA3: "KIR",
  },
  {
    ID: 117,
    ALPHA2: "CC",
    ALPHA3: "CCK",
  },
  {
    ID: 118,
    ALPHA2: "CO",
    ALPHA3: "COL",
  },
  {
    ID: 119,
    ALPHA2: "KM",
    ALPHA3: "COM",
  },
  {
    ID: 120,
    ALPHA2: "CD",
    ALPHA3: "COD",
  },
  {
    ID: 121,
    ALPHA2: "CG",
    ALPHA3: "COG",
  },
  {
    ID: 122,
    ALPHA2: "KP",
    ALPHA3: "PRK",
  },
  {
    ID: 123,
    ALPHA2: "KR",
    ALPHA3: "KOR",
  },
  {
    ID: 124,
    ALPHA2: "HR",
    ALPHA3: "HRV",
  },
  {
    ID: 125,
    ALPHA2: "CU",
    ALPHA3: "CUB",
  },
  {
    ID: 126,
    ALPHA2: "KW",
    ALPHA3: "KWT",
  },
  {
    ID: 127,
    ALPHA2: "LA",
    ALPHA3: "LAO",
  },
  {
    ID: 128,
    ALPHA2: "LS",
    ALPHA3: "LSO",
  },
  {
    ID: 129,
    ALPHA2: "LV",
    ALPHA3: "LVA",
  },
  {
    ID: 130,
    ALPHA2: "LB",
    ALPHA3: "LBN",
  },
  {
    ID: 131,
    ALPHA2: "LR",
    ALPHA3: "LBR",
  },
  {
    ID: 132,
    ALPHA2: "LY",
    ALPHA3: "LBY",
  },
  {
    ID: 133,
    ALPHA2: "LI",
    ALPHA3: "LIE",
  },
  {
    ID: 134,
    ALPHA2: "LT",
    ALPHA3: "LTU",
  },
  {
    ID: 135,
    ALPHA2: "LU",
    ALPHA3: "LUX",
  },
  {
    ID: 136,
    ALPHA2: "MO",
    ALPHA3: "MAC",
  },
  {
    ID: 137,
    ALPHA2: "MG",
    ALPHA3: "MDG",
  },
  {
    ID: 138,
    ALPHA2: "MW",
    ALPHA3: "MWI",
  },
  {
    ID: 139,
    ALPHA2: "MY",
    ALPHA3: "MYS",
  },
  {
    ID: 140,
    ALPHA2: "MV",
    ALPHA3: "MDV",
  },
  {
    ID: 141,
    ALPHA2: "ML",
    ALPHA3: "MLI",
  },
  {
    ID: 142,
    ALPHA2: "MT",
    ALPHA3: "MLT",
  },
  {
    ID: 143,
    ALPHA2: "MA",
    ALPHA3: "MAR",
  },
  {
    ID: 144,
    ALPHA2: "MH",
    ALPHA3: "MHL",
  },
  {
    ID: 145,
    ALPHA2: "MQ",
    ALPHA3: "MTQ",
  },
  {
    ID: 146,
    ALPHA2: "MR",
    ALPHA3: "MRT",
  },
  {
    ID: 147,
    ALPHA2: "MU",
    ALPHA3: "MUS",
  },
  {
    ID: 148,
    ALPHA2: "YT",
    ALPHA3: "MYT",
  },
  {
    ID: 149,
    ALPHA2: "MK",
    ALPHA3: "MKD",
  },
  {
    ID: 150,
    ALPHA2: "MX",
    ALPHA3: "MEX",
  },
  {
    ID: 151,
    ALPHA2: "FM",
    ALPHA3: "FSM",
  },
  {
    ID: 152,
    ALPHA2: "MD",
    ALPHA3: "MDA",
  },
  {
    ID: 153,
    ALPHA2: "MC",
    ALPHA3: "MCO",
  },
  {
    ID: 154,
    ALPHA2: "MN",
    ALPHA3: "MNG",
  },
  {
    ID: 155,
    ALPHA2: "ME",
    ALPHA3: "MNE",
  },
  {
    ID: 156,
    ALPHA2: "MS",
    ALPHA3: "MSR",
  },
  {
    ID: 157,
    ALPHA2: "MZ",
    ALPHA3: "MOZ",
  },
  {
    ID: 158,
    ALPHA2: "MM",
    ALPHA3: "MMR",
  },
  {
    ID: 159,
    ALPHA2: "NA",
    ALPHA3: "NAM",
  },
  {
    ID: 160,
    ALPHA2: "NR",
    ALPHA3: "NRU",
  },
  {
    ID: 161,
    ALPHA2: "NP",
    ALPHA3: "NPL",
  },
  {
    ID: 162,
    ALPHA2: "NC",
    ALPHA3: "NCL",
  },
  {
    ID: 163,
    ALPHA2: "NZ",
    ALPHA3: "NZL",
  },
  {
    ID: 164,
    ALPHA2: "NI",
    ALPHA3: "NIC",
  },
  {
    ID: 165,
    ALPHA2: "NL",
    ALPHA3: "NLD",
  },
  {
    ID: 167,
    ALPHA2: "NE",
    ALPHA3: "NER",
  },
  {
    ID: 168,
    ALPHA2: "NG",
    ALPHA3: "NGA",
  },
  {
    ID: 169,
    ALPHA2: "NU",
    ALPHA3: "NIU",
  },
  {
    ID: 170,
    ALPHA2: "MP",
    ALPHA3: "MNP",
  },
  {
    ID: 171,
    ALPHA2: "NF",
    ALPHA3: "NFK",
  },
  {
    ID: 172,
    ALPHA2: "NO",
    ALPHA3: "NOR",
  },
  {
    ID: 173,
    ALPHA2: "OM",
    ALPHA3: "OMN",
  },
  {
    ID: 174,
    ALPHA2: "TL",
    ALPHA3: "TLS",
  },
  {
    ID: 175,
    ALPHA2: "PK",
    ALPHA3: "PAK",
  },
  {
    ID: 176,
    ALPHA2: "PS",
    ALPHA3: "PSE",
  },
  {
    ID: 177,
    ALPHA2: "PW",
    ALPHA3: "PLW",
  },
  {
    ID: 178,
    ALPHA2: "PA",
    ALPHA3: "PAN",
  },
  {
    ID: 179,
    ALPHA2: "PG",
    ALPHA3: "PNG",
  },
  {
    ID: 180,
    ALPHA2: "PY",
    ALPHA3: "PRY",
  },
  {
    ID: 181,
    ALPHA2: "PE",
    ALPHA3: "PER",
  },
  {
    ID: 182,
    ALPHA2: "PH",
    ALPHA3: "PHL",
  },
  {
    ID: 183,
    ALPHA2: "PN",
    ALPHA3: "PCN",
  },
  {
    ID: 184,
    ALPHA2: "PL",
    ALPHA3: "POL",
  },
  {
    ID: 185,
    ALPHA2: "PT",
    ALPHA3: "PRT",
  },
  {
    ID: 186,
    ALPHA2: "PR",
    ALPHA3: "PRI",
  },
  {
    ID: 187,
    ALPHA2: "RE",
    ALPHA3: "REU",
  },
  {
    ID: 188,
    ALPHA2: "RW",
    ALPHA3: "RWA",
  },
  {
    ID: 189,
    ALPHA2: "RO",
    ALPHA3: "ROU",
  },
  {
    ID: 190,
    ALPHA2: "RU",
    ALPHA3: "RUS",
  },
  {
    ID: 191,
    ALPHA2: "SB",
    ALPHA3: "SLB",
  },
  {
    ID: 192,
    ALPHA2: "BL",
    ALPHA3: "BLM",
  },
  {
    ID: 193,
    ALPHA2: "MF",
    ALPHA3: "MAF",
  },
  {
    ID: 194,
    ALPHA2: "ZM",
    ALPHA3: "ZMB",
  },
  {
    ID: 195,
    ALPHA2: "WS",
    ALPHA3: "WSM",
  },
  {
    ID: 196,
    ALPHA2: "SM",
    ALPHA3: "SMR",
  },
  {
    ID: 197,
    ALPHA2: "ST",
    ALPHA3: "STP",
  },
  {
    ID: 198,
    ALPHA2: "SA",
    ALPHA3: "SAU",
  },
  {
    ID: 199,
    ALPHA2: "SE",
    ALPHA3: "SWE",
  },
  SCHWEIZ,
  {
    ID: 201,
    ALPHA2: "SN",
    ALPHA3: "SEN",
  },
  {
    ID: 202,
    ALPHA2: "RS",
    ALPHA3: "SRB",
  },
  {
    ID: 203,
    ALPHA2: "SC",
    ALPHA3: "SYC",
  },
  {
    ID: 204,
    ALPHA2: "SL",
    ALPHA3: "SLE",
  },
  {
    ID: 205,
    ALPHA2: "ZW",
    ALPHA3: "ZWE",
  },
  {
    ID: 206,
    ALPHA2: "SG",
    ALPHA3: "SGP",
  },
  {
    ID: 207,
    ALPHA2: "SX",
    ALPHA3: "SXM",
  },
  {
    ID: 208,
    ALPHA2: "SK",
    ALPHA3: "SVK",
  },
  {
    ID: 209,
    ALPHA2: "SI",
    ALPHA3: "SVN",
  },
  {
    ID: 210,
    ALPHA2: "SO",
    ALPHA3: "SOM",
  },
  {
    ID: 211,
    ALPHA2: "ES",
    ALPHA3: "ESP",
  },
  {
    ID: 212,
    ALPHA2: "LK",
    ALPHA3: "LKA",
  },
  {
    ID: 213,
    ALPHA2: "SH",
    ALPHA3: "SHN",
  },
  {
    ID: 214,
    ALPHA2: "KN",
    ALPHA3: "KNA",
  },
  {
    ID: 215,
    ALPHA2: "LC",
    ALPHA3: "LCA",
  },
  {
    ID: 216,
    ALPHA2: "PM",
    ALPHA3: "SPM",
  },
  {
    ID: 217,
    ALPHA2: "VC",
    ALPHA3: "VCT",
  },
  {
    ID: 218,
    ALPHA2: "ZA",
    ALPHA3: "ZAF",
  },
  {
    ID: 219,
    ALPHA2: "SD",
    ALPHA3: "SDN",
  },
  {
    ID: 220,
    ALPHA2: "GS",
    ALPHA3: "SGS",
  },
  {
    ID: 221,
    ALPHA2: "SS",
    ALPHA3: "SSD",
  },
  {
    ID: 222,
    ALPHA2: "SR",
    ALPHA3: "SUR",
  },
  {
    ID: 223,
    ALPHA2: "SJ",
    ALPHA3: "SJM",
  },
  {
    ID: 224,
    ALPHA2: "SZ",
    ALPHA3: "SWZ",
  },
  {
    ID: 225,
    ALPHA2: "SY",
    ALPHA3: "SYR",
  },
  {
    ID: 226,
    ALPHA2: "TJ",
    ALPHA3: "TJK",
  },
  {
    ID: 227,
    ALPHA2: "TW",
    ALPHA3: "TWN",
  },
  {
    ID: 228,
    ALPHA2: "TZ",
    ALPHA3: "TZA",
  },
  {
    ID: 229,
    ALPHA2: "TH",
    ALPHA3: "THA",
  },
  {
    ID: 230,
    ALPHA2: "TG",
    ALPHA3: "TGO",
  },
  {
    ID: 231,
    ALPHA2: "TK",
    ALPHA3: "TKL",
  },
  {
    ID: 232,
    ALPHA2: "TO",
    ALPHA3: "TON",
  },
  {
    ID: 233,
    ALPHA2: "TT",
    ALPHA3: "TTO",
  },
  {
    ID: 235,
    ALPHA2: "TD",
    ALPHA3: "TCD",
  },
  {
    ID: 236,
    ALPHA2: "CZ",
    ALPHA3: "CZE",
  },
  {
    ID: 237,
    ALPHA2: "TN",
    ALPHA3: "TUN",
  },
  {
    ID: 238,
    ALPHA2: "TR",
    ALPHA3: "TUR",
  },
  {
    ID: 239,
    ALPHA2: "TM",
    ALPHA3: "TKM",
  },
  {
    ID: 240,
    ALPHA2: "TC",
    ALPHA3: "TCA",
  },
  {
    ID: 241,
    ALPHA2: "TV",
    ALPHA3: "TUV",
  },
  {
    ID: 242,
    ALPHA2: "UG",
    ALPHA3: "UGA",
  },
  {
    ID: 243,
    ALPHA2: "UA",
    ALPHA3: "UKR",
  },
  {
    ID: 244,
    ALPHA2: "UM",
    ALPHA3: "UMI",
  },
  {
    ID: 245,
    ALPHA2: "UY",
    ALPHA3: "URY",
  },
  {
    ID: 246,
    ALPHA2: "UZ",
    ALPHA3: "UZB",
  },
  {
    ID: 247,
    ALPHA2: "VU",
    ALPHA3: "VUT",
  },
  {
    ID: 248,
    ALPHA2: "VA",
    ALPHA3: "VAT",
  },
  {
    ID: 249,
    ALPHA2: "VE",
    ALPHA3: "VEN",
  },
  {
    ID: 250,
    ALPHA2: "AE",
    ALPHA3: "ARE",
  },
  {
    ID: 251,
    ALPHA2: "US",
    ALPHA3: "USA",
  },
  {
    ID: 252,
    ALPHA2: "GB",
    ALPHA3: "GBR",
  },
  {
    ID: 253,
    ALPHA2: "VN",
    ALPHA3: "VNM",
  },
  {
    ID: 254,
    ALPHA2: "WF",
    ALPHA3: "WLF",
  },
  {
    ID: 255,
    ALPHA2: "CX",
    ALPHA3: "CXR",
  },
  {
    ID: 256,
    ALPHA2: "EH",
    ALPHA3: "ESH",
  },
  {
    ID: 257,
    ALPHA2: "CF",
    ALPHA3: "CAF",
  },
  {
    ID: 258,
    ALPHA2: "CY",
    ALPHA3: "CYP",
  },
] as const;
