/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import type { ErrorType } from "@/mutator/frontendApiInstance";
import { frontendApiInstance } from "@/mutator/frontendApiInstance";

import type {
  AddBasketEntryParams,
  AddComponentOptionParams,
  AddContractParams,
  AddContractToCustomerRequest,
  AddOptionParams,
  AddOptionToContractRequest,
  AddTagToSimParams,
  BasicCustomerData,
  Basket,
  BillFile,
  CallbackParams,
  CheckCscLoginResponse,
  CheckPINParams,
  CreateCustomerRequest,
  CreateTagParams,
  CreateTransactionParams,
  DatatransPaymentResponse,
  DatatransRequestDocument,
  DeleteTagParams,
  Device,
  Document,
  DocumentFile,
  DocumentGroup,
  DrBillOverview,
  DrPaymentMean,
  ESimActionResponse,
  ESimInfo,
  GetAvailableSunriseRoamingPackagesParams,
  GetBillFileParams,
  GetCustomerParams,
  GetDrBillsForCustomerParams,
  GetESimInfoParams,
  GetImagesParams,
  GetLabelsParams,
  GetOptionsParams,
  GetProductParams,
  GetProductsParams,
  GetRoamingParams,
  GetSimListParams,
  GetSimParams,
  GetUpdatedBasketParams,
  GetVoiceSettingsParams,
  IdentificationDocumentsRequest,
  IdentificationNotificationRequest,
  IdentificationNotificationResponse,
  IdentificationStatusResponse,
  Image,
  JsonNode,
  ListAvailableOptionsParams,
  MnpData,
  NetworkResetResponse,
  Option,
  OrderRoamingPackageParams,
  PayTransactionParams,
  Product,
  Provider,
  RemoveBasketEntryParams,
  RemoveComponentOptionParams,
  RemoveOptionParams,
  RemoveTagFromSimParams,
  RoamingInfo,
  SaveBasketParams,
  SaveLabelsParams,
  SetAutoRenewParams,
  SetDeviceByIndexParams,
  SetDeviceByUuidParams,
  SetEntryCustomDataParams,
  SetICCIDParams,
  SetMnpDataParams,
  SetPromotionalCodeParams,
  SignUpResponse,
  Sim,
  SimAutorenewResponse,
  SimCardDetail,
  SimCustomerInfo,
  SimOverview,
  SimTagResponse,
  SubscriberOrderResponse,
  SubscriberPackageInfoResponse,
  SubscriberPackageResponse,
  TagOverviewResponse,
  TagResponse,
  TerminateContractRequest,
  UpdateAccountRequest,
  UpdateAccountResponse,
  UpdateAddressRequest,
  UpdateSimDetailInfo,
  UpdateSimParams,
  UpdateTagParams,
  VoiceSettings,
  WorkflowResponse,
} from "./model";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get Billingmail, Reference and note of account
 * @summary Get Billingmail, Reference and note of account
 */
export const getAccountContact = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<UpdateAccountResponse>(
    { url: `/account/billingInfo`, method: "GET" },
    options,
  );
};

export const getGetAccountContactQueryKey = () => {
  return [`/account/billingInfo`] as const;
};

export const getGetAccountContactQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountContact>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAccountContact>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountContactQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAccountContact>>
  > = () => getAccountContact(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountContact>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAccountContactQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountContact>>
>;
export type GetAccountContactQueryError = ErrorType<unknown>;

/**
 * @summary Get Billingmail, Reference and note of account
 */

export function useGetAccountContact<
  TData = Awaited<ReturnType<typeof getAccountContact>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAccountContact>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAccountContactQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update Billingmail, Reference and note of account
 * @summary Update Billingmail, Reference and note of account
 */
export const updateAccountContact = (
  updateAccountRequest: UpdateAccountRequest,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<UpdateAccountResponse>(
    {
      url: `/account/billingInfo`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateAccountRequest,
    },
    options,
  );
};

export const getUpdateAccountContactMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountContact>>,
    TError,
    { data: UpdateAccountRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountContact>>,
  TError,
  { data: UpdateAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountContact>>,
    { data: UpdateAccountRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateAccountContact(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAccountContactMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountContact>>
>;
export type UpdateAccountContactMutationBody = UpdateAccountRequest;
export type UpdateAccountContactMutationError = ErrorType<unknown>;

/**
 * @summary Update Billingmail, Reference and note of account
 */
export const useUpdateAccountContact = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountContact>>,
    TError,
    { data: UpdateAccountRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAccountContact>>,
  TError,
  { data: UpdateAccountRequest },
  TContext
> => {
  const mutationOptions = getUpdateAccountContactMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Persist the basket. If the basket has no ID, then a new entry will be created and an ID will be assigned to it. A customer ID can be set optionally. The last unfinished basket is returned if the customer already has a basket.
 */
export const saveBasket = (
  params?: SaveBasketParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    { url: `/baskets`, method: "POST", params },
    options,
  );
};

export const getSaveBasketMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveBasket>>,
    TError,
    { params?: SaveBasketParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveBasket>>,
  TError,
  { params?: SaveBasketParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveBasket>>,
    { params?: SaveBasketParams }
  > = (props) => {
    const { params } = props ?? {};

    return saveBasket(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveBasketMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveBasket>>
>;

export type SaveBasketMutationError = ErrorType<unknown>;

/**
 * @summary Persist the basket. If the basket has no ID, then a new entry will be created and an ID will be assigned to it. A customer ID can be set optionally. The last unfinished basket is returned if the customer already has a basket.
 */
export const useSaveBasket = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveBasket>>,
    TError,
    { params?: SaveBasketParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveBasket>>,
  TError,
  { params?: SaveBasketParams },
  TContext
> => {
  const mutationOptions = getSaveBasketMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get and update a basket with the given id.
 */
export const getUpdatedBasket = (
  basketId: number,
  params?: GetUpdatedBasketParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    { url: `/baskets/${basketId}`, method: "GET", params },
    options,
  );
};

export const getGetUpdatedBasketQueryKey = (
  basketId: number,
  params?: GetUpdatedBasketParams,
) => {
  return [`/baskets/${basketId}`, ...(params ? [params] : [])] as const;
};

export const getGetUpdatedBasketQueryOptions = <
  TData = Awaited<ReturnType<typeof getUpdatedBasket>>,
  TError = ErrorType<unknown>,
>(
  basketId: number,
  params?: GetUpdatedBasketParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUpdatedBasket>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUpdatedBasketQueryKey(basketId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUpdatedBasket>>
  > = () => getUpdatedBasket(basketId, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!basketId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUpdatedBasket>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUpdatedBasketQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpdatedBasket>>
>;
export type GetUpdatedBasketQueryError = ErrorType<unknown>;

/**
 * @summary Get and update a basket with the given id.
 */

export function useGetUpdatedBasket<
  TData = Awaited<ReturnType<typeof getUpdatedBasket>>,
  TError = ErrorType<unknown>,
>(
  basketId: number,
  params?: GetUpdatedBasketParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUpdatedBasket>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUpdatedBasketQueryOptions(
    basketId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Checks recieved PIN
 */
export const checkPIN = (
  basketId: number,
  index: number,
  params: CheckPINParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/checkPIN/${index}/pin`,
      method: "PUT",
      params,
    },
    options,
  );
};

export const getCheckPINMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkPIN>>,
    TError,
    { basketId: number; index: number; params: CheckPINParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checkPIN>>,
  TError,
  { basketId: number; index: number; params: CheckPINParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checkPIN>>,
    { basketId: number; index: number; params: CheckPINParams }
  > = (props) => {
    const { basketId, index, params } = props ?? {};

    return checkPIN(basketId, index, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CheckPINMutationResult = NonNullable<
  Awaited<ReturnType<typeof checkPIN>>
>;

export type CheckPINMutationError = ErrorType<unknown>;

/**
 * @summary Checks recieved PIN
 */
export const useCheckPIN = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkPIN>>,
    TError,
    { basketId: number; index: number; params: CheckPINParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof checkPIN>>,
  TError,
  { basketId: number; index: number; params: CheckPINParams },
  TContext
> => {
  const mutationOptions = getCheckPINMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Removes a basket entry from the basket and updates its contents
 */
export const removeBasketEntry = (
  basketId: number,
  index: number,
  params?: RemoveBasketEntryParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    { url: `/baskets/${basketId}/entries/${index}`, method: "DELETE", params },
    options,
  );
};

export const getRemoveBasketEntryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeBasketEntry>>,
    TError,
    { basketId: number; index: number; params?: RemoveBasketEntryParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeBasketEntry>>,
  TError,
  { basketId: number; index: number; params?: RemoveBasketEntryParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeBasketEntry>>,
    { basketId: number; index: number; params?: RemoveBasketEntryParams }
  > = (props) => {
    const { basketId, index, params } = props ?? {};

    return removeBasketEntry(basketId, index, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveBasketEntryMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeBasketEntry>>
>;

export type RemoveBasketEntryMutationError = ErrorType<unknown>;

/**
 * @summary Removes a basket entry from the basket and updates its contents
 */
export const useRemoveBasketEntry = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeBasketEntry>>,
    TError,
    { basketId: number; index: number; params?: RemoveBasketEntryParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeBasketEntry>>,
  TError,
  { basketId: number; index: number; params?: RemoveBasketEntryParams },
  TContext
> => {
  const mutationOptions = getRemoveBasketEntryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Checks if PUK belongs to ICCID and adds ICCID to basket .
 */
export const setICCID = (
  basketId: number,
  index: number,
  params: SetICCIDParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/entries/${index}/addICCID`,
      method: "PUT",
      params,
    },
    options,
  );
};

export const getSetICCIDMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setICCID>>,
    TError,
    { basketId: number; index: number; params: SetICCIDParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setICCID>>,
  TError,
  { basketId: number; index: number; params: SetICCIDParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setICCID>>,
    { basketId: number; index: number; params: SetICCIDParams }
  > = (props) => {
    const { basketId, index, params } = props ?? {};

    return setICCID(basketId, index, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetICCIDMutationResult = NonNullable<
  Awaited<ReturnType<typeof setICCID>>
>;

export type SetICCIDMutationError = ErrorType<unknown>;

/**
 * @summary Checks if PUK belongs to ICCID and adds ICCID to basket .
 */
export const useSetICCID = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setICCID>>,
    TError,
    { basketId: number; index: number; params: SetICCIDParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setICCID>>,
  TError,
  { basketId: number; index: number; params: SetICCIDParams },
  TContext
> => {
  const mutationOptions = getSetICCIDMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Removes an option with the ID from the component with the specified ID from the basket entry on the specified index
 */
export const removeComponentOption = (
  basketId: number,
  index: number,
  componentId: number,
  optionId: number,
  params?: RemoveComponentOptionParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/entries/${index}/component/${componentId}/option/${optionId}`,
      method: "DELETE",
      params,
    },
    options,
  );
};

export const getRemoveComponentOptionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeComponentOption>>,
    TError,
    {
      basketId: number;
      index: number;
      componentId: number;
      optionId: number;
      params?: RemoveComponentOptionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeComponentOption>>,
  TError,
  {
    basketId: number;
    index: number;
    componentId: number;
    optionId: number;
    params?: RemoveComponentOptionParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeComponentOption>>,
    {
      basketId: number;
      index: number;
      componentId: number;
      optionId: number;
      params?: RemoveComponentOptionParams;
    }
  > = (props) => {
    const { basketId, index, componentId, optionId, params } = props ?? {};

    return removeComponentOption(
      basketId,
      index,
      componentId,
      optionId,
      params,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveComponentOptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeComponentOption>>
>;

export type RemoveComponentOptionMutationError = ErrorType<unknown>;

/**
 * @summary Removes an option with the ID from the component with the specified ID from the basket entry on the specified index
 */
export const useRemoveComponentOption = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeComponentOption>>,
    TError,
    {
      basketId: number;
      index: number;
      componentId: number;
      optionId: number;
      params?: RemoveComponentOptionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeComponentOption>>,
  TError,
  {
    basketId: number;
    index: number;
    componentId: number;
    optionId: number;
    params?: RemoveComponentOptionParams;
  },
  TContext
> => {
  const mutationOptions = getRemoveComponentOptionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Adds an option with the given ID to the component with the given ID of the basket entry with the specified index
 */
export const addComponentOption = (
  basketId: number,
  index: number,
  componentId: number,
  optionId: number,
  params?: AddComponentOptionParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/entries/${index}/component/${componentId}/option/${optionId}`,
      method: "PUT",
      params,
    },
    options,
  );
};

export const getAddComponentOptionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addComponentOption>>,
    TError,
    {
      basketId: number;
      index: number;
      componentId: number;
      optionId: number;
      params?: AddComponentOptionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addComponentOption>>,
  TError,
  {
    basketId: number;
    index: number;
    componentId: number;
    optionId: number;
    params?: AddComponentOptionParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addComponentOption>>,
    {
      basketId: number;
      index: number;
      componentId: number;
      optionId: number;
      params?: AddComponentOptionParams;
    }
  > = (props) => {
    const { basketId, index, componentId, optionId, params } = props ?? {};

    return addComponentOption(
      basketId,
      index,
      componentId,
      optionId,
      params,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type AddComponentOptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof addComponentOption>>
>;

export type AddComponentOptionMutationError = ErrorType<unknown>;

/**
 * @summary Adds an option with the given ID to the component with the given ID of the basket entry with the specified index
 */
export const useAddComponentOption = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addComponentOption>>,
    TError,
    {
      basketId: number;
      index: number;
      componentId: number;
      optionId: number;
      params?: AddComponentOptionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addComponentOption>>,
  TError,
  {
    basketId: number;
    index: number;
    componentId: number;
    optionId: number;
    params?: AddComponentOptionParams;
  },
  TContext
> => {
  const mutationOptions = getAddComponentOptionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Adds a device to the basket by index and updates its contents
 */
export const setDeviceByIndex = (
  basketId: number,
  index: number,
  deviceId: number,
  params?: SetDeviceByIndexParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/entries/${index}/device/${deviceId}`,
      method: "PUT",
      params,
    },
    options,
  );
};

export const getSetDeviceByIndexMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDeviceByIndex>>,
    TError,
    {
      basketId: number;
      index: number;
      deviceId: number;
      params?: SetDeviceByIndexParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setDeviceByIndex>>,
  TError,
  {
    basketId: number;
    index: number;
    deviceId: number;
    params?: SetDeviceByIndexParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setDeviceByIndex>>,
    {
      basketId: number;
      index: number;
      deviceId: number;
      params?: SetDeviceByIndexParams;
    }
  > = (props) => {
    const { basketId, index, deviceId, params } = props ?? {};

    return setDeviceByIndex(basketId, index, deviceId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetDeviceByIndexMutationResult = NonNullable<
  Awaited<ReturnType<typeof setDeviceByIndex>>
>;

export type SetDeviceByIndexMutationError = ErrorType<unknown>;

/**
 * @summary Adds a device to the basket by index and updates its contents
 */
export const useSetDeviceByIndex = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDeviceByIndex>>,
    TError,
    {
      basketId: number;
      index: number;
      deviceId: number;
      params?: SetDeviceByIndexParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setDeviceByIndex>>,
  TError,
  {
    basketId: number;
    index: number;
    deviceId: number;
    params?: SetDeviceByIndexParams;
  },
  TContext
> => {
  const mutationOptions = getSetDeviceByIndexMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Set mnp data for a basket and entry with the given id.
 */
export const setMnpData = (
  basketId: number,
  index: number,
  mnpData: MnpData,
  params?: SetMnpDataParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/entries/${index}/mnpData`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: mnpData,
      params,
    },
    options,
  );
};

export const getSetMnpDataMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setMnpData>>,
    TError,
    {
      basketId: number;
      index: number;
      data: MnpData;
      params?: SetMnpDataParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setMnpData>>,
  TError,
  { basketId: number; index: number; data: MnpData; params?: SetMnpDataParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setMnpData>>,
    {
      basketId: number;
      index: number;
      data: MnpData;
      params?: SetMnpDataParams;
    }
  > = (props) => {
    const { basketId, index, data, params } = props ?? {};

    return setMnpData(basketId, index, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetMnpDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof setMnpData>>
>;
export type SetMnpDataMutationBody = MnpData;
export type SetMnpDataMutationError = ErrorType<unknown>;

/**
 * @summary Set mnp data for a basket and entry with the given id.
 */
export const useSetMnpData = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setMnpData>>,
    TError,
    {
      basketId: number;
      index: number;
      data: MnpData;
      params?: SetMnpDataParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setMnpData>>,
  TError,
  { basketId: number; index: number; data: MnpData; params?: SetMnpDataParams },
  TContext
> => {
  const mutationOptions = getSetMnpDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Gets the available options for a basket entry for the specified index
 */
export const listAvailableOptions = (
  basketId: number,
  index: number,
  params?: ListAvailableOptionsParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Option[]>(
    {
      url: `/baskets/${basketId}/entries/${index}/options`,
      method: "GET",
      params,
    },
    options,
  );
};

export const getListAvailableOptionsQueryKey = (
  basketId: number,
  index: number,
  params?: ListAvailableOptionsParams,
) => {
  return [
    `/baskets/${basketId}/entries/${index}/options`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListAvailableOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAvailableOptions>>,
  TError = ErrorType<unknown>,
>(
  basketId: number,
  index: number,
  params?: ListAvailableOptionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAvailableOptions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListAvailableOptionsQueryKey(basketId, index, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAvailableOptions>>
  > = () => listAvailableOptions(basketId, index, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!(basketId && index),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listAvailableOptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAvailableOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAvailableOptions>>
>;
export type ListAvailableOptionsQueryError = ErrorType<unknown>;

/**
 * @summary Gets the available options for a basket entry for the specified index
 */

export function useListAvailableOptions<
  TData = Awaited<ReturnType<typeof listAvailableOptions>>,
  TError = ErrorType<unknown>,
>(
  basketId: number,
  index: number,
  params?: ListAvailableOptionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAvailableOptions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getListAvailableOptionsQueryOptions(
    basketId,
    index,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Adds a basket entry to the basket and updates its contents
 */
export const addBasketEntry = (
  basketId: number,
  productId: number,
  params?: AddBasketEntryParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    { url: `/baskets/${basketId}/entries/${productId}`, method: "PUT", params },
    options,
  );
};

export const getAddBasketEntryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addBasketEntry>>,
    TError,
    { basketId: number; productId: number; params?: AddBasketEntryParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addBasketEntry>>,
  TError,
  { basketId: number; productId: number; params?: AddBasketEntryParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addBasketEntry>>,
    { basketId: number; productId: number; params?: AddBasketEntryParams }
  > = (props) => {
    const { basketId, productId, params } = props ?? {};

    return addBasketEntry(basketId, productId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddBasketEntryMutationResult = NonNullable<
  Awaited<ReturnType<typeof addBasketEntry>>
>;

export type AddBasketEntryMutationError = ErrorType<unknown>;

/**
 * @summary Adds a basket entry to the basket and updates its contents
 */
export const useAddBasketEntry = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addBasketEntry>>,
    TError,
    { basketId: number; productId: number; params?: AddBasketEntryParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addBasketEntry>>,
  TError,
  { basketId: number; productId: number; params?: AddBasketEntryParams },
  TContext
> => {
  const mutationOptions = getAddBasketEntryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Set a promotional code for a basket with the given id. If promotional code is empty, a may already set code will be cleared.
 */
export const setPromotionalCode = (
  basketId: number,
  jsonNode: JsonNode,
  params?: SetPromotionalCodeParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/promotionalCode`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: jsonNode,
      params,
    },
    options,
  );
};

export const getSetPromotionalCodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setPromotionalCode>>,
    TError,
    { basketId: number; data: JsonNode; params?: SetPromotionalCodeParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setPromotionalCode>>,
  TError,
  { basketId: number; data: JsonNode; params?: SetPromotionalCodeParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setPromotionalCode>>,
    { basketId: number; data: JsonNode; params?: SetPromotionalCodeParams }
  > = (props) => {
    const { basketId, data, params } = props ?? {};

    return setPromotionalCode(basketId, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetPromotionalCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof setPromotionalCode>>
>;
export type SetPromotionalCodeMutationBody = JsonNode;
export type SetPromotionalCodeMutationError = ErrorType<unknown>;

/**
 * @summary Set a promotional code for a basket with the given id. If promotional code is empty, a may already set code will be cleared.
 */
export const useSetPromotionalCode = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setPromotionalCode>>,
    TError,
    { basketId: number; data: JsonNode; params?: SetPromotionalCodeParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setPromotionalCode>>,
  TError,
  { basketId: number; data: JsonNode; params?: SetPromotionalCodeParams },
  TContext
> => {
  const mutationOptions = getSetPromotionalCodeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Adds custom data to a specific basket entry
 */
export const setEntryCustomData = (
  basketId: number,
  uuid: string,
  jsonNode: JsonNode,
  params?: SetEntryCustomDataParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/${uuid}/customData`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: jsonNode,
      params,
    },
    options,
  );
};

export const getSetEntryCustomDataMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setEntryCustomData>>,
    TError,
    {
      basketId: number;
      uuid: string;
      data: JsonNode;
      params?: SetEntryCustomDataParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setEntryCustomData>>,
  TError,
  {
    basketId: number;
    uuid: string;
    data: JsonNode;
    params?: SetEntryCustomDataParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setEntryCustomData>>,
    {
      basketId: number;
      uuid: string;
      data: JsonNode;
      params?: SetEntryCustomDataParams;
    }
  > = (props) => {
    const { basketId, uuid, data, params } = props ?? {};

    return setEntryCustomData(basketId, uuid, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetEntryCustomDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof setEntryCustomData>>
>;
export type SetEntryCustomDataMutationBody = JsonNode;
export type SetEntryCustomDataMutationError = ErrorType<unknown>;

/**
 * @summary Adds custom data to a specific basket entry
 */
export const useSetEntryCustomData = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setEntryCustomData>>,
    TError,
    {
      basketId: number;
      uuid: string;
      data: JsonNode;
      params?: SetEntryCustomDataParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setEntryCustomData>>,
  TError,
  {
    basketId: number;
    uuid: string;
    data: JsonNode;
    params?: SetEntryCustomDataParams;
  },
  TContext
> => {
  const mutationOptions = getSetEntryCustomDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Adds a device to the basket by uuid and updates its contents
 */
export const setDeviceByUuid = (
  basketId: number,
  uuid: string,
  deviceId: number,
  params?: SetDeviceByUuidParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/${uuid}/device/${deviceId}`,
      method: "PUT",
      params,
    },
    options,
  );
};

export const getSetDeviceByUuidMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDeviceByUuid>>,
    TError,
    {
      basketId: number;
      uuid: string;
      deviceId: number;
      params?: SetDeviceByUuidParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setDeviceByUuid>>,
  TError,
  {
    basketId: number;
    uuid: string;
    deviceId: number;
    params?: SetDeviceByUuidParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setDeviceByUuid>>,
    {
      basketId: number;
      uuid: string;
      deviceId: number;
      params?: SetDeviceByUuidParams;
    }
  > = (props) => {
    const { basketId, uuid, deviceId, params } = props ?? {};

    return setDeviceByUuid(basketId, uuid, deviceId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetDeviceByUuidMutationResult = NonNullable<
  Awaited<ReturnType<typeof setDeviceByUuid>>
>;

export type SetDeviceByUuidMutationError = ErrorType<unknown>;

/**
 * @summary Adds a device to the basket by uuid and updates its contents
 */
export const useSetDeviceByUuid = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDeviceByUuid>>,
    TError,
    {
      basketId: number;
      uuid: string;
      deviceId: number;
      params?: SetDeviceByUuidParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setDeviceByUuid>>,
  TError,
  {
    basketId: number;
    uuid: string;
    deviceId: number;
    params?: SetDeviceByUuidParams;
  },
  TContext
> => {
  const mutationOptions = getSetDeviceByUuidMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Removes an option with the ID from the basket entry with the specified uuid
 */
export const removeOption = (
  basketId: number,
  uuid: string,
  optionId: number,
  params?: RemoveOptionParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    {
      url: `/baskets/${basketId}/${uuid}/option/${optionId}`,
      method: "DELETE",
      params,
    },
    options,
  );
};

export const getRemoveOptionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeOption>>,
    TError,
    {
      basketId: number;
      uuid: string;
      optionId: number;
      params?: RemoveOptionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeOption>>,
  TError,
  {
    basketId: number;
    uuid: string;
    optionId: number;
    params?: RemoveOptionParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeOption>>,
    {
      basketId: number;
      uuid: string;
      optionId: number;
      params?: RemoveOptionParams;
    }
  > = (props) => {
    const { basketId, uuid, optionId, params } = props ?? {};

    return removeOption(basketId, uuid, optionId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveOptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeOption>>
>;

export type RemoveOptionMutationError = ErrorType<unknown>;

/**
 * @summary Removes an option with the ID from the basket entry with the specified uuid
 */
export const useRemoveOption = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeOption>>,
    TError,
    {
      basketId: number;
      uuid: string;
      optionId: number;
      params?: RemoveOptionParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeOption>>,
  TError,
  {
    basketId: number;
    uuid: string;
    optionId: number;
    params?: RemoveOptionParams;
  },
  TContext
> => {
  const mutationOptions = getRemoveOptionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Adds an option with the given ID to the basket entry with the specified uuid
 */
export const addOption = (
  basketId: number,
  uuid: string,
  params: AddOptionParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Basket>(
    { url: `/baskets/${basketId}/${uuid}/options`, method: "PUT", params },
    options,
  );
};

export const getAddOptionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addOption>>,
    TError,
    { basketId: number; uuid: string; params: AddOptionParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addOption>>,
  TError,
  { basketId: number; uuid: string; params: AddOptionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addOption>>,
    { basketId: number; uuid: string; params: AddOptionParams }
  > = (props) => {
    const { basketId, uuid, params } = props ?? {};

    return addOption(basketId, uuid, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddOptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof addOption>>
>;

export type AddOptionMutationError = ErrorType<unknown>;

/**
 * @summary Adds an option with the given ID to the basket entry with the specified uuid
 */
export const useAddOption = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addOption>>,
    TError,
    { basketId: number; uuid: string; params: AddOptionParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addOption>>,
  TError,
  { basketId: number; uuid: string; params: AddOptionParams },
  TContext
> => {
  const mutationOptions = getAddOptionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * get info for bills of customer
 * @summary Get bills for customer (supports pagination)
 */
export const getDrBillsForCustomer = (
  params?: GetDrBillsForCustomerParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<DrBillOverview>(
    { url: `/bill`, method: "GET", params },
    options,
  );
};

export const getGetDrBillsForCustomerQueryKey = (
  params?: GetDrBillsForCustomerParams,
) => {
  return [`/bill`, ...(params ? [params] : [])] as const;
};

export const getGetDrBillsForCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getDrBillsForCustomer>>,
  TError = ErrorType<unknown>,
>(
  params?: GetDrBillsForCustomerParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDrBillsForCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDrBillsForCustomerQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDrBillsForCustomer>>
  > = () => getDrBillsForCustomer(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDrBillsForCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDrBillsForCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDrBillsForCustomer>>
>;
export type GetDrBillsForCustomerQueryError = ErrorType<unknown>;

/**
 * @summary Get bills for customer (supports pagination)
 */

export function useGetDrBillsForCustomer<
  TData = Awaited<ReturnType<typeof getDrBillsForCustomer>>,
  TError = ErrorType<unknown>,
>(
  params?: GetDrBillsForCustomerParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDrBillsForCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDrBillsForCustomerQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get the requested bills for customer used for a .zip
 * @summary Get the bills for a .zip
 */
export const getBillFile = (
  params: GetBillFileParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<BillFile>(
    { url: `/bill/zip`, method: "GET", params },
    options,
  );
};

export const getGetBillFileQueryKey = (params: GetBillFileParams) => {
  return [`/bill/zip`, ...(params ? [params] : [])] as const;
};

export const getGetBillFileQueryOptions = <
  TData = Awaited<ReturnType<typeof getBillFile>>,
  TError = ErrorType<unknown>,
>(
  params: GetBillFileParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBillFile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBillFileQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillFile>>> = () =>
    getBillFile(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBillFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBillFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBillFile>>
>;
export type GetBillFileQueryError = ErrorType<unknown>;

/**
 * @summary Get the bills for a .zip
 */

export function useGetBillFile<
  TData = Awaited<ReturnType<typeof getBillFile>>,
  TError = ErrorType<unknown>,
>(
  params: GetBillFileParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBillFile>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetBillFileQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * get file to bill
 * @summary Get bill file for bill
 */
export const getBillDocument = (
  billId: number,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<BillFile>(
    { url: `/bill/${billId}`, method: "GET" },
    options,
  );
};

export const getGetBillDocumentQueryKey = (billId: number) => {
  return [`/bill/${billId}`] as const;
};

export const getGetBillDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof getBillDocument>>,
  TError = ErrorType<unknown>,
>(
  billId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBillDocument>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBillDocumentQueryKey(billId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBillDocument>>
  > = () => getBillDocument(billId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!billId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBillDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBillDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBillDocument>>
>;
export type GetBillDocumentQueryError = ErrorType<unknown>;

/**
 * @summary Get bill file for bill
 */

export function useGetBillDocument<
  TData = Awaited<ReturnType<typeof getBillDocument>>,
  TError = ErrorType<unknown>,
>(
  billId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBillDocument>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetBillDocumentQueryOptions(billId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Add an option to an existing contract
 * @summary Add an option to an existing contract
 */
export const addOptionToContract = (
  serviceId: number,
  addOptionToContractRequest: AddOptionToContractRequest,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SignUpResponse>(
    {
      url: `/contract/${serviceId}/options`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: addOptionToContractRequest,
    },
    options,
  );
};

export const getAddOptionToContractMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addOptionToContract>>,
    TError,
    { serviceId: number; data: AddOptionToContractRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addOptionToContract>>,
  TError,
  { serviceId: number; data: AddOptionToContractRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addOptionToContract>>,
    { serviceId: number; data: AddOptionToContractRequest }
  > = (props) => {
    const { serviceId, data } = props ?? {};

    return addOptionToContract(serviceId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddOptionToContractMutationResult = NonNullable<
  Awaited<ReturnType<typeof addOptionToContract>>
>;
export type AddOptionToContractMutationBody = AddOptionToContractRequest;
export type AddOptionToContractMutationError = ErrorType<unknown>;

/**
 * @summary Add an option to an existing contract
 */
export const useAddOptionToContract = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addOptionToContract>>,
    TError,
    { serviceId: number; data: AddOptionToContractRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addOptionToContract>>,
  TError,
  { serviceId: number; data: AddOptionToContractRequest },
  TContext
> => {
  const mutationOptions = getAddOptionToContractMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * "Get the basic data of a customer. The customer can be identified by his functional or technical IDs.
 * @summary Get basic data for customer
 */
export const getCustomer = (
  params?: GetCustomerParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<BasicCustomerData>(
    { url: `/customer`, method: "GET", params },
    options,
  );
};

export const getGetCustomerQueryKey = (params?: GetCustomerParams) => {
  return [`/customer`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<void>,
>(
  params?: GetCustomerParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = () =>
    getCustomer(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomer>>
>;
export type GetCustomerQueryError = ErrorType<void>;

/**
 * @summary Get basic data for customer
 */

export function useGetCustomer<
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<void>,
>(
  params?: GetCustomerParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCustomerQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a customer
 */
export const createCustomer = (
  createCustomerRequest: CreateCustomerRequest,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SignUpResponse>(
    {
      url: `/customer`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCustomerRequest,
    },
    options,
  );
};

export const getCreateCustomerMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomer>>,
    TError,
    { data: CreateCustomerRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCustomer>>,
  TError,
  { data: CreateCustomerRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCustomer>>,
    { data: CreateCustomerRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createCustomer(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCustomer>>
>;
export type CreateCustomerMutationBody = CreateCustomerRequest;
export type CreateCustomerMutationError = ErrorType<unknown>;

/**
 * @summary Create a customer
 */
export const useCreateCustomer = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomer>>,
    TError,
    { data: CreateCustomerRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCustomer>>,
  TError,
  { data: CreateCustomerRequest },
  TContext
> => {
  const mutationOptions = getCreateCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update customer main address
 * @summary Update customer main address
 */
export const updateAddress = (
  updateAddressRequest: UpdateAddressRequest,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<void>(
    {
      url: `/customer/address`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateAddressRequest,
    },
    options,
  );
};

export const getUpdateAddressMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAddress>>,
    TError,
    { data: UpdateAddressRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAddress>>,
  TError,
  { data: UpdateAddressRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAddress>>,
    { data: UpdateAddressRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateAddress(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAddressMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAddress>>
>;
export type UpdateAddressMutationBody = UpdateAddressRequest;
export type UpdateAddressMutationError = ErrorType<unknown>;

/**
 * @summary Update customer main address
 */
export const useUpdateAddress = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAddress>>,
    TError,
    { data: UpdateAddressRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAddress>>,
  TError,
  { data: UpdateAddressRequest },
  TContext
> => {
  const mutationOptions = getUpdateAddressMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Check if login is available
 * @summary Check if login is available
 */
export const checkCscLogin = (
  login: string,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<CheckCscLoginResponse>(
    { url: `/customer/checkCscLogin/${login}`, method: "GET" },
    options,
  );
};

export const getCheckCscLoginQueryKey = (login: string) => {
  return [`/customer/checkCscLogin/${login}`] as const;
};

export const getCheckCscLoginQueryOptions = <
  TData = Awaited<ReturnType<typeof checkCscLogin>>,
  TError = ErrorType<unknown>,
>(
  login: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof checkCscLogin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckCscLoginQueryKey(login);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checkCscLogin>>
  > = () => checkCscLogin(login, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!login,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof checkCscLogin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckCscLoginQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkCscLogin>>
>;
export type CheckCscLoginQueryError = ErrorType<unknown>;

/**
 * @summary Check if login is available
 */

export function useCheckCscLogin<
  TData = Awaited<ReturnType<typeof checkCscLogin>>,
  TError = ErrorType<unknown>,
>(
  login: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof checkCscLogin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCheckCscLoginQueryOptions(login, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * add a contract to an existing customer
 * @summary add a contract to an existing customer
 */
export const addContract = (
  addContractToCustomerRequest: AddContractToCustomerRequest,
  params: AddContractParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SignUpResponse>(
    {
      url: `/customer/contracts`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: addContractToCustomerRequest,
      params,
    },
    options,
  );
};

export const getAddContractMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addContract>>,
    TError,
    { data: AddContractToCustomerRequest; params: AddContractParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addContract>>,
  TError,
  { data: AddContractToCustomerRequest; params: AddContractParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addContract>>,
    { data: AddContractToCustomerRequest; params: AddContractParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return addContract(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddContractMutationResult = NonNullable<
  Awaited<ReturnType<typeof addContract>>
>;
export type AddContractMutationBody = AddContractToCustomerRequest;
export type AddContractMutationError = ErrorType<unknown>;

/**
 * @summary add a contract to an existing customer
 */
export const useAddContract = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addContract>>,
    TError,
    { data: AddContractToCustomerRequest; params: AddContractParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addContract>>,
  TError,
  { data: AddContractToCustomerRequest; params: AddContractParams },
  TContext
> => {
  const mutationOptions = getAddContractMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * terminate a contract at an existing customer
 * @summary terminate a contract at an existing customer
 */
export const terminateContract = (
  contractId: number,
  terminateContractRequest: TerminateContractRequest,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<WorkflowResponse>(
    {
      url: `/customer/contracts/${contractId}/termination`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: terminateContractRequest,
    },
    options,
  );
};

export const getTerminateContractMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof terminateContract>>,
    TError,
    { contractId: number; data: TerminateContractRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof terminateContract>>,
  TError,
  { contractId: number; data: TerminateContractRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof terminateContract>>,
    { contractId: number; data: TerminateContractRequest }
  > = (props) => {
    const { contractId, data } = props ?? {};

    return terminateContract(contractId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TerminateContractMutationResult = NonNullable<
  Awaited<ReturnType<typeof terminateContract>>
>;
export type TerminateContractMutationBody = TerminateContractRequest;
export type TerminateContractMutationError = ErrorType<unknown>;

/**
 * @summary terminate a contract at an existing customer
 */
export const useTerminateContract = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof terminateContract>>,
    TError,
    { contractId: number; data: TerminateContractRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof terminateContract>>,
  TError,
  { contractId: number; data: TerminateContractRequest },
  TContext
> => {
  const mutationOptions = getTerminateContractMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Upload customer document
 * @summary Upload customer document
 */
export const uploadDocument = (
  identificationDocumentsRequest: IdentificationDocumentsRequest,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<void>(
    {
      url: `/customer/document`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: identificationDocumentsRequest,
    },
    options,
  );
};

export const getUploadDocumentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDocument>>,
    TError,
    { data: IdentificationDocumentsRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadDocument>>,
  TError,
  { data: IdentificationDocumentsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadDocument>>,
    { data: IdentificationDocumentsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return uploadDocument(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadDocument>>
>;
export type UploadDocumentMutationBody = IdentificationDocumentsRequest;
export type UploadDocumentMutationError = ErrorType<unknown>;

/**
 * @summary Upload customer document
 */
export const useUploadDocument = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDocument>>,
    TError,
    { data: IdentificationDocumentsRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadDocument>>,
  TError,
  { data: IdentificationDocumentsRequest },
  TContext
> => {
  const mutationOptions = getUploadDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * update an existing customer
 * @summary update an existing customer
 */
export const updateCustomer = (
  basicCustomerData: BasicCustomerData,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<BasicCustomerData>(
    {
      url: `/customer/updateCustomer`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: basicCustomerData,
    },
    options,
  );
};

export const getUpdateCustomerMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomer>>,
    TError,
    { data: BasicCustomerData },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCustomer>>,
  TError,
  { data: BasicCustomerData },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCustomer>>,
    { data: BasicCustomerData }
  > = (props) => {
    const { data } = props ?? {};

    return updateCustomer(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomer>>
>;
export type UpdateCustomerMutationBody = BasicCustomerData;
export type UpdateCustomerMutationError = ErrorType<unknown>;

/**
 * @summary update an existing customer
 */
export const useUpdateCustomer = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomer>>,
    TError,
    { data: BasicCustomerData },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCustomer>>,
  TError,
  { data: BasicCustomerData },
  TContext
> => {
  const mutationOptions = getUpdateCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create Transaction
 */
export const createTransaction = (
  params?: CreateTransactionParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<DatatransRequestDocument>(
    { url: `/datatrans`, method: "POST", params },
    options,
  );
};

export const getCreateTransactionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTransaction>>,
    TError,
    { params?: CreateTransactionParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTransaction>>,
  TError,
  { params?: CreateTransactionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTransaction>>,
    { params?: CreateTransactionParams }
  > = (props) => {
    const { params } = props ?? {};

    return createTransaction(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTransaction>>
>;

export type CreateTransactionMutationError = ErrorType<unknown>;

/**
 * @summary Create Transaction
 */
export const useCreateTransaction = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTransaction>>,
    TError,
    { params?: CreateTransactionParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTransaction>>,
  TError,
  { params?: CreateTransactionParams },
  TContext
> => {
  const mutationOptions = getCreateTransactionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Callback
 */
export const callback = (
  callbackBody: string,
  params?: CallbackParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<void>(
    {
      url: `/datatrans/callback`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: callbackBody,
      params,
    },
    options,
  );
};

export const getCallbackMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof callback>>,
    TError,
    { data: string; params?: CallbackParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof callback>>,
  TError,
  { data: string; params?: CallbackParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof callback>>,
    { data: string; params?: CallbackParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return callback(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CallbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof callback>>
>;
export type CallbackMutationBody = string;
export type CallbackMutationError = ErrorType<unknown>;

/**
 * @summary Callback
 */
export const useCallback = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof callback>>,
    TError,
    { data: string; params?: CallbackParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof callback>>,
  TError,
  { data: string; params?: CallbackParams },
  TContext
> => {
  const mutationOptions = getCallbackMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary pay with datatrans, basket required
 */
export const payTransaction = (
  params: PayTransactionParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<DatatransPaymentResponse>(
    { url: `/datatrans/pay`, method: "POST", params },
    options,
  );
};

export const getPayTransactionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof payTransaction>>,
    TError,
    { params: PayTransactionParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof payTransaction>>,
  TError,
  { params: PayTransactionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof payTransaction>>,
    { params: PayTransactionParams }
  > = (props) => {
    const { params } = props ?? {};

    return payTransaction(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PayTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof payTransaction>>
>;

export type PayTransactionMutationError = ErrorType<unknown>;

/**
 * @summary pay with datatrans, basket required
 */
export const usePayTransaction = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof payTransaction>>,
    TError,
    { params: PayTransactionParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof payTransaction>>,
  TError,
  { params: PayTransactionParams },
  TContext
> => {
  const mutationOptions = getPayTransactionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Gets list of devices
 */
export const getDevices = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Device[]>(
    { url: `/devices`, method: "GET" },
    options,
  );
};

export const getGetDevicesQueryKey = () => {
  return [`/devices`] as const;
};

export const getGetDevicesQueryOptions = <
  TData = Awaited<ReturnType<typeof getDevices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDevices>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDevicesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDevices>>> = () =>
    getDevices(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDevices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDevicesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDevices>>
>;
export type GetDevicesQueryError = ErrorType<unknown>;

/**
 * @summary Gets list of devices
 */

export function useGetDevices<
  TData = Awaited<ReturnType<typeof getDevices>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDevices>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDevicesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * get info for documents and bills of customer
 * @summary Get documents for customer
 */
export const getDocumentsForCustomer = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<DocumentGroup[]>(
    { url: `/document`, method: "GET" },
    options,
  );
};

export const getGetDocumentsForCustomerQueryKey = () => {
  return [`/document`] as const;
};

export const getGetDocumentsForCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentsForCustomer>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentsForCustomer>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentsForCustomerQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDocumentsForCustomer>>
  > = () => getDocumentsForCustomer(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentsForCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDocumentsForCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentsForCustomer>>
>;
export type GetDocumentsForCustomerQueryError = ErrorType<unknown>;

/**
 * @summary Get documents for customer
 */

export function useGetDocumentsForCustomer<
  TData = Awaited<ReturnType<typeof getDocumentsForCustomer>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentsForCustomer>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDocumentsForCustomerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * get info for documents of service
 * @summary Get documents for service
 */
export const getDocumentsForService = (
  serviceId: string,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Document[]>(
    { url: `/document/service/${serviceId}`, method: "GET" },
    options,
  );
};

export const getGetDocumentsForServiceQueryKey = (serviceId: string) => {
  return [`/document/service/${serviceId}`] as const;
};

export const getGetDocumentsForServiceQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentsForService>>,
  TError = ErrorType<unknown>,
>(
  serviceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentsForService>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentsForServiceQueryKey(serviceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDocumentsForService>>
  > = () => getDocumentsForService(serviceId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!serviceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentsForService>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDocumentsForServiceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentsForService>>
>;
export type GetDocumentsForServiceQueryError = ErrorType<unknown>;

/**
 * @summary Get documents for service
 */

export function useGetDocumentsForService<
  TData = Awaited<ReturnType<typeof getDocumentsForService>>,
  TError = ErrorType<unknown>,
>(
  serviceId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentsForService>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDocumentsForServiceQueryOptions(
    serviceId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * get document file (no bill)
 * @summary Get document file
 */
export const getDocument = (
  documentId: number,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<DocumentFile>(
    { url: `/document/${documentId}`, method: "GET" },
    options,
  );
};

export const getGetDocumentQueryKey = (documentId: number) => {
  return [`/document/${documentId}`] as const;
};

export const getGetDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocument>>,
  TError = ErrorType<unknown>,
>(
  documentId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocument>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentQueryKey(documentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocument>>> = () =>
    getDocument(documentId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!documentId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocument>>
>;
export type GetDocumentQueryError = ErrorType<unknown>;

/**
 * @summary Get document file
 */

export function useGetDocument<
  TData = Awaited<ReturnType<typeof getDocument>>,
  TError = ErrorType<unknown>,
>(
  documentId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocument>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetDocumentQueryOptions(documentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * notification of the current state of the PXL process of a user
 * @summary Endpoint for receiving PXL notifications
 */
export const postIdentificationNotification = (
  identificationNotificationRequest: IdentificationNotificationRequest,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<IdentificationNotificationResponse>(
    {
      url: `/identification/notification`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: identificationNotificationRequest,
    },
    options,
  );
};

export const getPostIdentificationNotificationMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postIdentificationNotification>>,
    TError,
    { data: IdentificationNotificationRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postIdentificationNotification>>,
  TError,
  { data: IdentificationNotificationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postIdentificationNotification>>,
    { data: IdentificationNotificationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postIdentificationNotification(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostIdentificationNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postIdentificationNotification>>
>;
export type PostIdentificationNotificationMutationBody =
  IdentificationNotificationRequest;
export type PostIdentificationNotificationMutationError = ErrorType<unknown>;

/**
 * @summary Endpoint for receiving PXL notifications
 */
export const usePostIdentificationNotification = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postIdentificationNotification>>,
    TError,
    { data: IdentificationNotificationRequest },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postIdentificationNotification>>,
  TError,
  { data: IdentificationNotificationRequest },
  TContext
> => {
  const mutationOptions =
    getPostIdentificationNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get identification status for a customer
 * @summary Get identification status for a customer
 */
export const getIdentificationStatus = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<IdentificationStatusResponse>(
    { url: `/identification/status`, method: "GET" },
    options,
  );
};

export const getGetIdentificationStatusQueryKey = () => {
  return [`/identification/status`] as const;
};

export const getGetIdentificationStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getIdentificationStatus>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getIdentificationStatus>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIdentificationStatusQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIdentificationStatus>>
  > = () => getIdentificationStatus(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIdentificationStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIdentificationStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIdentificationStatus>>
>;
export type GetIdentificationStatusQueryError = ErrorType<unknown>;

/**
 * @summary Get identification status for a customer
 */

export function useGetIdentificationStatus<
  TData = Awaited<ReturnType<typeof getIdentificationStatus>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getIdentificationStatus>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetIdentificationStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get URL for ID check
 * @summary Get URL for ID check
 */
export const getIdentificationURL = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<string>(
    { url: `/identification/url`, method: "GET" },
    options,
  );
};

export const getGetIdentificationURLQueryKey = () => {
  return [`/identification/url`] as const;
};

export const getGetIdentificationURLQueryOptions = <
  TData = Awaited<ReturnType<typeof getIdentificationURL>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getIdentificationURL>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIdentificationURLQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIdentificationURL>>
  > = () => getIdentificationURL(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIdentificationURL>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIdentificationURLQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIdentificationURL>>
>;
export type GetIdentificationURLQueryError = ErrorType<unknown>;

/**
 * @summary Get URL for ID check
 */

export function useGetIdentificationURL<
  TData = Awaited<ReturnType<typeof getIdentificationURL>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getIdentificationURL>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetIdentificationURLQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get labels for an application
 */
export const getLabels = (
  applicationId: number,
  params?: GetLabelsParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<JsonNode>(
    { url: `/labels/${applicationId}`, method: "GET", params },
    options,
  );
};

export const getGetLabelsQueryKey = (
  applicationId: number,
  params?: GetLabelsParams,
) => {
  return [`/labels/${applicationId}`, ...(params ? [params] : [])] as const;
};

export const getGetLabelsQueryOptions = <
  TData = Awaited<ReturnType<typeof getLabels>>,
  TError = ErrorType<unknown>,
>(
  applicationId: number,
  params?: GetLabelsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLabels>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLabelsQueryKey(applicationId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLabels>>> = () =>
    getLabels(applicationId, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!applicationId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getLabels>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetLabelsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLabels>>
>;
export type GetLabelsQueryError = ErrorType<unknown>;

/**
 * @summary Get labels for an application
 */

export function useGetLabels<
  TData = Awaited<ReturnType<typeof getLabels>>,
  TError = ErrorType<unknown>,
>(
  applicationId: number,
  params?: GetLabelsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLabels>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLabelsQueryOptions(applicationId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Save labels for an application
 */
export const saveLabels = (
  applicationId: number,
  saveLabelsBody: string,
  params?: SaveLabelsParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<void>(
    {
      url: `/labels/${applicationId}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: saveLabelsBody,
      params,
    },
    options,
  );
};

export const getSaveLabelsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveLabels>>,
    TError,
    { applicationId: number; data: string; params?: SaveLabelsParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveLabels>>,
  TError,
  { applicationId: number; data: string; params?: SaveLabelsParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveLabels>>,
    { applicationId: number; data: string; params?: SaveLabelsParams }
  > = (props) => {
    const { applicationId, data, params } = props ?? {};

    return saveLabels(applicationId, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveLabelsMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveLabels>>
>;
export type SaveLabelsMutationBody = string;
export type SaveLabelsMutationError = ErrorType<unknown>;

/**
 * @summary Save labels for an application
 */
export const useSaveLabels = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveLabels>>,
    TError,
    { applicationId: number; data: string; params?: SaveLabelsParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveLabels>>,
  TError,
  { applicationId: number; data: string; params?: SaveLabelsParams },
  TContext
> => {
  const mutationOptions = getSaveLabelsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete payment mean of customer
 * @summary Delete payment mean of customer
 */
export const deletePaymentMean = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<DrPaymentMean>(
    { url: `/paymentMean`, method: "DELETE" },
    options,
  );
};

export const getDeletePaymentMeanMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePaymentMean>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePaymentMean>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePaymentMean>>,
    void
  > = () => {
    return deletePaymentMean(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePaymentMeanMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePaymentMean>>
>;

export type DeletePaymentMeanMutationError = ErrorType<unknown>;

/**
 * @summary Delete payment mean of customer
 */
export const useDeletePaymentMean = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePaymentMean>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePaymentMean>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeletePaymentMeanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get payment mean of customer
 * @summary Get payment mean of customer
 */
export const getPaymentMean = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<DrPaymentMean>(
    { url: `/paymentMean`, method: "GET" },
    options,
  );
};

export const getGetPaymentMeanQueryKey = () => {
  return [`/paymentMean`] as const;
};

export const getGetPaymentMeanQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMean>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMean>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentMeanQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPaymentMean>>
  > = () => getPaymentMean(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMean>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPaymentMeanQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentMean>>
>;
export type GetPaymentMeanQueryError = ErrorType<unknown>;

/**
 * @summary Get payment mean of customer
 */

export function useGetPaymentMean<
  TData = Awaited<ReturnType<typeof getPaymentMean>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMean>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPaymentMeanQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Filter response with one or more request parameter
 * @summary Get products
 */
export const getProducts = (
  params?: GetProductsParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Product[]>(
    { url: `/products`, method: "GET", params },
    options,
  );
};

export const getGetProductsQueryKey = (params?: GetProductsParams) => {
  return [`/products`, ...(params ? [params] : [])] as const;
};

export const getGetProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProducts>>,
  TError = ErrorType<string>,
>(
  params?: GetProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProducts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProducts>>> = () =>
    getProducts(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProducts>>
>;
export type GetProductsQueryError = ErrorType<string>;

/**
 * @summary Get products
 */

export function useGetProducts<
  TData = Awaited<ReturnType<typeof getProducts>>,
  TError = ErrorType<string>,
>(
  params?: GetProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProducts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetProductsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get one product
 */
export const getProduct = (
  id: number,
  params?: GetProductParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Product>(
    { url: `/products/${id}`, method: "GET", params },
    options,
  );
};

export const getGetProductQueryKey = (
  id: number,
  params?: GetProductParams,
) => {
  return [`/products/${id}`, ...(params ? [params] : [])] as const;
};

export const getGetProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getProduct>>,
  TError = ErrorType<string>,
>(
  id: number,
  params?: GetProductParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProduct>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProduct>>> = () =>
    getProduct(id, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProduct>>
>;
export type GetProductQueryError = ErrorType<string>;

/**
 * @summary Get one product
 */

export function useGetProduct<
  TData = Awaited<ReturnType<typeof getProduct>>,
  TError = ErrorType<string>,
>(
  id: number,
  params?: GetProductParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProduct>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetProductQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Filter response with one or more request parameter
 * @summary Get options for product
 */
export const getOptions = (
  id: number,
  params?: GetOptionsParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Option[]>(
    { url: `/products/${id}/options`, method: "GET", params },
    options,
  );
};

export const getGetOptionsQueryKey = (
  id: number,
  params?: GetOptionsParams,
) => {
  return [`/products/${id}/options`, ...(params ? [params] : [])] as const;
};

export const getGetOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getOptions>>,
  TError = ErrorType<string>,
>(
  id: number,
  params?: GetOptionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOptions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOptionsQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOptions>>> = () =>
    getOptions(id, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOptions>>
>;
export type GetOptionsQueryError = ErrorType<string>;

/**
 * @summary Get options for product
 */

export function useGetOptions<
  TData = Awaited<ReturnType<typeof getOptions>>,
  TError = ErrorType<string>,
>(
  id: number,
  params?: GetOptionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOptions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOptionsQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get images for a product
 */
export const getImages = (
  productId: number,
  params?: GetImagesParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Image[]>(
    { url: `/products/${productId}/images`, method: "GET", params },
    options,
  );
};

export const getGetImagesQueryKey = (
  productId: number,
  params?: GetImagesParams,
) => {
  return [
    `/products/${productId}/images`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetImagesQueryOptions = <
  TData = Awaited<ReturnType<typeof getImages>>,
  TError = ErrorType<unknown>,
>(
  productId: number,
  params?: GetImagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getImages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetImagesQueryKey(productId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImages>>> = () =>
    getImages(productId, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!productId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getImages>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetImagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getImages>>
>;
export type GetImagesQueryError = ErrorType<unknown>;

/**
 * @summary Get images for a product
 */

export function useGetImages<
  TData = Awaited<ReturnType<typeof getImages>>,
  TError = ErrorType<unknown>,
>(
  productId: number,
  params?: GetImagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getImages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetImagesQueryOptions(productId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get List of all ONP Providers
 */
export const getOnpProviders = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Provider[]>(
    { url: `/providers/providers`, method: "GET" },
    options,
  );
};

export const getGetOnpProvidersQueryKey = () => {
  return [`/providers/providers`] as const;
};

export const getGetOnpProvidersQueryOptions = <
  TData = Awaited<ReturnType<typeof getOnpProviders>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getOnpProviders>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOnpProvidersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOnpProviders>>
  > = () => getOnpProviders(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOnpProviders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOnpProvidersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOnpProviders>>
>;
export type GetOnpProvidersQueryError = ErrorType<unknown>;

/**
 * @summary Get List of all ONP Providers
 */

export function useGetOnpProviders<
  TData = Awaited<ReturnType<typeof getOnpProviders>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getOnpProviders>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOnpProvidersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary show request logs
 */
export const getRequests = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<string[]>(
    { url: `/requests-log`, method: "GET" },
    options,
  );
};

export const getGetRequestsQueryKey = () => {
  return [`/requests-log`] as const;
};

export const getGetRequestsQueryOptions = <
  TData = Awaited<ReturnType<typeof getRequests>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getRequests>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRequestsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRequests>>> = () =>
    getRequests(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRequests>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRequestsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRequests>>
>;
export type GetRequestsQueryError = ErrorType<unknown>;

/**
 * @summary show request logs
 */

export function useGetRequests<
  TData = Awaited<ReturnType<typeof getRequests>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getRequests>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetRequestsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get URL to clients shop
 */
export const getShopUrl = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<string>(
    { url: `/settings/shopUrl`, method: "GET" },
    options,
  );
};

export const getGetShopUrlQueryKey = () => {
  return [`/settings/shopUrl`] as const;
};

export const getGetShopUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getShopUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getShopUrl>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetShopUrlQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getShopUrl>>> = () =>
    getShopUrl(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getShopUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetShopUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getShopUrl>>
>;
export type GetShopUrlQueryError = ErrorType<unknown>;

/**
 * @summary Get URL to clients shop
 */

export function useGetShopUrl<
  TData = Awaited<ReturnType<typeof getShopUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getShopUrl>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetShopUrlQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Check SIM availability, Precondition: User logged in, User has client
 */
export const checkSIM = (
  iccid: string,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<Sim>(
    { url: `/sim/checkAvailability/${iccid}`, method: "GET" },
    options,
  );
};

export const getCheckSIMQueryKey = (iccid: string) => {
  return [`/sim/checkAvailability/${iccid}`] as const;
};

export const getCheckSIMQueryOptions = <
  TData = Awaited<ReturnType<typeof checkSIM>>,
  TError = ErrorType<unknown>,
>(
  iccid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof checkSIM>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckSIMQueryKey(iccid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkSIM>>> = () =>
    checkSIM(iccid, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!iccid,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof checkSIM>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type CheckSIMQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkSIM>>
>;
export type CheckSIMQueryError = ErrorType<unknown>;

/**
 * @summary Check SIM availability, Precondition: User logged in, User has client
 */

export function useCheckSIM<
  TData = Awaited<ReturnType<typeof checkSIM>>,
  TError = ErrorType<unknown>,
>(
  iccid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof checkSIM>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCheckSIMQueryOptions(iccid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * endpoint for starting an eSIM change workflow for the given sim
 * @summary start eSimChange workflow
 */
export const startESimChange = (
  simId: number,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<ESimActionResponse>(
    { url: `/sim/eSimChange/${simId}`, method: "POST" },
    options,
  );
};

export const getStartESimChangeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startESimChange>>,
    TError,
    { simId: number },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startESimChange>>,
  TError,
  { simId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startESimChange>>,
    { simId: number }
  > = (props) => {
    const { simId } = props ?? {};

    return startESimChange(simId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartESimChangeMutationResult = NonNullable<
  Awaited<ReturnType<typeof startESimChange>>
>;

export type StartESimChangeMutationError = ErrorType<unknown>;

/**
 * @summary start eSimChange workflow
 */
export const useStartESimChange = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startESimChange>>,
    TError,
    { simId: number },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof startESimChange>>,
  TError,
  { simId: number },
  TContext
> => {
  const mutationOptions = getStartESimChangeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * get eSIM info for sims
 * @summary get eSIM info for sims
 */
export const getESimInfo = (
  params: GetESimInfoParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<ESimInfo[]>(
    { url: `/sim/eSimInfo`, method: "GET", params },
    options,
  );
};

export const getGetESimInfoQueryKey = (params: GetESimInfoParams) => {
  return [`/sim/eSimInfo`, ...(params ? [params] : [])] as const;
};

export const getGetESimInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getESimInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetESimInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getESimInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetESimInfoQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getESimInfo>>> = () =>
    getESimInfo(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getESimInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetESimInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getESimInfo>>
>;
export type GetESimInfoQueryError = ErrorType<unknown>;

/**
 * @summary get eSIM info for sims
 */

export function useGetESimInfo<
  TData = Awaited<ReturnType<typeof getESimInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetESimInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getESimInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetESimInfoQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * endpoint for starting an eSIM reset workflow for the given sim
 * @summary start eSimReset workflow
 */
export const startESimReset = (
  simId: number,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<ESimActionResponse>(
    { url: `/sim/eSimReset/${simId}`, method: "POST" },
    options,
  );
};

export const getStartESimResetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startESimReset>>,
    TError,
    { simId: number },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startESimReset>>,
  TError,
  { simId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startESimReset>>,
    { simId: number }
  > = (props) => {
    const { simId } = props ?? {};

    return startESimReset(simId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartESimResetMutationResult = NonNullable<
  Awaited<ReturnType<typeof startESimReset>>
>;

export type StartESimResetMutationError = ErrorType<unknown>;

/**
 * @summary start eSimReset workflow
 */
export const useStartESimReset = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startESimReset>>,
    TError,
    { simId: number },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof startESimReset>>,
  TError,
  { simId: number },
  TContext
> => {
  const mutationOptions = getStartESimResetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * endpoint for starting an network reset workflow for the given sim
 * @summary start network reset workflow
 */
export const startNetworkReset = (
  simId: number,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<NetworkResetResponse>(
    { url: `/sim/networkReset/${simId}`, method: "POST" },
    options,
  );
};

export const getStartNetworkResetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startNetworkReset>>,
    TError,
    { simId: number },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startNetworkReset>>,
  TError,
  { simId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startNetworkReset>>,
    { simId: number }
  > = (props) => {
    const { simId } = props ?? {};

    return startNetworkReset(simId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartNetworkResetMutationResult = NonNullable<
  Awaited<ReturnType<typeof startNetworkReset>>
>;

export type StartNetworkResetMutationError = ErrorType<unknown>;

/**
 * @summary start network reset workflow
 */
export const useStartNetworkReset = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startNetworkReset>>,
    TError,
    { simId: number },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof startNetworkReset>>,
  TError,
  { simId: number },
  TContext
> => {
  const mutationOptions = getStartNetworkResetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * get roaming data for sim cards
 * @summary get roaming data
 */
export const getRoaming = (
  params: GetRoamingParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<RoamingInfo[]>(
    { url: `/sim/roamingInfo`, method: "GET", params },
    options,
  );
};

export const getGetRoamingQueryKey = (params: GetRoamingParams) => {
  return [`/sim/roamingInfo`, ...(params ? [params] : [])] as const;
};

export const getGetRoamingQueryOptions = <
  TData = Awaited<ReturnType<typeof getRoaming>>,
  TError = ErrorType<unknown>,
>(
  params: GetRoamingParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRoaming>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRoamingQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoaming>>> = () =>
    getRoaming(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRoaming>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRoamingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRoaming>>
>;
export type GetRoamingQueryError = ErrorType<unknown>;

/**
 * @summary get roaming data
 */

export function useGetRoaming<
  TData = Awaited<ReturnType<typeof getRoaming>>,
  TError = ErrorType<unknown>,
>(
  params: GetRoamingParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRoaming>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetRoamingQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * get details of a single sim
 * @summary Get simdetail
 */
export const getSim = (
  params: GetSimParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SimCardDetail>(
    { url: `/sim/sim`, method: "GET", params },
    options,
  );
};

export const getGetSimQueryKey = (params: GetSimParams) => {
  return [`/sim/sim`, ...(params ? [params] : [])] as const;
};

export const getGetSimQueryOptions = <
  TData = Awaited<ReturnType<typeof getSim>>,
  TError = ErrorType<unknown>,
>(
  params: GetSimParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSim>>, TError, TData>;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSimQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSim>>> = () =>
    getSim(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSim>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSimQueryResult = NonNullable<Awaited<ReturnType<typeof getSim>>>;
export type GetSimQueryError = ErrorType<unknown>;

/**
 * @summary Get simdetail
 */

export function useGetSim<
  TData = Awaited<ReturnType<typeof getSim>>,
  TError = ErrorType<unknown>,
>(
  params: GetSimParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSim>>, TError, TData>;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSimQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * update details of a single sim
 * @summary Update simdetail
 */
export const updateSim = (
  updateSimDetailInfo: UpdateSimDetailInfo,
  params: UpdateSimParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SimCardDetail>(
    {
      url: `/sim/sim`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateSimDetailInfo,
      params,
    },
    options,
  );
};

export const getUpdateSimMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSim>>,
    TError,
    { data: UpdateSimDetailInfo; params: UpdateSimParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSim>>,
  TError,
  { data: UpdateSimDetailInfo; params: UpdateSimParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSim>>,
    { data: UpdateSimDetailInfo; params: UpdateSimParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return updateSim(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSimMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSim>>
>;
export type UpdateSimMutationBody = UpdateSimDetailInfo;
export type UpdateSimMutationError = ErrorType<unknown>;

/**
 * @summary Update simdetail
 */
export const useUpdateSim = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSim>>,
    TError,
    { data: UpdateSimDetailInfo; params: UpdateSimParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateSim>>,
  TError,
  { data: UpdateSimDetailInfo; params: UpdateSimParams },
  TContext
> => {
  const mutationOptions = getUpdateSimMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary get info on whether a customer can perform certain actions for sims
 */
export const getSimCustomerActions = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SimCustomerInfo>(
    { url: `/sim/simCustomerActions`, method: "GET" },
    options,
  );
};

export const getGetSimCustomerActionsQueryKey = () => {
  return [`/sim/simCustomerActions`] as const;
};

export const getGetSimCustomerActionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSimCustomerActions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSimCustomerActions>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSimCustomerActionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSimCustomerActions>>
  > = () => getSimCustomerActions(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSimCustomerActions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSimCustomerActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSimCustomerActions>>
>;
export type GetSimCustomerActionsQueryError = ErrorType<unknown>;

/**
 * @summary get info on whether a customer can perform certain actions for sims
 */

export function useGetSimCustomerActions<
  TData = Awaited<ReturnType<typeof getSimCustomerActions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSimCustomerActions>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSimCustomerActionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * get list of all sim cards of a customer
 * @summary Get list view dashboard (supports pagination)
 */
export const getSimList = (
  params?: GetSimListParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SimOverview>(
    { url: `/sim/sims`, method: "GET", params },
    options,
  );
};

export const getGetSimListQueryKey = (params?: GetSimListParams) => {
  return [`/sim/sims`, ...(params ? [params] : [])] as const;
};

export const getGetSimListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getSimList>>,
  TError = ErrorType<unknown>,
>(
  params?: GetSimListParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSimList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSimListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSimList>>> = ({
    pageParam,
  }) =>
    getSimList(
      { ...params, page: pageParam || params?.["page"] },
      requestOptions,
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getSimList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSimListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSimList>>
>;
export type GetSimListInfiniteQueryError = ErrorType<unknown>;

/**
 * @summary Get list view dashboard (supports pagination)
 */

export function useGetSimListInfinite<
  TData = Awaited<ReturnType<typeof getSimList>>,
  TError = ErrorType<unknown>,
>(
  params?: GetSimListParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSimList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSimListInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetSimListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSimList>>,
  TError = ErrorType<unknown>,
>(
  params?: GetSimListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSimList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSimListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSimList>>> = () =>
    getSimList(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSimList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSimListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSimList>>
>;
export type GetSimListQueryError = ErrorType<unknown>;

/**
 * @summary Get list view dashboard (supports pagination)
 */

export function useGetSimList<
  TData = Awaited<ReturnType<typeof getSimList>>,
  TError = ErrorType<unknown>,
>(
  params?: GetSimListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSimList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSimListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get available subscription packages
 * @summary Get available subscription packages
 */
export const getAvailableSunriseRoamingPackages = (
  simId: number,
  params: GetAvailableSunriseRoamingPackagesParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SubscriberPackageResponse>(
    {
      url: `/sim/subscription/${simId}/availablePackages`,
      method: "GET",
      params,
    },
    options,
  );
};

export const getGetAvailableSunriseRoamingPackagesQueryKey = (
  simId: number,
  params: GetAvailableSunriseRoamingPackagesParams,
) => {
  return [
    `/sim/subscription/${simId}/availablePackages`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAvailableSunriseRoamingPackagesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAvailableSunriseRoamingPackages>>,
  TError = ErrorType<unknown>,
>(
  simId: number,
  params: GetAvailableSunriseRoamingPackagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvailableSunriseRoamingPackages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAvailableSunriseRoamingPackagesQueryKey(simId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAvailableSunriseRoamingPackages>>
  > = () => getAvailableSunriseRoamingPackages(simId, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!simId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableSunriseRoamingPackages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAvailableSunriseRoamingPackagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableSunriseRoamingPackages>>
>;
export type GetAvailableSunriseRoamingPackagesQueryError = ErrorType<unknown>;

/**
 * @summary Get available subscription packages
 */

export function useGetAvailableSunriseRoamingPackages<
  TData = Awaited<ReturnType<typeof getAvailableSunriseRoamingPackages>>,
  TError = ErrorType<unknown>,
>(
  simId: number,
  params: GetAvailableSunriseRoamingPackagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvailableSunriseRoamingPackages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAvailableSunriseRoamingPackagesQueryOptions(
    simId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * change autoRenew setting. for given simId. Will only change AR for watch when watchId is given
 * @summary change autoRenew setting
 */
export const setAutoRenew = (
  simId: number,
  params: SetAutoRenewParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SimAutorenewResponse>(
    {
      url: `/sim/subscription/${simId}/changeAutoRenew`,
      method: "POST",
      params,
    },
    options,
  );
};

export const getSetAutoRenewMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAutoRenew>>,
    TError,
    { simId: number; params: SetAutoRenewParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAutoRenew>>,
  TError,
  { simId: number; params: SetAutoRenewParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAutoRenew>>,
    { simId: number; params: SetAutoRenewParams }
  > = (props) => {
    const { simId, params } = props ?? {};

    return setAutoRenew(simId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAutoRenewMutationResult = NonNullable<
  Awaited<ReturnType<typeof setAutoRenew>>
>;

export type SetAutoRenewMutationError = ErrorType<unknown>;

/**
 * @summary change autoRenew setting
 */
export const useSetAutoRenew = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAutoRenew>>,
    TError,
    { simId: number; params: SetAutoRenewParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAutoRenew>>,
  TError,
  { simId: number; params: SetAutoRenewParams },
  TContext
> => {
  const mutationOptions = getSetAutoRenewMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * get basket for adding a new package
 * @summary get basket for adding a new package
 */
export const orderRoamingPackage = (
  simId: number,
  params: OrderRoamingPackageParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SubscriberOrderResponse>(
    { url: `/sim/subscription/${simId}/orderPackages`, method: "GET", params },
    options,
  );
};

export const getOrderRoamingPackageQueryKey = (
  simId: number,
  params: OrderRoamingPackageParams,
) => {
  return [
    `/sim/subscription/${simId}/orderPackages`,
    ...(params ? [params] : []),
  ] as const;
};

export const getOrderRoamingPackageQueryOptions = <
  TData = Awaited<ReturnType<typeof orderRoamingPackage>>,
  TError = ErrorType<unknown>,
>(
  simId: number,
  params: OrderRoamingPackageParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof orderRoamingPackage>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrderRoamingPackageQueryKey(simId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof orderRoamingPackage>>
  > = () => orderRoamingPackage(simId, params, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!simId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof orderRoamingPackage>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrderRoamingPackageQueryResult = NonNullable<
  Awaited<ReturnType<typeof orderRoamingPackage>>
>;
export type OrderRoamingPackageQueryError = ErrorType<unknown>;

/**
 * @summary get basket for adding a new package
 */

export function useOrderRoamingPackage<
  TData = Awaited<ReturnType<typeof orderRoamingPackage>>,
  TError = ErrorType<unknown>,
>(
  simId: number,
  params: OrderRoamingPackageParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof orderRoamingPackage>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrderRoamingPackageQueryOptions(
    simId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get subscription package info for a sim
 * @summary Get subscription package info for a sim
 */
export const getSubscription = (
  simId: number,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SubscriberPackageInfoResponse>(
    { url: `/sim/subscription/${simId}/packages`, method: "GET" },
    options,
  );
};

export const getGetSubscriptionQueryKey = (simId: number) => {
  return [`/sim/subscription/${simId}/packages`] as const;
};

export const getGetSubscriptionQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(
  simId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSubscription>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionQueryKey(simId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSubscription>>
  > = () => getSubscription(simId, requestOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!simId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscription>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscription>>
>;
export type GetSubscriptionQueryError = ErrorType<unknown>;

/**
 * @summary Get subscription package info for a sim
 */

export function useGetSubscription<
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(
  simId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSubscription>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSubscriptionQueryOptions(simId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * get voice settings for a single sim
 * @summary get voice settings for a single sim
 */
export const getVoiceSettings = (
  params: GetVoiceSettingsParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<VoiceSettings>(
    { url: `/sim/voiceSettings`, method: "GET", params },
    options,
  );
};

export const getGetVoiceSettingsQueryKey = (params: GetVoiceSettingsParams) => {
  return [`/sim/voiceSettings`, ...(params ? [params] : [])] as const;
};

export const getGetVoiceSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getVoiceSettings>>,
  TError = ErrorType<unknown>,
>(
  params: GetVoiceSettingsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVoiceSettings>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetVoiceSettingsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getVoiceSettings>>
  > = () => getVoiceSettings(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getVoiceSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetVoiceSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVoiceSettings>>
>;
export type GetVoiceSettingsQueryError = ErrorType<unknown>;

/**
 * @summary get voice settings for a single sim
 */

export function useGetVoiceSettings<
  TData = Awaited<ReturnType<typeof getVoiceSettings>>,
  TError = ErrorType<unknown>,
>(
  params: GetVoiceSettingsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVoiceSettings>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof frontendApiInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetVoiceSettingsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * update voice settings for a single sim
 * @summary Update voice settings for a single sim
 */
export const updateVoiceSettings = (
  voiceSettings: VoiceSettings,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<VoiceSettings>(
    {
      url: `/sim/voiceSettings`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: voiceSettings,
    },
    options,
  );
};

export const getUpdateVoiceSettingsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateVoiceSettings>>,
    TError,
    { data: VoiceSettings },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateVoiceSettings>>,
  TError,
  { data: VoiceSettings },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateVoiceSettings>>,
    { data: VoiceSettings }
  > = (props) => {
    const { data } = props ?? {};

    return updateVoiceSettings(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateVoiceSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateVoiceSettings>>
>;
export type UpdateVoiceSettingsMutationBody = VoiceSettings;
export type UpdateVoiceSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Update voice settings for a single sim
 */
export const useUpdateVoiceSettings = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateVoiceSettings>>,
    TError,
    { data: VoiceSettings },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateVoiceSettings>>,
  TError,
  { data: VoiceSettings },
  TContext
> => {
  const mutationOptions = getUpdateVoiceSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary get all Tags for a customer
 */
export const getTags = (
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<TagOverviewResponse>(
    { url: `/tag`, method: "GET" },
    options,
  );
};

export const getGetTagsQueryKey = () => {
  return [`/tag`] as const;
};

export const getGetTagsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTags>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTags>>, TError, TData>;
  request?: SecondParameter<typeof frontendApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTags>>> = () =>
    getTags(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTags>>
>;
export type GetTagsQueryError = ErrorType<unknown>;

/**
 * @summary get all Tags for a customer
 */

export function useGetTags<
  TData = Awaited<ReturnType<typeof getTags>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTags>>, TError, TData>;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Add Tag to Sim
 */
export const addTagToSim = (
  params: AddTagToSimParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SimTagResponse>(
    { url: `/tag/addTagToSim`, method: "POST", params },
    options,
  );
};

export const getAddTagToSimMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTagToSim>>,
    TError,
    { params: AddTagToSimParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addTagToSim>>,
  TError,
  { params: AddTagToSimParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addTagToSim>>,
    { params: AddTagToSimParams }
  > = (props) => {
    const { params } = props ?? {};

    return addTagToSim(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddTagToSimMutationResult = NonNullable<
  Awaited<ReturnType<typeof addTagToSim>>
>;

export type AddTagToSimMutationError = ErrorType<unknown>;

/**
 * @summary Add Tag to Sim
 */
export const useAddTagToSim = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTagToSim>>,
    TError,
    { params: AddTagToSimParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addTagToSim>>,
  TError,
  { params: AddTagToSimParams },
  TContext
> => {
  const mutationOptions = getAddTagToSimMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create Tag
 */
export const createTag = (
  params: CreateTagParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<TagResponse>(
    { url: `/tag/createTag`, method: "POST", params },
    options,
  );
};

export const getCreateTagMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTag>>,
    TError,
    { params: CreateTagParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTag>>,
  TError,
  { params: CreateTagParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTag>>,
    { params: CreateTagParams }
  > = (props) => {
    const { params } = props ?? {};

    return createTag(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTag>>
>;

export type CreateTagMutationError = ErrorType<unknown>;

/**
 * @summary Create Tag
 */
export const useCreateTag = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTag>>,
    TError,
    { params: CreateTagParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTag>>,
  TError,
  { params: CreateTagParams },
  TContext
> => {
  const mutationOptions = getCreateTagMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Tag
 */
export const deleteTag = (
  params: DeleteTagParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<TagResponse>(
    { url: `/tag/deleteTag`, method: "DELETE", params },
    options,
  );
};

export const getDeleteTagMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTag>>,
    TError,
    { params: DeleteTagParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTag>>,
  TError,
  { params: DeleteTagParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTag>>,
    { params: DeleteTagParams }
  > = (props) => {
    const { params } = props ?? {};

    return deleteTag(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTag>>
>;

export type DeleteTagMutationError = ErrorType<unknown>;

/**
 * @summary Delete Tag
 */
export const useDeleteTag = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTag>>,
    TError,
    { params: DeleteTagParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTag>>,
  TError,
  { params: DeleteTagParams },
  TContext
> => {
  const mutationOptions = getDeleteTagMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove Tag from Sim
 */
export const removeTagFromSim = (
  params: RemoveTagFromSimParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<SimTagResponse>(
    { url: `/tag/removeTagFromSim`, method: "DELETE", params },
    options,
  );
};

export const getRemoveTagFromSimMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeTagFromSim>>,
    TError,
    { params: RemoveTagFromSimParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeTagFromSim>>,
  TError,
  { params: RemoveTagFromSimParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeTagFromSim>>,
    { params: RemoveTagFromSimParams }
  > = (props) => {
    const { params } = props ?? {};

    return removeTagFromSim(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveTagFromSimMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeTagFromSim>>
>;

export type RemoveTagFromSimMutationError = ErrorType<unknown>;

/**
 * @summary Remove Tag from Sim
 */
export const useRemoveTagFromSim = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeTagFromSim>>,
    TError,
    { params: RemoveTagFromSimParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeTagFromSim>>,
  TError,
  { params: RemoveTagFromSimParams },
  TContext
> => {
  const mutationOptions = getRemoveTagFromSimMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update a Tag
 */
export const updateTag = (
  params: UpdateTagParams,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<TagResponse>(
    { url: `/tag/updateTag`, method: "PUT", params },
    options,
  );
};

export const getUpdateTagMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTag>>,
    TError,
    { params: UpdateTagParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTag>>,
  TError,
  { params: UpdateTagParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTag>>,
    { params: UpdateTagParams }
  > = (props) => {
    const { params } = props ?? {};

    return updateTag(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTag>>
>;

export type UpdateTagMutationError = ErrorType<unknown>;

/**
 * @summary Update a Tag
 */
export const useUpdateTag = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTag>>,
    TError,
    { params: UpdateTagParams },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTag>>,
  TError,
  { params: UpdateTagParams },
  TContext
> => {
  const mutationOptions = getUpdateTagMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sends PIN to entered MSISDN
 */
export const sendSMS = (
  msisdn: string,
  options?: SecondParameter<typeof frontendApiInstance>,
) => {
  return frontendApiInstance<number>(
    { url: `/validation/sendSMS/${msisdn}`, method: "PUT" },
    options,
  );
};

export const getSendSMSMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendSMS>>,
    TError,
    { msisdn: string },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendSMS>>,
  TError,
  { msisdn: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendSMS>>,
    { msisdn: string }
  > = (props) => {
    const { msisdn } = props ?? {};

    return sendSMS(msisdn, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendSMSMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendSMS>>
>;

export type SendSMSMutationError = ErrorType<unknown>;

/**
 * @summary Sends PIN to entered MSISDN
 */
export const useSendSMS = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendSMS>>,
    TError,
    { msisdn: string },
    TContext
  >;
  request?: SecondParameter<typeof frontendApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendSMS>>,
  TError,
  { msisdn: string },
  TContext
> => {
  const mutationOptions = getSendSMSMutationOptions(options);

  return useMutation(mutationOptions);
};
