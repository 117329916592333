import "./PersonalDetails.scss";

import { Tab } from "@headlessui/react";
import clsx from "clsx";
import React, { useContext, useLayoutEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FCC } from "types";

import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import {
  AuthAndPersonalDetailsFormFields,
  CustomerTypeAndSegment,
} from "@/components/Onboarding/Steps/PersonalDetails/personalDetails.types.ts";
import { TermsAgreementField } from "@/components/Onboarding/Steps/PersonalDetails/TermsAndConditionsField.tsx";
import { useHandleAnonymousUserSubmit } from "@/components/Onboarding/Steps/PersonalDetails/useHandleAnonymousUserSubmit";
import { useHandleUpdateCustomer } from "@/components/Onboarding/Steps/PersonalDetails/useHandleUpdateCustomer.ts";
import { usePersonalDetailsForm } from "@/components/Onboarding/Steps/PersonalDetails/usePersonalDetailsForm.ts";
import { Step } from "@/components/Onboarding/Steps/Step";
import { TwoFactorCodeModal } from "@/components/Portal/UserAccount/Security/TwoFactorCodeModal.tsx";
import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { DataContext } from "@/provider/DataContextProvider";
import {
  BasicCustomerData,
  BasicCustomerDataTwoFactorMethod,
} from "@/services/model";
import { getEntry } from "@/utils/dataContextHelpers";

import { AuthFormFields } from "./AuthFormFields";
import {
  excludedFieldsBusiness,
  excludedFieldsBusinessAnonymous,
  excludedFieldsPrivate,
  excludedFieldsPrivateAnonymous,
  PersonalDetailsFormFields,
} from "./PersonalDetailsFormFields/PersonalDetailsFormFields";
import { useCustomerData } from "./useCustomerState";

/**
 * PersonalDetails Component
 *
 * This component handles the personal details step in the onboarding process.
 * It manages form state for both private and business customers, handles
 * form submission for new and existing users, and navigates to the next step
 * upon successful submission.
 *
 * Key features:
 * - Switches between private and business customer forms
 * - Handles form state and validation
 * - Manages login and account creation in the eSIM Journey
 * - Submits user data to update or create customer profiles
 */
export const PersonalDetails: FCC = () => {
  const { dataContext, setDataContext } = useContext(DataContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAnonymousUser] = useIsAnonymousUser();

  const {
    isExistingAccount,
    setIsExistingAccount,
    twoFactorMethod,
    setTwoFactorMethod,
    selectedCustomerTypeTabIndex,
    setSelectedCustomerTypeTabIndex,
    customerData,
    isLoadingCustomer,
  } = useCustomerData();

  const { formMethods, formValues } = usePersonalDetailsForm();

  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);

  const isValid = customerData && formValues && formMethods.formState.isValid;

  const persistCustomerAndGoToCheckout = (
    customerData: BasicCustomerData,
    target = "/onboarding/5",
  ) => {
    setDataContext((prev) => ({
      ...prev,
      allowedMaxOnboardingStep: 5,
      customerData: customerData,
    }));
    navigate(target);
  };

  const { handleCustomerUpdate, isUpdatingCustomer } = useHandleUpdateCustomer(
    persistCustomerAndGoToCheckout,
    formMethods,
    formValues,
  );

  const { handleAnonymousUser } = useHandleAnonymousUserSubmit(
    isExistingAccount,
    formMethods,
    formValues,
    persistCustomerAndGoToCheckout,
    handleCustomerUpdate,
  );

  const navigateToNext = async (
    formValues: AuthAndPersonalDetailsFormFields,
  ) => {
    const customerTypeAndCustomerSegment: CustomerTypeAndSegment = {
      // ...If we have a business customer (organizationName is set),
      ...(formValues.organizationName !== undefined
        ? // ... we want to create the customer as customerType: 2, meaning they are a business customer.
          { customerType: { id: 2 }, customerSegment: { id: 1 } }
        : // ... else they are a private customer with customerType: 1
          { customerType: { id: 1 }, customerSegment: { id: 2 } }),
    };

    if (
      twoFactorMethod !== null &&
      twoFactorMethod !== BasicCustomerDataTwoFactorMethod.STANDARD
    ) {
      setShowTwoFactorModal(true);
      return;
    }

    if (isAnonymousUser) {
      await handleAnonymousUser(customerTypeAndCustomerSegment);
    }

    if (isValid) {
      handleCustomerUpdate(customerData, customerTypeAndCustomerSegment);
    }
  };

  const isLoading = isLoadingCustomer || isUpdatingCustomer;

  const disablePersonalDetailsForm =
    isAnonymousUser && isExistingAccount === null;

  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  const shouldShowTwoFactorModal = showTwoFactorModal && !!twoFactorMethod;

  const tabPanelIndex = disablePersonalDetailsForm ? -1 : 0;

  const excludedPersonalFormFields = isAnonymousUser
    ? excludedFieldsPrivateAnonymous
    : excludedFieldsPrivate;
  return (
    <Step
      id="onboarding-personal-details"
      headline={t("Onboarding.sections.personal-details.header")}
      subheadline={t(
        `Onboarding.sections.personal-details.subtitle${
          getEntry(dataContext)?.esim ? "Esim" : "Sim"
        }`,
      )}
    >
      {isLoading && <LoadingSpinner />}

      {shouldShowTwoFactorModal && (
        <TwoFactorCodeModal
          setShowModal={setShowTwoFactorModal}
          method={twoFactorMethod}
          successAction={() => console.log("success")}
        />
      )}

      <form onSubmit={formMethods.handleSubmit(navigateToNext)}>
        <FormProvider {...formMethods}>
          {isAnonymousUser && (
            <div className="mt-8">
              <h3 className="text-secondary-100">
                {t("portal:user-account.personalDetails.loginData.title")}
              </h3>

              <p className="my-6 text-black">
                {t(
                  `Onboarding.sections.personal-details.loginSubtitle.${
                    isExistingAccount ? "existingAccount" : "newAccount"
                  }`,
                )}
              </p>

              <AuthFormFields
                isExistingAccount={isExistingAccount}
                setIsExistingAccount={setIsExistingAccount}
                setTwoFactorMethod={setTwoFactorMethod}
              />
            </div>
          )}

          {/* We show this form in all cases, except on 'login' mode. */}
          {isAnonymousUser && isExistingAccount ? null : (
            <div className="mt-10">
              {isAnonymousUser && (
                <h3
                  className={clsx(
                    "text-secondary-100 mb-6",
                    disablePersonalDetailsForm && "opacity-20",
                  )}
                >
                  {t("translation:Onboarding.sections.personal-details.header")}
                </h3>
              )}
              <Tab.Group
                selectedIndex={selectedCustomerTypeTabIndex}
                onChange={setSelectedCustomerTypeTabIndex}
              >
                <Tab.List
                  className={clsx(disablePersonalDetailsForm && "opacity-20")}
                >
                  <Tab disabled={disablePersonalDetailsForm}>
                    {t("Onboarding.sections.personal-details.tabs.private")}
                  </Tab>

                  <Tab disabled={disablePersonalDetailsForm}>
                    {t("Onboarding.sections.personal-details.tabs.business")}
                  </Tab>
                </Tab.List>

                <Tab.Panels>
                  <Tab.Panel
                    id="private"
                    className="mt-6"
                    tabIndex={tabPanelIndex}
                  >
                    <PersonalDetailsFormFields
                      disableAllFields={disablePersonalDetailsForm}
                      excludedFields={excludedPersonalFormFields}
                    />
                  </Tab.Panel>

                  <Tab.Panel className="mt-6" tabIndex={tabPanelIndex}>
                    <PersonalDetailsFormFields
                      disableAllFields={disablePersonalDetailsForm}
                      excludedFields={
                        isAnonymousUser
                          ? excludedFieldsBusinessAnonymous
                          : excludedFieldsBusiness
                      }
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>

              <div className="h-16" />

              <HighlightPanel
                className={clsx(disablePersonalDetailsForm && "opacity-20")}
              >
                <p>{t("Onboarding.sections.personal-details.hint.text")}</p>
              </HighlightPanel>

              {isAnonymousUser && !isExistingAccount && (
                <TermsAgreementField disabled={disablePersonalDetailsForm} />
              )}
            </div>
          )}
          <div className="nav-button-container">
            <Button
              type="button"
              onClick={() => navigate("../../3/rate-selection")}
              className="accent inverted"
            >
              {t("Common.label.back")}
            </Button>

            <SubmitButton
              dataTestid="continue-button"
              className="accent"
              label={t("Common.label.forward")}
            />
          </div>
        </FormProvider>
      </form>
    </Step>
  );
};
