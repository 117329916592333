import clsx from "clsx";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Info } from "@/assets/icons/icons";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";
import { ProgressBar } from "@/components/Layout/ProgressBar/ProgressBar";
import { mobileAboRoamingPackages } from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import { RoamingInfo } from "@/services/model";

type RoamingUsageProps = {
  className?: string;
  days: number;
  description: string;
  initial: number;
  initialDescription: number;
  isFirstOfUsageZone: boolean;
  remaining: number;
  remainingDescription: number;
  roamingInfo: RoamingInfo;
  title: string;
  showFlatMobileExplanation?: boolean;
};

/** Calculates and displays the data usage of a roaming option */
export const RoamingUsage = ({
  className,
  days,
  description,
  initial,
  initialDescription,
  isFirstOfUsageZone,
  remaining,
  remainingDescription,
  roamingInfo,
  title,
  showFlatMobileExplanation = false,
}: RoamingUsageProps) => {
  const [usage, setUsage] = useState<number | undefined>(0);
  const { t } = useTranslation("portal");

  const status = roamingInfo.roamingStatus;
  const isActive = status === "active";

  const getPercentage = (
    initial: number | undefined,
    current: number | undefined,
  ) => {
    if (!!initial && !!current) {
      return ((initial - current) / initial) * 100;
    }
    return 100;
  };

  useEffect(() => {
    setUsage(isActive ? getPercentage(initial, remaining) : undefined);
  }, [roamingInfo, isActive, initial, remaining]);

  const isUsedUpMobileAboRoaming =
    mobileAboRoamingPackages.includes(roamingInfo.optionId || 0) &&
    remaining === 0;
  return (
    <section
      className={clsx(className, isUsedUpMobileAboRoaming && "opacity-50")}
    >
      {showFlatMobileExplanation && !isUsedUpMobileAboRoaming ? (
        <MobileAboProRataExplanation title={title} />
      ) : (
        <p className="text-base mb-4">{title}</p>
      )}

      <ProgressBar usageBarWidth={usage} />

      <span className={clsx("description", !isActive && "inactive")}>
        {status === "ordered" &&
          t("cockpit.managementTile.roaming.showOption.ordered.usage")}

        {status === "ready" && (
          <Trans
            components={[<span key="0" className="font-semibold" />]}
            t={t}
            values={{
              days,
            }}
          >
            {isFirstOfUsageZone
              ? "cockpit.managementTile.roaming.showOption.ready.firstPackageOfUsageZone.usage"
              : "cockpit.managementTile.roaming.showOption.ready.notFirstPackageOfUsageZone.usage"}
          </Trans>
        )}

        {status === "active" && (
          <p>
            <Trans
              components={[<span key="0" className="font-semibold" />]}
              values={{
                initial: initialDescription,
                remaining: remainingDescription,
              }}
              t={t}
            >
              {description}
            </Trans>
            {isUsedUpMobileAboRoaming && (
              <span className="text-primary-100">
                . <br />
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileHint.renewalReminder",
                )}
              </span>
            )}
          </p>
        )}
      </span>
    </section>
  );
};

const MobileAboProRataExplanation = ({ title }: { title: string }) => {
  const { t } = useTranslation("portal");
  return (
    <SectionButtonHeader className="mb-4" header={title}>
      <Tooltip
        position="bottom"
        className="mt-4 !px-6 !py-4 right text-black font-normal text-left text-base min-w-[327px]"
        content={
          <>
            <h3 className="text-lg font-semibold text-primary-100 pb-2 block">
              {t(
                "cockpit.managementTile.roaming.showOption.flatMobileTooltip.title",
              )}
            </h3>
            <ul className="list-disc pl-4">
              <li>
                <b>
                  {t(
                    "cockpit.managementTile.roaming.showOption.flatMobileTooltip.monthlyRenewal.title",
                  )}
                  :
                </b>
                <br />
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileTooltip.monthlyRenewal.description",
                )}
              </li>
              <li>
                <b>
                  {t(
                    "cockpit.managementTile.roaming.showOption.flatMobileTooltip.proRataVolume.title",
                  )}
                  :
                </b>
                <br />
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileTooltip.proRataVolume.description",
                )}
              </li>
            </ul>
            <p className="pt-2">
              <b>
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileTooltip.example.title",
                )}
                :
              </b>
              <br />
              {t(
                "cockpit.managementTile.roaming.showOption.flatMobileTooltip.example.description",
              )}
            </p>
          </>
        }
      >
        <Info className="w-[21px] h-[21px] text-primary-100" />
      </Tooltip>
    </SectionButtonHeader>
  );
};
