import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { mobileAbos } from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import { listAvailableOptions, useListAvailableOptions } from "@/services/api";
import {
  Basket,
  Charge,
  Option,
  Product,
  ProductAvailability,
} from "@/services/model";

import { getChargeString } from "./translationHelpers";

export interface TransformedOptions {
  no_voice_month: TransformedProduct;
  no_voice_year: TransformedProduct;
  voice_month: TransformedProduct;
  voice_year: TransformedProduct;
}
export enum Duration {
  year = "year",
  month = "month",
  unknwon = "unknown",
}
export interface TransformedProduct {
  id?: number;
  bandwidthLabel?: string;
  chargeVoice?: string;
  chargeFlat?: string;
  minContractDur?: number;
  duration?: Duration;
  baseDisplayValue?: string;
  shortName?: string;
  recommended?: boolean;
  availability?: ProductAvailability;
  precharged?: boolean;
  // Linked to the voice product of a data-only product
  voice?: number;
  // Linked to the data product of a voice option
  data?: number;
  isVoiceProduct: boolean;
}
export type TransformedProductsById = { [key: string]: TransformedProduct };
export interface TransformedProducts {
  processedProductsById: TransformedProductsById;
  preSelectedProcessedProduct: TransformedProduct | undefined;
  recommendVoice: boolean;
  prechargeVoice: boolean;
}

const isVoiceProduct = (product: Product) => product.productGroup?.id === 1303;

const freeCharge: Charge = { amount: 0, currency: { id: 1 } };

/**
 * Get charge price for data rate from Product
 *
 * chargeType with id 4 is the charge for the flat
 **/
const getDataChargeFromProduct = (
  product: Product,
  precharged: boolean | undefined,
) =>
  getChargeString(
    precharged
      ? freeCharge
      : product.charges?.filter(({ chargeType }) => chargeType?.id === 4)?.[0],
  );

/**
 * Get charge price for data rate from Product
 *
 * chargeType with id 4 is the charge for the flat
 **/
const getVoiceChargeFromProduct = (
  product: Product,
  precharged: boolean | undefined,
) =>
  getChargeString(
    precharged
      ? freeCharge
      : product.charges?.filter(
          ({ chargeType }) => chargeType?.id === 400,
        )?.[0],
  );

export const transformProducts: (
  optionsFromApi: Option[] | undefined,
) => TransformedProducts = (optionsFromApi) => {
  if (!optionsFromApi) {
    return {
      processedProductsById: {},
      preSelectedProcessedProduct: undefined,
      recommendVoice: false,
      prechargeVoice: false,
    };
  }

  const processedProductsById: TransformedProductsById = {};
  let preSelectedProcessedProduct: TransformedProduct | undefined;
  let prechargedOptionSet = false;
  let recommendVoice = false;
  let prechargeVoice = false;

  const filteredOptions = optionsFromApi.filter(
    ({ product }) =>
      product &&
      product.baseDisplayValue &&
      product.id &&
      product?.productSegment?.id === 4,
  );

  const recommendedSet = new Set(
    optionsFromApi
      .filter((option) => option.recommended)
      .map((option) => option.product?.baseDisplayValue),
  );

  filteredOptions.forEach(({ product, recommended, precharged }) => {
    if (!product?.id) return;
    const isVoice = isVoiceProduct(product);
    const baseDisplayValue = product.baseDisplayValue;
    const isRecommended = recommended || recommendedSet.has(baseDisplayValue);

    const transformedProduct: TransformedProduct = {
      id: product.id,
      bandwidthLabel: product?.bandwidthLabel,
      minContractDur: product?.minContractDur,
      baseDisplayValue,
      shortName: product?.shortName,
      ...getVoiceAndData(product, optionsFromApi),
      duration:
        product?.minContractDur === 365
          ? Duration.year
          : product?.minContractDur === 30
            ? Duration.month
            : undefined,
      recommended: isRecommended,
      chargeFlat: getDataChargeFromProduct(product, precharged),
      ...(isVoice
        ? { chargeVoice: getVoiceChargeFromProduct(product, precharged) }
        : undefined),
      availability: product.availability,
      precharged,
      isVoiceProduct: isVoice,
    };

    if (isVoice) {
      if (recommended) recommendVoice = true;
      if (precharged) prechargeVoice = true;
    }

    processedProductsById[product.id] = transformedProduct;

    if (
      (!prechargedOptionSet &&
        isRecommended &&
        transformedProduct.chargeFlat) ||
      precharged
    ) {
      if (precharged) {
        if (!preSelectedProcessedProduct || !prechargedOptionSet || isVoice) {
          prechargedOptionSet = true;
          preSelectedProcessedProduct = transformedProduct;
        }
      } else if (
        !preSelectedProcessedProduct ||
        preSelectedProcessedProduct.duration === Duration.year
      ) {
        preSelectedProcessedProduct = transformedProduct;
      }
    }
  });

  return {
    processedProductsById,
    preSelectedProcessedProduct,
    recommendVoice,
    prechargeVoice,
  };
};

export const combineProductsByBaselabel = (products: TransformedProductsById) =>
  Object.values(products)
    .filter((product) => product.id === product.data)
    .reduce(
      (
        prev: { [key: string]: TransformedProduct[] },
        curr: TransformedProduct,
      ): { [key: string]: TransformedProduct[] } =>
        curr.baseDisplayValue
          ? {
              ...prev,
              [curr.baseDisplayValue]: [
                ...(prev[curr.baseDisplayValue] ?? []),
                curr,
              ],
            }
          : prev,
      {},
    );

export const getNextPaymentDate = (days?: number) => {
  const date = new Date();
  date.setDate(date.getDate() + (days ?? 0));
  return date;
};

export const getPrechargedProductFromOptions = (options: Option[]) =>
  options.find((option) => option.precharged)?.product;

export const useGetPrechargedOptionForBasket = (basket: Basket | undefined) => {
  const { data: availableOptions } = useListAvailableOptions(
    basket?.id || -1,
    0,
    undefined,
    { query: { enabled: !!basket } },
  );
  if (!availableOptions) return undefined;

  return getPrechargedProductFromOptions(availableOptions);
};

export const useHasPrechargedMobileAbo = (basket: Basket | undefined) => {
  const prechargedOption = useGetPrechargedOptionForBasket(basket);
  return (
    !!prechargedOption?.id &&
    mobileAbos.includes(prechargedOption.id.toString())
  );
};

export const getPreselectedOption: (
  basket: Basket | undefined,
) => Promise<RateFormProps> = async (basket) => {
  if (!basket) return { rate: "", voice: false, numberPorting: "no" };

  const entry = basket?.entries["0"];
  const optionInBasketId = entry?.options?.[0]?.product?.id;

  const options = await listAvailableOptions(
    basket?.id || -1,
    0,
    undefined,
  ).then((res) => transformProducts(res));

  const { preSelectedProcessedProduct, prechargeVoice, processedProductsById } =
    options || {};

  // Following part covers the logic necessary to set an option from the basket or a pre-selected flat option
  const preselectionLocal: Partial<RateFormProps> = {
    rate: undefined,
    voice: false,
    numberPorting: "no",
  };
  // Wenn wir noch keine Rate gesetzt, und transformierte Produkte haben...
  if (processedProductsById && Object.keys(processedProductsById).length > 0) {
    // Setzt den Formstate basierend auf einer gegebenen Produkt-ID
    const setVoiceAndRate = (productID: number) => {
      const product = processedProductsById[productID];

      if (product?.data) {
        preselectionLocal["rate"] = product.data.toString();
      }
      // Wenn das Produkt eine Voice RoamingOptionRow ist, dann aktiviere die Voice RoamingOptionRow Checkbox.
      if (product?.voice === productID) {
        preselectionLocal["voice"] = true;
      }
    };
    // Does set the form state based on the provided product id - but ignores the voice option
    const setRateOnly = (productID: number) => {
      const product = processedProductsById[productID];

      if (product?.data) {
        preselectionLocal["rate"] = product.data.toString();
      }
    };

    // Priorisierung der Vorauswahl:
    // Prio A: Wenn wir ein vorausgewähltes und voraufgeladenes Produkt haben, dann nimm dieses, da kein anderes Produkt gewählt werden kann.
    // Prio B: Wenn der Kunde schon ein Produkt gewählt hat und diese im Basket liegt, dann nimm dieses.
    // Prio C: Ansonsten: Wenn eine Produktempfehlung, also ein vorausgewähltes, nicht-voraufgeladenes Produkt existiert, dann wähle dieses.
    if (preSelectedProcessedProduct) {
      if (
        preSelectedProcessedProduct.precharged &&
        preSelectedProcessedProduct.id !== undefined
      ) {
        // Prio A
        setVoiceAndRate(preSelectedProcessedProduct.id);

        if (prechargeVoice) {
          preselectionLocal["voice"] = true;
        }
        // special case: Prio A tritt ein, allerdings hat der Nutzer die Voice RoamingOptionRow im Basket. Wir müssen das erkennen und voice vorauswählen
        else if (
          optionInBasketId &&
          processedProductsById[optionInBasketId]?.voice === optionInBasketId
        ) {
          preselectionLocal["voice"] = true;
        }
      } else {
        if (optionInBasketId) {
          // Prio B
          setVoiceAndRate(optionInBasketId);

          const voiceOptionInBasket =
            processedProductsById[optionInBasketId]?.voice;
          if (voiceOptionInBasket === optionInBasketId) {
            preselectionLocal["voice"] = true;
          }
        } else {
          // Prio C
          const { id } = preSelectedProcessedProduct;
          if (id) {
            // For a recommended product only the rate should be set, not the voice option.
            setRateOnly(id);
          }
        }
      }
    }
    // Wenn wir ein Produkt im Warenkorb haben, wollen wir auch bei einem vorausgewählten Produkt die Nummernportierung wiederherstellen.
    if (optionInBasketId) {
      preselectionLocal["numberPorting"] = entry.onp ? "yes" : "no";
    }
  }

  return {
    numberPorting: "no",
    voice: false,
    rate: "",
    ...preselectionLocal,
  };
};

const getVoiceAndData = (product: Product, optionsFromApi: Option[]) => {
  const isMobileAbo = mobileAbos.includes(`${product.id}`);
  if (isMobileAbo) {
    return {
      voice: product.id,
      data: product.id,
    };
  }
  if (isVoiceProduct(product)) {
    return {
      voice: product.id,
      data: optionsFromApi.filter(
        (value) =>
          value.product?.baseDisplayValue === product.baseDisplayValue &&
          value.product?.minContractDur === product?.minContractDur &&
          product.id !== value.product?.id,
      )[0]?.product?.id,
    };
  } else {
    return {
      voice: optionsFromApi.filter(
        (value) =>
          value.product?.baseDisplayValue === product.baseDisplayValue &&
          value.product?.minContractDur === product?.minContractDur &&
          product.id !== value.product?.id,
      )[0]?.product?.id,
      data: product.id,
    };
  }
};
