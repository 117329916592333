import "./SettingToggle.scss";

import clsx from "clsx";
import { FC, ReactNode } from "react";

import { CheckSingle, Close, Exclamation } from "@/assets/icons/icons";
import { Toggle } from "@/components/Interface/Toggle/Toggle";
import { useQueryParam } from "@/hooks/useQueryParam";

interface Props {
  checked: boolean;
  title: ReactNode;
  subtitle: ReactNode;
  blocked?: boolean;
  onToggle: () => void;
}

export const SettingToggle: FC<Props> = ({
  checked,
  title,
  subtitle,
  blocked,
  onToggle,
}) => {
  const [mode] = useQueryParam("mode");

  return (
    <div className={clsx("mb-6", { "opacity-25": blocked })}>
      <div className="setting-grid-container">
        <div className="title">{title}</div>
        <div className="toggle">
          {blocked ? (
            <Exclamation />
          ) : mode === "edit" ? (
            <Toggle enabled={checked} onChange={onToggle} />
          ) : checked ? (
            <CheckSingle />
          ) : (
            <Close className="w-6 h-6 close" />
          )}
        </div>
        <div className="subtitle">{subtitle}</div>
      </div>
    </div>
  );
};
