import "./DeviceTile.scss";

import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FCC } from "types";

import { ArrowRight } from "@/assets/icons/icons";

export interface DeviceTileProps {
  icon?: JSX.Element;
  navigationTarget?: string;
  disableForPending?: boolean;
  className?: string;
}
interface TestableDeviceTileProps extends DeviceTileProps {
  dataTestid: string;
}

export const getTitle = (title: string) => {
  return title.length > 24 ? `${title.substring(0, 25)}...` : title;
};

export const DeviceTile: FCC<TestableDeviceTileProps> = ({
  navigationTarget,
  icon,
  children,
  dataTestid,
  className,
}) => {
  const { t } = useTranslation("portal");

  const wrapperProps = useMemo(() => {
    return {
      "data-testid": "device-tile",
      className: clsx(className, "tile-container"),
    };
  }, [className]);

  // we want the tile to be clickable if it has a navigationTarget prop

  const Wrapper: FCC = useCallback(
    ({ children }) =>
      navigationTarget ? (
        <Link {...wrapperProps} to={navigationTarget}>
          {children}
        </Link>
      ) : (
        <div {...wrapperProps}>{children}</div>
      ),
    [navigationTarget, wrapperProps],
  );

  return (
    <Wrapper>
      <Transition
        appear={true}
        show={true}
        enter="transition-opacity ease-linear duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="tile" data-testid={dataTestid}>
          <div className="tile-watermark-container">{icon}</div>
          <div className="content-container">
            {children}
            {navigationTarget && (
              <div className="button-wrapper">
                <div className="button">
                  <span className="manage">{t("layout.tiles.manage")}</span>
                  <ArrowRight className="text-highlight-100 hover:underline" />
                </div>
              </div>
            )}
          </div>
        </div>
      </Transition>
    </Wrapper>
  );
};
