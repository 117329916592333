import "./AddRoamingOption.scss";

import { RadioGroup } from "@headlessui/react";
import { captureException, withScope } from "@sentry/react";
import { useAtom } from "jotai";
import { FC, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Combobox } from "@/components/Interface/FormFields/Combobox/Combobox";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { CountrylistDialog } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/AddRoamingOption/CountryList/CountryList";
import { RoamingOptionContainer } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/AddRoamingOption/RoamingOptionContainer/RoamingOptionContainer";
import { RoamingOptionRow } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/AddRoamingOption/RoamingOptionRow/RoamingOptionRow";
import { optionBasketAtom } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/roamingUtils";
import type { Country } from "@/constants/countries";
import { useGetSortedCountries } from "@/hooks/useGetSortedCountries";
import { useHandleError } from "@/hooks/useHandleError";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import {
  orderRoamingPackage,
  useGetAvailableSunriseRoamingPackages,
} from "@/services/api";
import { GetAvailableSunriseRoamingPackagesPackageType } from "@/services/model";
import { getCurrency } from "@/utils/translationHelpers";

type RoamingOptionsForm = {
  selectedCountry: Country | null;
  selectedRateId: number | null;
};

export const AddRoamingOption: FC = () => {
  const [isFetchingOrder, setIsFetchingOrder] = useState(false);
  const [, setOptionBasket] = useAtom(optionBasketAtom);
  const [simId] = useSimIdFromQueryParam();
  const { t: portalT } = useTranslation("portal");
  const { t: translationT } = useTranslation("translation");
  const handleError = useHandleError();
  const navigate = useNavigate();
  const sortedCountries = useGetSortedCountries(true);
  const { watch, control, setValue, handleSubmit } =
    useForm<RoamingOptionsForm>({
      defaultValues: { selectedCountry: null, selectedRateId: null },
    });

  const selectedCountry = watch("selectedCountry");
  const selectedRateId = watch("selectedRateId");

  const {
    data: availableRoamingPackages,
    isFetching: isFetchingAvailableRoamingPackages,
  } = useGetAvailableSunriseRoamingPackages(
    parseInt(simId),
    {
      packageType: GetAvailableSunriseRoamingPackagesPackageType.Data_Roaming,
      country: selectedCountry?.ALPHA2,
    },
    {
      query: {
        keepPreviousData: true,
        onSuccess(data) {
          const selectedRateIdInNewData = data.subscriberPackagesRoaming?.some(
            (subscriberPackage) => subscriberPackage.id === selectedRateId,
          );

          // we clear the selectedRateId if the new fetched roaming packages
          // don't contain it.
          if (!selectedRateIdInNewData) setValue("selectedRateId", null);
        },
      },
    },
  );

  const subscriberPackages =
    availableRoamingPackages?.subscriberPackagesRoaming;

  const scrollRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView?.({ behavior: "smooth" });
  }, []);

  const cancel = () => {
    navigate(`../display?simId=${simId}`, { replace: true });
  };

  const confirm = () => {
    // Pull a new basket and add it to the Atom
    if (typeof selectedRateId === "number") {
      setIsFetchingOrder(true);
      orderRoamingPackage(Number(simId), {
        optionId: selectedRateId,
      })
        .then((data) => {
          setIsFetchingOrder(false);
          setOptionBasket(data?.basket);
          navigate(`../checkout?simId=${simId}`, { replace: true });
        })
        .catch((error) => {
          setIsFetchingOrder(false);
          if (
            `${error?.response?.data}`.includes(
              "Open roaming package order for SIM",
            )
          ) {
            handleError(
              "Open roaming package order for SIM",
              portalT("cockpit.managementTile.roaming.addOption.errorWait"),
            );
          } else {
            handleError(error);
          }
          withScope(function (scope) {
            scope.setFingerprint(["/customer/contracts"]);
            scope.setTag("section", "portal");
            scope.setTag("domain", "AddRoamingOption");
            scope.setTag("endpoint", "addContract");
            captureException(error);
          });
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(confirm)}>
      {(isFetchingOrder || isFetchingAvailableRoamingPackages) && (
        <LoadingSpinner />
      )}

      <h3 ref={scrollRef} className="text-secondary-100 mb-2 md:mb-6">
        {portalT("cockpit.managementTile.roaming.availableOptions")}
      </h3>

      <p className="max-w-sm mb-8 md:text-black">
        {portalT("cockpit.managementTile.roaming.addOption.selectCountryHint")}
      </p>

      <Controller
        control={control}
        name="selectedCountry"
        render={({ field }) => (
          <Combobox<Country>
            displayValue={(country: Country) =>
              country && "ALPHA3" in country
                ? translationT(`countries.${country.ALPHA3}`)
                : ""
            }
            className="max-w-sm mb-14"
            label={portalT(
              "cockpit.managementTile.roaming.addOption.countryDropdownLabel",
            )}
            field={field}
            dataTestId="countries"
            options={sortedCountries}
            placeholder={portalT(
              "cockpit.managementTile.roaming.addOption.countryDropdownPlaceholder",
            )}
          />
        )}
      />

      <p className="md:text-black mb-8">
        {portalT("cockpit.managementTile.roaming.addOption.selectPackageHint")}
      </p>

      <div className="table-container">
        <div className="roaming-label-container font-x-small">
          <span className="rate">
            {portalT("cockpit.managementTile.roaming.addOption.rate")}
          </span>
          <span className="volume">
            {portalT("cockpit.managementTile.roaming.addOption.volume")}
          </span>
          <span className="price">
            {portalT("cockpit.managementTile.roaming.addOption.price")}
          </span>
        </div>

        <RadioGroup
          value={selectedRateId}
          onChange={(value: number) => {
            setValue("selectedRateId", value);
          }}
        >
          {subscriberPackages?.map(
            ({
              id,
              shortName,
              initialAmount,
              minContractDur,
              minContractDurUnit,
              price,
            }) => (
              <RoamingOptionContainer
                key={shortName}
                className="px-4 md:px-6 xl:px-20 py-6"
                selected={id === selectedRateId}
              >
                <RoamingOptionRow
                  id={id}
                  selected={id === selectedRateId}
                  rate={shortName}
                  volume={initialAmount}
                  duration={
                    minContractDurUnit === "months" && minContractDur === 12
                      ? portalT(
                          "cockpit.managementTile.roaming.addOption.365days",
                        )
                      : ""
                  }
                  price={getCurrency(Number(price), "CHF")}
                />
              </RoamingOptionContainer>
            ),
          )}
        </RadioGroup>
      </div>

      <div className="mt-10 md:text-black">
        <Trans
          t={portalT}
          components={[<CountrylistDialog key={0} />]}
          i18nKey="cockpit.managementTile.roaming.addOption.countryList"
        />
      </div>

      <ConfirmationButtonGroup
        successText={portalT("common.buttons.continue")}
        cancelText={portalT("common.buttons.cancel")}
        cancelAction={() => cancel()}
        disableSuccessButton={selectedRateId === null || isFetchingOrder}
      />
    </form>
  );
};
