import { Tab } from "@headlessui/react";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AppearTransition } from "@/components/Interface/AppearTransition/AppearTransition";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { MobileAboForm } from "@/components/Onboarding/Steps/RateSelection/MobileAboForm";
import {
  MobileAboSwitch,
  mobileAboValues,
} from "@/components/Onboarding/Steps/RateSelection/MobileAboSwitch";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import {
  getMobileAbos,
  mobileAbos,
} from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import { Rates } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffChange/Rates/Rates";
import { VoiceOption } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffChange/VoiceOption/VoiceOption";
import { useCanBookVoiceOption } from "@/hooks/useCanBookVoiceOption";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { getAvailableSunriseRoamingPackages, getSim } from "@/services/api";
import {
  GetAvailableSunriseRoamingPackagesPackageType,
  SimCardDetail,
} from "@/services/model";
import {
  portalTariffProductIDAtom,
  tariffCheckoutPaymentPersistenceAtom,
} from "@/utils/atoms";
import { hasWatchSim, isInactive } from "@/utils/deviceUtils";
import { TransformedProducts, transformProducts } from "@/utils/tariffUtils";

import { transformOptionsToPreslection } from "./VoiceOption/transformOptionsToPreslection";

/**
 * This component gets the available products for an existing sim
 * and allows the user to pick one of these options.
 **/
export const TariffChange = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [simDetail, setSimDetail] = useState<SimCardDetail | undefined>();
  const [options, setOptions] = useState<TransformedProducts | undefined>();
  const [simId] = useSimIdFromQueryParam();

  // The form includes the picked `rate` and `voice`,
  // which is the data product and the voice flatrate.
  // We use an async default value to fetch the sim details and the available products.
  const rateFormMethods = useForm<Omit<RateFormProps, "numberPorting">>({
    defaultValues: async () => {
      const simDetail = await getSim({ simId: parseInt(simId) });
      const options = await getAvailableSunriseRoamingPackages(
        parseInt(simId),
        {
          packageType: GetAvailableSunriseRoamingPackagesPackageType.Data_CH,
        },
      ).then((packages) => transformProducts(packages?.subscriberPackageData));

      setSimDetail(simDetail);
      setOptions(options);

      const { rate, voice } = transformOptionsToPreslection(
        simDetail,
        options,
        portalTariffProductID || simDetail?.serviceID,
      );

      return {
        rate: rate || "",
        voice: voice,
      };
    },
  });
  const values = rateFormMethods.watch();

  // Atom for saving the selected product id
  const [portalTariffProductID, setPortalTariffProductID] = useAtom(
    portalTariffProductIDAtom,
  );
  const [, setTariffPaymentPersistence] = useAtom(
    tariffCheckoutPaymentPersistenceAtom,
  );

  const canBookVoiceOption = useCanBookVoiceOption();

  const selectedOption =
    typeof values.rate === "string"
      ? values.voice === true
        ? options?.processedProductsById[values.rate]?.voice
        : Number(values.rate)
      : undefined;

  const navigateForwards = () => {
    setPortalTariffProductID(selectedOption);
    setTariffPaymentPersistence(undefined);
    navigate(`../checkout?simId=${simDetail?.simId}`, {
      replace: true,
    });
  };
  const navigateBack = () => {
    setPortalTariffProductID(undefined);
    navigate(`../display?simId=${simDetail?.simId}`, { replace: true });
  };

  // We disable the "forward" button if neither of rate or voice option has changed.
  // It is sufficient that only one of this changes, because the user might just add
  // the voice option to their current active contract
  const disableSuccessButton =
    // If the device is inactive...
    (isInactive(simDetail)
      ? // we only disable if no option has been selected.
        selectedOption === undefined
      : // If its not inactive, we have to look at the state of automatic renewal next.
        simDetail?.autoRenew && simDetail?.successorForRenew?.id
        ? // If we have automatic renewal, we disable the continue button if the selection matches the successor,
          // because this selection is already active and will be renewed, therefore it cannot be booked again.
          selectedOption === simDetail?.successorForRenew?.id
        : // If we don't have automatic renewal active, we can book anything
          false) || !selectedOption;

  const mobileAboProducts = getMobileAbos(options?.processedProductsById);

  const initialMobileAboSwitchValue = mobileAbos.includes(
    `${simDetail?.serviceID}`,
  )
    ? mobileAboValues.FLAT_MOBILE
    : mobileAboValues.ALL_RATES;

  const CustomRates = useMemo(
    () =>
      ({ options }: { options: TransformedProducts }) => {
        return (
          <>
            <Rates options={options} />
            {canBookVoiceOption && (
              <VoiceOption
                options={options}
                hasWatchSim={!!hasWatchSim(simDetail)}
                currentRateId={values.rate}
              />
            )}
            <p className="mt-4 font-x-small leading-6 text-secondary-100 md:mt-2">
              <b>{t("translation:Onboarding.sections.rate-selection.hint")}</b>
              {t("portal:cockpit.managementTile.tariff.change.hint")}
            </p>
          </>
        );
      },
    [canBookVoiceOption, simDetail, t, values.rate],
  );

  return (
    <AppearTransition>
      <FormProvider {...rateFormMethods}>
        {!!options ? (
          <div>
            <MobileAboSwitch initialValue={initialMobileAboSwitchValue}>
              <Tab.Panels>
                <Tab.Panel>
                  <MobileAboForm
                    value={rateFormMethods.watch("rate")}
                    setValue={(rate) => rateFormMethods.setValue("rate", rate)}
                    mobileAbos={mobileAboProducts}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <CustomRates options={options} />
                </Tab.Panel>
              </Tab.Panels>
            </MobileAboSwitch>
          </div>
        ) : (
          <p data-testid="loading-indicator">{t("portal:loading")}</p>
        )}
      </FormProvider>
      <ConfirmationButtonGroup
        successText={t("common.buttons.continue")}
        successAction={() => navigateForwards()}
        disableSuccessButton={disableSuccessButton}
        cancelText={t("common.buttons.cancel")}
        cancelAction={() => navigateBack()}
      />
    </AppearTransition>
  );
};
