import { QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";

const is401Error = (err: unknown): boolean =>
  err instanceof AxiosError && err.response?.status === 401;

export const useQueryClient = (
  handleError: (err: unknown, errorMessage?: string) => void,
) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: (err) => {
              if (!is401Error(err)) {
                handleError(err);
              }
            },
            retry: false,
          },
          mutations: {
            onError: (err) => {
              if (!is401Error(err)) {
                handleError(err);
              }
            },
          },
        },
      }),
    [handleError],
  );
  return queryClient;
};
