import { AxiosHeaders } from "axios";
import { useSetAtom } from "jotai";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OPTIONS } from "@/components/Onboarding/Onboarding.constants";
import { useAlert } from "@/hooks/useAlert";
import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { DataContext } from "@/provider/DataContextProvider";
import { addBasketEntry, saveBasket } from "@/services/api";
import { PUBLIC_USER_AUTHORIZATION, TOKEN_URL } from "@/utils/apiUrls";
import { accessTokenAtom, AlertTypes } from "@/utils/atoms";

/**
 * This hook retrieves an access token for a public user from the backend, and
 * uses it to create a basket with an eSIM.
 * With the preselected eSIM, it then redirects the user to the onboarding as an
 * anonymous user.
 */
export const useAnonymousLogin = () => {
  const setAccessToken = useSetAtom(accessTokenAtom);
  const [, setIsAnonymousUser] = useIsAnonymousUser();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { setDataContext } = useContext(DataContext);
  const { t } = useTranslation();

  const logInAsAnonymous = () =>
    // Get an OAuth token by using the public user
    fetch(`${TOKEN_URL.toString()}?grant_type=client_credentials&scope=all`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: PUBLIC_USER_AUTHORIZATION,
      },
    })
      .then(async (res: any) => {
        const anonymousLoginData = await res.json();

        if (anonymousLoginData.access_token === undefined) {
          throw new Error("No access token received");
        }

        const headers = new AxiosHeaders({
          Authorization: `Bearer ${anonymousLoginData.access_token}`,
        });

        try {
          const basket = await saveBasket(undefined, { headers });

          if (!basket.id) throw new Error("No basket id received");

          const basketWithEsimBaseOption = await addBasketEntry(
            basket.id,
            OPTIONS.BASE_OPTION,
            {
              isESIM: true,
            },
            { headers },
          );

          setDataContext((prev) => {
            return {
              ...prev,
              allowedMaxOnboardingStep: 2,
              basket: basketWithEsimBaseOption,
            };
          });
          setAccessToken(anonymousLoginData.access_token);
          setIsAnonymousUser(true);

          navigate("/onboarding/2/device-type", { replace: true });
        } catch (e) {
          showAlert({
            text: t("Error.response"),
            type: AlertTypes.error,
          });
        }
      })
      .catch((e) => {
        showAlert({
          text: t("Error.agentLogin.failed"),
          type: AlertTypes.error,
        });
      });

  return logInAsAnonymous;
};
