import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { Logo } from "@/assets/icons/icons";
import { MenuItemLink } from "@/components/Interface/MenuItems/MenuItemLink";
import { Collapsible } from "@/components/Layout/Collapsible/Collapsible";

export const getMenuItems = (t: TFunction) => {
  return [
    { label: t("Footer.website.label"), link: t("Footer.website.url") },
    {
      label: t("Footer.termsConditions.label"),
      link: t("Footer.termsConditions.url"),
    },
    {
      label: t("Footer.dataProtection.label"),
      link: t("Footer.dataProtection.url"),
    },
    { label: t("Footer.imprint.label"), link: t("Footer.imprint.url") },
    { label: t("Footer.disclaimer.label"), link: t("Footer.disclaimer.url") },
  ];
};

export const CollapsibleLinkMenu = () => {
  const { t } = useTranslation();
  const menuItems = getMenuItems(t);
  return (
    <Collapsible
      id="link-menu"
      icon={
        <Logo className="w-6 [&>path]:fill-white xl:[&>path]:fill-primary-100 group-hover:[&>path]:fill-white" />
      }
      label={t("Common.logoName")}
    >
      {menuItems.map(({ label, link }) => (
        <MenuItemLink key={label} label={label} link={link} />
      ))}
    </Collapsible>
  );
};
