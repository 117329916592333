import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { FreeEsimTrialTooltip } from "@/components/Onboarding/Steps/RateSelection/FreeEsimTrialTooltip/FreeEsimTrialTooltip";
import { OptionContainer } from "@/components/Onboarding/Steps/RateSelection/OptionContainer/OptionContainer";
import { RateRadioButton } from "@/components/Onboarding/Steps/RateSelection/RateRadioButton/RateRadioButton";
import { TransformedProduct } from "@/utils/tariffUtils";

export const RateOption = ({
  name,
  yearOption,
  monthOption,
  preSelectedProcessedProduct,
  isSelected,
  currentProductId,
  readonlyProductId,
  shouldShowEsimTrial = false,
}: {
  name: string;
  yearOption: TransformedProduct;
  monthOption: TransformedProduct;
  preSelectedProcessedProduct?: TransformedProduct;
  readonlyProductId?: number;
  currentProductId?: number;
  isSelected: boolean;
  shouldShowEsimTrial?: boolean;
}) => {
  const { t } = useTranslation();
  const recommended = yearOption?.recommended || monthOption?.recommended;

  const yearIsPreselected =
    yearOption?.id === preSelectedProcessedProduct?.id ||
    yearOption.voice === preSelectedProcessedProduct?.id;

  const monthIsPreselected =
    monthOption?.id === preSelectedProcessedProduct?.id ||
    monthOption.voice === preSelectedProcessedProduct?.id;

  const idIsPreselected = yearIsPreselected || monthIsPreselected;

  const precharged = preSelectedProcessedProduct?.precharged;

  return (
    <OptionContainer
      selected={isSelected}
      recommended={recommended}
      // Row is disabled if we have a precharged option and neither the year nor month option is that precharged
      // option.
      disabled={precharged && !idIsPreselected}
      precharged={precharged && idIsPreselected}
    >
      <div className="descriptions" data-testid="rate-descriptions">
        <span className="rate-label">{name}</span>
        <span className="speed-label">{monthOption?.bandwidthLabel} Mbits</span>
      </div>
      <div className="durations">
        <div
          data-testid="rate-durations"
          className={clsx(
            "duration",
            precharged && !monthIsPreselected && "disabled",
          )}
        >
          {monthOption && (
            <RateRadioButton
              isReadonly={readonlyProductId === monthOption?.id}
              transformedProduct={monthOption}
              disabled={precharged && !monthIsPreselected}
              preSelectedProcessedProduct={preSelectedProcessedProduct}
            />
          )}
          <div className="days-label">
            {t("Onboarding.sections.rate-selection.30days")}
          </div>
        </div>
        <div
          className={clsx(
            "duration",
            precharged && !yearIsPreselected && "disabled",
          )}
        >
          {yearOption && (
            <RateRadioButton
              isReadonly={readonlyProductId === yearOption?.id}
              transformedProduct={yearOption}
              disabled={precharged && !yearIsPreselected}
              preSelectedProcessedProduct={preSelectedProcessedProduct}
            />
          )}
          <div className="days-label">
            {t("Onboarding.sections.rate-selection.365days")}
          </div>
        </div>
        {shouldShowEsimTrial &&
          (currentProductId === yearOption.id ||
            currentProductId === monthOption.id) && (
            <p className="flex mt-4 col-span-2 font-x-small whitespace-break-spaces">
              <span className="mr-2">
                {t("Onboarding.sections.rate-selection.esim-trial-hint")}
              </span>
              <FreeEsimTrialTooltip leftOnMobile={true} />
            </p>
          )}
      </div>
    </OptionContainer>
  );
};
