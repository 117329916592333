import { useAtom } from "jotai";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AppearTransition } from "@/components/Interface/AppearTransition/AppearTransition";
import { Button } from "@/components/Interface/Button/Button";
import { Modal } from "@/components/Interface/Modal/Modal";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { LightboxWrapper } from "@/components/Portal/UserAccount/PaymentMethods/LightboxWrapper/LightboxWrapper";
import {
  PaymentProviderPicker,
  paymentProvidersObject,
} from "@/components/Portal/UserAccount/PaymentMethods/PaymentProviderPicker/PaymentProviderPicker";
import { useAlert } from "@/hooks/useAlert";
import { useHandleCheckoutError } from "@/hooks/useHandleCheckoutError";
import { usePaymentMode } from "@/hooks/usePaymentMode";
import { useQueryParam } from "@/hooks/useQueryParam";
import {
  useCreateTransaction,
  useDeletePaymentMean,
  useGetCustomer,
} from "@/services/api";
import {
  DrCreditCardCardType,
  DrPaymentMeanReasonsItem,
} from "@/services/model";
import {
  AlertInterface,
  AlertTypes,
  userAccountStateAtom,
} from "@/utils/atoms";
import { isBusinessCustomer } from "@/utils/customerUtils";

import { BillingInfo } from "./components/BillingInfo/BillingInfo";
import { RequestInvoicePanel } from "./RequestInvoicePanel";

export const PaymentMethods: FC = () => {
  const { t } = useTranslation("portal", {
    keyPrefix: "user-account.paymentMethods",
  });
  const [paymentProvider, setPaymentProvider] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userAccountState, setUserAccountState] = useAtom(userAccountStateAtom);
  const { data: customer } = useGetCustomer();
  const { editingPayment, editingBillingInfo } = userAccountState;

  const setAddingOrChanging = (isEditing: boolean) => {
    setUserAccountState((prev) => ({
      ...prev,
      editingPayment: isEditing,
    }));
  };
  const showAlert = useAlert();

  const [paymentStatus, setPaymentStatus] = useQueryParam("payment");
  const isSuccess = paymentStatus === "success";
  useEffect(() => {
    if (isSuccess) {
      showAlert({
        type: AlertTypes.success,
        text: t("changeSuccessAlert"),
      });
      setPaymentStatus("", { replace: true });
      setUserAccountState((prev) => ({ ...prev, editingPayment: false }));
    }
  }, [setUserAccountState, setPaymentStatus, t, showAlert, isSuccess]);

  useHandleCheckoutError(true);

  const {
    isInvoice,
    isCreditCard,
    maskedCardNumberString,
    cardType,
    noPaymentMethod,
    canDeleteCreditCard,
    refetch,
    isLoading: paymentMeansAreLoading,
  } = usePaymentMode();

  const openBill = DrPaymentMeanReasonsItem.OPEN_BILL;
  const payAsYou = DrPaymentMeanReasonsItem.PAY_AS_YOU_GO_ON;
  const serviceSettingsOn = DrPaymentMeanReasonsItem.RATED_SERVICES_SETTINGS_ON;
  const outstandingServices =
    DrPaymentMeanReasonsItem.OUTSTANDING_RATED_SERVICES_CHARGES;

  // Handle errors for deleting payment mean
  const handleErrors = (
    reasons: string | DrPaymentMeanReasonsItem[] | undefined,
  ) => {
    const alert: AlertInterface = {
      type: AlertTypes.error,
      text: t("errorAlerts.generalErrorAlert"),
    };
    if (reasons?.includes(openBill)) {
      alert.text = t("errorAlerts.openBill");
    } else if (reasons?.includes(payAsYou)) {
      alert.text = t("errorAlerts.payAsYouGo");
    } else if (reasons?.includes(serviceSettingsOn)) {
      alert.text = t("errorAlerts.serviceSettingsOn");
    } else if (reasons?.includes(outstandingServices)) {
      alert.text = t("errorAlerts.outstandingCharges");
    }

    showAlert(alert);
  };

  const { mutate: deletePaymentMean } = useDeletePaymentMean({
    mutation: {
      onSuccess: (data) => {
        if (canDeleteCreditCard === true) {
          showAlert({
            type: AlertTypes.success,
            text: t("deleteSuccessAlert"),
          });
          refetch().then(() => {
            setAddingOrChanging(false);
            setIsModalOpen(false);
          });
        }
        if (data.canDeleteCreditCard === false) {
          handleErrors(data.reasons);
        }
      },
      onError: (error) => handleErrors(`${error.response?.data}`),
    },
  });

  const { data: datatransRequestDocument, mutate: createTransaction } =
    useCreateTransaction();

  const handleAddingOrChanging = (s: DrCreditCardCardType | "") => {
    setPaymentProvider(s);
    createTransaction({ params: undefined }, {});
  };

  const showBillingInfo =
    !!customer && isBusinessCustomer(customer) && isInvoice;

  const isEditingAsBusinessCustomer =
    editingPayment && customer && isBusinessCustomer(customer);
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {paymentMeansAreLoading ? (
        <LoadingSpinner />
      ) : (
        <AppearTransition>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-14">
            <section className="management-section">
              <SectionButtonHeader
                className={
                  "font-semibold text-xl-header text-secondary-100 mb-6"
                }
                header={t("title")}
              >
                {!isInvoice &&
                  !editingBillingInfo &&
                  (editingPayment || !noPaymentMethod) && (
                    <div className="selection-row">
                      <button
                        className="change-button"
                        onClick={() => {
                          setAddingOrChanging(!editingPayment);
                        }}
                      >
                        {t(`${editingPayment ? "cancel" : "change"}`)}
                      </button>
                    </div>
                  )}
              </SectionButtonHeader>
              {isModalOpen && (
                <Modal
                  className="max-h-[500px]"
                  title={t("deletePaymentmean")}
                  onClose={() => setIsModalOpen(false)}
                >
                  {t("confirmDelete")}
                  <div className="flex justify-end mt-6">
                    <Button
                      onClick={() => {
                        deletePaymentMean();
                      }}
                      className="accent flex"
                      dataTestid="delete-button-modal"
                    >
                      {t("delete")}
                    </Button>
                  </div>
                </Modal>
              )}
              <p className="text-lg leading-tight">
                {t(
                  `${
                    !editingPayment && noPaymentMethod
                      ? "noPaymentMethod"
                      : "subtitle"
                  }`,
                )}
              </p>
              {!editingPayment && noPaymentMethod && (
                <Button
                  className={"accent w-full mt-14"}
                  dataTestid="add-payment-method-button"
                  onClick={() => setAddingOrChanging(true)}
                >
                  {t("addPaymentMethod")}
                </Button>
              )}
              {isInvoice && (
                <PaymentProviderPicker
                  className="mt-8"
                  paymentOptions={[
                    {
                      label: t("invoice"),
                      value: "invoice",
                      disabled: true,
                    },
                  ]}
                  selected={"invoice"}
                />
              )}
              {isCreditCard && cardType && (
                <>
                  <div className="flex justify-between">
                    <PaymentProviderPicker
                      className="mt-8 w-[208px] whitespace-pre-wrap"
                      paymentOptions={[
                        {
                          ...paymentProvidersObject[cardType],
                          subtext: maskedCardNumberString,
                          disabled: true,
                        },
                      ]}
                      selected={cardType}
                    />
                    {editingPayment && (
                      <div className="flex items-end">
                        <button
                          className="mb-6 text-[13px] text-primary-100 underline"
                          onClick={() => setIsModalOpen(true)}
                          data-testid="delete-button"
                        >
                          {t("delete")}
                        </button>
                      </div>
                    )}
                  </div>
                  {editingPayment && (
                    <hr className="h-px bg-primary-100 border-0 mt-2" />
                  )}
                </>
              )}
              {editingPayment && (
                <>
                  <PaymentProviderPicker
                    className="mt-8 ml-[3px] md:ml-0 w-[208px]"
                    selected={paymentProvider}
                    onChange={(s: DrCreditCardCardType) => {
                      handleAddingOrChanging(s);
                      setIsLoading(true);
                    }}
                  />
                  <LightboxWrapper
                    paymentProvider={paymentProvider}
                    transactionId={datatransRequestDocument?.refNo}
                    sign={datatransRequestDocument?.sign}
                    enabled={
                      !!(
                        datatransRequestDocument?.refNo &&
                        datatransRequestDocument?.sign
                      )
                    }
                  />
                </>
              )}
            </section>
            {showBillingInfo && (
              <section className="management-section row-span-2">
                <BillingInfo />
              </section>
            )}
            <section className="management-section">
              {isEditingAsBusinessCustomer && <RequestInvoicePanel />}
            </section>
          </div>
        </AppearTransition>
      )}
    </>
  );
};
