import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { ListBoxMultiple } from "@/components/Interface/FormFields/ListBoxMultiple/ListBoxMultiple";
import { FilterDialogForm } from "@/components/Portal/Cockpit/Filter/Filter";
import { GetSimListSimTypeItem } from "@/services/model";

interface Props {
  control: Control<FilterDialogForm, any>;
}

export const SimTypeFilter: FCC<Props> = ({ control }) => {
  const { t } = useTranslation("portal");
  return (
    <div>
      <Controller<FilterDialogForm>
        control={control}
        name="simType"
        render={({ field: { onChange, value } }) => (
          <ListBoxMultiple<GetSimListSimTypeItem>
            selected={
              (typeof value === "string"
                ? [value]
                : value) as GetSimListSimTypeItem[]
            }
            setSelected={onChange}
            displayValue={(value) =>
              t(`cockpit.filter.dropdowns.simType.${value}`)
            }
            availableValues={["TRIPLESIM", "ESIM"]}
            placeholder={t("cockpit.filter.dropdowns.simType.placeholder")}
            itemLabel={t("cockpit.filter.dropdowns.simType.placeholder")}
          />
        )}
      />
    </div>
  );
};
