import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StyledLink, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { AuthenticatorSmsConfirmForm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsConfirmForm";
import { TwoFactorConfirmationCodeForm } from "@/components/Welcome/AuthenticatorSetup/TwoFactorConfirmationInput";
import { useRequestSmsToken } from "@/hooks/useRequestSmsToken";
import { authenticatorRoutes } from "@/router/AuthenticatorRoutes";
import {
  useSetUpTwoFactorAuthentication,
  useValidateTwoFactorAuthentication,
} from "@/services/auth";
import { ValidateTwoFactorAuthenticationMethod } from "@/services/model";
import { phoneNumberAtom, twoFactorBackupCodesAtom } from "@/utils/atoms";

// TODO: This component is very similar to TwoFactorSmsConfirm.tsx
export const AuthenticatorSmsConfirm = ({
  navigationTarget = authenticatorRoutes.confirmCodes,
}: {
  navigationTarget?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [phoneNumber] = useAtom(phoneNumberAtom);
  const [, setBackupCodes] = useAtom(twoFactorBackupCodesAtom);
  const formMethods = useForm<TwoFactorConfirmationCodeForm>({
    defaultValues: {
      code: "",
    },
  });
  const { mutateAsync: validateTwoFactorAuth } =
    useValidateTwoFactorAuthentication({
      mutation: {
        onError: (
          error: AxiosError<{
            errorCode: number;
            message: string;
          }>,
        ) => {
          formMethods.setError("code", {
            type: "manual",
            message: t("portal:authenticator.app.setup.step2.invalidCode"),
          });
        },
      },
    });

  const resendParams = {
    method: ValidateTwoFactorAuthenticationMethod.SMS,
    phoneNumber,
  };

  const resendAction = useRequestSmsToken(
    useSetUpTwoFactorAuthentication,
    resendParams,
  );

  return (
    <FormProvider {...formMethods}>
      <form
        className="flex flex-col gap-10 text-black"
        onSubmit={formMethods.handleSubmit((data) => {
          validateTwoFactorAuth({
            params: {
              method: ValidateTwoFactorAuthenticationMethod.SMS,
              code: parseInt(data.code),
            },
          }).then((data) => {
            if (data.backupCodes) {
              setBackupCodes(data.backupCodes);
              navigate(navigationTarget);
            }
          });
        })}
      >
        <AuthenticatorSmsConfirmForm resendAction={resendAction} />
        <ConfirmationButtonFlexBox justify="between" forceButtonWidth>
          <StyledLink className="inverted accent" to={-1 as any}>
            {t("portal:common.buttons.back")}
          </StyledLink>
          <SubmitButton
            label={t("portal:authenticator.sms.verification.verify")}
            className="accent"
          />
        </ConfirmationButtonFlexBox>
      </form>
    </FormProvider>
  );
};
