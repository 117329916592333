import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SmileySpinner from "@/assets/SmileySpinner.svg?react";

export const AuthenticatorDone = () => {
  const navigate = useNavigate();
  // Redirect after 5 seconds
  useEffect(() => {
    setTimeout(() => {
      navigate("/portal/cockpit");
    }, 5000);
  }, [navigate]);

  const { t } = useTranslation();
  return (
    <div className="text-center flex-col justify-center items-center gap-10 inline-flex">
      <h1 className="px-10 max-w-[40rem] text-primary-100 text-4xl font-semibold">
        <Trans t={t} i18nKey="portal:authenticator.done.thankYou" />
      </h1>
      <SmileySpinner className="animate-spin " />
      <p className=" text-black text-lg">
        {t("portal:authenticator.done.redirectText")}
      </p>
    </div>
  );
};
