import "@/components/Onboarding/Wizard/Wizard.scss";

import { useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import { FCC } from "types";

import { Section } from "@/components/Onboarding/Onboarding.constants";
import { WizardBasePath } from "@/hooks/useWizard";

import { Steps } from "./Steps";

interface Props {
  sections: Section[];
  basePath: WizardBasePath;
}

export const Wizard: FCC<Props> = ({ children, sections, basePath }) => {
  const { t } = useTranslation();
  const headingRef = useRef<HTMLHeadingElement>(null);

  const parameters = useMatch(`${basePath}/:step/:substep`);
  const step = parameters?.params.step
    ? Number.parseInt(parameters.params.step)
    : 1;
  const substep = parameters?.params.substep;

  useEffect(() => {
    // We only want to focus the heading if the user is not on the first step.
    if (step > 1) {
      if (headingRef?.current?.scrollIntoView) {
        headingRef?.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [parameters, headingRef, step]);

  useEffect(() => {
    const scroller = document.getElementById("step-scroller");
    if (scroller && scroller.scrollTo && window.innerWidth < 768) {
      scroller.scrollTo({
        top: 0,
        left: (step - 1) * 125,
        behavior: "smooth",
      });
    }
  }, [step]);

  return (
    <>
      <div id="wizard-wrapper" ref={headingRef}>
        <span id="step-title">
          <Trans
            i18nKey="Wizard.step"
            t={t}
            values={{ step: step, all: sections.length }}
            components={[<strong key="1" id="current-step" />]}
          />
        </span>
        <div id="step-scroller">
          <div id="all-steps">
            <Steps
              sections={sections}
              currentStep={step}
              currentSubStep={substep}
            />
          </div>
        </div>
      </div>
      {children}
    </>
  );
};
