import { Provider } from "jotai/react";
import { createStore } from "jotai/vanilla";
import { Navigate } from "react-router-dom";

import { useTwoFactorAuthStatus } from "@/hooks/useTwoFactorAuthStatus";
import { portalRoutes } from "@/router/routes/portalRoutes";
import { FCC } from "@/types";

const twoFactorStore = createStore();
export const TwoFactorChangeProtection: FCC = ({ children }) => {
  const [isVerified] = useTwoFactorAuthStatus();

  if (!isVerified) {
    return <Navigate to={portalRoutes.user.security.base} />;
  }

  return <Provider store={twoFactorStore}>{children}</Provider>;
};
