import "./Tablist.scss";

import { TabWithDisableHint } from "@/components/Interface/TabWithDisableHint/TabWithDisableHint";
import { FadeOverlayContainer } from "@/components/Layout/FadeOverlayContainer/FadeOverlayContainer";

export type TabElement = {
  hidden?: boolean;
  target: string;
  disabled: boolean;
  showHint: boolean;
  text: string;
};

export const Tablist = ({ tabs }: { tabs: TabElement[] }) => {
  return (
    <FadeOverlayContainer id="scroll-container">
      <nav role="tablist" className="tabgroup">
        <ul>
          {tabs
            .filter(({ hidden }) => !hidden)
            .map(({ target, disabled, showHint, text }, index) => (
              <li key={index}>
                <TabWithDisableHint
                  target={target}
                  disabled={disabled}
                  showHint={showHint}
                >
                  {text}
                </TabWithDisableHint>
              </li>
            ))}
        </ul>
      </nav>
    </FadeOverlayContainer>
  );
};
