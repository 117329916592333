const theme = {
  "primary-100": "#0014ff",
  "primary-75": "#404fff",
  "primary-50": "#8089ff",
  "primary-25": "#ccd0ff",
  "primary-1": "#ebedff",
  "secondary-100": "#ff004f",
  "secondary-75": "#ff3372",
  "secondary-50": "#ff80a7",
  "secondary-25": "#ffccdc",
  "highlight-100": "#00dcc3",
  "highlight-75": "#40e5d2",
  "highlight-50": "#80eee1",
  "highlight-25": "#ccf8f3",
  "error-100": "#ff004f",
  "error-75": "#ff3372",
  "error-50": "#ff80a7",
  "error-25": "#ffccdc",
  black: "#000",
  white: "#fff",
  "subtle-selected": "#f5fefd",
};

export default theme;
