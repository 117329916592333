import { setUser } from "@sentry/react";
import { FC } from "react";
import { Navigate } from "react-router-dom";

import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { useGetCustomer } from "@/services/api";
import { isBusinessCustomer } from "@/utils/customerUtils";

// TODO: Not a route component, but a component that is used in a route component. Should be moved.
export const CockpitRoute: FC = () => {
  const { data, isLoading } = useGetCustomer(undefined, {
    query: {
      staleTime: 5 * (60 * 1000), // 5mins
      onSuccess: (data) => {
        setUser({ id: data.id?.toString() });
      },
    },
  });

  if (!isLoading && data !== undefined) {
    if (isBusinessCustomer(data)) {
      return <Navigate to="/portal/cockpit/list" replace />;
    } else {
      return <Navigate to="/portal/cockpit/tiles" replace />;
    }
  } else {
    return <LoadingSpinner />;
  }
};
