/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type ESimInfoStatus =
  (typeof ESimInfoStatus)[keyof typeof ESimInfoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ESimInfoStatus = {
  CREATED: "CREATED",
  DOWNLOADED: "DOWNLOADED",
  INSTALLED: "INSTALLED",
  AVAILABLE: "AVAILABLE",
  PREBOOKED: "PREBOOKED",
  RELEASED: "RELEASED",
  DOWNLOAD_INITIATED: "DOWNLOAD_INITIATED",
  DELETED: "DELETED",
  DELETE_SUCCEEDED: "DELETE_SUCCEEDED",
  EXPIRED_BEFORE_DOWNLOAD: "EXPIRED_BEFORE_DOWNLOAD",
  EXPIRED_AFTER_DOWNLOAD: "EXPIRED_AFTER_DOWNLOAD",
  MARKED_FOR_DELETION: "MARKED_FOR_DELETION",
  MARKED_FOR_DELETION_WITH_HISTORY: "MARKED_FOR_DELETION_WITH_HISTORY",
  ALLOCATED: "ALLOCATED",
  LINKED: "LINKED",
  CONFIRMED: "CONFIRMED",
  UNAVAILABLE: "UNAVAILABLE",
  INSTALLED_ENABLED: "INSTALLED_ENABLED",
  INSTALLED_DISABLED: "INSTALLED_DISABLED",
  ERROR_INSTALL_FAILED: "ERROR_INSTALL_FAILED",
  ERROR_DELETE_FAILED: "ERROR_DELETE_FAILED",
  ERROR_DOWNLOAD_FAILED: "ERROR_DOWNLOAD_FAILED",
  UNKNOWN: "UNKNOWN",
} as const;
