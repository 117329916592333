import "./Header.scss";

import { Link } from "react-router-dom";
import { FCC } from "types";

import Logo from "@/assets/header/Logo.svg?react";

export const Header: FCC = ({ children }) => {
  return (
    <header id="header-container" data-testid="header">
      <div className="wave" />
      <Link to="/">
        <Logo className="logo" />
      </Link>
      {children}
    </header>
  );
};
