import { FC } from "react";

import { Background } from "@/components/Layout/Background/Background";
import { Footer } from "@/components/Layout/Footer/Footer";
import { WelcomeContent } from "@/components/Welcome/WelcomeContent/WelcomeContent";
import { WelcomeHeader } from "@/components/Welcome/WelcomeHeader/WelcomeHeader";

export const Welcome: FC = () => (
  <Background>
    <WelcomeHeader />
    <WelcomeContent />
    <Footer className="welcome-screen" />
  </Background>
);
