import { ExtendedSimCardDetail } from "@/hooks/useExtendedSims";
import { SimCardDetailSimStatus } from "@/services/model";
import {
  hasEsimInfo,
  hasFlatSpeedPackage,
  hasSimId,
  isKYCPendingTile,
  isNumberPortingTile,
  isPendingEsim,
  isPendingPhysicalSim,
  needsQRCodeTile,
} from "@/utils/deviceUtils";

import { ActiveSimNoPackageTile } from "./ActiveSimNoPackageTile/ActiveSimNoPackageTile";
import { ActiveSimTile } from "./ActiveSimTile/ActiveSimTile";
import { AddDeviceTile } from "./AddDeviceTile/AddDeviceTile";
import { InactiveSimTile } from "./InactiveTile/InactiveSimTile";
import { NumberPortingTile } from "./NumberPortingTile/NumberPortingTile";
import { PendingSimTile } from "./PendingTile/PendingSimTile";
import { QRCodeTile } from "./QRCodeTile/QRCodeTile";
import { SuspendedSimTile } from "./SuspendedSimTile/SuspendedSimTile";
import { UnverifiedSimTile } from "./UnverifiedSimTile/UnverifiedSimTile";
import { UpsellTile } from "./UpsellTile/UpsellTile";

export const mapDevice = (
  device: ExtendedSimCardDetail,
  needsIdCheck: boolean | undefined,
  managedUnscannedDevices: number[],
) => {
  if (!hasSimId(device)) {
    return null;
  }
  if (isKYCPendingTile(device, needsIdCheck)) {
    return <UnverifiedSimTile device={device} />;
  }
  if (isNumberPortingTile(device)) {
    return <NumberPortingTile device={device} />;
  }
  if (isPendingEsim(device)) {
    return <PendingSimTile device={device} />;
  }
  if (isPendingPhysicalSim(device)) {
    return <PendingSimTile device={device} />;
  }
  if (
    // typeguard ExtendedSimCardDetail into ExtendedSimCardDetailWithEsimInfo
    hasEsimInfo(device) &&
    needsQRCodeTile(device) &&
    !managedUnscannedDevices.some((value) => value === device.simId)
  ) {
    return <QRCodeTile device={device} />;
  }

  // No special case applies, we can display the normal tiles
  switch (device.simStatus) {
    // If the status is green, the device is active with automatic renewal of its tariff.
    case SimCardDetailSimStatus.green:
      return <ActiveSimTile device={device} />;
    // If the status is yellow, the device and its tariff will become inactive after the remaining days.
    // If there's no flat speed package, it means that we do have an active roaming package,
    // so we show a different tile, which informs of this.
    case SimCardDetailSimStatus.yellow:
      if (!hasFlatSpeedPackage(device)) {
        return <ActiveSimNoPackageTile device={device} />;
      }
      return <ActiveSimTile device={device} />;
    // If the status is red, the device is currently blocked through customer service.
    case SimCardDetailSimStatus.red:
      return <SuspendedSimTile device={device} />;
    // If the status is grey, and no conditions above match,
    // the device has become inactive after running out of remaining days
    case SimCardDetailSimStatus.grey:
      return <InactiveSimTile device={device} />;
    default:
      return null;
  }
};

export const MetaTiles = (
  <>
    <AddDeviceTile />
    <UpsellTile />
  </>
);
