import clsx from "clsx";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { StyledLink } from "@/components/Interface/Button/Button";
import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { mobileAbos } from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import { TariffHeader } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffHeader/TariffHeader";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { SpeedAndData } from "@/components/Portal/Cockpit/SpeedAndData/SpeedAndData";
import { SimCardDetail, SimCardDetailSimStatusReason } from "@/services/model";
import {
  getSpeedFromLocalFlatSpeed,
  getTariffName,
  hasNumberPorting,
  hasWatchSimOrdered,
  isActive,
  isInactive,
} from "@/utils/deviceUtils";

import { AutomaticRenewalBarGraph } from "./AutomaticRenewalBarGraph";
import { CostDetails } from "./CostDetails/CostDetails";
import { FlatMobileCostDetails } from "./FlatMobileCostDetails";
import { TariffChangeDisabledTooltip } from "./TariffChangeDisabledTooltip";

export type Price = {
  base: number;
  voice?: number;
  watch?: number;
  total: number;
};

export const TariffDisplay: FC = () => {
  const { data: simDetail } = useDisplayedSim();

  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.tariff",
  });

  if (!simDetail) return null;

  const isSimActive = isActive(simDetail);
  const isSimInactive = isInactive(simDetail);
  const tariffName = getTariffName(simDetail);

  return (
    <div className="management-grid-layout">
      <section data-testid="tariff-section" className="management-section">
        <TariffHeader title={t("yourTariff")} className="mb-6" />
        <SectionButtonHeader header={t("tariffOptions")}>
          <SimActiveHeader simDetail={simDetail} />
        </SectionButtonHeader>
        <div
          className={clsx(
            "mt-2 font-semibold",
            simDetail.servicePending || isSimInactive
              ? "text-primary-50"
              : "text-primary-100",
          )}
        >
          {tariffName}
        </div>
        <SpeedAndData
          className={clsx(
            "w-fit",
            (simDetail.servicePending || isSimInactive) && "opacity-25",
          )}
          speed={
            simDetail?.servicePending
              ? "-"
              : getSpeedFromLocalFlatSpeed(simDetail.localFlatSpeed)
          }
        />
        {isSimActive && <AutomaticRenewalDisplay simDetail={simDetail} />}
        {isSimInactive && <BookTariffLink simDetail={simDetail} />}
      </section>
      {isSimActive && <TariffPriceSection simDetail={simDetail} />}
      {isSimInactive && <InactiveHint simDetail={simDetail} />}
    </div>
  );
};

const SimActiveHeader = ({ simDetail }: { simDetail: SimCardDetail }) => {
  const { t } = useTranslation();
  const isTariffChangeDisabled =
    simDetail?.servicePending || hasWatchSimOrdered(simDetail);
  return isTariffChangeDisabled ? (
    <TariffChangeDisabledTooltip simDetail={simDetail}>
      <span className="font-x-small text-primary-25 underline">
        {t("portal:common.buttons.change")}
      </span>
    </TariffChangeDisabledTooltip>
  ) : (
    <Link
      className="change-button"
      to={`../change?simId=${simDetail.simId}`}
      replace
    >
      {t("portal:common.buttons.change")}
    </Link>
  );
};

const InactiveHint = ({ simDetail }: { simDetail: SimCardDetail }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.tariff",
  });
  const isNumberPorting = hasNumberPorting(simDetail);
  return (
    <div className="mt-0 lg:mt-12">
      {simDetail.simStatusReason ===
      SimCardDetailSimStatusReason.LOCK_REQUESTED ? (
        <HighlightPanel headline={t("goodToKnow.warning")}>
          <span className="font-regular">
            <Trans
              t={t}
              i18nKey="goodToKnow.missingPaymentMeanHint"
              components={[
                <Link
                  className="text-primary-100 underline"
                  key="1"
                  replace
                  to="/portal/user/payments"
                />,
              ]}
            />
          </span>
        </HighlightPanel>
      ) : (
        <HighlightPanel>
          <span className="font-regular">
            {t(
              `goodToKnow.${isNumberPorting ? "numberPorting" : "inactiveHint"}`,
            )}
          </span>
        </HighlightPanel>
      )}
    </div>
  );
};

const SimChangeLink = ({ simDetail }: { simDetail: SimCardDetail }) => {
  const { t } = useTranslation();

  const buttonText = t(
    simDetail.autoRenew ? "common.buttons.disable" : "common.buttons.enable",
  );
  return (
    <SectionButtonHeader
      className="mt-14"
      header={t("portal:cockpit.managementTile.tariff.autoRenew")}
    >
      {simDetail?.servicePending ? (
        <span className="font-x-small text-primary-25 underline">
          {buttonText}
        </span>
      ) : (
        <Link
          className="change-button"
          to={`../automatic-renewal?simId=${simDetail?.simId}`}
          replace
        >
          {buttonText}
        </Link>
      )}
    </SectionButtonHeader>
  );
};

const TariffPriceSection = ({ simDetail }: { simDetail: SimCardDetail }) => {
  const isDowngrade = !!simDetail?.successorForRenew?.isDowngrade;
  const isMobileAbo = mobileAbos.includes(`${simDetail.serviceID}`);
  return (
    <section data-testid="tariff-price-section" className="management-section">
      {isMobileAbo ? (
        <FlatMobileCostDetails simDetail={simDetail} />
      ) : (
        <CostDetails simDetail={simDetail} isDowngrade={isDowngrade} />
      )}

      {isDowngrade && (
        <CostDetails
          className="mt-14 xl:mt-8"
          simDetail={simDetail}
          isDowngrade={isDowngrade}
          isFutureCosts={true}
        />
      )}
    </section>
  );
};

const BookTariffLink = ({ simDetail }: { simDetail: SimCardDetail }) => {
  const isNumberPorting = hasNumberPorting(simDetail);
  const { t } = useTranslation();
  return (
    <StyledLink
      className="accent w-full mt-14"
      to={`../change?simId=${simDetail.simId}`}
      replace
      disabled={
        isNumberPorting ||
        simDetail.simStatusReason ===
          SimCardDetailSimStatusReason.LOCK_REQUESTED
      }
    >
      {t("portal:cockpit.managementTile.tariff.bookTariff")}
    </StyledLink>
  );
};

const AutomaticRenewalDisplay = ({
  simDetail,
}: {
  simDetail: SimCardDetail;
}) => {
  return (
    <>
      <SimChangeLink simDetail={simDetail} />
      <AutomaticRenewalBarGraph />
    </>
  );
};
