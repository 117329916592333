import { useGetCustomer } from "@/services/api";
import { canOnlyBookData } from "@/utils/customerUtils";

export const useCanBookVoiceOption = () => {
  const { data: customerData } = useGetCustomer();

  const canBookVoiceOption = !(customerData && canOnlyBookData(customerData));

  return canBookVoiceOption;
};
