import { useEffect, useRef, useState } from "react";

import { useLogoutOnTwoFactorError } from "@/components/Welcome/AuthenticatorSetup/authenticator.utils";
import {
  useCancelSetup,
  useChangeTwoFactorAuthentication,
  useSetUpTwoFactorAuthentication,
} from "@/services/auth";
import {
  ChangeTwoFactorAuthenticationParams,
  SetUpTwoFactorAuthenticationReponse,
} from "@/services/model";

export const useRequestTwoFactorAppSetup = (
  useMutation:
    | typeof useSetUpTwoFactorAuthentication
    | typeof useChangeTwoFactorAuthentication,
  params: ChangeTwoFactorAuthenticationParams,
  shouldLogout = true,
) => {
  const [authSetupData, setAuthSetupData] = useState<
    SetUpTwoFactorAuthenticationReponse | undefined
  >(undefined);
  const hasRequestedSetup = useRef(false);

  const { requestChange, data } = useRequestWithCancel(
    useMutation,
    params,
    shouldLogout,
  );

  useEffect(() => {
    if (hasRequestedSetup.current) return;
    hasRequestedSetup.current = true;
    requestChange().then((data) => {
      setAuthSetupData(data);
    });
  }, [requestChange]);

  return { authSetupData: data || authSetupData };
};

const useRequestWithCancel = (
  useMutation:
    | typeof useSetUpTwoFactorAuthentication
    | typeof useChangeTwoFactorAuthentication,
  params: ChangeTwoFactorAuthenticationParams,
  shouldLogout = true,
) => {
  const [data, setData] = useState<SetUpTwoFactorAuthenticationReponse | null>(
    null,
  );
  const logoutOnError = useLogoutOnTwoFactorError();

  const { mutateAsync: cancelSetup } = useCancelSetup({
    mutation: {
      onSuccess: () => {
        // We need to wait before we can request a new setup
        setTimeout(() => {
          requestChange({ params }).then((data) => {
            setData(data);
          });
        }, 5000);
      },
      onError: () => null,
    },
  });

  const { mutateAsync: requestChange } = useMutation({
    mutation: {
      onError: (error: any) => {
        if (shouldLogout) logoutOnError(error);
        if (
          (error.response?.status === 400 &&
            error.response.data?.errorCode === 403) ||
          (error.response?.status === 500 &&
            error.response.data.includes(
              "TwoFactorAuthentication change in progress",
            ))
        ) {
          cancelSetup();
        }
      },
    },
  });

  return { requestChange: () => requestChange({ params }), data };
};
