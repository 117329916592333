import "./ActiveSimTile.scss";

import { useTranslation } from "react-i18next";
import { DeviceFC } from "types";

import { RemainingTime } from "@/components/Portal/Cockpit/RemainingTime/RemainingTime";
import { RoamingUsage } from "@/components/Portal/Cockpit/RoamingUsage/RoamingUsage";
import { SpeedAndData } from "@/components/Portal/Cockpit/SpeedAndData/SpeedAndData";
import {
  DeviceTile,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import {
  getFlatNameFromDevice,
  getIconForDevice,
  getSpeedFromLocalFlatSpeed,
} from "@/utils/deviceUtils";

export const ExpiresSoonFlag = () => {
  const { t } = useTranslation("portal");
  return (
    <span data-testid="expires-soon" className="expires-soon">
      {t("layout.tiles.activeSim.expiresSoon")}
    </span>
  );
};

export const ActiveSimTile: DeviceFC = ({ device }) => {
  const icon = getIconForDevice(device.device?.description);
  const { t } = useTranslation("portal");
  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );

  return (
    <DeviceTile
      navigationTarget={`../manage?simId=${device.simId}`}
      icon={icon}
      dataTestid="active-sim"
      className="active-sim-tile"
    >
      {/* The check here has to be === false, because the property can be undefined, !device.autoRenew wouldn't be enough. */}
      {(device.remainingDays || 0) <= 4 && device.autoRenew === false && (
        <ExpiresSoonFlag />
      )}
      <h2 data-testid="tile-title" className="tile-title">
        {title}
      </h2>
      <h3 className="tile-subtitle">{getFlatNameFromDevice(device)}</h3>
      <div id="active-sim-content">
        <SpeedAndData
          speed={getSpeedFromLocalFlatSpeed(device.localFlatSpeed)}
        />
        {device.autoRenew !== undefined &&
          device.remainingDays !== undefined &&
          device.minContractDuration !== undefined && (
            <RemainingTime
              totalTime={device.minContractDuration}
              remainingTime={device.remainingDays ?? 0}
              hasRenewal={device.autoRenew}
              successorForRenew={
                device.successorForRenew?.id !== device.serviceID
                  ? device.successorForRenew
                  : undefined
              }
            />
          )}
        {device.roamingInfo && <RoamingUsage device={device} />}
      </div>
    </DeviceTile>
  );
};
