export const PORTAL_BASE_ROUTE = "/portal/";
export const portalRoutes = {
  cockpit: {
    base: PORTAL_BASE_ROUTE + "cockpit",
    tiles: PORTAL_BASE_ROUTE + "cockpit/tiles",
    manage: PORTAL_BASE_ROUTE + "cockpit/manage",
    tariff: {
      base: PORTAL_BASE_ROUTE + "cockpit/manage/tariff",
      display: PORTAL_BASE_ROUTE + "cockpit/manage/tariff/display",
      change: PORTAL_BASE_ROUTE + "cockpit/manage/tariff/change",
      checkout: PORTAL_BASE_ROUTE + "cockpit/manage/tariff/checkout",
      automaticRenewal:
        PORTAL_BASE_ROUTE + "cockpit/manage/tariff/automatic-renewal",
    },
    roaming: {
      base: PORTAL_BASE_ROUTE + "cockpit/manage/roaming",
      display: PORTAL_BASE_ROUTE + "cockpit/manage/roaming/display",
      checkout: PORTAL_BASE_ROUTE + "cockpit/manage/roaming/checkout",
      booking: PORTAL_BASE_ROUTE + "cockpit/manage/roaming/booking",
    },
    voice: PORTAL_BASE_ROUTE + "cockpit/manage/voice",
    watchSim: {
      base: PORTAL_BASE_ROUTE + "cockpit/manage/watch-sim",
      display: PORTAL_BASE_ROUTE + "cockpit/manage/watch-sim/display",
      checkout: PORTAL_BASE_ROUTE + "cockpit/manage/watch-sim/checkout",
      automaticRenewal:
        PORTAL_BASE_ROUTE + "cockpit/manage/watch-sim/automatic-renewal",
    },
    details: {
      base: PORTAL_BASE_ROUTE + "cockpit/manage/details",
      display: PORTAL_BASE_ROUTE + "cockpit/manage/details/display",
      reset: PORTAL_BASE_ROUTE + "cockpit/manage/details/display/reset",
      reload: PORTAL_BASE_ROUTE + "cockpit/manage/details/reload",
      transfer: PORTAL_BASE_ROUTE + "cockpit/manage/details/transfer",
      physicalToEsim:
        PORTAL_BASE_ROUTE + "cockpit/manage/details/physicalToEsim",
    },
    list: PORTAL_BASE_ROUTE + "cockpit/list",
  },
  addDevice: {
    base: PORTAL_BASE_ROUTE + "add-device",
    step1: PORTAL_BASE_ROUTE + "add-device/1",
    simSelection: PORTAL_BASE_ROUTE + "add-device/1/sim-selection",
    simVerification: PORTAL_BASE_ROUTE + "add-device/1/sim-verification",
    step2: PORTAL_BASE_ROUTE + "add-device/2",
    deviceType: PORTAL_BASE_ROUTE + "add-device/2/device-type",
    step3: PORTAL_BASE_ROUTE + "add-device/3",
    rateSelection: PORTAL_BASE_ROUTE + "add-device/3/rate-selection",
    numberPorting: PORTAL_BASE_ROUTE + "add-device/3/number-porting",
    step4: PORTAL_BASE_ROUTE + "add-device/4",
    checkout: PORTAL_BASE_ROUTE + "add-device/4/checkout",
  },
  user: {
    base: PORTAL_BASE_ROUTE + "user",
    personal: PORTAL_BASE_ROUTE + "user/personal",
    payments: PORTAL_BASE_ROUTE + "user/payments",
    inbox: PORTAL_BASE_ROUTE + "user/inbox",
    security: {
      base: PORTAL_BASE_ROUTE + "user/security",
      //###########################################################################
      //############### INITIAL SETUP INSIDE PORTAL
      initialSetup: PORTAL_BASE_ROUTE + "user/security/initial-setup",
      initialSmsSetup: PORTAL_BASE_ROUTE + "user/security/initial-sms-setup",
      initialSmsConfirm:
        PORTAL_BASE_ROUTE + "user/security/initial-sms-confirm",
      initialAppSetup: PORTAL_BASE_ROUTE + "user/security/initial-app-setup",
      //###########################################################################
      //
      //###########################################################################
      //############### CHANGE OF EXISTING 2FA
      change2faApp: PORTAL_BASE_ROUTE + "user/security/change-app",
      change2faSms: PORTAL_BASE_ROUTE + "user/security/change-sms",
      //############### CHANGE TO SMS
      setup2faSms: PORTAL_BASE_ROUTE + "user/security/setup-sms",
      verify2faSms: PORTAL_BASE_ROUTE + "user/security/verify-sms",
      //############### CHANGE SMS NUMBER
      verify2faNumberChange:
        PORTAL_BASE_ROUTE + "user/security/verify-number-change",
      //############### CHANGE TO APP (ONLY 1 SCREEN SINCE CONFIRM + VERIFY IS SHOWN)
      setup2faApp: PORTAL_BASE_ROUTE + "user/security/setup-app",
      //############### CONFIRMATIONS
      confirmBackupCodeReception:
        PORTAL_BASE_ROUTE + "user/security/confirm-backup-codes",
      confirmStandard: PORTAL_BASE_ROUTE + "user/security/confirm-standard",
      //###########################################################################
    },
  },
  bills: PORTAL_BASE_ROUTE + "bills",
  referral: PORTAL_BASE_ROUTE + "referral",
  notFound: PORTAL_BASE_ROUTE + "*",
};
