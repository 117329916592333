import { FCC } from "types";

import { BlueSidemenuBlob } from "@/components/Layout/BlueSidemenuBlob/BlueSidemenuBlob";
import { CollapsibleLanguageMenu } from "@/components/Layout/Footer/CollapsibleLanguageMenu";
import { CollapsibleLinkMenu } from "@/components/Layout/Footer/CollapsibleLinkMenu";
import { SupportLinkItem } from "@/components/Layout/Footer/SupportLinkItem";

export const Sidemenu: FCC = ({ children }) => (
  <div className="h-[70vh] max-h-[756px] w-[474px] absolute bottom-0 left-0 pt-32 pb-10">
    <BlueSidemenuBlob />
    <nav
      className="h-[inherit] fixed flex bottom-0 left-0 flex-col ml-10 pt-36 z-30 justify-between w-44 gap-8"
      id="menu-items"
    >
      {children}
      <div className="flex mt-auto mb-10 flex-row gap-4 items-center">
        <CollapsibleLanguageMenu />
        <SupportLinkItem />
        <CollapsibleLinkMenu />
      </div>
    </nav>
  </div>
);
