import { useTranslation } from "react-i18next";

import { Button } from "@/components/Interface/Button/Button";
import { LandingBlob } from "@/components/Welcome/LandingBlob/LandingBlob";
import { useAnonymousLogin } from "@/hooks/useAnonymousLogin";

export const WelcomeEsimContent = () => {
  const { t } = useTranslation();
  const loginAsAnonymous = useAnonymousLogin();

  return (
    <LandingBlob>
      <div id="text-and-buttons">
        <h1 className="mb-6 mt-[17rem] md:mt-12 text-secondary-100">
          {t("EsimLanding.title")}
        </h1>

        <p className="mb-10 max-w-[600px]">{t("EsimLanding.description")}</p>

        <div className="flex flex-col md:flex-row gap-4 md:gap-6 w-[210px] md:w-auto">
          <Button
            className="min-w-full md:min-w-fit"
            onClick={loginAsAnonymous}
          >
            {t("Content.button.start")}
          </Button>
        </div>
      </div>

      <p className="text-base mt-10 md:mt-20">
        {t("EsimLanding.identificationHint")}
      </p>
    </LandingBlob>
  );
};
