import { Provider } from "jotai/react";
import { createStore } from "jotai/vanilla";
import { FC } from "react";
import { Outlet } from "react-router-dom";

import { Background } from "@/components/Layout/Background/Background";
import { Footer } from "@/components/Layout/Footer/Footer";
import { WelcomeHeader } from "@/components/Welcome/WelcomeHeader/WelcomeHeader";

export const authenticatorStore = createStore();

export const AuthenticatorLayout: FC = () => (
  <Provider store={authenticatorStore}>
    <Background>
      <WelcomeHeader />
      <div className="flex mt-16 md:mt-40 2xl:mt-4">
        <div className="bg-white flex flex-col items-center justify-center w-fit max-w-4xl h-fit m-4 md:m-auto mt-14 md:mt-auto p-10">
          <Outlet />
        </div>
      </div>
      <Footer className="welcome-screen" />
    </Background>
  </Provider>
);
