import { AuthenticatorSmsSetup } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsSetup";
import { portalRoutes } from "@/router/routes/portalRoutes.ts";

export const TwoFactorInitialSmsSetupView = () => (
  <div className="max-w-[560px]">
    <AuthenticatorSmsSetup
      successTarget={portalRoutes.user.security.initialSmsConfirm}
    />
  </div>
);
