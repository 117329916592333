import { createContext, FC, PropsWithChildren, useState } from "react";

import { FullScreenLoadingSpinner } from "@/components/Layout/FullScreenLoadingSpinner/FullScreenLoadingSpinner";

export type FullScreenLoadingType = {
  setFullScreenLoading: (isLoading: boolean, message?: string) => void;
  isLoading: boolean;
};
export const FullScreenLoadingContext = createContext<FullScreenLoadingType>({
  setFullScreenLoading: () => undefined,
  isLoading: false,
});

// This provider is used to wrap the entire app and provide a loading state
// that shows a loading spinner and a message in the center of the screen.
export const FullScreenLoadingProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <FullScreenLoadingContext.Provider
      value={{
        setFullScreenLoading: (isLoading: boolean, message?: string) => {
          if (message) {
            setMessage(message);
          }
          setIsLoading(isLoading);
        },
        isLoading,
      }}
    >
      {isLoading && <FullScreenLoadingSpinner message={message} />}
      {children}
    </FullScreenLoadingContext.Provider>
  );
};
