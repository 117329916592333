import { useSetAtom } from "jotai";
import { useCallback } from "react";

import { AlertInterface, alertListAtom } from "@/utils/atoms";

export const useAlert = () => {
  const setAlertList = useSetAtom(alertListAtom);

  const showAlert = useCallback(
    (alert: AlertInterface) => setAlertList((prev) => [...prev, alert]),
    [setAlertList],
  );

  return showAlert;
};
