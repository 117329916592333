import * as React from "react";
import { useSearchParams } from "react-router-dom";

export interface NavigateOptions {
  replace?: boolean;
  state?: any;
}

export const useQueryParam = (key: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key) || "";
  const value = React.useMemo(() => paramValue, [paramValue]);

  const setValue = React.useCallback(
    (newValue: string | undefined, options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);
      if (!newValue) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, newValue);
      }
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams],
  );

  return [value, setValue] as const;
};
