import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { Globe } from "@/assets/icons/icons";
import { MenuItemButton } from "@/components/Interface/MenuItems/MenuItemButton";
import { Collapsible } from "@/components/Layout/Collapsible/Collapsible";

export const getLanguages = (t: TFunction) => [
  { label: t("Languages.english"), code: "en" },
  { label: t("Languages.german"), code: "de" },
  { label: t("Languages.french"), code: "fr" },
];

/**
 * This component is used to display the language menu in the header.
 * It is a collapsible menu that displays the available languages.
 **/
export const CollapsibleLanguageMenu = () => {
  const { i18n, t } = useTranslation();
  return (
    <Collapsible
      id="language-menu"
      icon={
        <Globe className="w-6 [&>path]:stroke-white [&>path]:fill-white xl:[&>path]:fill-primary-100 group-hover:[&>path]:fill-white" />
      }
      label={t("Common.language")}
    >
      {getLanguages(t).map(({ label, code }) => (
        <MenuItemButton
          key={`${code}-${label}`}
          label={label}
          selected={i18n?.resolvedLanguage === code}
          buttonHandler={() => i18n.changeLanguage(code)}
        />
      ))}
    </Collapsible>
  );
};
