// This hook checks if the user is currently an anonymous user.
// In the eSIM journey, the user is anonymous until they either login or create

import { useAtom } from "jotai";

import { refreshTokenAtom } from "@/utils/atoms";
import { anonymousLoginStatus } from "@/utils/authentication";

// a user in step 4 of the onboarding.
export const useIsAnonymousUser = () => {
  const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom);

  const setAnonymousUser = (isAnonymousUser: boolean) => {
    if (isAnonymousUser) {
      setRefreshToken(anonymousLoginStatus);
      return;
    }
    setRefreshToken(null);
  };

  return [refreshToken === anonymousLoginStatus, setAnonymousUser] as const;
};
