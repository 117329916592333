import { useContext } from "react";
import { UseFormReturn } from "react-hook-form";

import {
  AuthAndPersonalDetailsFormFields,
  CustomerTypeAndSegment,
} from "@/components/Onboarding/Steps/PersonalDetails/personalDetails.types.ts";
import { DataContext } from "@/provider/DataContextProvider.tsx";
import { useUpdateCustomer } from "@/services/api.ts";
import { BasicCustomerData } from "@/services/model";
import {
  transformBasicCustomerDataToPersonalDetailsForm,
  transformPersonalDetailsFormToBasicCustomerData,
} from "@/utils/customerUtils.ts";
import { log } from "@/utils/log";

export const useHandleUpdateCustomer = (
  persistCustomerAndGoToCheckout: any,
  formMethods: UseFormReturn<AuthAndPersonalDetailsFormFields>,
  formValues: AuthAndPersonalDetailsFormFields,
): {
  handleCustomerUpdate: handleUpdateCustomerFunction;
  isUpdatingCustomer: boolean;
} => {
  const { mutateAsync: updateCustomer, isLoading: isUpdatingCustomer } =
    useUpdateCustomer();
  const { setDataContext } = useContext(DataContext);

  function handleCustomerUpdate(
    customerData: BasicCustomerData,
    customerTypeAndCustomerSegment: CustomerTypeAndSegment,
  ) {
    const customerDataToSubmit = {
      ...transformPersonalDetailsFormToBasicCustomerData(
        formValues,
        customerData,
      ),
      ...customerTypeAndCustomerSegment,
    };

    updateCustomer({
      data: customerDataToSubmit,
    })
      .then((data) => {
        setDataContext((prev) => ({
          ...prev,
          personalDetails:
            transformBasicCustomerDataToPersonalDetailsForm(data),
        }));
        persistCustomerAndGoToCheckout(customerDataToSubmit);
      })
      .catch((error) => {
        if (`${error?.response?.data}`.includes("invalid phone number")) {
          formMethods.setError("phone", {
            type: "backendError",
          });
        } else {
          log("Error in customer form: " + error);
        }
      });
  }

  return {
    handleCustomerUpdate,
    isUpdatingCustomer,
  };
};

export type handleUpdateCustomerFunction = (
  customerData: BasicCustomerData,
  customerTypeAndCustomerSegment: CustomerTypeAndSegment,
) => void;
