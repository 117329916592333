import { setUser } from "@sentry/react";
import { useIsFetching } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";

import { PortalHeading } from "@/components/Portal/PortalHeading/PortalHeading";
import { useAllSims } from "@/hooks/useAllSims";
import { getGetSimListQueryKey, useGetCustomer } from "@/services/api";
import { GetSimListSimStatusItem } from "@/services/model";

export const WelcomeText = () => {
  const { t } = useTranslation("portal");
  const simListIsLoading =
    useIsFetching({ queryKey: [getGetSimListQueryKey] }) > 0;
  const simCards = useAllSims();

  const activeCount =
    simCards?.filter(
      ({ simStatus }) =>
        simStatus === GetSimListSimStatusItem.GREEN.toLowerCase() ||
        simStatus === GetSimListSimStatusItem.YELLOW.toLowerCase(),
    ).length || 0;
  const noAutoRenewal =
    simCards?.filter(
      ({ simStatus }) =>
        simStatus === GetSimListSimStatusItem.YELLOW.toLowerCase(),
    ).length || 0;
  const suspendedCount =
    simCards?.filter(
      ({ simStatus }) =>
        simStatus === GetSimListSimStatusItem.RED.toLowerCase(),
    ).length || 0;

  const { data: customer, isLoading: customerIsLoading } = useGetCustomer(
    undefined,
    {
      query: {
        onSuccess: (data) => {
          setUser({ id: data.id?.toString() });
        },
        // Safe to cache for 5 minutes, as the name will never change.
        staleTime: 5 * 60 * 1000,
      },
    },
  );
  const customerName = customer?.firstName || "User";

  const isLoading = customerIsLoading || simListIsLoading;
  const headingText = `${t("cockpit.heading.hi")} ${customerName}`;
  return (
    <PortalHeading isLoading={isLoading} heading={headingText}>
      <p>{t("cockpit.heading.welcomeText")}</p>
      <p className="min-h-[45px] xl:min-h-[unset]">
        <Trans
          t={t}
          values={{ count: activeCount }}
          components={[
            <span key="0" className="text-primary-100 font-semibold" />,
          ]}
        >
          cockpit.heading.activeDeviceText
        </Trans>
        {(noAutoRenewal > 0 || isLoading) && (
          <>
            {", "}
            <Trans
              t={t}
              values={{ count: noAutoRenewal }}
              components={[
                <span key="0" className="text-secondary-100 font-semibold" />,
              ]}
            >
              cockpit.heading.devicesWithoutRenewal
            </Trans>
          </>
        )}

        <span>.</span>
      </p>
      {suspendedCount > 0 && (
        <p className="mt-4">
          <span className="text-secondary-100 font-semibold">
            {t("cockpit.heading.suspendedDevice", { count: suspendedCount })}
          </span>
        </p>
      )}
    </PortalHeading>
  );
};
