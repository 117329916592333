import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ListBox } from "@/components/Interface/FormFields/ListBox/ListBox";
import { DetailsChangeRow } from "@/components/Portal/Cockpit/DeviceManagement/Details/DetailsChangeRow/DetailsChangeRow";
import { useGetDevices } from "@/services/api";
import { Device } from "@/services/model";
import {
  DeviceName,
  deviceNameToValueMap,
  getIconForDevice,
} from "@/utils/deviceUtils";

export const DeviceTypeRow = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const device = watch("device");
  const { data: devices } = useGetDevices();

  const renderOptionWithIcon = (deviceType: Device, selected: boolean) => (
    <div className={clsx("flex", { selected })}>
      <div className="icon my-auto">
        {getIconForDevice(deviceType.description)}
      </div>
    </div>
  );

  return (
    <DetailsChangeRow
      header={t("common.device.attributes.deviceType")}
      label={t(
        `Common.devices.${
          deviceNameToValueMap[
            (device?.description as DeviceName) || "Smartphone"
          ]
        }`,
      )}
    >
      {devices && device && (
        <ListBox<Device>
          className="text-black"
          dataTestId="device-listbox"
          availableValues={devices}
          placeholder={device}
          selected={device}
          by={(a, b) => a.id === b.id}
          setSelected={(value) => setValue("device", value)}
          displayValue={(value) =>
            t(
              `Common.devices.${
                deviceNameToValueMap[
                  (value?.description as DeviceName) || "Smartphone"
                ]
              }`,
            )
          }
          renderOption={renderOptionWithIcon}
        />
      )}
    </DetailsChangeRow>
  );
};
