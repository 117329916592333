import "./EsimDisabledActionsTooltip.scss";

import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";
import { ESimInfoEsimactionNotAllowedReasonItem } from "@/services/model";

interface Props {
  disabledButtonText: string;
  reasons: ESimInfoEsimactionNotAllowedReasonItem[];
}

// The following tooltip is needed to let the user know why the functionality in their reload/transfer button
// is disabled. It reads from an array of reasons from the BE endpoint EsimInfoEsimActionNotAllowed and once the user
// and appears once the user tired clicking on the buttons, thus helping them know why the function is unavailable.
export const EsimDisabledActionsTooltip: FC<Props> = ({
  disabledButtonText,
  reasons,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.details.sections.details",
  });

  // Reasons from api
  const {
    SimSuspended,
    SimInactive,
    NoMoreActions,
    WrongSimType,
    NoAutoRenew,
    SimNoActivePackage,
    SimTerminated,
    SimCancelled,
    StatusNotAllowed,
  } = ESimInfoEsimactionNotAllowedReasonItem;

  const mapReasonsToTranslation = (
    reasons: ESimInfoEsimactionNotAllowedReasonItem[],
  ) => {
    return reasons
      .map((reason) => {
        switch (reason) {
          case SimSuspended:
            return t("reasons.simSuspended");
          case SimInactive:
            return t("reasons.simInactive");
          case NoMoreActions:
            return t("reasons.noMoreActions");
          case WrongSimType:
            return t("reasons.wrongSimType");
          case NoAutoRenew:
            return t("reasons.noAutoRenew");
          case SimNoActivePackage:
            return t("reasons.simNoActivePackage");
          case SimTerminated || SimCancelled:
            return t("reasons.simTerminated");
          case StatusNotAllowed:
            return t("reasons.StatusNotAllowed");
          default:
            return "";
        }
      })
      .filter((reason) => reason.length);
  };
  const translatedReasons = mapReasonsToTranslation(reasons);

  return (
    <Tooltip
      className="w-72"
      dataTestId="esim-action-disabled"
      content={
        <>
          <h5 className="text-base">
            {t("portal:common.tooltip.actionNotPossible.title")}
          </h5>
          <p className="text-black font-x-small">
            {t("portal:common.tooltip.actionNotPossible.text", {
              count: translatedReasons.length,
            })}
          </p>
          <ul className="font-x-small text-black list-disc list-outside pl-4">
            {translatedReasons?.map((reason: string) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </>
      }
      position="top"
      leftOnMobile
    >
      <p className="disabled-button">{disabledButtonText}</p>
    </Tooltip>
  );
};
