import "./PortalHeading.scss";

import clsx from "clsx";
import { FCC } from "types";

export const PortalHeading: FCC<{ isLoading?: boolean; heading: string }> = ({
  heading,
  isLoading,
  children,
}) => {
  return (
    <div
      id="portal-heading"
      data-testid="portal-heading"
      className={clsx(isLoading && "isLoading")}
    >
      <span className="portal-title">{heading}</span>
      {children}
    </div>
  );
};
