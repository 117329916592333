import "./Cockpit.scss";

import clsx from "clsx";
import { FC } from "react";
import { Outlet, useMatch } from "react-router-dom";

import { CockpitMenu } from "@/components/Portal/Cockpit/CockpitMenu/CockpitMenu";
import { WelcomeText } from "@/components/Portal/Cockpit/WelcomeText/WelcomeText";

import { UserWarning } from "./UserWarning/UserWarning";

export const Cockpit: FC = () => {
  const isManagement = useMatch("/portal/cockpit/tiles/manage");

  return (
    <div className="portal-content-wrapper">
      <WelcomeText />
      <UserWarning />

      <div className={clsx("cockpit-wrapper", isManagement && "management")}>
        <CockpitMenu />
        <Outlet />
      </div>
    </div>
  );
};
