import { RadioGroup } from "@headlessui/react";
import { useContext } from "react";

import { useIsTariffChange } from "@/hooks/useIsTariffChange";
import { DataContext } from "@/provider/DataContextProvider";
import {
  TransformedProduct,
  useGetPrechargedOptionForBasket,
} from "@/utils/tariffUtils";

import { MobileAboCard } from "./MobileAboCard";

export const MobileAboForm = ({
  value,
  setValue,
  mobileAbos,
}: {
  value: string | undefined;
  setValue: (value: string) => void;
  mobileAbos: TransformedProduct[];
}) => {
  const prechargedProduct = usePrechargedProductIfBasketExists();
  return (
    <div className="flex justify-center mt-8">
      <RadioGroup
        className="flex gap-10 flex-row flex-wrap justify-center"
        value={value ?? ""}
        onChange={setValue}
      >
        {mobileAbos.map((product) => (
          <MobileAboCard
            disabled={
              prechargedProduct !== undefined &&
              prechargedProduct.id !== product.id
            }
            value={value}
            product={product}
            key={product.id}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

const useDataContext = () => {
  const { dataContext } = useContext(DataContext);
  return dataContext;
};

const usePrechargedProductIfBasketExists = () => {
  const isTariffChange = useIsTariffChange();
  const { basket } = useDataContext();
  return useGetPrechargedOptionForBasket(isTariffChange ? undefined : basket);
};
