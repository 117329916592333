import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { verifierAtom } from "@/utils/atoms";
import { generateVerifier, getAuthorizeURL } from "@/utils/authentication";

export const useRedirectToAuthorizationService = () => {
  const { i18n } = useTranslation();
  const setVerifier = useSetAtom(verifierAtom);
  // Redirect the user to the login provider
  const redirectToAuthorize = async () => {
    // Generate code-challenge
    const newVerifier = generateVerifier();
    setVerifier(newVerifier);

    // If we have a german language, we redirect to the german single sign on provider.
    // If not, we default to english.
    const url = await getAuthorizeURL(
      i18n?.resolvedLanguage ?? "en",
      newVerifier,
    );

    window.location.assign(url);
  };

  return redirectToAuthorize;
};
