import "./BookedRoamingOption.scss";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { ConnectionIcon, Costs } from "@/assets/icons/icons";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { mobileAboRoamingPackages } from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import { CountrylistDialog } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/AddRoamingOption/CountryList/CountryList";
import { RoamingUsage } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/ShowRoamingOptions/RoamingUsage/RoamingUsage";
import { RoamingInfo, RoamingInfoRoamingStatus } from "@/services/model";
import { stripFlatName } from "@/utils/deviceUtils";
import { getCurrency } from "@/utils/translationHelpers";

type BookedRoamingOptionProps = {
  index: number;
  isFirstOfUsageZone: boolean;
  roamingInfo: RoamingInfo;
};

/**
 * This component displays an already booked roaming option.
 * It can be either active, ready or ordered.
 *
 * Active means roaming option is in use, the user is in the given country.
 * Ready means roaming option is waiting for the user to use it, but they are not in the country yet.
 * Ordered means that the option is still pending and not yet ready to be used.
 *
 * @param roaming - The RoamingInfo that this component displays
 * @param index - The index of the RoamingInfo inside the list of roaming options.
 */
export const BookedRoamingOption = ({
  index,
  isFirstOfUsageZone,
  roamingInfo,
}: BookedRoamingOptionProps) => {
  const { t } = useTranslation("portal");
  const status = roamingInfo.roamingStatus;
  const isMobileAboRoaming =
    !!roamingInfo.optionId &&
    mobileAboRoamingPackages.includes(roamingInfo.optionId);
  const isMonthlyMobileAboRoaming =
    isMobileAboRoaming && roamingInfo.optionId === 131208;

  const isActive = status === RoamingInfoRoamingStatus.active;
  const isOrdered = status === RoamingInfoRoamingStatus.ordered;

  const title = isMobileAboRoaming
    ? t("mobileAbo.name")
    : t("cockpit.managementTile.roaming.package", {
        index: index + 1,
      });

  const name = isMobileAboRoaming
    ? t("mobileAbo.nameRoaming")
    : stripFlatName(roamingInfo.shortName);
  const flatName = isOrdered ? "-" : name;
  const roamingVolume = isOrdered ? "-" : `${roamingInfo?.initialVolume} MB`;

  const roamingCosts = isMobileAboRoaming
    ? t("mobileAbo.included")
    : getCurrency(roamingInfo.charge || 0, "CHF");
  const roamingCostsText = isOrdered ? "-" : roamingCosts;

  return (
    <div className="roaming-option" data-testid="roaming-option">
      <h3 className="title">{title}</h3>

      <div className="mb-14">
        <SectionButtonHeader
          className="mb-2 h-6"
          header={t("cockpit.managementTile.roaming.availableOptions")}
        >
          {isActive && <CountrylistDialog />}
        </SectionButtonHeader>

        <span
          className={clsx(
            "font-semibold",
            isActive ? "text-primary-100" : "text-primary-50",
          )}
        >
          {flatName}
        </span>

        <div className={clsx("icon-wrapper", !isActive && "opacity-25")}>
          <div className="icon-row">
            <ConnectionIcon />
            {roamingVolume}
          </div>

          <div className="icon-row">
            <Costs />
            {roamingCostsText}
          </div>
        </div>
      </div>

      <RoamingUsage
        showFlatMobileExplanation={isMonthlyMobileAboRoaming}
        className="mb-4"
        days={roamingInfo.remainingDays || 0}
        description="cockpit.managementTile.roaming.showOption.active.remainingData"
        initial={roamingInfo.initialVolume || 0}
        initialDescription={roamingInfo.initialVolume || 0}
        isFirstOfUsageZone={isFirstOfUsageZone}
        remaining={roamingInfo.remainingVolume || 0}
        remainingDescription={
          (roamingInfo.initialVolume || 0) - (roamingInfo.remainingVolume || 0)
        }
        roamingInfo={roamingInfo}
        title={t("cockpit.managementTile.roaming.showOption.active.dataUsage")}
      />

      {isMonthlyMobileAboRoaming ? (
        <MobileAboRoamingHints roamingInfo={roamingInfo} />
      ) : (
        <RoamingUsage
          roamingInfo={roamingInfo}
          days={roamingInfo.remainingDays || 0}
          description="cockpit.managementTile.roaming.showOption.active.runtimeUsage"
          initial={roamingInfo.initialDays || 0}
          initialDescription={roamingInfo.initialDays || 0}
          isFirstOfUsageZone={isFirstOfUsageZone}
          remaining={roamingInfo.remainingDays || 0}
          remainingDescription={roamingInfo.remainingDays || 0}
          title={t("cockpit.managementTile.roaming.showOption.active.runtime")}
        />
      )}
    </div>
  );
};

const MobileAboRoamingHints = ({
  roamingInfo,
}: {
  roamingInfo: RoamingInfo;
}) => {
  const { t } = useTranslation("portal");

  const isUsedUpMobileAboRoaming =
    mobileAboRoamingPackages.includes(roamingInfo.optionId || 0) &&
    roamingInfo.remainingVolume === 0;

  if (isUsedUpMobileAboRoaming) {
    return null;
  }

  // If the initial volume is less than 2048 MB, we started the mobile abo
  // somewhere in the middle of the month. In this case, we show a hint to the user
  // which explains the odd volume.
  if (roamingInfo.initialVolume && roamingInfo.initialVolume < 2048) {
    return (
      <p className="text-base">
        <b className="text-primary-100">
          {t("cockpit.managementTile.roaming.showOption.flatMobileHint.title")}
        </b>
        <br />
        {t("cockpit.managementTile.roaming.showOption.flatMobileHint.text")}
      </p>
    );
  } else {
    return (
      <p className="text-base text-primary-100">
        {t(
          "cockpit.managementTile.roaming.showOption.flatMobileHint.renewalReminder",
        )}
      </p>
    );
  }
};
