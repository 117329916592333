import { useQueryClient } from "@tanstack/react-query";

import { useGetSimQueryParamFilter } from "@/hooks/useGetSimQueryParamFilter";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import {
  getGetSimListQueryKey,
  getGetSimQueryKey,
  useUpdateSim,
} from "@/services/api";
import { SimCardDetail, SimOverview } from "@/services/model";

export const useSimMutation = () => {
  const filters = useGetSimQueryParamFilter();
  const queryClient = useQueryClient();

  const [simId] = useSimIdFromQueryParam();

  return useUpdateSim({
    mutation: {
      onSuccess: (data) => {
        queryClient.setQueryData(
          getGetSimQueryKey({ simId: parseInt(simId) }),
          (oldData: SimCardDetail | undefined) => ({
            ...oldData,
            device: data.device ?? oldData?.device,
            alias: data.alias ?? oldData?.alias,
          }),
        );

        const simListQueryKey = getGetSimListQueryKey(filters);
        const simList = queryClient.getQueryData<SimOverview>(simListQueryKey);

        if (simList) {
          queryClient.setQueryData(simListQueryKey, {
            ...simList,
            simCards: simList.simCards?.map((sim) =>
              sim.simId === Number(simId) ? data : sim,
            ),
          });
        }
      },
    },
  });
};
