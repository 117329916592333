import { useAtom } from "jotai";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import {
  useChangeTwoFactorAuthentication,
  useChangeTwoFactorAuthenticationSMSNumber,
  useSetUpTwoFactorAuthentication,
} from "@/services/auth";
import { AlertTypes, phoneNumberAtom } from "@/utils/atoms";

import { useAlert } from "./useAlert";

export const useRequestSmsToken = (
  useMutation:
    | typeof useChangeTwoFactorAuthentication
    | typeof useSetUpTwoFactorAuthentication
    | typeof useChangeTwoFactorAuthenticationSMSNumber,
  params: any,
) => {
  const [phoneNumber] = useAtom(phoneNumberAtom);
  const { t } = useTranslation();
  const showToast = useAlert();
  const { mutateAsync: resendSmsToken } = useMutation();
  const resendCode = debounce(
    () => {
      resendSmsToken({
        params: {
          ...params,
          phoneNumber,
        },
      })
        .then(() => {
          showToast({
            text: t(
              "portal:authenticator.sms.verification.resendToast.success",
            ),
            type: AlertTypes.success,
            title: "Success",
          });
        })
        .catch(() => {
          showToast({
            text: t("portal:authenticator.sms.verification.resendToast.error"),
            type: AlertTypes.error,
            title: "Error",
          });
        });
    },
    1000,
    {
      leading: true,
      trailing: false,
    },
  );

  return resendCode;
};
