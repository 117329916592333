import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Interface/Button/Button";
import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { Modal } from "@/components/Interface/Modal/Modal";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { TwoFactorSMSValidation } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsConfirmForm";
import {
  TwoFactorConfirmationCodeForm,
  TwoFactorConfirmationInput,
} from "@/components/Welcome/AuthenticatorSetup/TwoFactorConfirmationInput";
import { useAlert } from "@/hooks/useAlert";
import { useTwoFactorAuthStatus } from "@/hooks/useTwoFactorAuthStatus";
import { useAuthenticate, useValidate } from "@/services/auth";
import { ValidateTwoFactorAuthenticationMethod } from "@/services/model";
import { AlertTypes } from "@/utils/atoms";
import { mutationWithoutErrorHandling } from "@/utils/mutationWithoutErrorHandling";

export const TwoFactorCodeModal = ({
  setShowModal,
  method,
  successAction,
}: {
  setShowModal: (show: boolean) => void;
  method: keyof typeof ValidateTwoFactorAuthenticationMethod;
  successAction: () => void;
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<TwoFactorConfirmationCodeForm>({
    reValidateMode: "onSubmit",
  });
  const showToast = useAlert();
  const [showRecoveryCodeForm, setShowRecoveryCodeForm] = useState(false);

  const [, setIsVerified] = useTwoFactorAuthStatus();

  const { mutateAsync: authenticateTwoFactorUsage } = useAuthenticate();
  const { mutateAsync: validateCode } = useValidate(
    mutationWithoutErrorHandling,
  );

  const hasRequestedTwoFactorUsage = useRef(false);

  useEffect(() => {
    const debouncedRequest = debounce(() => authenticateTwoFactorUsage(), 1000);
    if (hasRequestedTwoFactorUsage.current) return;
    debouncedRequest();
    hasRequestedTwoFactorUsage.current = true;
  }, [authenticateTwoFactorUsage]);

  const isSubmitting = useRef(false);
  const verifyAndRedirect = (data: TwoFactorConfirmationCodeForm) => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;
    validateCode({
      params: {
        code: Number(data.code),
      },
    })
      .then((response) => {
        if (response) {
          showToast({
            text: t("portal:user-account.security.2fa.modal.success"),
            type: AlertTypes.success,
          });
          setIsVerified(true);
          successAction();
        }
      })
      .catch(() => {
        showToast({
          text: t("portal:user-account.security.2fa.modal.error"),
          type: AlertTypes.error,
        });
        formMethods.setError("code", {
          type: "manual",
          message: t("portal:user-account.security.2fa.modal.error"),
        });
      })
      .finally(() => {
        isSubmitting.current = false;
      });
  };

  const title = showRecoveryCodeForm
    ? t("portal:user-account.security.2fa.modal.recoveryTitle")
    : t("portal:user-account.security.2fa.modal.title");

  const resetAndShowRecoveryCodeForm = (value: boolean) => {
    formMethods.reset();
    setShowRecoveryCodeForm(value);
  };
  return (
    <Modal
      className="max-w-fit"
      title={<h3 className="text-secondary-100">{title}</h3>}
      showCloseButton={false}
      onClose={() => null}
      hasScroll={false}
    >
      <form
        onSubmit={(e) => {
          formMethods.clearErrors();
          formMethods.handleSubmit(verifyAndRedirect)(e);
        }}
        className="flex flex-col gap-10 text-black"
      >
        <FormProvider {...formMethods}>
          <div className="flex flex-col gap-6">
            {showRecoveryCodeForm ? (
              <RecoveryCodeForm
                setShowRecoveryCodeForm={resetAndShowRecoveryCodeForm}
              />
            ) : (
              <VerificationCodeForm
                authenticateTwoFactorUsage={authenticateTwoFactorUsage}
                method={method}
                setShowRecoveryCodeForm={resetAndShowRecoveryCodeForm}
              />
            )}
          </div>
        </FormProvider>
        <ConfirmationButtonFlexBox forceButtonWidth>
          <Button
            type="button"
            onClick={() => setShowModal(false)}
            className="accent inverted"
          >
            {t("portal:common.buttons.cancel")}
          </Button>
          <Button type="submit" className="accent">
            {t("portal:authenticator.sms.verification.verify")}
          </Button>
        </ConfirmationButtonFlexBox>
      </form>
    </Modal>
  );
};

const TwoFactorAppValidation = () => (
  <div className="w-fit flex flex-col gap-2">
    <TwoFactorConfirmationInput />
  </div>
);

/**
 * Displays a form for verifying the user's identity. Uses their two-factor authentication method,
 * so either SMS or app-based verification method.
 * Allows to switch to the RecoveryCodeForm.
 */
const VerificationCodeForm = ({
  authenticateTwoFactorUsage,
  method,
  setShowRecoveryCodeForm,
}: {
  authenticateTwoFactorUsage: () => void;
  method: keyof typeof ValidateTwoFactorAuthenticationMethod;
  setShowRecoveryCodeForm: (show: boolean) => void;
}) => {
  const { t } = useTranslation();
  const hasSmsTwoFactorMethod =
    method === ValidateTwoFactorAuthenticationMethod.SMS;
  const hasAppTwoFactorMethod =
    method === ValidateTwoFactorAuthenticationMethod.APP;

  return (
    <>
      <p>{t("portal:user-account.security.2fa.modal.description")}</p>
      {hasSmsTwoFactorMethod && (
        <TwoFactorSMSValidation resendAction={authenticateTwoFactorUsage} />
      )}
      {hasAppTwoFactorMethod && <TwoFactorAppValidation />}

      <p className="flex flex-row gap-2 text-left flex-wrap">
        <span>{t("portal:user-account.security.2fa.modal.unableToUse")}</span>
        <button
          type="button"
          className="text-highlight-100 underline font-semibold w-fit text-left"
          onClick={() => setShowRecoveryCodeForm(true)}
        >
          {t("portal:user-account.security.2fa.modal.useUniqueCode")}
        </button>
      </p>
    </>
  );
};

/**
 * Renders a form for entering a 2FA recovery code.
 * Displays a description, an input field for the recovery code,
 * and a button to switch to the VerificationCodeForm again.
 */
const RecoveryCodeForm = ({
  setShowRecoveryCodeForm,
}: {
  setShowRecoveryCodeForm: (show: boolean) => void;
}) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<TwoFactorConfirmationCodeForm>();

  return (
    <div className="w-fit flex flex-col gap-2 max-w-[580px]">
      <p>
        {t("portal:user-account.security.2fa.modal.recoveryCodeDescription")}
      </p>
      <FormField
        label={t("portal:user-account.security.2fa.modal.recoveryCode")}
        id={"recovery-code"}
        placeholder="000000000000"
        name={"code"}
        errors={errors}
        maxLength={12}
        register={register}
        options={{
          required: t("translation:label.validation.required"),
          pattern: {
            value: /^[0-9]{12}$/,
            message: t("portal:user-account.security.2fa.modal.error"),
          },
        }}
      />
      <p className="flex flex-row gap-2 text-left flex-wrap">
        <span>{t("portal:user-account.security.2fa.modal.foundYourCode")}</span>
        <button
          type="button"
          className="text-highlight-100 underline font-semibold w-fit"
          onClick={() => setShowRecoveryCodeForm(false)}
        >
          {t("portal:user-account.security.2fa.modal.title")}
        </button>
      </p>
    </div>
  );
};
