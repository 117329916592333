import { Transition } from "@headlessui/react";
import { Trans, useTranslation } from "react-i18next";
import { DeviceFC } from "types";

import { RoamingGlobeIcon } from "@/assets/icons/icons";
import { ExtendedSimCardDetail } from "@/hooks/useExtendedSims";
import { getFirstOfSortedRoaming } from "@/utils/deviceUtils";

export const RoamingUsage: DeviceFC = ({
  device,
}: {
  device: ExtendedSimCardDetail;
}) => {
  // We want only the most important of maximum three roaming infos displayed here.
  const roamingInfo = getFirstOfSortedRoaming(device);
  const hasRoaming = (roamingInfo?.initialVolume || 0) > 0;
  const { t } = useTranslation();

  return (
    <Transition
      className="flex flex-row gap-3 items-center whitespace-nowrap text-base mb-4"
      appear
      show={hasRoaming}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <RoamingGlobeIcon role="img" title={t("translation:Icons.roaming")} />

      <Trans
        t={t}
        components={[<span key={0} className="font-semibold"></span>]}
        values={{
          spent:
            (roamingInfo?.initialVolume || 0) -
            (roamingInfo?.remainingVolume || 0),
          available: roamingInfo?.initialVolume,
        }}
        i18nKey={"layout.tiles.activeSim.roamingUsage"}
      />
    </Transition>
  );
};
