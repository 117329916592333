import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox.tsx";
import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage.tsx";
import { AuthAndPersonalDetailsFormFields } from "@/components/Onboarding/Steps/PersonalDetails/personalDetails.types.ts";

export type TermsAndConditionsFieldType = {
  termsAndConditions?: boolean;
};

export const TermsAgreementField = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<AuthAndPersonalDetailsFormFields>();

  return (
    <div className="mt-8 font-small text-black">
      <Checkbox
        disabled={disabled}
        id="termsAgreement"
        name="termsAndConditions"
        value="yes"
        labelComponent={
          <p className={clsx(disabled && "opacity-20")}>
            <Trans
              components={[
                <a
                  target="_blank"
                  key="termsAndConditionsLink"
                  href={t("Footer.termsConditions.url")}
                  className="text-primary-100 underline"
                  rel="noreferrer"
                  title="Terms and Conditions"
                />,
                <a
                  target="_blank"
                  key="dataProtectionLink"
                  href={t("Footer.dataProtection.url")}
                  className="text-primary-100 underline"
                  rel="noreferrer"
                  title="Data Protection"
                />,
              ]}
              i18nKey="Onboarding.sections.personal-details.acceptTerms"
            />
          </p>
        }
        errors={errors}
        register={register}
      />
      {errors && errors["termsAndConditions"] && (
        <ErrorMessage
          name="termsAndConditions"
          message={t("label.validation.required")}
        />
      )}
    </div>
  );
};
