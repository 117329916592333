import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useHandleError } from "@/hooks/useHandleError";

export const useHandleCheckoutError = (sumIsZero?: boolean) => {
  const handleError = useHandleError();
  const { t } = useTranslation("translation");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const isError = searchParams.get("payment") === "error";
    const errorCode = searchParams.get("errorCode");
    if (isError) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("errorCode");
      newSearchParams.delete("payment");
      switch (errorCode) {
        case "1004":
          handleError("Payment fail 1004", t("Error.payment.notValid"));
          break;
        case "1400":
          handleError("Payment fail 1400", t("Error.payment.notValid"));
          break;
        case "1006":
          handleError(
            "Payment fail 1006",
            `${
              sumIsZero
                ? t("Error.payment.expiredZero")
                : t("Error.payment.expired")
            } ${t("Error.payment.pleaseCheckInput")}`,
          );
          break;
        case "1402":
          handleError(
            "Payment fail 1402",
            `${
              sumIsZero
                ? t("Error.payment.expiredZero")
                : t("Error.payment.expired")
            } ${t("Error.payment.pleaseCheckInput")}`,
          );
          break;
        default:
          handleError(
            `Payment fail ${errorCode}`,
            `${
              sumIsZero ? t("Error.payment.failZero") : t("Error.payment.fail")
            } ${t("Error.payment.pleaseCheckData")} `,
          );
      }
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [sumIsZero, handleError, t, searchParams, setSearchParams]);
};
