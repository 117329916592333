import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { SimCardDetailNetworkResetNotAllowedReasonsItem } from "@/services/model";

import { NetworkResetDisabledTooltip } from "./NetworkResetDisabledTooltip/NetworkResetDisabledTooltip";

export const NetworkReset = ({
  disabled,
  reasons,
}: {
  disabled?: boolean;
  reasons?: SimCardDetailNetworkResetNotAllowedReasonsItem[];
}) => {
  const [simId] = useSimIdFromQueryParam();
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.details.sections.details.networkReset",
  });

  return (
    <>
      <h3 className="text-secondary-100 font-semibold mb-6 mt-16">
        {t("title")}
      </h3>
      <div>
        <SectionButtonHeader
          className="font-semibold !mb-0"
          header={t("label")}
        >
          {disabled ? (
            <NetworkResetDisabledTooltip reasons={reasons} leftOnMobile={true}>
              <button className="disabled-button" disabled>
                {t("link")}
              </button>
            </NetworkResetDisabledTooltip>
          ) : (
            <Link
              className="font-normal change-button"
              to={`../display/reset?simId=${simId}`}
              replace
            >
              {t("link")}
            </Link>
          )}
        </SectionButtonHeader>
        <p className="text-xs leading-6">{t("text")}</p>
      </div>
    </>
  );
};
