import "./Footer.scss";

import clsx from "clsx";
import { FCCC } from "types";

import { Claim } from "@/components/Layout/Claim/Claim";

import { CollapsibleLanguageMenu } from "./CollapsibleLanguageMenu";
import { CollapsibleLinkMenu } from "./CollapsibleLinkMenu";
import { SupportLinkItem } from "./SupportLinkItem";

export const Footer: FCCC = ({ className }) => {
  return (
    <footer
      id="footer-wrapper"
      data-testid="footer"
      className={clsx(
        "relative min-h-[128px] pt-[57px] flex flex-col md:flex-row-reverse md:justify-between",
        className,
      )}
    >
      <div className="flex flex-col items-start ml-6 mb-6 md:flex-row md:mr-6 gap-4 md:items-center xl:mr-[112px] md:m-0">
        <CollapsibleLanguageMenu />
        <SupportLinkItem />
        <CollapsibleLinkMenu />
      </div>
      <div className="ml-2 my-auto md:ml-2 md:mb-2 xl:ml-10 xl:mb-4 flex justify-between">
        <Claim />
      </div>
    </footer>
  );
};
