import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { useGetESimInfo } from "@/services/api";
import {
  isEsim,
  isPhysicalSIM,
  isUnscannedEsimInfo,
} from "@/utils/deviceUtils";

import { SimDetailActions } from "./SimDetailActions";
import { UnscannedQRCodeDetails } from "./UnscannedQRCodeDetails";

export const DetailsEsimDisplay = () => {
  const [simId] = useSimIdFromQueryParam();
  const { data: simDetail } = useDisplayedSim();
  const { data: esimInfo } = useGetESimInfo(
    { simIds: [Number(simId)] },
    { query: { enabled: !isNaN(parseInt(simId)) } },
  );
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.details.sections.details",
  });

  const hasEsimInfo = !!esimInfo && esimInfo.length > 0;

  // This might be disabled if there is a change to the sim in progress
  const simCanSwitchToEsim = simDetail?.simActions?.simActions?.includes(
    "canChangeTripleToESim",
  );

  return simDetail ? (
    <>
      {isEsim(simDetail) && (
        <>
          <h3 className="text-secondary-100 font-semibold mb-6">
            {t("esimProfile")}
          </h3>
          {hasEsimInfo ? (
            isUnscannedEsimInfo(esimInfo[0]) ? (
              <UnscannedQRCodeDetails simDetail={simDetail} />
            ) : (
              <SimDetailActions
                simDetail={simDetail}
                esimInfo={esimInfo}
                simId={simId}
              />
            )
          ) : (
            <LoadingSpinner />
          )}
        </>
      )}
      {isPhysicalSIM(simDetail) && (
        <>
          <h3 className="text-secondary-100 font-semibold mb-6">
            {t("esimProfile")}
          </h3>
          <div id="change-to-esim">
            <SectionButtonHeader header={t("changeToEsim")}>
              {simCanSwitchToEsim ? (
                <Link
                  replace
                  className="change-button"
                  to={`../physicalToEsim?simId=${simId}`}
                >
                  {t("portal:common.buttons.switch")}
                </Link>
              ) : (
                <p className="text-primary-25 underline decoration-primary-25">
                  {t("portal:common.buttons.switch")}
                </p>
              )}
            </SectionButtonHeader>
            <span className="text-xs">{t("esimHint")}</span>
          </div>
        </>
      )}
    </>
  ) : (
    <LoadingSpinner />
  );
};
