import "./Rates.scss";

import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RateOption } from "@/components/Onboarding/Steps/RateSelection/RateOption/RateOption";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import {
  combineProductsByBaselabel,
  TransformedProduct,
} from "@/utils/tariffUtils";

export interface RatesProps {
  processedProductsById: { [p: string]: TransformedProduct };
  preSelectedProcessedProduct: TransformedProduct | undefined;
  readonlyProductId?: number;
  currentRate: RateFormProps["rate"];
  shouldShowEsimTrial?: boolean;
}
export const Rates: FC<RatesProps> = ({
  processedProductsById,
  preSelectedProcessedProduct,
  readonlyProductId,
  currentRate,
  shouldShowEsimTrial = false,
}) => {
  const { t } = useTranslation();
  const currentProduct =
    processedProductsById[currentRate || preSelectedProcessedProduct?.id || ""];
  const optionsContainers = useMemo(
    () =>
      processedProductsById
        ? Object.entries(combineProductsByBaselabel(processedProductsById)).map(
            ([rate, [monthOption, yearOption]], index) =>
              rate === "Flat Mobile" ? null : (
                <RateOption
                  key={index}
                  isSelected={currentProduct?.baseDisplayValue === rate}
                  preSelectedProcessedProduct={preSelectedProcessedProduct}
                  readonlyProductId={readonlyProductId}
                  yearOption={yearOption}
                  monthOption={monthOption}
                  name={rate}
                  currentProductId={currentProduct?.id}
                  shouldShowEsimTrial={shouldShowEsimTrial}
                />
              ),
          )
        : null,
    [
      currentProduct,
      readonlyProductId,
      processedProductsById,
      preSelectedProcessedProduct,
      shouldShowEsimTrial,
    ],
  );

  return (
    <fieldset>
      <div className="labels-container">
        <div className="descriptions">
          <span className="label">
            {t("Onboarding.sections.rate-selection.rate")}
          </span>
          <span className="label">
            {t("Onboarding.sections.rate-selection.speed")}
          </span>
        </div>
        <div className="durations">
          <span className="label">
            {t("Onboarding.sections.rate-selection.30days")}
          </span>
          <span className="label">
            {t("Onboarding.sections.rate-selection.365days")}
          </span>
        </div>
      </div>
      {optionsContainers}
    </fieldset>
  );
};
