import { FC, useCallback, useEffect } from "react";

import { env } from "@/utils/environmentHelpers";
import { filterConfigurationForParams } from "@/utils/lightboxHelpers";

export interface LightboxConfiguration {
  merchantId: string; // Merchant identifier provided to you by Datatrans.
  refno: string; // Any value by which you would like to reference the payment.
  currency: string; // The type of currency that will be used for the payment.
  sign: string; // Transaction security parameter. (From Datatrans' Webadmin Tool).
  production: boolean;
  paymentmethod: string[];
  themeConfiguration: {
    brandColor: string;
  };
  successUrl: string;
  cancelUrl: string;
  errorUrl: string;
  useAlias: string;
}

export interface LightboxCallbacks {
  onLoaded: () => void;
  onOpened: () => void;
  onCancelled: () => void;
  onError: (data: any) => void;
}

interface LightboxProps extends LightboxCallbacks {
  config: LightboxConfiguration;
}

const getUrl = (production: boolean) =>
  production
    ? "https://pay.datatrans.com/upp/payment/js/datatrans-2.0.0.min.js"
    : "https://pay.sandbox.datatrans.com/upp/payment/js/datatrans-2.0.0.min.js";

// TODO: This problably shouldn't be a react component but just helper functions
export const Lightbox: FC<LightboxProps> = ({
  config,
  onLoaded,
  onOpened,
  onCancelled,
  onError,
}) => {
  const cleanupLightbox = () => {
    // TODO: Add typings for window.Datatrans (and remove `@ts-ignore`s)
    // @ts-ignore
    if (window?.Datatrans) {
      try {
        // @ts-ignore
        window?.Datatrans.close();
      } catch (err) {} // eslint-disable-line no-empty
    }
  };

  const startPayment = useCallback(() => {
    const paymentConfig = {
      params: filterConfigurationForParams(config),
      loaded: onLoaded,
      opened: onOpened,
      closed: onCancelled,
      error: onError,
    };
    // @ts-ignore
    window?.Datatrans?.startPayment(paymentConfig);
  }, [config, onCancelled, onError, onLoaded, onOpened]);

  useEffect(() => {
    const production = env.isProd;
    const scriptSource = getUrl(production);

    if (document.querySelector('script[src="' + scriptSource + '"]')) {
      startPayment();
      return;
    }

    const script = document.createElement("script");
    script.src = scriptSource;
    script.onload = () => {
      startPayment();
    };
    document.body.appendChild(script);

    return cleanupLightbox;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
