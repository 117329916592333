import { TFuncKey } from "i18next";

import { LookupValue } from "@/services/model";

/**
 * A section is part of a process, displayed by the step.
 * It has 0-n substeps, each describing a progress within the section.
 *
 * @property title - The title of the section
 * @property step -  Step in the process
 * @property substeps -  Maps url's to certain progress, like 50%
 * @property last - If set, don't show progress bar
 */
export type Section = {
  title: TFuncKey;
  // Included substeps, used to display progress in between sections
  substeps: {
    [substep: string]: number;
  };
};

/**
 * Maps onboarding routes to sections.
 */
export const onboardingSections: Section[] = [
  {
    title: "Onboarding.sections.sim-selection.title",
    substeps: {
      "sim-selection": 0.25,
      "sim-verification": 0.5,
    },
  },
  {
    title: "Onboarding.sections.device-type.title",
    substeps: {
      "device-type": 0.25,
    },
  },
  {
    title: "Onboarding.sections.rate-selection.title",
    substeps: {
      "rate-selection": 0.25,
      "number-porting": 0.5,
    },
  },
  {
    title: "Onboarding.sections.personal-details.title",
    substeps: {
      "personal-details": 0.25,
    },
  },
  {
    title: "Onboarding.sections.checkout.title",
    substeps: {
      checkout: 0.25,
    },
  },
];

/**
 * The possible options for the basket api.
 */
export enum OPTIONS {
  //  The default, base option that we use to create baskets with
  BASE_OPTION = 131001,
}

export const LEGAL_FORMS: LookupValue[] = [
  { id: 1, description: "Einzelfirma" },
  { id: 2, description: "Einfache Gesellschaft" },
  { id: 3, description: "Kollektivgesellschaft" },
  { id: 4, description: "Kommanditgesellschaft" },
  { id: 5, description: "AG" },
  { id: 6, description: "GmbH" },
  { id: 7, description: "Kommandit-AG" },
  { id: 8, description: "Genossenschaft" },
  { id: 9, description: "Verein" },
  { id: 10, description: "Stiftung" },
  { id: 11, description: "Öffentlich-rechtliche Körperschaft" },
];

const requireUIDCheck: string[] = [
  "AG",
  "GmbH",
  "Kommandit-AG",
  "Genossenschaft",
  "Verein",
  "Stiftung",
];

export const doesRequireUIDCheck = (value: LookupValue) =>
  requireUIDCheck.some(
    (element) =>
      value && value.description && value.description.includes(element),
  );
