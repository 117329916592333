import "./InputLabel.scss";

import clsx from "clsx";

type InputLabelProps = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  htmlFor: string;
  label: string;
  required?: boolean;
};

export const InputLabel = ({
  className,
  disabled,
  error,
  htmlFor,
  label,
  required,
}: InputLabelProps) => {
  return (
    <label
      className={clsx(
        "input-label",
        disabled && "disabled",
        error && "error",
        className,
      )}
      htmlFor={htmlFor}
    >
      {`${label} ${required ? "*" : ""}`}
    </label>
  );
};
