/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type DrPaymentMeanPaymentMode =
  (typeof DrPaymentMeanPaymentMode)[keyof typeof DrPaymentMeanPaymentMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrPaymentMeanPaymentMode = {
  MANUAL_PAYMENT: "MANUAL_PAYMENT",
  CREDITCARD: "CREDITCARD",
  NO_PAYMENT_MEAN: "NO_PAYMENT_MEAN",
} as const;
