/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Compax Authorization Service
 * API by Compax
 * OpenAPI spec version: 116.0.0-SNAPSHOT
 */

export type ValidateTwoFactorAuthenticationMethod =
  (typeof ValidateTwoFactorAuthenticationMethod)[keyof typeof ValidateTwoFactorAuthenticationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValidateTwoFactorAuthenticationMethod = {
  SMS: "SMS",
  APP: "APP",
  STANDARD: "STANDARD",
} as const;
