import { Trans, useTranslation } from "react-i18next";
import { DeviceFC } from "types";

import { RoamingUsage } from "@/components/Portal/Cockpit/RoamingUsage/RoamingUsage";
import { SpeedAndData } from "@/components/Portal/Cockpit/SpeedAndData/SpeedAndData";
import {
  DeviceTile,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import {
  getIconForDevice,
  getSpeedFromLocalFlatSpeed,
} from "@/utils/deviceUtils";

/**
 * This device tile version is used when the sim has an active roaming package
 * but no active flat rate package.
 */
export const ActiveSimNoPackageTile: DeviceFC = ({ device }) => {
  const { t } = useTranslation("portal");
  const icon = getIconForDevice(device.device?.description);
  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );

  return (
    <DeviceTile
      navigationTarget={`../manage?simId=${device.simId}`}
      icon={icon}
      dataTestid="active-sim-no-package-tile"
    >
      <div id="inactive-wrapper" className="opacity-50">
        <h2 className="tile-title">{title}</h2>

        <h3 className="tile-subtitle">
          {t("layout.tiles.inactive.noPackageActive")}
        </h3>

        <SpeedAndData
          speed={getSpeedFromLocalFlatSpeed(device.localFlatSpeed)}
        />
      </div>

      <div id="active-sim-content">
        <div className="text-primary-100 text-base mt-6 mb-6">
          <Trans
            t={t}
            components={[<span key={0} className="font-semibold"></span>]}
            i18nKey={"layout.tiles.inactive.activateText"}
          />
        </div>

        <RoamingUsage device={device} />
      </div>
    </DeviceTile>
  );
};
