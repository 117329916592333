import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Interface/Button/Button";

import { useRedirectToAuthorizationService } from "./useRedirectToAuthorizationService";

export const LoginButton: React.FC = () => {
  const { t } = useTranslation();
  const redirectToAuthorizationService = useRedirectToAuthorizationService();

  return (
    <Button
      className="min-w-full md:min-w-fit"
      onClick={redirectToAuthorizationService}
    >
      {t("Content.button.toCustomerPortal")}
    </Button>
  );
};
