import { expectedAuthTypeQueryParam } from "@/components/Portal/UserAccount/Security/TwoFactorAuthStatus.tsx";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useGetCustomer } from "@/services/api";
import {
  BasicCustomerDataTwoFactorMethod,
  ValidateTwoFactorAuthenticationMethod,
} from "@/services/model";

export const useTwoFactorMethodFromQueryParam = () => {
  const [expectedAuthType, setExpectedAuthType] = useQueryParam(
    expectedAuthTypeQueryParam,
  );
  const { data: customerData } = useGetCustomer(undefined, {
    query: {
      keepPreviousData: true,
      refetchInterval: (data) => {
        if (expectedAuthType) {
          if (expectedAuthType !== data?.twoFactorMethod) {
            return 5000;
          } else {
            setExpectedAuthType(undefined);
          }
        }
        return 0;
      },
      cacheTime: 0,
    },
  });
  // If the user has no two factor method set, we default to the "standard" method (username + password)
  if (
    customerData?.twoFactorMethod === BasicCustomerDataTwoFactorMethod.STANDARD
  ) {
    return ValidateTwoFactorAuthenticationMethod.STANDARD;
  }
  // Else, we check
  if (expectedAuthType !== "") {
    return expectedAuthType === BasicCustomerDataTwoFactorMethod.APP
      ? ValidateTwoFactorAuthenticationMethod.APP
      : ValidateTwoFactorAuthenticationMethod.SMS;
  }

  if (customerData?.twoFactorMethod) {
    return customerData.twoFactorMethod;
  }

  return BasicCustomerDataTwoFactorMethod.STANDARD;
};
