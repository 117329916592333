import type { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";
import { SimCardDetail } from "@/services/model";
import { hasWatchSimOrdered } from "@/utils/deviceUtils";

interface Props extends PropsWithChildren {
  simDetail: SimCardDetail;
}

type TariffChangeDisabledReasonsType = {
  watchSimStatusOrdered: boolean;
  orderInProcess: boolean;
};

/**
 * This tooltip lets the user know the reasons why their tariff option cannot
 * be changed.
 */
export const TariffChangeDisabledTooltip: FC<Props> = ({
  simDetail,
  children,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.tariff",
  });

  const possibleReasons: TariffChangeDisabledReasonsType = {
    watchSimStatusOrdered: hasWatchSimOrdered(simDetail),
    orderInProcess: !!simDetail.servicePending,
  };
  const definiteReasonsAmount = Object.values(possibleReasons).filter(
    (reason) => reason,
  ).length;

  return (
    <Tooltip
      className="w-72"
      leftOnMobile
      content={
        <>
          <h5 className="text-base font-semibold">
            {t("portal:common.tooltip.actionNotPossible.title")}
          </h5>

          <p className="text-black font-x-small">
            {t("portal:common.tooltip.actionNotPossible.text", {
              count: definiteReasonsAmount,
            })}
          </p>

          <ul className="font-x-small text-black list-disc list-inside">
            {possibleReasons.watchSimStatusOrdered && (
              <li>{t("changeDisabledReasons.watchSimNotActive")}</li>
            )}

            {possibleReasons.orderInProcess && (
              <li>{t("changeDisabledReasons.orderInProcess")}</li>
            )}
          </ul>
        </>
      }
    >
      {children}
    </Tooltip>
  );
};
