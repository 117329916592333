import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Price } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffDisplay/TariffDisplay";
import { TariffHeader } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffHeader/TariffHeader";
import { SimCardDetail } from "@/services/model";
import { getBaseFlatName, stripFlatName } from "@/utils/deviceUtils";
import { getNextPaymentDate } from "@/utils/tariffUtils";
import { getCurrency, getDateString } from "@/utils/translationHelpers";

const getPriceObject = (detail: {
  priceBase?: number;
  priceVoice?: number;
  priceWatch?: number;
  priceTotal?: number;
}): Price => ({
  base: detail.priceBase || 0,
  voice: detail.priceVoice,
  watch: detail.priceWatch,
  total: detail.priceTotal || 0,
});

export const CostDetails = ({
  simDetail,
  className,
  isDowngrade,
  isFutureCosts,
}: {
  simDetail: SimCardDetail;
  className?: string;
  isDowngrade: boolean;
  isFutureCosts?: boolean;
}) => {
  const { t } = useTranslation();

  const price =
    isFutureCosts && simDetail.successorForRenew
      ? getPriceObject(
          simDetail.successorForRenew || {
            priceWatch: simDetail.priceWatch || undefined,
          },
        )
      : getPriceObject(simDetail);

  const minContractDuration = isFutureCosts
    ? simDetail?.successorForRenew?.minContractDuration
    : simDetail.minContractDuration;

  const autoRenewText = simDetail.autoRenew
    ? minContractDuration
      ? t("cockpit.managementTile.tariff.payInterval", {
          days: minContractDuration || 30,
        })
      : ""
    : t("cockpit.managementTile.tariff.once");

  const texts = { title: "", subtitle: "", payIntervalLabel: "" };

  if (isFutureCosts) {
    texts.title = t("cockpit.managementTile.tariff.subsequentCosts");
    texts.payIntervalLabel = autoRenewText;
    texts.subtitle =
      typeof simDetail.remainingDays === "number"
        ? `${t("cockpit.managementTile.tariff.starting")} ${getDateString(
            getNextPaymentDate(simDetail.remainingDays + 1),
          )}`
        : "";
  } else if (simDetail.autoRenew && isDowngrade) {
    texts.title = t("cockpit.managementTile.tariff.currentCosts");
    texts.subtitle = texts.subtitle = `${t(
      "cockpit.managementTile.tariff.until",
    )} ${getDateString(getNextPaymentDate(simDetail.remainingDays))}`;
  } else {
    texts.title = t("cockpit.managementTile.tariff.costs");
    texts.payIntervalLabel = autoRenewText;
  }

  const watchSim = price.watch && {
    name: "Watch SIM",
    price: price.watch,
  };

  const { title, subtitle, payIntervalLabel } = texts;

  return (
    <div
      className={className}
      data-testid={
        isFutureCosts ? "subsequent-cost-display" : "current-cost-display"
      }
    >
      <TariffHeader
        className={clsx(subtitle ? "mb-2" : "mb-6")}
        title={title}
      />
      <p className="mb-6">{subtitle}</p>
      <div className="flex justify-between">
        <span className="text-base mb-2" data-testid="flat-name">
          {isFutureCosts
            ? stripFlatName(simDetail.successorForRenew?.description)
            : getBaseFlatName(simDetail)}
        </span>
        <span className="text-base mb-2">{getCurrency(price.base, "CHF")}</span>
      </div>
      {price.voice && (
        <div className="flex justify-between">
          <span className="text-base mb-2">
            {t("common.device.attributes.voiceOption")}
          </span>
          <span className="text-base mb-2">
            {getCurrency(price.voice, "CHF")}
          </span>
        </div>
      )}
      {watchSim && (
        <div className="flex justify-between">
          <span className="text-base mb-2">{watchSim.name}</span>
          <span className="text-base mb-2">
            {getCurrency(watchSim.price, "CHF")}
          </span>
        </div>
      )}

      <hr className="border-primary-100 mt-[14px]" />
      <p className="text-right text-xl font-semibold text-primary-100">
        {getCurrency(price.total, "CHF")}
      </p>
      <div className="text-right text-base" data-testid="pay-interval-label">
        {payIntervalLabel}
      </div>
    </div>
  );
};
