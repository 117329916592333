import axios, { AxiosResponse } from "axios";

import { CompaxOAuth2AccessTokenModel } from "@/services/model";
import {
  accessTokenAtom,
  refreshTokenAtom,
  showsSessionExpiredModalAtom,
  store,
} from "@/utils/atoms";

import { AUTHORIZE_URL, TOKEN_URL } from "./apiUrls";
import { env } from "./environmentHelpers";
import { initTranslation } from "./i18n";

interface AuthResponse {
  access_token: string;
  token_type: "bearer";
  refresh_token: string;
  expires_in: number;
  scope: "all";
}

interface AuthErrorResponse {
  error: string;
  error_description: string;
}

export const anonymousLoginStatus = "IS_ANONYMOUS_LOGIN";
export const agentLoginStatus = "IS_AGENT_LOGIN";

// Base auth url used for both the redirect to the login page and the request for tokens after login
const getAuthURL = (baseURL: string) => {
  const url = new URL(baseURL);
  // client_id identifies the client application, as the SSO server has multiple clients
  // we identify as digital republic client here
  url.searchParams.set("client_id", env.clientAppId || "");
  // we want to redirect back to the current page after login
  url.searchParams.set(
    "redirect_uri",
    `${env.isTest ? "https:" : window.location.protocol}//${
      // Calls to window.location.host are not allowed in tests due to DOM restrictions, so we use localhost instead
      env.isTest ? "localhost" : window.location.host
    }${env.publicUrl || "/"}`,
  );
  // our PKCE implementation generates a code_challenge and code_verifier, the method is SHA-256
  url.searchParams.set("code_challenge_method", "S256");

  return url;
};

/**
 * Use code and verifier to request authorization token
 */
export const fetchAuthToken = async (code: string, verifier: string) => {
  if (!code || !verifier) {
    throw Error("'code' and/or 'verifier' empty");
  }

  const url = getAuthURL(TOKEN_URL.toString());
  url.searchParams.set("grant_type", "authorization_code");
  url.searchParams.set("code", code);
  url.searchParams.set("code_verifier", verifier);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  const json = (await response.json()) as AuthResponse | AuthErrorResponse;

  if ("error" in json) {
    throw Error(
      `Fetching auth token returned an error: ${json.error_description}`,
    );
  }

  return json;
};

/**
 * Generate a code_verifier that will be sent to request tokens
 */
export const generateVerifier = () => {
  const dec2hex = (dec: number) => `0${dec.toString(16)}`.substring(-2);

  const generateRandomString = () => {
    let array = new Uint32Array(56 / 2);
    array = env.isTest ? array : window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join("");
  };

  return generateRandomString();
};

/**
 * Generate a code_challenge from the code_verifier that will be sent to request an authorization_code
 */
const generateChallengeFromVerifier = async (verifier: string) => {
  const sha256 = (plain: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);

    return window.crypto.subtle.digest("SHA-256", data);
  };

  const base64urlencode = (a: ArrayBuffer) => {
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    let str = "";

    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }

    return window
      .btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  };

  const hashed = await sha256(verifier);

  return base64urlencode(hashed);
};

// Calculate a login link with a code challenge and verifier
export const getAuthorizeURL = async (lang: string, verifier: string) => {
  const challenge = await generateChallengeFromVerifier(verifier);

  const url = getAuthURL(AUTHORIZE_URL.toString());
  url.searchParams.set("response_type", "code");
  url.searchParams.set("scope", "all");
  url.searchParams.set("user_language", lang);
  url.searchParams.set("code_challenge", challenge);

  return url;
};

const resetSession = (to = "/#/welcome") => {
  localStorage.clear();
  window.location.assign(to);
};
// Function that will be called to refresh authorization
export const refreshAuthorization = async (failedRequest: any) => {
  const i18n = initTranslation();
  const refreshToken = store.get(refreshTokenAtom);

  if (!refreshToken) {
    resetSession();
    return Promise.reject(new Error("Session expired"));
  }

  if (refreshToken === anonymousLoginStatus) {
    alert(i18n.t("sessionExpired.anonymousLogin"));
    resetSession("/#/welcome-esim");

    return;
  } else if (refreshToken === agentLoginStatus) {
    alert(
      // We cannot use our pretty alert here, as this is non-react context.
      // Should be okay, as users will never see this, only internal users with access to agent logins.
      i18n.t("sessionExpired.agentLogin"),
    );
    resetSession();

    return;
  }

  const url = new URL(TOKEN_URL);
  url.searchParams.append("grant_type", "refresh_token");
  url.searchParams.append("refresh_token", refreshToken);
  url.searchParams.append("client_id", env.clientAppId);

  return axios
    .post(url.toString())
    .then((response: AxiosResponse<CompaxOAuth2AccessTokenModel>) => {
      store.set(accessTokenAtom, response.data.access_token!);
      store.set(refreshTokenAtom, response.data.refresh_token!);
      failedRequest.response.config.headers["Authorization"] =
        `Bearer ${response.data.access_token}`;
      return Promise.resolve();
    })
    .catch((error) => {
      if (!store.get(showsSessionExpiredModalAtom)) {
        if (
          // If we get the following error message, our agent login has expired.
          typeof error?.response?.data?.error_description === "string" &&
          error.response.data.error_description.includes(
            "Wrong client for this refresh token",
          )
        ) {
          alert(i18n.t("sessionExpired.agentLogin"));
        } else {
          alert(i18n.t("sessionExpired.general"));
        }
        store.set(showsSessionExpiredModalAtom, true);
      }

      resetSession();
      return Promise.reject();
    });
};
