import { UseFormReturn } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Accordion } from "@/components/Interface/Accordion/Accordion";
import { TransformedProduct, TransformedProducts } from "@/utils/tariffUtils";

import { Rates } from "./Rates/Rates";
import { RateFormProps } from "./RateSelection";
import { VoiceOption } from "./VoiceOption/VoiceOption";

/**
 * This component is a form that allows the user to select a custom rate and voice option.
 * It is used in the rate selection step of the onboarding process and is the alternative to picking one of the "Mobile Abo" products.
 */
export const CustomRateForm = ({
  options,
  selectedOption,
  currentRate,
  shouldShowEsimTrial,
  canBookVoiceOption,
  currentVoiceOption,
  voiceIsSelected,
}: {
  options: TransformedProducts | undefined;
  selectedOption: TransformedProduct | undefined;
  rateFormMethods: UseFormReturn<RateFormProps, any, RateFormProps>;
  currentRate: string;
  shouldShowEsimTrial: boolean;
  canBookVoiceOption: boolean;
  currentVoiceOption?: TransformedProduct;
  voiceIsSelected: boolean;
}) => {
  const { t } = useTranslation();
  const {
    preSelectedProcessedProduct,
    prechargeVoice,
    processedProductsById,
    recommendVoice,
  } = options || {};
  const isFlat2000 = selectedOption?.baseDisplayValue?.includes("2000");
  return (
    <>
      <Accordion
        label={t("Onboarding.sections.rate-selection.yourRate")}
        className="mt-10"
      >
        {processedProductsById && (
          <Rates
            processedProductsById={processedProductsById}
            preSelectedProcessedProduct={preSelectedProcessedProduct}
            currentRate={currentRate}
            shouldShowEsimTrial={shouldShowEsimTrial}
          />
        )}
        {isFlat2000 && (
          <p className="flat-2000-hint">
            {t("translation:Onboarding.sections.rate-selection.flat2000Hint")}
          </p>
        )}
      </Accordion>
      {preSelectedProcessedProduct?.precharged && (
        <p className="font-x-small text-primary-100">
          {t(
            "translation:Onboarding.sections.rate-selection.prechargedRateHint",
          )}
        </p>
      )}
      {canBookVoiceOption && (
        <>
          <Accordion
            label={t("Onboarding.sections.rate-selection.unlimitedCalltime")}
            className="mt-10"
          >
            <VoiceOption
              recommendVoice={recommendVoice || false}
              prechargeVoice={prechargeVoice || false}
              currentVoice={currentVoiceOption}
              hasFreeTrial={shouldShowEsimTrial}
            />
            {!voiceIsSelected && (
              <p className="mt-4 font-x-small leading-6 text-secondary-100 md:mt-2">
                <b>{t("Onboarding.sections.rate-selection.hint")}</b>
                <Trans
                  t={t}
                  i18nKey="Onboarding.sections.rate-selection.numberPortingDisabledExplanation"
                  components={[<b key={1} />]}
                />
              </p>
            )}
          </Accordion>
        </>
      )}
    </>
  );
};
