import SmileyLoadingSpinner from "@/assets/SmileySpinner.svg?react";

export const FullScreenLoadingSpinner = ({ message }: { message: string }) => {
  return (
    <div className="fixed z-[99] inset-0 backdrop-blur-xl flex items-center justify-center h-screen">
      <div className="flex flex-col gap-8">
        <SmileyLoadingSpinner
          data-testid="spinner"
          className="animate-spin mx-auto"
        />
        <p className="font-semibold text-primary-100">{message}</p>
      </div>
    </div>
  );
};
