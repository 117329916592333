import "./SimVerification.scss";

import clsx from "clsx";
import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SimCard from "@/assets/icons/SimCard.svg?react";
import IccidPukHint from "@/assets/onboarding/sim-verification/IccidAndPuk.svg?react";
import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { IconTile } from "@/components/Onboarding/IconTile/IconTile";
import { Step } from "@/components/Onboarding/Steps/Step";
import { useHandleError } from "@/hooks/useHandleError";
import { useWizardBasePath } from "@/hooks/useWizard";
import { DataContext } from "@/provider/DataContextProvider";
import { useCheckSIM, useSetICCID } from "@/services/api";

import { HintOverlay } from "./HintOverlay/HintOverlay";

export type SimVerificationFormProps = {
  iccid: string;
  puk: string;
};

export const formatIccid = (input: string) => {
  const regex =
    /^(\d{6})\s?(\d{4})\s?(\d{4})\s?(\d{4})\s?(\d\d)$|^(\d{6})\s?(\d{4})\s?(\d{4})\s?(\d{4})(\d+)$|^(\d{6})\s?(\d{4})\s?(\d{4})(\d+)$|^(\d{6})\s?(\d{4})(\d+)$|^(\d{6})(\d*)?$/;
  const cardValue = input.match(regex);
  const formatted = cardValue
    ? cardValue
        .slice(1)
        .filter((x) => x)
        .join(" ")
    : input;

  return formatted.substring(0, 24);
};

export const SimVerification: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { dataContext, setDataContext } = useContext(DataContext);
  const basePath = useWizardBasePath();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleError = useHandleError();

  const {
    register,
    watch,
    resetField,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<SimVerificationFormProps>({
    mode: "onChange",
  });

  useEffect(() => {
    if (dataContext?.simVerification !== undefined) {
      if (dataContext.simVerification?.iccid !== "") {
        setValue("iccid", dataContext.simVerification.iccid, {
          shouldValidate: true,
        });
      }
      if (dataContext.simVerification?.puk !== "") {
        setValue("puk", dataContext.simVerification.puk, {
          shouldValidate: true,
        });
      }
    }
  }, [dataContext.simVerification, setValue, trigger]);

  const watchPUK = watch("puk");
  const watchIccid = watch("iccid");
  const shortenedIccid = (watchIccid && watchIccid.replaceAll(" ", "")) || "";
  const iccidIsValid = (v: string) =>
    v ? v.replaceAll(" ", "").length === 20 : false;

  const { data: sim, isFetching: isLoadingCheckSim } = useCheckSIM(
    shortenedIccid,
    {
      query: {
        enabled: iccidIsValid(watchIccid),
        // Clear the iccid field errors when validating again loading
        onSuccess: (sim) => {
          // If the puk is not mandatory for this sim, reset any remaining errors
          if (!sim.puk_mandatory) {
            resetField("puk");
          }
          if (sim?.message === "Sim is not available") {
            setError("iccid", {
              type: "iccidValidError",
            });
          } else {
            clearErrors();
          }
        },
      },
    },
  );

  const pukMandatory = sim?.puk_mandatory === true;
  const isValid =
    iccidIsValid(watchIccid) &&
    sim?.message === "Sim is available" &&
    (!pukMandatory || watchPUK?.length === 8);

  const { mutate: setICCID, isLoading: isSettingICCID } = useSetICCID({
    mutation: {
      onSuccess: () => {
        if (isValid !== false) {
          navigate(`${basePath}/2/`);
        }
      },
      onError: (error) => {
        if (
          typeof error.response?.data === "string" &&
          error.response?.data.includes("PUK doesn't match ICCID!")
        ) {
          setError("puk", {
            message: t(
              "Onboarding.sections.sim-verification.puk.validation.rejected",
            ),
            type: "validate",
          });
        } else {
          handleError(error);
        }
      },
    },
  });

  const saveAndNavigateBackward = async () => {
    setDataContext((prev) => ({
      ...prev,
      simVerification: { puk: watchPUK, iccid: watchIccid },
    }));
    navigate(`${basePath}/1/sim-selection`);
  };

  // Set ICCID and navigate to next step
  const saveAndNavigateForward = () => {
    setDataContext((prev) => ({
      ...prev,
      simVerification: { puk: watchPUK, iccid: watchIccid },
    }));
    if (isValid && dataContext.basket?.id) {
      setICCID({
        basketId: dataContext.basket.id,
        index: 0,
        params: {
          iccid: shortenedIccid,
          clientId: 1,
          ...(pukMandatory ? { expanded: false, puk: watchPUK } : {}),
        },
      });
    }
  };

  const continueIsEnabled = isValid && !isLoadingCheckSim;

  return (
    <Step
      id="onboarding-sim-verification"
      headline={t("Onboarding.sections.sim-selection.title")}
      subheadline={t("Onboarding.sections.sim-selection.subtitle")}
    >
      {(isLoadingCheckSim || isSettingICCID) && <LoadingSpinner />}
      <form onSubmit={handleSubmit(saveAndNavigateForward)}>
        <div className="content-wrapper">
          <div className="tile-and-inputs">
            <IconTile
              icon={<SimCard />}
              label={t("Tile.label.activateSim")}
              selected
            />
            <div className="h-12" />
            <div className="inputs">
              <FormField<SimVerificationFormProps>
                id="iccd-input"
                label={t("Onboarding.sections.sim-verification.iccid.label")}
                name="iccid"
                errors={errors}
                register={register}
                type="text"
                placeholder={t(
                  "Onboarding.sections.sim-verification.iccid.placeholder",
                )}
                options={{
                  required: t("label.validation.required"),
                  validate: (v: string) => {
                    if (sim?.message === "Sim is not available") {
                      return String(
                        t(
                          "Onboarding.sections.sim-verification.iccid.validation.invalid",
                        ),
                      );
                    }
                    return true;
                  },
                  pattern: {
                    // Valid is either 5 groups of digits.
                    value: /^\d{6}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{2}$/,
                    message: t(
                      "Onboarding.sections.sim-verification.iccid.validation.badFormat",
                    ),
                  },
                  onChange: (e: KeyboardEvent) => {
                    const input = e.target as HTMLInputElement;
                    // JavaScript regex engine constraints, splits ICCID into match groups
                    setValue("iccid", formatIccid(input.value));
                  },
                  iccidValidError: t(
                    "Onboarding.sections.sim-verification.iccid.validation.invalid",
                  ),
                }}
              >
                <button
                  onClick={() => setShowOverlay(!showOverlay)}
                  className="overlay-button"
                  type="button"
                  autoFocus={false}
                  tabIndex={-1}
                >
                  {t("Onboarding.sections.sim-verification.iccid.helpHint")}
                </button>
              </FormField>
              <FormField<SimVerificationFormProps>
                id="puk-input"
                label={t("Onboarding.sections.sim-verification.puk.label")}
                name="puk"
                errors={errors}
                register={register}
                type="text"
                placeholder={t(
                  "Onboarding.sections.sim-verification.puk.placeholder",
                )}
                options={{
                  required: pukMandatory,
                  disabled: !pukMandatory,
                  pattern: {
                    value: /^\d{8}$/,
                    message: t(
                      "Onboarding.sections.sim-verification.puk.validation.badFormat",
                    ),
                  },
                  onChange: (e: KeyboardEvent) => {
                    const input = e.target as HTMLInputElement;
                    let value = input.value;
                    if (value.length > 8) {
                      value = value.substring(0, 8);
                    }
                    setValue("puk", value);
                  },
                }}
              />
            </div>
          </div>
          <div className={clsx("hint-image", pukMandatory && "withPuk")}>
            <IccidPukHint />
          </div>
        </div>
        <div className="nav-button-container">
          <Button
            onClick={() => saveAndNavigateBackward()}
            className="accent inverted"
          >
            {t("Common.label.back")}
          </Button>
          <SubmitButton
            disabled={!continueIsEnabled}
            className="accent"
            label={t("Common.label.forward")}
          />
        </div>
      </form>
      <HintOverlay
        setVisible={setShowOverlay}
        visible={showOverlay}
        hint={
          <div className={clsx("hint-image", pukMandatory && "withPuk")}>
            <IccidPukHint />
          </div>
        }
      />
    </Step>
  );
};
