import "./Checkout.scss";

import { captureException, captureMessage, withScope } from "@sentry/react";
import clsx from "clsx";
import { useAtom } from "jotai";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, StyledLink } from "@/components/Interface/Button/Button";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { OnboardingDueNow } from "@/components/Onboarding/Steps/Checkout/OnboardingDueNow/OnboardingDueNow";
import { Step } from "@/components/Onboarding/Steps/Step";
import {
  PaymentMeanSection,
  PaymentProviderObject,
} from "@/components/Portal/Cockpit/DeviceManagement/Roaming/CheckoutRoamingOption/PaymentMeanSection/PaymentMeanSection";
import { getHeader } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/roamingUtils";
import { EsimJourneyCheckoutBlockedDialog } from "@/components/Portal/EsimJourneyCheckoutBlockedDialog";
import { LightboxWrapper } from "@/components/Portal/UserAccount/PaymentMethods/LightboxWrapper/LightboxWrapper";
import { useEsimJourneyBasket } from "@/hooks/useContinueEsimJourney";
import { useHandleCheckoutError } from "@/hooks/useHandleCheckoutError";
import { useHandleError } from "@/hooks/useHandleError";
import { useIsInsidePortal } from "@/hooks/useIsInsidePortal";
import { useIsOnboarding } from "@/hooks/useIsOnboarding";
import { transformPaymentMean } from "@/hooks/usePaymentMode";
import { useQueryParam } from "@/hooks/useQueryParam";
import { ErrorType } from "@/mutator/frontendApiInstance";
import { DataContext } from "@/provider/DataContextProvider";
import { FullScreenLoadingContext } from "@/provider/FullScreenLoadingProvider/FullScreenLoadingProvider";
import {
  getCustomer,
  useAddContract,
  useAddOption,
  useCreateTransaction,
  useGetCustomer,
  useGetPaymentMean,
  useGetSimCustomerActions,
  useGetUpdatedBasket,
  usePayTransaction,
} from "@/services/api";
import {
  BasketEntry,
  CreateTransactionDatatransOrderType,
  DatatransPaymentResponse,
  DatatransPaymentResponseStatus,
  SignUpResponse,
} from "@/services/model";
import { pushGoogleAnalyticsCheckout } from "@/utils/analytics/checkoutAnalytics";
import { basketIsUpdatedAtom } from "@/utils/atoms";
import {
  canAddESim,
  cannotAddEsimBecauseBillDunning,
  cannotAddEsimBecauseIDCheckOpen,
  cannotAddEsimBecauseReachedMaximumSims,
  canOnlyBookData,
} from "@/utils/customerUtils";
import {
  getEntry,
  hasBasketEntryWithVoiceOption,
} from "@/utils/dataContextHelpers";

import { AutomaticRenewal } from "./AutomaticRenewal/AutomaticRenewal";
import { DueRecurringWrappedCard } from "./DueRecurringWrappedCard/DueRecurringWrappedCard";
import { EsimHint } from "./EsimHint/EsimHint";
import { Summary } from "./Summary/Summary";
import { TermsAgreement } from "./TermsAgreement/TermsAgreement";

export interface FinalFormProps extends PaymentProviderObject {
  terms: boolean;
  reoccuringPayment: "yes" | "no" | null;
}

export const Checkout: FC = () => {
  useEsimJourneyBasket();

  const [basketIsUpdated, setBasketIsUpdated] = useAtom(basketIsUpdatedAtom);
  const [isSubmittingCheckout, setIsSubmittingCheckout] = useState(false);
  const { dataContext, setDataContext } = useContext(DataContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleError = useHandleError();
  const { setFullScreenLoading } = useContext(FullScreenLoadingContext);
  useEffect(() => {
    if (isSubmittingCheckout) {
      setFullScreenLoading(true, t("Onboarding.sections.checkout.loading"));
    }
  }, [setFullScreenLoading, t, isSubmittingCheckout]);

  const isInsidePortal = useIsInsidePortal();
  const isOnboarding = useIsOnboarding();
  const backLink = isInsidePortal ? "/portal/add-device/3" : "/onboarding/4";
  const hasPaymentSuccessParam = useQueryParam("payment")?.[0] === "success";
  const { data: customerData } = useGetCustomer();
  const canBookVoiceOption = !(customerData && canOnlyBookData(customerData));
  const entry = getEntry(dataContext) as BasketEntry;
  const isEsim = entry.esim;
  const hasVoiceOption = hasBasketEntryWithVoiceOption(dataContext);

  const {
    watch,
    register,
    formState: { errors },
    control,
  } = useForm<FinalFormProps>({
    defaultValues: { ...(dataContext.checkout || { reoccuringPayment: null }) },
  });
  const watchedReoccuringPayment = watch("reoccuringPayment");
  const watchedPaymentProvider = watch("paymentProvider");
  const watchedTerms = watch("terms");
  const hasAutomaticRenewal = watchedReoccuringPayment === "yes";

  const { isInvoice, isCreditCard, maskedCardNumberString, cardType } =
    transformPaymentMean(
      useGetPaymentMean({
        query: {
          enabled: !isOnboarding,
        },
      }).data,
    );

  const { data: simCustomerActions } = useGetSimCustomerActions();

  const oneTimeAmount = dataContext.basket?.total?.oneTime?.amount;
  const oneTimeAmountIsZero =
    oneTimeAmount !== undefined && oneTimeAmount === 0;

  const isReoccuringPaymentWithoutPaymentMean =
    hasAutomaticRenewal &&
    !watchedPaymentProvider &&
    !(isInvoice || isCreditCard);

  const isOneTimePaymentWithoutPaymentMean =
    !oneTimeAmountIsZero &&
    !watchedPaymentProvider &&
    !(isInvoice || isCreditCard);

  const isPrepaidWithAutoRenew =
    oneTimeAmountIsZero && isReoccuringPaymentWithoutPaymentMean;
  const needsEsimHint = isEsim && !isInsidePortal;
  useGetUpdatedBasket(
    dataContext.basket?.id || -1,
    { updateBasket: true },
    {
      query: {
        enabled: !!dataContext.basket?.id,
        onSuccess: (fetchedBasket) => {
          // We need to remove the voice option for users who are not allowed to
          // select it, but did so as anonymous users before logging in.
          if (!canBookVoiceOption && hasVoiceOption) {
            const { selectedOption } = dataContext;

            addOption({
              basketId: fetchedBasket.id as number,
              uuid: fetchedBasket.entries[0].uuid as string,
              params: {
                optionId: selectedOption?.data as number,
              },
            });
          } else {
            setDataContext((prev) => ({
              ...prev,
              basket: fetchedBasket,
            }));
            setBasketIsUpdated(true);
          }
        },
      },
    },
  );
  const { mutate: addOption } = useAddOption({
    mutation: {
      onSuccess: (data) => {
        setDataContext({
          ...dataContext,
          basket: data,
        });
        setBasketIsUpdated(true);
      },
    },
  });

  useHandleCheckoutError(oneTimeAmountIsZero);

  const handleSuccess = useCallback(async () => {
    // If we have a eSIM checkout...
    if (isEsim) {
      const customer = await getCustomer();
      // send Google Analytics tracking event for an eSIM purchase
      pushGoogleAnalyticsCheckout(dataContext, customer);
    }
    // Clear the basket and info entered during the onboarding
    // and reset the allowedMaxOnboardingStep, so the user cannot navigate
    // from step 1 to step 5 in the next onboarding process.
    setDataContext({ allowedMaxOnboardingStep: undefined });
    navigate("/portal");
    setFullScreenLoading(false);
  }, [isEsim, dataContext, navigate, setFullScreenLoading, setDataContext]);

  // If the user has just paid (query param is = success ), we need to handle the success
  useEffect(() => {
    if (!isSubmittingCheckout && hasPaymentSuccessParam) {
      setIsSubmittingCheckout(true); // To prevent double checkout on re-rendering
      handleSuccess();
    }
  }, [
    hasPaymentSuccessParam,
    dataContext?.basket,
    isSubmittingCheckout,
    handleSuccess,
  ]);

  const {
    mutate: createTransaction,
    data: datatransRequestDocument,
    isLoading: isCreatingTransaction,
  } = useCreateTransaction();

  // Both usePayTransaction and useAddContract share the same behavior onSuccess and onError
  const transactionMutationOptions = {
    onSuccess: (data: DatatransPaymentResponse | SignUpResponse) => {
      if ("signUpId" in data) {
        handleSuccess();
      } else {
        if (data.status === DatatransPaymentResponseStatus.OK) {
          handleSuccess();
        } else {
          setIsSubmittingCheckout(false);
          handleError(
            "Payment failure",
            `${t("Error.payment.fail")} ${t("Error.payment.pleaseCheckData")}`,
          );
          withScope(function (scope) {
            scope.setFingerprint(["/contract/serviceId/options"]);
            scope.setTag("section", isInsidePortal ? "portal" : "onboarding");
            scope.setTag("endpoint", "addOptionToContract");
            scope.setContext("paymentContext", {
              isInvoice,
              isCreditCard,
              maskedCardNumberString,
              cardType,
            });
            captureMessage("Payment failure");
          });
        }
      }
    },
    onError: (err: ErrorType<unknown>) => {
      setFullScreenLoading(false);
      // If it fails we report the error in sentry
      handleError(err);
      withScope(function (scope) {
        scope.setFingerprint(["/datatrans/pay"]);
        scope.setTag("section", isInsidePortal ? "portal" : "onboarding");
        scope.setTag("endpoint", "payTransaction");
        scope.setContext("paymentContext", {
          basketId: dataContext?.basket?.id,
          isInvoice,
          isCreditCard,
          maskedCardNumberString,
          cardType,
        });
        captureException(err);
      });
    },
  };

  // This is used to pay transactions with a saved credit card
  const { mutate: payTransaction } = usePayTransaction({
    mutation: transactionMutationOptions,
  });

  const {
    mutate: addContract,
    isLoading: isAddContractLoading,
    isSuccess: isAddContractDone,
  } = useAddContract({
    mutation: transactionMutationOptions,
  });
  const addContractCallback = useCallback(
    (basketId: number) =>
      addContract({
        data: {
          header: getHeader(),
          basketId,
        },
        params: { autoRenew: hasAutomaticRenewal },
      }),
    [addContract, hasAutomaticRenewal],
  );

  const handleSubmit = () => {
    const basketId = dataContext?.basket?.id;
    // If we don't have a basket id, we can't checkout.
    if (basketId === undefined) {
      captureMessage("Wanted to submit undefined basket id!");
      handleError("Wanted to submit undefined basket id!");
      return;
    }
    if (oneTimeAmount === undefined) {
      captureMessage("Wanted to charge undefined oneTime amount");
      handleError("Wanted to charge undefined oneTime amount");
      return;
    }
    // If we have any (could be zero) amount and are not already adding...
    if (!isSubmittingCheckout) {
      // Save current context
      setDataContext((prev) => ({
        ...prev,
        checkout: { reoccuringPayment: watchedReoccuringPayment },
      }));
      // If we have a saved payment mean or the amount is zero...
      if (
        (isInsidePortal && (isInvoice || isCreditCard)) ||
        (oneTimeAmountIsZero && !watchedPaymentProvider)
      ) {
        setIsSubmittingCheckout(true); // To prevent double checkout on re-rendering
        if (isInsidePortal && isCreditCard) {
          // We create the contract by paying the transaction
          payTransaction({
            params: {
              datatransOrderType:
                CreateTransactionDatatransOrderType.AddContractToCustomer,
              basketId,
              autorenew: hasAutomaticRenewal,
            },
          });
        }
        // Contract should be added directly if we are in the "add device checkout".
        // Or if we have a sumIsZero transaction WITHOUT specified paymentMean!
        else if (!isAddContractDone && !isAddContractLoading) {
          addContractCallback(basketId);
        }
      } else {
        // Else we create a transaction, which will in turn open the Lightbox, allow the user to enter payment information
        // and call addContract backend-side if the payment was successful.
        createTransaction({
          params: {
            datatransOrderType:
              CreateTransactionDatatransOrderType.AddContractToCustomer,
            basketId,
            autorenew: hasAutomaticRenewal,
          },
        });
      }
    }
  };

  // Disable continue if...
  const continueIsDisabled =
    (isEsim && !canAddESim(simCustomerActions) && !!isInsidePortal) ||
    isAddContractLoading ||
    isAddContractDone ||
    isSubmittingCheckout ||
    (isPrepaidWithAutoRenew
      ? watchedTerms === false
      : // the terms were not accepted...
        watchedTerms === false ||
        // or the user did not select a renewal radio button,
        // for whether they want to enable automatic renewal
        typeof watchedReoccuringPayment !== "string" ||
        // or they want to have automatic renewal but no payment mean yet
        isReoccuringPaymentWithoutPaymentMean ||
        // or they need to pay something right now and don't have a payment mean yet
        isOneTimePaymentWithoutPaymentMean);

  return (
    <Step
      id="onboarding-final"
      headline={t("Onboarding.sections.checkout.title")}
      subheadline={t(
        `Onboarding.sections.checkout.${
          isAddContractLoading || isAddContractDone || isSubmittingCheckout
            ? "subtitleWhileHandling"
            : "subtitle"
        }`,
      )}
      navigation={
        <div className="nav-button-container">
          <StyledLink to={backLink} target="_self" className="accent inverted">
            {t("Common.label.back")}
          </StyledLink>
          <Button
            dataTestid="continue-button"
            className="accent"
            disabled={continueIsDisabled}
            onClick={() => handleSubmit()}
          >
            {t("Common.label.complete")}
          </Button>
        </div>
      }
    >
      {isEsim &&
        simCustomerActions &&
        !canAddESim(simCustomerActions) &&
        !!isInsidePortal && (
          <EsimJourneyCheckoutBlockedDialog
            hasIDCheckPending={cannotAddEsimBecauseIDCheckOpen(
              simCustomerActions,
            )}
            hasReachedMaximumAmountOfSims={cannotAddEsimBecauseReachedMaximumSims(
              simCustomerActions,
            )}
            hasBillDunning={cannotAddEsimBecauseBillDunning(simCustomerActions)}
          />
        )}
      {isCreatingTransaction ||
      isAddContractLoading ||
      isAddContractDone ||
      isSubmittingCheckout ? (
        // Don't render checkout screen if we're still loading something or handling the successful payment,
        // as this leads to errors in the rendered children (e.g. PaymentMeanSection without a basket)
        <LoadingSpinner />
      ) : (
        <div
          id="checkout-layout"
          className={clsx(needsEsimHint && "withEsimHint")}
        >
          <Summary />
          <AutomaticRenewal register={register} errors={errors} />
          <section id="payment-section">
            {dataContext.basket !== undefined && basketIsUpdated ? (
              <>
                <PaymentMeanSection
                  isInvoice={isInvoice}
                  isCreditCard={isCreditCard}
                  cardType={cardType}
                  maskedCardNumberString={maskedCardNumberString}
                  control={control}
                  enableShowCurrent={!!isInsidePortal}
                  sumIsZero={oneTimeAmountIsZero}
                />
                <LightboxWrapper
                  paymentProvider={watchedPaymentProvider}
                  transactionId={datatransRequestDocument?.refNo}
                  sign={datatransRequestDocument?.sign}
                  enabled={datatransRequestDocument !== undefined}
                />
              </>
            ) : (
              <LoadingSpinner />
            )}
          </section>
          <div id="due-cards">
            <div id="due-now">
              <OnboardingDueNow />
            </div>
            {watchedReoccuringPayment === "yes" && (
              <div className="due-reoccuring">
                <DueRecurringWrappedCard />
              </div>
            )}
          </div>

          {needsEsimHint && <EsimHint />}
          <div id="terms-agreement">
            <TermsAgreement<FinalFormProps>
              register={register}
              errors={errors}
              name="terms"
            />
          </div>
        </div>
      )}
    </Step>
  );
};
