import { AxiosError } from "axios";

import { useLogoutOnTwoFactorError } from "@/components/Welcome/AuthenticatorSetup/authenticator.utils";
import type {
  useValidate,
  useValidateTwoFactorAuthentication,
} from "@/services/auth";

type useValidationMutationType =
  | typeof useValidateTwoFactorAuthentication
  | typeof useValidate;

interface MutationError {
  errorCode: number;
  message: string;
}

export const useValidateTwoFactorAppCode = <
  T extends useValidationMutationType,
>(
  setError: () => void,
  shouldLogout = false,
  useMutation: T,
) => {
  const logoutOnError = useLogoutOnTwoFactorError();

  const { isLoading, mutateAsync: validateTwoFactorAuth } = useMutation({
    mutation: {
      onError: (error: AxiosError<MutationError>) => {
        if (shouldLogout) logoutOnError(error);
        if (
          error.response?.status === 400 &&
          error.response.data?.errorCode === 402
        ) {
          setError();
        }
      },
    },
  });

  return {
    isLoading,
    validateTwoFactorAuth:
      validateTwoFactorAuth as ReturnType<T>["mutateAsync"],
  };
};
