import "./CockpitMenu.scss";

import clsx from "clsx";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { FCC } from "types";

import { ListViewIcon, TilesViewIcon } from "@/assets/icons/icons";
import { Filter } from "@/components/Portal/Cockpit/Filter/Filter";
import { isListViewAtom } from "@/utils/atoms";

export const CockpitMenu: FCC = () => {
  const [searchParams] = useSearchParams();
  const [isListView, setListView] = useAtom(isListViewAtom);
  const location = useLocation();
  const someTileInManagement = location.pathname.includes("manage");

  useEffect(() => {
    if (location.pathname.includes("cockpit/tiles")) {
      setListView(false);
    }
    if (location.pathname.includes("cockpit/list")) {
      setListView(true);
    }
  }, [location.pathname, setListView]);

  return (
    <div
      className={clsx(
        "cockpit-icons",
        someTileInManagement ? "opacity-50 z-[-100]" : "",
      )}
    >
      <Filter />
      <div className="toggle-icon-wrapper">
        <NavLink
          className={clsx(someTileInManagement && !isListView && "active")}
          to={`/portal/cockpit/tiles?${searchParams.toString()}`}
        >
          <button className="toggle-left">
            <TilesViewIcon />
            <span className="sr-only">Toggle tiles view</span>
          </button>
        </NavLink>
        <NavLink
          className={clsx(someTileInManagement && isListView && "active")}
          to={`/portal/cockpit/list?${searchParams.toString()}`}
        >
          <button className="toggle-right">
            <ListViewIcon />
            <span className="sr-only">Toggle list view</span>
          </button>
        </NavLink>
      </div>
    </div>
  );
};
