import { createInstance, InitOptions } from "i18next";
import LanguageDetector, {
  DetectorOptions,
} from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import de from "@/locales/de/translation.json";
import en from "@/locales/en/translation.json";
import fr from "@/locales/fr/translation.json";

import { env } from "./environmentHelpers";

const languageDetectionOptions: DetectorOptions = {
  // order and from where user language should be detected
  order: ["localStorage", "navigator"],

  // keys or params to lookup language from
  lookupLocalStorage: "i18nextLng",

  // cache user language on localStorage
  caches: ["localStorage"],
};

export const resources = {
  de,
  en,
  fr,
} as const;

export const initializationOptions: InitOptions = {
  detection: languageDetectionOptions,
  resources: {
    de,
    en,
    fr,
  },
  ns: ["translation", "portal"],
  fallbackNS: ["portal", "translation"],
  returnNull: false,
  nonExplicitSupportedLngs: true, // fallback from de-CH to de
  fallbackLng: "en",
  debug: env.isDev,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
};

let i18n: ReturnType<typeof createInstance>;

export const initTranslation = (forceNormalInitialization = false) => {
  // do not initialize singleton twice except in tests that `forceNormalInitialization`
  if (!i18n || forceNormalInitialization) {
    i18n = createInstance();
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(
        env.isTest && !forceNormalInitialization
          ? {
              lng: "ci",
              ns: ["translation", "portal"],
              interpolation: {
                escapeValue: false, // not needed for react!!
              },
              resources: { en: { translationsNS: {} } },
            }
          : initializationOptions,
      );
  }

  return i18n;
};
