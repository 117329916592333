import { Menu } from "@headlessui/react";
import clsx from "clsx";

/**
 * This component is used to display a link in a menu.
 * It has to be wrapped in the Menu.Items component of a Menu component.
 *
 * @example
 * ```jsx
 * <Menu>
 *  <Menu.Items>
 *  <MenuItemLink
 *  link="https://www.google.com"
 *  label="Google"
 *  />
 *  </Menu.Items>
 *  </Menu>
 *  ```
 *  @param link - The link that is opened when the link is clicked.
 *  @param label - The label of the link. This has to be unique.
 *  @returns A link that can be used in a menu.
 **/
export const MenuItemLink = ({
  label,
  link,
}: {
  link: string;
  label: string;
}) => {
  return (
    <Menu.Item key={label}>
      {({ active }) => (
        <a
          href={link}
          className={clsx(
            "hover:text-secondary-100 hover:underline pb-4 md:pb-2 block",
            active && "underline font-semibold",
          )}
          data-testid={label}
          target="_blank"
          rel="noreferrer"
        >
          {label}
        </a>
      )}
    </Menu.Item>
  );
};
