import { FormOfAddresses } from "@/components/Onboarding/Steps/PersonalDetails/PersonalDetailsFormFields/PersonalDetailsFormFields";
import { mobileAbos } from "@/components/Onboarding/Steps/RateSelection/rateSelection.utils";
import { DataContextInterface } from "@/provider/DataContextProvider";
import { BasicCustomerData } from "@/services/model";
import { getCountryAlpha2FromAlpha3 } from "@/utils/countries";

export const pushGoogleAnalyticsCheckout = (
  dataContext: DataContextInterface,
  customer: BasicCustomerData,
) => {
  if (window.dataLayer) {
    // For Google Analytics the birthdate needs to be formatted like YYYYMMDD
    const birthdate = dataContext.personalDetails?.dateOfBirth
      ? new Date(dataContext.personalDetails.dateOfBirth)
      : undefined;
    const birthdateFormatted = birthdate
      ? birthdate.getFullYear().toString() +
        (birthdate.getMonth() + 1 < 10 // result of getMonth() is zero-based, so we need to increment it
          ? "0" + (birthdate.getMonth() + 1)
          : birthdate.getMonth() + 1) +
        (birthdate.getDate() < 10
          ? "0" + birthdate.getDate()
          : birthdate.getDate())
      : "";
    const productName =
      dataContext.selectedOption?.shortName +
      (dataContext.basket?.entries[0].options?.some(
        (option) => option.product.id === dataContext.selectedOption?.voice,
      )
        ? " | Voice"
        : "");

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "purchase_connect",
      ecommerce: {
        purchase: {
          actionField: {
            id: dataContext.basket?.customer, // Kundennummer (nicht die technische ID)
            affiliation: "Online Store",
            revenue: dataContext.basket?.total?.oneTime?.amount, // Total transaction value (incl. tax and shipping)
            tax: dataContext.basket?.total?.oneTime?.taxAmounts
              ?.map((taxAmount) => taxAmount.amount ?? 0)
              .reduce((totalTaxes, taxAmount) => totalTaxes + taxAmount),
            shipping: "0.00",
            coupon: "",
          },
          products: [
            {
              id: dataContext.basket?.entries[0].product.id,
              name: productName,
              price: dataContext.basket?.total?.oneTime?.amount,
              brand: "Digital Republic",
              category: "eSIM",
              variant: "",
              quantity: 1,
              coupon: "",
            },
          ],
        },
        currency: "CHF",
        items: [
          {
            // all same as 'products' but different field names (in order to match main site DL format)
            item_id: dataContext.basket?.entries[0].product.id,
            item_name: productName,
            item_brand: "Digital Republic",
            price: dataContext.basket?.total?.oneTime?.amount,
            item_category: "eSIM",
            quantity: 1,
            google_business_vertical: "retail",
            ...getMParameterForFlatMobile(dataContext),
          },
        ],
        user_data: {
          first_name: dataContext.personalDetails?.firstName,
          last_name: dataContext.personalDetails?.lastName,
          city: dataContext.personalDetails?.city,
          postal_code: dataContext.personalDetails?.zip,
          country: getCountryAlpha2FromAlpha3(
            dataContext.personalDetails?.country.ALPHA3,
          ), // country als Alpha2
          phone_number: dataContext.personalDetails?.phone,
          email_address: customer?.email,
          birth_date: birthdateFormatted, // YYYYMMDD
          gender:
            dataContext.personalDetails?.formOfAddress === FormOfAddresses.mrs
              ? "f"
              : dataContext.personalDetails?.formOfAddress ===
                  FormOfAddresses.mr
                ? "m"
                : "",
        },
      },
    });
  }
};

const getMParameterForFlatMobile = (dataContext: DataContextInterface) => {
  const selectedOption =
    dataContext.basket?.entries?.[0]?.options?.[0]?.product?.id || 0;
  const isMobileAbo = mobileAbos.includes(`${selectedOption}`);
  const priceMap = {
    131601: 348.55,
    131611: 120,
  };
  if (isMobileAbo) {
    return {
      M: priceMap[selectedOption as keyof typeof priceMap],
    };
  } else {
    return {};
  }
};
