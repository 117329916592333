import { FieldErrors, Path, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { useGetCustomer } from "@/services/api";

export const NameConfirmationField = <T extends { name: string }>({
  register,
  errors,
}: {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
}) => {
  const { t } = useTranslation();
  const { data: customer } = useGetCustomer();

  const validateCurrentName = (value: string) => {
    const cleanValue = value.replaceAll(" ", "").toLowerCase();
    return cleanValue !==
      `${customer?.firstName}${customer?.lastName}`
        .replaceAll(" ", "")
        .toLowerCase()
      ? t("portal:authenticator.backupCodes.nameWrong")
      : true;
  };

  return (
    <label className="flex flex-col gap-2">
      <h3 className="text-lg text-primary-100">
        {t("portal:authenticator.backupCodes.steps.2.title")}
      </h3>
      <span className="sr-only">
        {t("portal:authenticator.backupCodes.steps.2.placeholder", {
          name: `${customer?.firstName} ${customer?.lastName}`,
        })}
      </span>
      <FormField
        id="name"
        placeholder={t("portal:authenticator.backupCodes.steps.2.placeholder", {
          name: `${customer?.firstName} ${customer?.lastName}`,
        })}
        register={register}
        errors={errors}
        name={"name" as Path<T>}
        options={{
          required: t("portal:authenticator.backupCodes.nameRequired"),
          validate: validateCurrentName,
        }}
      />
    </label>
  );
};
