import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FCC } from "types";

import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { useAlert } from "@/hooks/useAlert";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { startNetworkReset } from "@/services/api";
import { AlertTypes } from "@/utils/atoms";

export const NetworkResetConfirmation: FCC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.details.sections.details.networkReset",
  });
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [simId] = useSimIdFromQueryParam();

  return (
    <div className="max-w-[560px]">
      <h3 className="mb-6 text-secondary-100">{t("title")}</h3>
      <h3 className="font-semibold mb-4">{t("label")}</h3>
      <Trans
        t={t}
        components={[<p key="pragraph" className="pt-4" />]}
        i18nKey="confirmationText"
      />
      <ul className="list-disc pl-7">
        <li className="mt-4">{t("recommendations.wait")}</li>
        <li className="mt-4">{t("recommendations.remove")}</li>
        <li className="mt-4">{t("recommendations.reboot")}</li>
        <li className="mt-4">{t("recommendations.rebootWatchSim")}</li>
      </ul>
      <ConfirmationButtonGroup
        successText={t("portal:common.buttons.confirm")}
        successAction={() => {
          startNetworkReset(parseInt(simId))
            .then((response) => {
              if (!response.resetAllowed) {
                showAlert({
                  type: AlertTypes.error,
                  text: t("error"),
                });
              } else {
                showAlert({
                  type: AlertTypes.success,
                  text: t("success"),
                });
              }
            })
            .then(() => {
              navigate(`../?simId=${simId}`, { replace: true });
            })
            .catch(() => {
              showAlert({
                type: AlertTypes.error,
                text: t("error"),
              });
            });
        }}
        cancelText={t("portal:common.buttons.cancel")}
        cancelAction={() => {
          navigate(`../?simId=${simId}`, { replace: true });
        }}
      />
    </div>
  );
};
