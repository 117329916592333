import { AxiosError } from "axios";

export const errorShouldBeRetried: (error: AxiosError<any>) => boolean = (
  error,
) => {
  console.log(
    error.response?.data?.errorCode === 403 &&
      !(error.response?.data?.message || "").includes(
        "Die eingegebene Rufnummer ist ungültig",
      ),
  );
  return (
    (error.response?.status === 400 &&
      error.response?.data?.errorCode === 403 &&
      !(error.response?.data?.message || "").includes(
        "Die eingegebene Rufnummer ist ungültig",
      )) ||
    (error.response?.status === 500 &&
      error.response?.data.includes(
        "TwoFactorAuthentication change in progress",
      ))
  );
};
