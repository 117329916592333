import { captureMessage } from "@sentry/react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Interface/Button/Button";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { ListBox } from "@/components/Interface/FormFields/ListBox/ListBox";
import { OrSeparator } from "@/components/Layout/OrSeparator";
import { OPTIONS } from "@/components/Onboarding/Onboarding.constants";
import { useAllSims } from "@/hooks/useAllSims";
import {
  DataContext,
  DataContextInterface,
} from "@/provider/DataContextProvider";
import {
  addBasketEntry,
  saveBasket,
  setDeviceByUuid,
  updateSim,
} from "@/services/api";
import { SimCardDetail } from "@/services/model";
import { canBeChangedToFlatMobile } from "@/utils/deviceUtils";

export const FlatMobileDeviceToUpgradeSelection = () => {
  const { t } = useTranslation();
  const [selectedDevice, setSelectedDevice] = useState<
    SimCardDetail | undefined
  >(undefined);

  const { setDataContext } = useContext(DataContext);

  const simCards = useAllSims();
  // We only show sim cards that actually are able to change their tariff
  const filteredSimCards = simCards.filter((sim) =>
    canBeChangedToFlatMobile(sim),
  );

  const navigate = useNavigate();
  const redirectToDeviceSelection = async () => {
    if (wantsToRegisterNewDevice) {
      saveBasket({})
        .then((basket) => {
          setDataContext((prev: DataContextInterface) => ({
            ...prev,
            basket,
            allowedMaxOnboardingStep: 2,
          }));
          if (!basket.id) {
            console.error("Did not get basket id");
            return;
          }
          addBasicProductToBasket(basket.id).then((basket) => {
            const entry = basket?.entries?.[0];
            if (entry.uuid && basket.id) {
              setDeviceByUuid(basket.id, entry?.uuid, 1).then(() => {
                navigate("/portal/add-device/2/device-type?device=1");
              });
            } else {
              console.error("Did not get basket entry uuid");
            }
          });
        })
        .catch((error) => {
          captureMessage("Error while creating basket in flat mobile upsell");
        });
    } else {
      if (!selectedDevice?.device || !selectedDevice.simId) return;
      // Change sim device to smartphone
      updateSim(
        {
          device_type: 1,
          sim_alias: selectedDevice.alias,
        },
        { simId: selectedDevice.simId },
      )
        .then(() => {
          // Navigate to tariff change
          navigate(
            `/portal/cockpit/manage/tariff/change?simId=${selectedDevice.simId}`,
          );
        })
        .catch((error) => {
          captureMessage(
            "Error while updating sim device in flat mobile upsell",
          );
        });
    }
  };

  type RegisterNewDeviceForm = { registerNewDevice: boolean };
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<RegisterNewDeviceForm>({
    defaultValues: {
      registerNewDevice: false,
    },
  });
  const wantsToRegisterNewDevice = watch("registerNewDevice");
  const isContinueDisabled = !(selectedDevice || wantsToRegisterNewDevice);

  return (
    <div className="flex gap-1 flex-col">
      <p className="mb-2 block">
        <Trans
          i18nKey="portal:layout.tiles.upsellSim.chooseDeviceDescription"
          t={t}
        />
      </p>
      <h3 className="text-primary-100 block">
        {t("portal:layout.tiles.upsellSim.chooseDevice")}
      </h3>

      <ListBox<SimCardDetail | undefined>
        className="max-w-full mb-2"
        selected={wantsToRegisterNewDevice ? undefined : selectedDevice}
        disabled={wantsToRegisterNewDevice}
        setSelected={setSelectedDevice}
        displayValue={(value) => value?.alias || ""}
        availableValues={filteredSimCards}
        placeholder={t("portal:layout.tiles.upsellSim.selectDevicePlaceholder")}
      />
      <OrSeparator />

      <Checkbox<RegisterNewDeviceForm>
        name="registerNewDevice"
        errors={errors}
        register={register}
        label={t("portal:layout.tiles.upsellSim.registerNewDevice")}
      />

      <div className="flex justify-end">
        <Button
          onClick={redirectToDeviceSelection}
          disabled={isContinueDisabled}
          className="accent"
        >
          {t("portal:common.buttons.continue")}
        </Button>
      </div>
    </div>
  );
};

const addBasicProductToBasket = async (basketId: number) =>
  addBasketEntry(basketId, OPTIONS.BASE_OPTION, { isESIM: false });
