import "./TableRow.scss";

import { FCC } from "types";

//this is just the row component for the both tables
export const TableRow: FCC = ({ children }) => {
  return (
    <tr data-testid="table-row" className="row-layout font-small">
      {children}
    </tr>
  );
};
