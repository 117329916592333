import "./Portal.scss";

import { setUser } from "@sentry/react";
import { FC, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { AgentLoginBanner } from "@/components/Interface/Alert/AgentLoginBanner";
import { Background } from "@/components/Layout/Background/Background";
import { Footer } from "@/components/Layout/Footer/Footer";
import { PortalHeader } from "@/components/Portal/PortalHeader/PortalHeader";
import { PortalSidemenu } from "@/components/Portal/PortalSidemenu/PortalSidemenu";
import { useIsAgentLogin } from "@/hooks/useIsAgentLogin.ts";
import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { useGetCustomer } from "@/services/api";

// Layout and authentication wrapper for the portal.
export const Portal: FC = () => {
  const [isAnonymousUser] = useIsAnonymousUser();

  const navigate = useNavigate();
  if (isAnonymousUser) {
    navigate("/onboarding/2", { replace: true });
  }

  const { data: customerData } = useGetCustomer(undefined, {
    // For this check, we can safely cache it for 5 minutes.
    query: {
      enabled: !isAnonymousUser,
      refetchOnMount: "always",
    },
  });

  useEffect(() => {
    setUser({ id: customerData?.id?.toString() });
  }, [customerData]);

  const isAgentLogin = useIsAgentLogin();

  if (customerData?.onboardingFinished === false) {
    return <Navigate to="/onboarding/" replace />;
  } else {
    return (
      <Background>
        <PortalHeader />
        {isAgentLogin && <AgentLoginBanner />}
        <main id="portal-wrapper">
          <PortalSidemenu />
          <Outlet />
        </main>
        <Footer className="has-wave" />
      </Background>
    );
  }
};
