import { OrSeparator } from "@/components/Layout/OrSeparator";
import { TwoFactorAppAlternatives } from "@/components/Portal/UserAccount/Security/Change/TwoFactorAppAlternatives";
import { TwoFactorQRCodeSection } from "@/components/Portal/UserAccount/Security/Change/TwoFactorQrCodeSection";
import { TwoFactorChangeTitle } from "@/components/Portal/UserAccount/Security/TwoFactorChangeTitle";

export const TwoFactorChangeAppView = () => (
  <div className="max-w-[560px] flex flex-col gap-6">
    <TwoFactorChangeTitle />
    <TwoFactorQRCodeSection />
    <OrSeparator />
    <TwoFactorAppAlternatives />
  </div>
);
