import { useGetCustomer } from "@/services/api";

import { useIsAnonymousUser } from "./useIsAnonymousUser";

// We can use this hook to get the customer data in the onboarding process
// where the customer might be an anonymous user and we cannot use the useGetCustomer hook.
export const useCustomerInOnboarding = () => {
  const [isAnonymousUser] = useIsAnonymousUser();
  const { data: customerData } = useGetCustomer(undefined, {
    query: {
      enabled: !isAnonymousUser,
    },
  });
  return customerData;
};
