import "./Referral.scss";

import qs from "qs";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Email,
  Facebook,
  LinkedIn,
  MenuPartner,
  Telegram,
  Twitter,
  WhatsApp,
} from "@/assets/icons/icons";
import { ClipboardField } from "@/components/Interface/ClipboardFiled/ClipboardField";
import { Modal } from "@/components/Interface/Modal/Modal";
import { PortalHeading } from "@/components/Portal/PortalHeading/PortalHeading";
import { useGetCustomer } from "@/services/api";

const useGetLink = () => {
  const { i18n } = useTranslation();
  const { data: customer } = useGetCustomer();

  const currentLang = (i18n?.resolvedLanguage || "en").slice(0, 2) as
    | "en"
    | "de"
    | "fr";
  const customerName = customer?.firstName;
  const referrals = customer?.campaignReferrals;
  const referralCode = referrals
    ?.filter((id) => id?.campaignIdentifier === "TREES")
    .map((id) => id.referralCode);

  return {
    data: `https://digitalrepublic.ch/${currentLang}/r1?ref=${customerName}-${referralCode}`,
  };
};

const useGetLinks = () => {
  const { t } = useTranslation("portal", { keyPrefix: "referral" });
  const { data: link } = useGetLink();

  const createURL = (baseURL: string, params: any) =>
    `${baseURL}?${qs.stringify(params)}`;

  const title = t("shareTitle");
  const text = t("shareContent");

  return [
    {
      icon: <Facebook />,
      link: createURL("https://facebook.com/sharer/sharer.php", { u: link }),
    },
    {
      icon: <Twitter />,
      link: createURL("https://twitter.com/intent/tweet", {
        url: link,
        text,
      }),
    },
    {
      icon: <LinkedIn />,
      link: createURL("https://www.linkedin.com/shareArticle", {
        mini: true,
        url: link,
        source: link,
        title: text,
        summary: text,
      }),
    },
    {
      icon: <WhatsApp />,
      link: createURL("whatsapp://send", { text: `${text} ${link}` }),
    },
    {
      icon: <Telegram />,
      link: createURL("https://telegram.me/share/url", { url: link, text }),
    },
    {
      icon: <Email />,
      link: createURL("mailto:", {
        subject: title,
        body: `${text}\n\n${link}`,
      }),
    },
  ];
};

export const Referral = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("portal", { keyPrefix: "referral" });
  const { data: customer } = useGetCustomer();
  const links = useGetLinks();

  const customerName = customer?.firstName;

  return (
    <>
      <PortalHeading heading={customerName ? `${customerName},` : ""}>
        <p className="mb-4 mt-8 md:mt-10 md:mb-14">{t("spreadTheWord")}</p>
      </PortalHeading>
      <div
        id="user-watermark-top"
        className="tile-watermark-container !-right-8 md:hidden"
      >
        <MenuPartner className="w-full h-full" />
      </div>
      <div id="referral-wrapper" className="tile-width-wrapper">
        <div className="tile-watermark-container">
          <MenuPartner />
        </div>
        <div className="management-tile active">
          <div className="max-w-[400px]">
            <h2 className="text-secondary-100 md:text-4xl md:leading-[1.33]">
              {t("youRecommend")}
            </h2>
            <p className="mt-12">{t("invite")}</p>
            <p className="mt-6">
              <Trans
                t={t}
                i18nKey="plant"
                components={[
                  <button
                    key={0}
                    className="font-semibold underline text-primary-100"
                    onClick={() => setIsModalOpen(true)}
                  />,
                ]}
              />
            </p>
            <p className="mt-12">{t("copyLink")}</p>
            <div className="flex mt-6">
              <ClipboardField
                content={useGetLink().data}
                successMessage={t("linkCopied")}
                errorMessage={t("linkCopyError")}
              />
            </div>
            <p className="mt-12">{t("share")}</p>
            <div className="flex gap-4 mt-6">
              {links.map(({ icon, link }) => (
                <a
                  key={link}
                  className="flex justify-center items-center w-8 h-8 rounded-full bg-primary-100"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          className="max-h-[500px] pb-0 md:pb-0"
          title={t("modal.title")}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="mb-4 font-semibold text-primary-100">
            {t("modal.firstSection.title")}
          </div>
          <p className="text-base">{t("modal.firstSection.text")}</p>
          <div className="mt-6 mb-4 font-semibold text-primary-100">
            {t("modal.secondSection.title")}
          </div>
          <p className="pb-6 text-base">
            <Trans
              t={t}
              i18nKey="modal.secondSection.text"
              components={[
                <a
                  key={0}
                  className="underline text-primary-100"
                  href="https://www.edenprojects.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </p>
        </Modal>
      )}
    </>
  );
};
