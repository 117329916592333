import "./Button.scss";

import clsx from "clsx";
import React, { MouseEventHandler } from "react";
import { Link, LinkProps } from "react-router-dom";
import { FCC } from "types";

type ButtonType = "submit" | "button";

type ButtonProps = {
  onClick?: MouseEventHandler;
  className?: string;
  disabled?: boolean;
  dataTestid?: string;
  type?: ButtonType;
};

export const Button: FCC<ButtonProps> = ({
  onClick = () => null,
  className = "",
  children,
  disabled = false,
  dataTestid,
  type,
}) => {
  return (
    <div id="click-button-wrapper" className="button-wrapper font-semibold">
      <button
        type={type}
        tabIndex={disabled ? -1 : 0}
        data-testid={dataTestid}
        onClick={disabled ? () => null : onClick}
        className={clsx(className, disabled && "disabled")}
      >
        {children}
      </button>
    </div>
  );
};

type AnchorProps = {
  href?: string;
  disabled?: boolean;
  className?: string;
  label?: string;
  target?: "_self" | "_blank" | "_parent" | "_top";
};
export const Anchor = ({
  href = "/",
  disabled = false,
  className = "",
  label = "",
  target = "_blank",
}: AnchorProps) => {
  return (
    <div id="navigate-button-wrapper" className="button-wrapper font-semibold">
      <a
        aria-label={label}
        href={href}
        className={clsx(className, disabled && "disabled")}
        target={target}
        rel="noreferrer"
      >
        {label}
      </a>
    </div>
  );
};

export const StyledLink: FCC<
  LinkProps &
    React.RefAttributes<HTMLAnchorElement> & {
      disabled?: boolean;
      "data-testid"?: string;
    }
> = (props) =>
  props.disabled ? (
    <div className="fakebutton-wrapper button-wrapper font-semibold">
      <div
        data-testid={props["data-testid"] ?? undefined}
        className="fakebutton accent w-full mt-14 disabled"
      >
        {props.children}
      </div>
    </div>
  ) : (
    <div id="styled-link-wrapper" className="button-wrapper font-semibold">
      <Link {...props}>{props.children}</Link>
    </div>
  );

export const SubmitButton = ({
  label,
  className,
  disabled,
  dataTestid,
}: {
  label: string;
  className?: string;
  disabled?: boolean;
  dataTestid?: string;
}) => (
  <div
    id="submit-button-wrapper"
    data-testid={dataTestid}
    className="button-wrapper font-semibold"
  >
    <button type="submit" className={clsx(className, disabled && "disabled")}>
      {label}
    </button>
  </div>
);
