import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ListBoxMultiple } from "@/components/Interface/FormFields/ListBoxMultiple/ListBoxMultiple";
import { FilterDialogForm } from "@/components/Portal/Cockpit/Filter/Filter";
import { GetSimListDevicesItem } from "@/services/model";
import { deviceValueToNameMap, getIconForDevice } from "@/utils/deviceUtils";

interface Props {
  control: Control<FilterDialogForm, "devices">;
}

export const DeviceTypeFilter = ({ control }: Props) => {
  const { t } = useTranslation();

  const renderOptionWithIcon = (
    deviceType: GetSimListDevicesItem,
    selected: boolean,
  ) => (
    <>
      <div className={`text text-primary-100 ${selected ? "selected" : ""}`}>
        <div className="icon my-auto">
          {getIconForDevice(deviceValueToNameMap[deviceType])}
        </div>
        {t(`Common.devices.${deviceType}`)}
      </div>
    </>
  );

  return (
    <div>
      <Controller<FilterDialogForm>
        control={control}
        name="devices"
        render={({ field: { onChange, value } }) => (
          <ListBoxMultiple<GetSimListDevicesItem>
            selected={
              (typeof value === "string"
                ? [value]
                : value) as FilterDialogForm["devices"]
            }
            setSelected={onChange}
            displayValue={(value) => t(`Common.devices.${value}`)}
            availableValues={
              Object.keys(GetSimListDevicesItem) as GetSimListDevicesItem[]
            }
            placeholder={t("cockpit.filter.dropdowns.deviceType")}
            renderOption={renderOptionWithIcon}
            itemLabel={t("cockpit.filter.dropdowns.device")}
          />
        )}
      />
    </div>
  );
};
