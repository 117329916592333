import "./CircleActiveToDone.css";
import "./WizardStep.scss";

import clsx from "clsx";
import { TFuncKey } from "i18next";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FCC } from "types";

import CircleActiveToDone from "@/assets/step/ActiveToDone.svg?react";
import CircleActive from "@/assets/step/CircleActive.svg?react";
import { Section } from "@/components/Onboarding/Onboarding.constants";
import { StepsProps } from "@/components/Onboarding/Wizard/Steps";

// TODO: Split up into separate files

type State = "active" | "done" | "inactive";

const CircleInactive: FC = () => <span className="circle" />;

interface CircleProps {
  state: State;
}

const Circle: FC<CircleProps> = ({ state }) => {
  switch (state) {
    case "active":
      return <CircleActive />;
    case "done":
      return <CircleActiveToDone />;
    default:
      return <CircleInactive />;
  }
};

interface LineProps {
  progress: number;
  property: PropertyKey;
}

const Line: FC<LineProps> = ({ progress, property }) => (
  <>
    {/* Line consists of two spans, one with progression, one without.*/}
    <span
      className="line progress"
      style={{ [property]: `${progress * 100}%` }}
    />
    <span
      className="line remaining"
      style={{ [property]: `${100 - progress * 100}%` }}
    />
  </>
);

interface StepProps extends Section, Omit<StepsProps, "sections"> {
  step: number;
  last?: boolean;
  title: TFuncKey;
}

/**
 * A single step in the wizard. Also used in the progress bar of the number porting.
 *
 * TODO: Refactor into two components, one for the number porting, one for the wizard.
 */
export const WizardStep: FC<StepProps> = ({
  title,
  step,
  substeps,
  currentStep,
  currentSubStep,
  property = "width",
  last,
}) => {
  const { t } = useTranslation();

  const currentProgress = substeps[currentSubStep || ""];
  const [state, progress] = useMemo<readonly [State, number]>(() => {
    if (currentProgress && currentStep === step) {
      // If we have a subroute, this step is active with progress...
      return ["active", currentProgress];
    } else if (step < currentStep) {
      // this step appears before the current step, it is done ...
      return ["done", 1];
    }

    return ["inactive", 0];
  }, [currentProgress, currentStep, step]);

  const stepContainerProps = {
    className: clsx("step-wrapper", state, last && "end"),
    "data-testid": `step-${step}`,
  };
  const stepPath = `${step}/${Object.keys(substeps)?.[0]}`;
  const StepContainer: FCC = ({ children }) =>
    state === "done" && property === "width" ? (
      // If the state is done, it is a link to the completed step
      <Link {...stepContainerProps} to={stepPath}>
        {children}
      </Link>
    ) : (
      // Otherwise, the step is just a div.
      <div {...stepContainerProps}>{children}</div>
    );

  return (
    <StepContainer>
      <div className="span-wrapper">
        <Circle state={state} />
        {!last && <Line progress={progress} property={property} />}
      </div>
      <span className="step-name" data-testid={`step-${step}-name`}>
        {`${t(title as TFuncKey<"translation">)}`}
      </span>
    </StepContainer>
  );
};
