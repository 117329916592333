import "./MenuItem.scss";

import clsx from "clsx";
import { Link, useMatch, useNavigate } from "react-router-dom";

export const MenuItem = ({
  icon,
  target,
  text,
  onClick,
}: {
  icon: JSX.Element;
  text: string;
  target: string;
  onClick?: () => void;
}) => {
  // Append * to get match on redirecting routes
  const match = useMatch(target + "/*");
  const navigate = useNavigate();
  return (
    <li className={clsx("navigation-item", !!match && "active")}>
      {
        // Links should not have onClick side-effects, if we have a onClick function
        // we show a button
        onClick !== undefined ? (
          <button
            data-testid={`menuitem-${text}`}
            onClick={() => {
              onClick();
              navigate(target);
            }}
          >
            {icon}
            <span className="link">{text}</span>
          </button>
        ) : (
          <Link data-testid={`menuitem-${text}`} to={target}>
            {icon}
            <span className="link">{text}</span>
          </Link>
        )
      }
    </li>
  );
};
