import "@/components/Portal/Cockpit/SpeedAndData/SpeedAndData.scss";
import "@/components/Portal/Cockpit/Tiles/InactiveTile/InactiveSimTile.scss";

import { useTranslation } from "react-i18next";
import { DeviceFC } from "types";

import { ConnectionIcon, SpeedIcon } from "@/assets/icons/icons";
import { SpeedAndData } from "@/components/Portal/Cockpit/SpeedAndData/SpeedAndData";
import {
  DeviceTile,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import { SimCardDetailSimStatusReason } from "@/services/model";
import {
  getFlatNameFromDevice,
  getIconForDevice,
  getSpeedFromLocalFlatSpeed,
} from "@/utils/deviceUtils";

export const PendingSimTile: DeviceFC = ({ device }) => {
  const { t } = useTranslation("portal");
  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );
  const icon = getIconForDevice(device.device?.description);
  return (
    <DeviceTile
      navigationTarget={`../manage?simId=${device.simId}`}
      icon={icon}
      dataTestid="pending-sim"
      className={"pending-wrapper"}
    >
      <div id="inactive-wrapper">
        <h2 className="tile-title">{title}</h2>
        <h3 className="tile-subtitle">{getFlatNameFromDevice(device)}</h3>
        {device.localFlatSpeed ? (
          <SpeedAndData
            speed={getSpeedFromLocalFlatSpeed(device.localFlatSpeed)}
          />
        ) : (
          <div className="device-info">
            <div className="icon-row mr-4">
              <SpeedIcon />
              <span> -- </span>
            </div>
            <div className="icon-row">
              <ConnectionIcon />
              <span> -- </span>
            </div>
          </div>
        )}
      </div>
      <div className="usage-info mt-16">
        <div id="bar-wrapper">
          <div id="background-bar" />
        </div>
        <div className="mt-2">
          <span className="text-base text-primary-50 leading-normal text-left">
            {device.simStatusReason ===
            SimCardDetailSimStatusReason.LOCK_REQUESTED
              ? t("layout.tiles.pending.missingPaymentMethod")
              : t("layout.tiles.pending.inProcess")}
          </span>
        </div>
      </div>
    </DeviceTile>
  );
};
