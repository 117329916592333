import "./BillListHeader.scss";

import { compact } from "lodash";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import IndeterminateCheckbox from "@/assets/ux/IndeterminateCheckbox.svg?react";
import SelectedCheckbox from "@/assets/ux/SelectedCheckbox.svg?react";
import UnselectedCheckbox from "@/assets/ux/UnselectedCheckbox.svg?react";
import { TableHeader } from "@/components/Layout/Table/TableHeader/TableHeader";
import { TableSortButton } from "@/components/Layout/Table/TableSortButton/TableSortButton";
import { BillListBillIdForm } from "@/components/Portal/Bills/BillList";

const enum availableSortFields {
  DATE = "Datum",
  NAME = "Abrechnungsmonat",
  NUMBER = "Rechnungsnummer",
  AMOUNT = "Betrag",
  STATUS = "Status",
}

// this is the table header row for the invoices list
export const BillListHeader = ({
  currentPageBillIds,
}: {
  currentPageBillIds: number[];
}) => {
  const { t } = useTranslation("portal");

  const { watch, setValue } = useFormContext<BillListBillIdForm>();

  const currentSelectedBillIds = compact(Object.values(watch("billIds")));
  // Count the selections on the current table page
  const currentPageSelectionCount = currentSelectedBillIds.filter((id) =>
    currentPageBillIds.includes(Number(id)),
  ).length;

  const headerCheckboxClick = () => {
    if (currentPageSelectionCount === currentPageBillIds.length) {
      currentPageBillIds.forEach((billId) =>
        // @ts-ignore - This works because billIds is an object, which we can index like this
        setValue(`billIds.${billId}`, false),
      );
    } else {
      currentPageBillIds.forEach((billId) =>
        // @ts-ignore - This works because billIds is an object, which we can index like this
        setValue(`billIds.${billId}`, billId),
      );
    }
  };

  return (
    <TableHeader>
      <th className="bill-selection">
        <button
          title={t(
            currentPageSelectionCount > 0
              ? "bills.list.tableHeaders.deselectAllBills"
              : "bills.list.tableHeaders.selectAllBills",
          )}
          data-testid="bill-list-header-checkbox"
          onClick={headerCheckboxClick}
          className="bill-selector"
        >
          {currentPageSelectionCount > 0 ? (
            <div className="has-selection-container">
              {
                // Are ALL bills of the current page selected?
                currentPageSelectionCount === currentPageBillIds.length ? (
                  <SelectedCheckbox className="checkbox" />
                ) : (
                  <IndeterminateCheckbox className="checkbox" />
                )
              }
            </div>
          ) : (
            <UnselectedCheckbox className="checkbox" />
          )}
        </button>
      </th>
      <th>
        <TableSortButton
          title={t("bills.list.tableHeaders.date")}
          sortField={availableSortFields.DATE}
        />
      </th>
      <th>
        <TableSortButton
          title={t("bills.list.tableHeaders.invoiceFor")}
          sortField={availableSortFields.NAME}
        />
      </th>
      <th>
        <TableSortButton
          title={t("bills.list.tableHeaders.number")}
          sortField={availableSortFields.NUMBER}
        />
      </th>
      <th>
        <TableSortButton
          title={t("bills.list.tableHeaders.amount")}
          sortField={availableSortFields.AMOUNT}
        />
      </th>
      <th>
        <TableSortButton
          title={t("bills.list.tableHeaders.status")}
          sortField={availableSortFields.STATUS}
        />
      </th>
      <th>{t("bills.list.tableHeaders.open")}</th>
    </TableHeader>
  );
};
