import { useAtomValue } from "jotai";

import { useIsAgentLogin } from "@/hooks/useIsAgentLogin.ts";
import { needsIDCheck } from "@/hooks/useNeedsIDCheck.ts";
import { useGetCustomer, useGetIdentificationStatus } from "@/services/api.ts";
import { BasicCustomerDataTwoFactorMethod } from "@/services/model";
import { hasChosenStandardLoginAtom } from "@/utils/atoms.ts";

/**
 * Check if the user should skip the authenticator setup based on their onboarding status and two-factor configuration.
 * @returns true if the user should skip the authenticator setup, null while loading.
 */
export const useShouldSkipAuthenticatorSetup = (): boolean | null => {
  const { data: customerData } = useGetCustomer();
  const { data: identificationStatus } = useGetIdentificationStatus();
  const isAgentLogin = useIsAgentLogin();
  const hasChosenStandardLogin = useAtomValue(hasChosenStandardLoginAtom);

  if (!identificationStatus || !customerData) {
    return null;
  }
  const needsIdCheck = needsIDCheck(identificationStatus);

  return (
    customerData?.twoFactorMethod !==
      BasicCustomerDataTwoFactorMethod.STANDARD ||
    needsIdCheck ||
    hasChosenStandardLogin ||
    isAgentLogin
  );
};
