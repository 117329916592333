import clsx from "clsx";
import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { useDebounce } from "@/hooks/useDebounce";
import { checkCscLogin } from "@/services/api";
import {
  BasicCustomerDataTwoFactorMethod,
  CheckCscLoginResponseCheckCscLoginStatus,
} from "@/services/model";
import { FORGOT_PASSWORD_URL } from "@/utils/apiUrls";
import { accessTokenAtom } from "@/utils/atoms";
import { emailRegex, passwordRegex } from "@/utils/customerUtils";

type AuthFormFieldsProps = {
  isExistingAccount: boolean | null;
  setIsExistingAccount: React.Dispatch<React.SetStateAction<boolean | null>>;
  setTwoFactorMethod: React.Dispatch<
    React.SetStateAction<BasicCustomerDataTwoFactorMethod | null>
  >;
};

export type AuthFormFieldsType = {
  email: string;
  password: string;
  passwordRepeat: string;
};

export const AuthFormFields = ({
  isExistingAccount,
  setIsExistingAccount,
  setTwoFactorMethod,
}: AuthFormFieldsProps) => {
  const { t } = useTranslation();
  // Register form with email using react-hook-form
  const {
    register,
    formState: { isValidating, errors },
    watch,
  } = useFormContext<AuthFormFieldsType>();

  const accessToken = useAtomValue(accessTokenAtom);

  const emailHasErrors = !!errors.email;
  const email = !isValidating && !emailHasErrors && watch("email");
  const [debouncedEmail, isDebouncing] = useDebounce(email, 500);

  useEffect(() => {
    if (emailHasErrors) setIsExistingAccount(null);
  }, [emailHasErrors, setIsExistingAccount]);

  // Use debouncedEmail in useEffect to check if email is already in use
  useEffect(() => {
    if (debouncedEmail) {
      // Username is the url encoded email value
      checkCscLogin(debouncedEmail).then((res) => {
        if (
          res.checkCscLoginStatus ===
          CheckCscLoginResponseCheckCscLoginStatus.login_available
        ) {
          setIsExistingAccount(false);
        } else if (
          res.checkCscLoginStatus ===
          CheckCscLoginResponseCheckCscLoginStatus.login_exists
        ) {
          setIsExistingAccount(true);
        }
        if (
          res.method &&
          res.method !== BasicCustomerDataTwoFactorMethod.STANDARD
        ) {
          setTwoFactorMethod(res.method);
        }
      });
    }
  }, [debouncedEmail, setIsExistingAccount, accessToken, setTwoFactorMethod]);

  return (
    <div className="max-w-[472px] grid gap-y-6">
      <FormField
        className={clsx(
          isDebouncing &&
            "[&>.relative>input]:bg-gray-50 [&>.relative>input]:animate-pulse [&>.relative>input]:opacity-50",
        )}
        id="new-email-input"
        label={t("user-account.personalDetails.loginData.email")}
        autocomplete="email"
        name="email"
        errors={errors}
        register={register}
        type="email"
        placeholder={t(
          "translation:Onboarding.sections.personal-details.form.placeholder.email",
        )}
        options={{
          required: t("translation:label.validation.required"),
          pattern: {
            value: emailRegex,
            message: t("translation:label.validation.email"),
          },
          maxLength: 50,
          backendError: t(
            "user-account.personalDetails.loginData.emailInUseError",
          ),
          invalidEmailError: t(
            "user-account.personalDetails.loginData.emailInvalid",
          ),
        }}
      />
      <div>
        <FormField
          label={t("user-account.personalDetails.loginData.password")}
          id="password-input"
          autocomplete="current-password"
          name="password"
          errors={errors}
          register={register}
          type="password"
          placeholder={t(
            "translation:Onboarding.sections.personal-details.form.placeholder.password",
          )}
          options={{
            disabled: isExistingAccount === null,
            required: t("translation:label.validation.required"),
            ...(isExistingAccount === false && {
              pattern: {
                message: t("translation:label.validation.password"),
                value: passwordRegex,
              },
            }),
            backendError: t(
              "user-account.personalDetails.loginData.passwordError",
            ),
          }}
        />

        {isExistingAccount && (
          <a
            aria-label="Forgot password?"
            tabIndex={0}
            href={FORGOT_PASSWORD_URL.toString()}
            className="text-sm font-semibold text-primary-100 active:underline focus-within:underline focus-visible:underline focus:underline hover:underline mt-2 block"
            target="_blank"
            rel="noreferrer"
          >
            {t("user-account.personalDetails.loginData.forgotPassword")}
          </a>
        )}
      </div>
      {!isExistingAccount && (
        <FormField
          label={t("user-account.personalDetails.loginData.passwordRepeat")}
          id="password-repeat-input"
          autocomplete="repeat-password"
          name="passwordRepeat"
          errors={errors}
          register={register}
          type="password"
          placeholder={t(
            "translation:Onboarding.sections.personal-details.form.placeholder.password",
          )}
          options={{
            disabled: isExistingAccount === null,
            required: t("translation:label.validation.required"),
            validate: {
              match: (v) =>
                v === watch("password") ||
                t("user-account.personalDetails.loginData.passwordNoMatch"),
            },
            backendError: t(
              "user-account.personalDetails.loginData.passwordError",
            ),
          }}
        />
      )}
    </div>
  );
};
