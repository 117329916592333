import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ArrowRight } from "@/assets/icons/icons";
import { Modal } from "@/components/Interface/Modal/Modal";
import { DeviceTile } from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";

import { FlatMobileDeviceToUpgradeSelection } from "./FlatMobileDeviceToUpgradeSelection";

export const FlatMobileUpsellCTA = () => {
  const { t } = useTranslation();

  const [showDeviceSelection, setShowDeviceSelection] = useState(false);

  return (
    <button className="h-fit" onClick={() => setShowDeviceSelection(true)}>
      {showDeviceSelection && (
        <Modal
          title={t("portal:layout.tiles.upsellSim.modalTitle")}
          onClose={() => setShowDeviceSelection(false)}
        >
          <FlatMobileDeviceToUpgradeSelection />
        </Modal>
      )}
      <DeviceTile dataTestid="upsell-tile">
        <div className="upsell-wrapper">
          <p className="upsell-claim">
            {t("portal:layout.tiles.upsellSim.flatMobileClaim")}
          </p>
          <h2 className="text-primary-100 mt-6">
            {t("portal:layout.tiles.upsellSim.flatMobileUpsellCTA")}
          </h2>
        </div>
        <div className="button-wrapper">
          <div className="button">
            <span className="manage">
              {t("portal:layout.tiles.upsellSim.mobileAboCtaButton")}
            </span>
            <ArrowRight />
          </div>
        </div>
      </DeviceTile>
    </button>
  );
};
