import * as Sentry from "@sentry/react";

import { env } from "./environmentHelpers";

export const initializeSentry = () =>
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment:
      // Are we building for prod or qa?
      env.isProd ? "production" : "qa",
    tracesSampleRate: 0.0,
    release: env.releaseNumber,
    beforeSend: (event) => {
      // Filter the event for axios 401 errors
      if (
        event.exception?.values?.[0]?.value ===
        "Request failed with status code 401"
      ) {
        return null;
      }
      // Filter email from url of GET requests to checkCscLogin,
      // schema is /frontend-api-v2/customer/checkCscLogin/foo@example.com
      if (
        event.request?.url?.includes("/frontend-api-v2/customer/checkCscLogin")
      ) {
        event.request.url = event.request.url.replace(
          /\/frontend-api-v2\/customer\/checkCscLogin\/[^/]+/,
          "/frontend-api-v2/customer/checkCscLogin/REDACTED",
        );
      }

      return event;
    },
  });
