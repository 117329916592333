import { useLayoutEffect, useState } from "react";

import { DesktopSplashScreen } from "@/components/Layout/DesktopSplashScreen/DesktopSplashScreen";
import { MobileSplashScreen } from "@/components/Layout/MobileSplashScreen/MobileSplashScreen";

/**
 * # SplashScreens
 * This component wraps all the splash screens that are displayed on the first load.
 * It also uses the `useEffect` hook to set a timeout that will hide the splash screen.
 **/
export const SplashScreens = () => {
  const [showTransition, setShowTransition] = useState(false);

  useLayoutEffect(() => {
    setShowTransition(true);
    const timeoutID = window.setTimeout(() => {
      setShowTransition(false);
    }, 2500);

    return () => window.clearTimeout(timeoutID);
  }, []);

  return (
    <>
      <DesktopSplashScreen showTransition={showTransition} />
      <MobileSplashScreen showTransition={showTransition} />
    </>
  );
};

export interface SplashScreenInterface {
  showTransition: boolean;
}
