import "@/components/Portal/UserAccount/PersonalDetails/PersonalDetails.scss";

import { FCC } from "types";

import { CompanyData } from "@/components/Portal/UserAccount/PersonalDetails/CompanyData/CompanyData";
import { PersonalData } from "@/components/Portal/UserAccount/PersonalDetails/PersonalData/PersonalData";

export const PersonalDetails: FCC = () => {
  return (
    <section id="personal-details">
      <CompanyData />
      <PersonalData />
    </section>
  );
};
