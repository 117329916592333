import "./Table.scss";

import { Transition } from "@headlessui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { Pagination } from "@/components/Interface/Pagination/Pagination";
import { FadeOverlayContainer } from "@/components/Layout/FadeOverlayContainer/FadeOverlayContainer";

interface Props {
  headline: string;
  paginationPages: number;
  noRows?: boolean;
}

export const Table: FCC<Props> = ({
  children,
  headline,
  paginationPages,
  noRows = false,
}) => {
  const { t } = useTranslation("portal");

  return (
    <Transition
      className="table-box"
      data-testid="table-box"
      appear={true}
      show={true}
      enter="transition-opacity ease-linear duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="table-shadow-box">
        <h2 className="table-box-header">{headline}</h2>
        {/* Does not accept a class name - since it gets modified programmatically */}
        <FadeOverlayContainer>
          <table className="table">{children}</table>
        </FadeOverlayContainer>
        {noRows && (
          <div className="text-base text-center font-semibold mt-20 text-primary-50 w-full">
            {t("cockpit.deviceList.noSearchResults")}
          </div>
        )}
      </div>
      {paginationPages > 1 ? (
        <Pagination paginationPages={paginationPages} />
      ) : (
        <div className="pb-10" />
      )}
    </Transition>
  );
};
