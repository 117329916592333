import "@/components/Welcome/WelcomeHeader/WelcomeHeader.scss";

import Logo from "@/assets/welcome/logo.svg?react";
import { FCC } from "@/types";

export const WelcomeHeader: FCC = ({ children }) => (
  <header id="welcome-header" data-testid="header">
    <div id="welcome-logo">
      <Logo />
    </div>
    {children}
  </header>
);
