import { atom, unstable_createStore } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { SimTransferStates } from "@/hooks/useSimTransferStateInfo";
import {
  Product,
  SimCardDetail,
  SubscriberOrderResponse,
} from "@/services/model";

export const store = unstable_createStore();

export const accessTokenAtom = atomWithStorage<string | null>(
  "accessToken",
  null,
);
export const refreshTokenAtom = atomWithStorage<string | null>(
  "refreshToken",
  null,
);
export const verifierAtom = atomWithStorage<string | undefined>(
  "verifier",
  undefined,
);

export const destinationAtom = atomWithStorage<string | undefined>(
  "destination",
  undefined,
);

export const managedUnscannedDevicesAtom = atomWithStorage<number[]>(
  "managedUnscannedDevices",
  [],
);
export const tariffCheckoutPaymentPersistenceAtom = atomWithStorage<
  | {
      product?: Product;
      subscriberOrderResponse?: SubscriberOrderResponse;
      simDetail?: SimCardDetail;
    }
  | undefined
>("tariffCheckoutPaymentPersistence", undefined);

export const portalTariffProductIDAtom = atom<number | undefined>(undefined);

export enum AlertTypes {
  "error",
  "info",
  "success",
  "warning",
}

export interface AlertInterface {
  type: AlertTypes;
  title?: string;
  text: string;
}

export const alertListAtom = atom<AlertInterface[]>([]);

export type UserAccountState = {
  editingMail: boolean;
  editingPassword: boolean;
  editingPersonalData: boolean;
  editingPayment: boolean;
  editingBillingInfo: boolean;
  editingSecurity: boolean;
};
export const userAccountStateAtomInitialState: UserAccountState = {
  editingMail: false,
  editingPassword: false,
  editingPersonalData: false,
  editingPayment: false,
  editingBillingInfo: false,
  editingSecurity: false,
};
export const userAccountStateAtom = atom<UserAccountState>(
  userAccountStateAtomInitialState,
);

export const isEditingSimDetailsAtom = atom<boolean>(false);

export const isListViewAtom = atomWithStorage<boolean | undefined>(
  "listView",
  false,
);

export const basketIsUpdatedAtom = atom<boolean>(false);

export const esimTransferTypeAtom = atom<SimTransferStates>({});

export const esimJourneyBasketIdAtom = atomWithStorage<number | null>(
  "esimJourneyBasketId",
  null,
);

export const showsSessionExpiredModalAtom = atom<boolean>(false);

export const twoFactorBackupCodesAtom = atom<number[]>([]);

export const phoneNumberAtom = atom<string>("");

export const hasChosenStandardLoginAtom = atomWithStorage<boolean>(
  "hasChosenStandardLogin",
  false,
);
