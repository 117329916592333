import React, { useState } from "react";
import { FieldValues } from "react-hook-form";

import { FormField } from "@/components/Interface/FormFields/FormField/FormField.tsx";
import { FormFieldProps } from "@/components/Interface/FormFields/FormField/FormField.utils.ts";

export interface FormFieldWithHintProps<TFormValues extends FieldValues>
  extends FormFieldProps<TFormValues> {
  hint?: string;
}

export const FormFieldWithHint = <TFormValues extends object>(
  props: React.PropsWithChildren<FormFieldWithHintProps<TFormValues>>,
) => {
  const [selected, setSelected] = useState(false);

  return (
    <div className="form-field-container">
      <FormField
        {...props}
        options={{
          ...props.options,
          onBlur: props.options?.readOnly
            ? undefined
            : () => setSelected(false),
        }}
        // Disabled fields should not be focusable
        {...(props.options?.readOnly
          ? undefined
          : { onFocus: () => setSelected(true) })}
      >
        {props.children}
      </FormField>

      {props.hint && selected && !props.errors[props.name]?.message && (
        <span className="hint">{props.hint}</span>
      )}
    </div>
  );
};
