import { useLayoutEffect, useState } from "react";

/**
 * This hook can be used to listen for the resizing of the window. It will
 * return the current height and width of the screen.
 */
export const useWindowResize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};
