import { Charge, LookupValue } from "@/services/model";

import { log } from "./log.ts";

export type availableCurrencies = "CHF";
export const getCurrencyFromLookupValue = (
  currency: LookupValue | undefined,
): availableCurrencies => {
  switch (currency?.id) {
    case 1:
      return "CHF";
    default:
      log(`Could not getCurrencyFromLookupValue: ${JSON.stringify(currency)}`);
      return "CHF";
  }
};

export const getChargeString = (charge: Charge | undefined) => {
  if (charge?.amount != null) {
    return getCurrency(
      charge.amount,
      getCurrencyFromLookupValue(charge.currency),
    );
  }
};

export const getCurrency = (val: number, currency: string) => {
  return new Intl.NumberFormat("de-CH", { style: "currency", currency })
    .format(val)
    .toString();
};

export const getDateString = (date: Date) => {
  return date.toLocaleDateString("de-CH", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};
