import { useTranslation } from "react-i18next";
import { DeviceFC } from "types";

import { IconSecurity } from "@/assets/icons/icons";
import {
  DeviceTile,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import { getIconForDevice } from "@/utils/deviceUtils";

export const SuspendedSimTile: DeviceFC = ({ device }) => {
  const { t } = useTranslation("portal");
  const icon = getIconForDevice(device.device?.description);
  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );

  return (
    <DeviceTile dataTestid="suspended-sim" icon={icon}>
      <h2 data-testid="tile-title" className="tile-title highlight">
        {title}
      </h2>
      <div className="flex flex-row gap-4">
        <div>
          <h3 className="tile-subtitle highlight">
            {t("layout.tiles.suspendedSim.simSuspended")}
          </h3>
          <div className="mt-4 font-x-small" data-testid="blocked-message">
            {t("layout.tiles.suspendedSim.yourSimSuspended")}
          </div>
        </div>
        <div>
          <IconSecurity />
        </div>
      </div>
    </DeviceTile>
  );
};
