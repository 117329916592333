import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Info } from "@/assets/icons/icons";

// this is a banner component, which renders permantly fixed at the top of the page
export const AgentLoginBanner: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="fixed z-[100] h-10 w-full top-0 left-0 right-0 bg-primary-25">
      <div className="flex flex-row py-2">
        <div className="flex flex-row m-auto text-primary-100">
          <Info className="w-6 h-6 mr-2" />
          <p>{t("portal:common.alerts.agentLogin")}</p>
        </div>
      </div>
    </div>
  );
};
