export const ErrorMessage = ({
  name,
  message,
}: {
  name: string;
  message?: string;
}) => {
  return message ? (
    <p
      data-testid={`${name}-alert`}
      role="alert"
      className="!text-sm !text-secondary-100"
    >
      {message}
    </p>
  ) : null;
};
